import React from "react";
import "./semiCircleProgressBar.css";

const SemiCircleProgressBar = ({
    progress,
    children,
    width,
    height,
    colorLow="#fb425c",
    colorMedium="#fff127",
    colorHigh="#51fd6d",
    classColor,
}) => {
    const progresso = progress > 100 ? 100 : progress;
    const radius = Math.min(width, height) / 2 - 6; // Raio ajustado com base no tamanho
    const circumference = 2 * Math.PI * radius; // Circunferência completa
    const arcLength = (240 / 360) * circumference; // Comprimento do arco de 240° (representando 100% do progresso)
    const strokeDashoffset = arcLength - (progresso / 100) * arcLength; // Offset proporcional ao progresso

    const strokeColor = progresso < 30 ? colorLow : progresso >= 30 && progresso <= 80 ? colorMedium : colorHigh;

    return (
        <div className="semi-circle-progress-container" style={{ width, height }}>
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                {/* Fundo (arco completo com 240° de preenchimento) */}
                <circle
                    cx={width / 2}
                    cy={height / 2}
                    r={radius}
                    fill="none"
                    stroke="#e6e6e6"
                    strokeDasharray={`${arcLength} ${circumference}`}
                    strokeDashoffset={0}
                    transform={`rotate(-10 ${width / 2} ${height / 2})`} // Rotaciona para posicionar no topo
                />
                {/* Progresso (preenchendo parte do arco superior) */}
                <circle
                    cx={width / 2}
                    cy={height / 2}
                    r={radius}
                    fill="none"
                    stroke={strokeColor}
                    strokeDasharray={`${arcLength} ${circumference}`}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                    transform={`rotate(-10 ${width / 2} ${height / 2})`} // Rotaciona para alinhar ao fundo
                />
            </svg>
            {/* Texto no centro */}
            <div className={`progress-text text-${classColor}`}>{children}</div>
        </div>
    );
};

export { SemiCircleProgressBar };
