import React from "react";

export default (props) => {
    return (
        <div
            className="info-box"
            style={{
                ...props.style,
                minHeight: props.minHeight || 70,
                padding: 0,
                backgroundColor: props.twoColors ? "#fff" : props.backgroundColor || "#BA0000",
                color: props.twoColors ? "#222" : "#fff",
                margin: 5,
            }}
            onClick={props.children && props.children[1] ? () => ({}) : props.onClick}
        >
            {props.icon && (
                <span
                    className="info-box-icon"
                    style={{
                        height: props.minHeight || 70,
                        width: 70,
                        backgroundColor: props.twoColors ? props.twoColors : props.backgroundColor ? props.backgroundColor + "EE" : "#C60000EE",
                        boxShadow: "#200 1px 1px 3px",
                        color: "#fff",
                    }}
                >
                    <i className={props.icon} aria-hidden="true"></i>
                </span>
            )}
            {props.children ? (
                <span
                    className="info-box-icon"
                    style={{
                        backgroundColor: props.twoColors ? props.twoColors : props.backgroundColor ? props.backgroundColor + "EE" : "#C60000EE",
                        boxShadow: "#200 1px 1px 3px",
                    }}
                >
                    {props.children[0] || props.children}
                </span>
            ) : null}
            <div
                className="info-box-content"
                style={{
                    overflowWrap: "break-word",
                    fontWeight: props.textBold ? "bold" : null,
                }}
            >
                <span style={{ marginBottom: 5, fontSize: "0.95rem" }}>{props.text}</span>
                {props.childrenColumn ? (
                    <div className="d-flex flex-column">
                        <div style={{ width: "100%" }}>
                            <strong>{props.number}</strong>
                        </div>
                        <div className="d-flex align-items-center justify-content-end" style={{ width: "100%", fontSize: "1rem" }}>
                            {props.children ? props.children[1] : null}
                            <div
                                className="px-2"
                                onClick={props.children && props.children[1] ? props.onClick : () => ({})} //se tiver um filho que é Select (monit Solar), só chama onclick no checkbox, senao chama no card inteiro
                            >
                                <div style={{ cursor: "pointer" }}> {props.onClick ? props.checked ? <i className="fas fa-check" /> : <i className="far fa-square" /> : null}</div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex">
                        <div style={{ width: (props.children && props.children[1]) || props.onClick ? "20%" : "100%" }}>
                            <strong>{props.number}</strong>
                        </div>
                        <div className="d-flex align-items-center justify-content-end" style={{ width: "80%", fontSize: "1rem" }}>
                            {props.children ? props.children[1] : null}
                            <div style={{ cursor: "pointer" }}>{props.onClick ? props.checked ? <i className="fas fa-check" /> : <i className="far fa-square" /> : null}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
