import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";
import confirmDialog from "../common/dialog/confirmDialog";
import { buscarSessao } from "../auth/authActions";

export function setModoTela(modo, registro = {}) {
    return {
        type: "USUARIO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}
export function initForm(registro = {}) {
    return [initialize("usuarioForm", registro)];
}

export function getLista() {
    return (dispatch, getState) => {
        const usuarioLogado = getState().auth.usuarioLogado;
        if (!usuarioLogado) return {};

        const queryParam = usuarioLogado.acesso_cliente ? `?id_cliente=${usuarioLogado.id_cliente}` : "";
        const request = axios.get(`${consts.API_URL}/usuario${queryParam}`);

        dispatch({
            type: "USUARIO_LISTADO",
            payload: request,
        });
    };
}

export function setPermissao(registro) {
    return (dispatch, getState) => {
        if (registro.id) {
            dispatch(setAguardando(true));
            axios
                .post(`${consts.API_URL}/usuario/permissao`, {
                    ...registro,
                })
                .then((resp) => {
                    dispatch([{ type: "USER_FETCHED", payload: resp.data }]);
                    dispatch(buscarSessao());
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                })
                .finally(() => dispatch(setAguardando(false)));
        }
    };
}

export function setPermissaoUnidadeConsumidora({ unidades_consumidoras_usuario, id_usuario }) {
    return async (dispatch, getState) => {
        try {
            if (!getState().auth.usuarioLogado) return {};

            const id_usuario_logado = getState().auth.usuarioLogado.id;
            dispatch(setAguardando(true));
            await axios.post(`${consts.API_URL}/usuario/permissaoUnidadeConsumidora`, {
                unidades_consumidoras_usuario,
                id_usuario,
                id_usuario_logado,
            });
            setSuccess("Operação Realizada com sucesso.");
            dispatch(getLista());
            dispatch(setModoTela("lista"));
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function setAguardando(valor) {
    return {
        type: "USUARIO_SET_AGUARDANDO",
        payload: valor,
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: "USUARIO_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaCliente() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};
            
            const request = axios.get(`${consts.API_URL}/cliente/listarSelect?id_usuario_logado=${usuarioLogado.id}`);

            dispatch({
                type: "USUARIO_CLIENTE_LISTADO",
                payload: request,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export function getTotalUsuarios() {
    const request = axios.get(`${consts.API_URL}/usuario/totalUsuarios`);
    return {
        type: "TOTAL_USUARIOS",
        payload: request,
    };
}

export function getLogados() {
    const request = axios.get(`${consts.API_URL}/usuario/listarLogados`);
    return {
        type: "LOGADOS_USUARIOS",
        payload: request,
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        if (!registro.id) {
            axios
                .post(`${consts.API_URL}/usuario`, {
                    ...registro,
                })
                .then((resp) => {
                    if (registro.usuario_implantacao) {
                        const permissoesUsuarioImplantacao = {
                            id: resp.data.id,
                            acesso_cadastro_cliente_adicionar: true,
                            acesso_cadastro_cliente_editar: true,
                            acesso_cadastro_cliente_excluir: true,
                            acesso_cadastro_cliente_visualizar: true,
                            acesso_fatura_editar: true,
                            acesso_fatura_editar_fechado: true,
                        };
                        dispatch(setPermissao(permissoesUsuarioImplantacao));
                    }

                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());

                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        } else {
            confirmDialog("Deseja realmente alterar?", () => {
                axios
                    .put(`${consts.API_URL}/usuario`, {
                        ...registro,
                    })
                    .then((resp) => {
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getLista());
                        dispatch(setModoTela("lista"));
                    })
                    .catch((e) => {
                        setErroAPI(e);
                    });
            });
        }
    };
}

export function setEmitirAlertaSonoro() {
    return (dispatch, getState) => {
        const alteracaoConfirmada = getState().auth.usuarioLogado.emitir_alerta_sonoro
            ? window.confirm("Deseja realmente desativar o alerta sonoro?")
            : window.confirm("Deseja realmente ativar o alerta sonoro?");
        if (alteracaoConfirmada)
            axios
                .patch(`${consts.API_URL}/usuario/emitirAlertaSonoro/${getState().auth.usuarioLogado.id}`, {
                    emitir_alerta_sonoro: !getState().auth.usuarioLogado.emitir_alerta_sonoro,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(buscarSessao());
                })
                .catch((e) => {
                    setErroAPI(e);
                });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        confirmDialog("Deseja realmente excluir?", () => {
            axios
                .delete(`${consts.API_URL}/usuario?id=${registro.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}
