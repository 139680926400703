import axios from "axios";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";

//actions em uso
/**  setModoTela,
    setFiltro,
    setAguardando,
    getLista,
    getListaCliente,
    getListaUnidadeConsumidora,
    gerarRelatorioMensal,
    gerarRelatorioSolarMensal,
    enviarRelatorio,
    getDadosRelatorioSafra, */

export function setModoTela(modo, registro = {}) {
    return {
        type: "FATURA_RELATORIO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setFiltro(filtro) {
    return {
        type: "FATURA_RELATORIO_FILTRO",
        payload: filtro,
    };
}

export function setAguardando(aguardando) {
    return {
        type: "FATURA_RELATORIO_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function gerarRelatorioMensal(registro) {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            dispatch(setAguardando(true));

            const filtro = getState().faturaRelatorio.filtro;

            const mesAno = `${filtro.mes}_${filtro.ano}`;
            if (!mesAno) {
                setError("Selecione o ano e o mês");
                return {};
            }

            // Prefeitura Municipal De Santiago
            if (registro.id_cliente == 58) {
                const resp = await axios.get(
                    `${consts.API_URL}/relatorioMensalGrupoB?id_usuario_logado=${usuarioLogado.id}&id_cliente=${registro.id_cliente}&nome_cliente=${registro.nome_cliente}&competencia=${mesAno}&resumido=${registro.relatorio_resumido}`
                );

                if (resp.data && resp.data.arquivo_pdf && resp.data.nomePdf) {
                    dispatch(setArquivoPDF(resp.data.arquivo_pdf, resp.data.nomePdf));
                }
            } else {
                const resp = await axios.get(
                    `${consts.API_URL}/relatorioMensal?id_usuario_logado=${usuarioLogado.id}&id_cliente=${registro.id_cliente || null}&nome_cliente=${
                        registro.nome_cliente || null
                    }&competencia=${mesAno}&resumido=${registro.relatorio_resumido}&id_tipo_contrato_demanda=1`,
                    { timeout: 60000 } //id_tipo_contrato_demanda 1 = fora ponta, 2 ponta, 3 geracao
                );

                if (resp.data && resp.data.arquivo_pdf && resp.data.nomePdf) {
                    dispatch(setArquivoPDF(resp.data.arquivo_pdf, resp.data.nomePdf));
                }
            }
        } catch (ex) {
            setErroAPI(ex);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function gerarRelatorioSolarMensal(registro) {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            dispatch(setAguardando(true));
            const filtro = getState().faturaRelatorio.filtro;
            const mesAno = `${filtro.mes}_${filtro.ano}`;
            if (!mesAno) {
                setError("Selecione o ano e o mês");
                return {};
            }
            const resp = await axios.get(
                `${consts.API_URL}/relatorioSolarMensal?id_usuario_logado=${usuarioLogado.id}&id_cliente=${registro.id_cliente || null}&id_unidade_consumidora=${
                    registro.id_unidade_consumidora || null
                }&nome_unidade_consumidora=${registro.nome_unidade_consumidora || null}&nome_cliente=${registro.nome_cliente || null}&competencia=${mesAno}`,
                { timeout: 60000 }
            );

            if (resp.data && resp.data.arquivo_pdf && resp.data.nomePdf) {
                dispatch(setArquivoPDF(resp.data.arquivo_pdf, resp.data.nomePdf));
            }
        } catch (ex) {
            setErroAPI(ex);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function enviarRelatorio(id_cliente) {
    //IMPLEMENTAR BUSCA DA LISTA DE TIPO DE RELATORI DO BANCO POSTERIORMENTE, POR ENQUANTO ESTÁ ESTATICO NO REDUCER
    return async (dispatch, getState) => {
        try {
            const filtro = getState().faturaRelatorio.filtro;

            let registro = null;

            if (filtro.id_tipo_relatorio == 1) {
                //tipo 1 passa a mesma competencia como inicial e final
                registro = {
                    id_cliente,
                    id_tipo_relatorio: filtro.id_tipo_relatorio,
                    competencia_inicial: `${filtro.ano}${filtro.mes}`,
                    competencia_final: `${filtro.ano}${filtro.mes}`,
                };
            }

            if (filtro.id_tipo_relatorio == 2) {
                //tipo 2 passa a mesma competencia inicio e fim de safra
                registro = {
                    id_cliente,
                    id_tipo_relatorio: filtro.id_tipo_relatorio,
                    competencia_inicial: `${filtro.ano_safra.split("/")[0]}06`,
                    competencia_final: `${filtro.ano_safra.split("/")[1]}05`,
                };
            }

            if (!registro) {
                setError("Não foi possível concluir a operação");
                return {};
            }
            dispatch(setAguardando(true));

            await axios.post(`${consts.API_URL}/fatura/enviarRelatorio`, registro);

            dispatch(getLista());

            setSuccess('Status do relatorio alterado para  "Enviado"');
        } catch (ex) {
            setErroAPI(ex);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getDadosRelatorioSafra(id_cliente) {
    return async (dispatch, getState) => {
        try {
            const filtro = getState().faturaRelatorio.filtro;
            const idClienteValidado = id_cliente || 0;

            dispatch(setAguardando(true));

            const competencia_inicial = `${filtro.ano_safra.split("/")[0]}06`;
            const competencia_final = `${filtro.ano_safra.split("/")[1]}05`;
            if (!competencia_inicial || !competencia_final) {
                setError("Selecione o ano safra");
                return {};
            }
            const resp = await axios.get(
                `${consts.API_URL}/fatura/gerarRelatorioSafra?id_cliente=${idClienteValidado}&competencia_inicial=${competencia_inicial}&competencia_final=${competencia_final}`
            );

            dispatch({
                type: "FATURA_RELATORIO_DADOS_SAFRA",
                payload: resp,
            });
        } catch (ex) {
            setError("Erro ao carregar registros");
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const filtro = getState().faturaRelatorio.filtro;

            const idClienteValidado = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : filtro.id_cliente;

            let lista = [];

            dispatch(setAguardando(true));

            //MODO MENSAL
            if (filtro.id_tipo_relatorio == 1) {
                const competencia = `${filtro.ano}${filtro.mes}`;
                if (!competencia) {
                    setError("Selecione o ano e o mês");
                    return {};
                }
                const resp = await axios.get(
                    `${consts.API_URL}/fatura/listaRelatorioMensal?id_usuario_logado=${usuarioLogado.id}&id_cliente=${idClienteValidado}&competencia=${competencia}`
                );
                lista = resp.data;
            }

            //MODO SAFRA
            if (filtro.id_tipo_relatorio == 2) {
                const competencia_inicial = `${filtro.ano_safra.split("/")[0]}06`;
                const competencia_final = `${filtro.ano_safra.split("/")[1]}05`;
                if (!competencia_inicial || !competencia_final) {
                    setError("Selecione o ano safra");
                    return {};
                }
                const resp = await axios.get(
                    `${consts.API_URL}/fatura/listaRelatorioSafra?id_usuario_logado=${usuarioLogado.id}&id_cliente=${idClienteValidado}&competencia_inicial=${competencia_inicial}&competencia_final=${competencia_final}`
                );
                lista = resp.data;
            }

            //MODO ANUAL (falta implementar)

            dispatch({
                type: "FATURA_RELATORIO_LISTADO",
                payload: { data: lista },
            });
        } catch (ex) {
            setError("Erro ao carregar registros");
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getListaCliente() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const request = axios.get(`${consts.API_URL}/cliente/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "FATURA_RELATORIO_CLIENTE_SELECT_LISTADO",
                payload: request,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export function getListaUnidadeConsumidora() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "FATURA_RELATORIO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
                payload: request,
            });
        } catch (e) {
            setErroAPI(e);
        }
    };
}
