import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import Select from "../../common/form/select";
import Content from "../../common/template/content";
import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import ContentCardFooter from "../../common/template/contentCardFooter";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import NewTr from "../../common/table/newTr";
import Button from "../../common/button/button";
import ButtonTable from "../../common/table/buttonTable";
import Form from "./faturaItemForm";
import FormFaturaAdicional from "./faturaAdicionalForm";
import { Loading } from "../../common/loading/loading";
import { ModalAlert } from "../../common/dialog/modalAlert";

import {
    setModoTela,
    initForm,
    carregarPdfFaturaAdicional,
    salvar,
    excluir,
    getLista,
    getListaProduto,
    getListaUnidadeMedida,
    importar,
    limpar,
    redefinirTipo,
    salvarFaturaAdicional,
    excluirFaturaAdicional,
} from "./faturaItemActions";

class FaturaItem extends Component {
    state = {
        linhaSelecionada: null,
        idItemAlteracaoTipo: null,
        id_tipo: null,
        exibirModalMercadoLivre: false,
    };

    componentWillMount() {
        this.props.getListaProduto();
        this.props.getListaUnidadeMedida();
        if (this.props.faturaFormularioValues && this.props.faturaFormularioValues.id) {
            this.props.getLista(this.props.faturaFormularioValues.id);
        }
    }

    render() {
        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}
                {this.state.exibirModalMercadoLivre ? (
                    <ModalAlert titulo="Importação de Custos de Mercado Livre" handleClose={() => this.setState({ ...this.state, exibirModalMercadoLivre: false })}>
                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                            <tbody>
                                <tr style={{ borderBottom: "1px solid #ccc" }}>
                                    <td style={{ paddingInline: 50 }}>Custo Energia ACL Longo Prazo</td>
                                    <td style={{ textAlign: "center", verticalAlign: "top" }}>
                                        <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
                                            <i
                                                className={this.props.faturaFormularioValues.arquivo_pdf_custo_energia_acl_lp ? "fas fa-check" : "fas fa-times"}
                                                style={{
                                                    color: this.props.faturaFormularioValues.arquivo_pdf_custo_energia_acl_lp ? "#2cdf2c" : "red",
                                                    position: "absolute",
                                                    top: 27,
                                                    left: "47%",
                                                    fontSize: "0.8rem",
                                                }}
                                            ></i>
                                            <ButtonTable
                                                style={{ margin: 0 }}
                                                title={this.props.faturaFormularioValues.arquivo_pdf_custo_energia_acl_lp ? "Visualizar PDF" : "Incluir PDF"}
                                                type={"warning"}
                                                icon={"fas fa-file-pdf"}
                                                visible={true}
                                                event={() => {
                                                    this.setState({ ...this.state, exibirModalMercadoLivre: false });
                                                    const { competencia, nome_unidade_consumidora } = this.props.faturaFormularioValues;
                                                    this.props.carregarPdfFaturaAdicional({
                                                        modo_tela: "fatura_adicional",
                                                        registro: {},
                                                        id_produto: 19958,
                                                        nomePdf: `Custo_Energia_ACL_LP_UC_${nome_unidade_consumidora.split(" - ")[0]}_${String(competencia).substring(
                                                            0,
                                                            2
                                                        )}-${String(competencia).substring(3)}`,
                                                    });
                                                }}
                                            />
                                            <div>NF-e</div>
                                        </div>
                                    </td>

                                    <td style={{ verticalAlign: "top" }}>
                                        <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
                                            <i
                                                className={this.props.faturaFormularioValues.arquivo_pdf_boleto_energia_acl_lp ? "fas fa-check" : "fas fa-times"}
                                                style={{
                                                    color: this.props.faturaFormularioValues.arquivo_pdf_boleto_energia_acl_lp ? "#2cdf2c" : "red",
                                                    position: "absolute",
                                                    top: 27,
                                                    left: "43%",
                                                    fontSize: "0.8rem",
                                                }}
                                            ></i>
                                            <ButtonTable
                                                style={{ margin: 0 }}
                                                title={this.props.faturaFormularioValues.arquivo_pdf_boleto_energia_acl_lp ? "Visualizar PDF" : "Incluir PDF"}
                                                type={"warning"}
                                                icon={"fas fa-file-pdf"}
                                                visible={true}
                                                event={() => {
                                                    this.setState({ ...this.state, exibirModalMercadoLivre: false });
                                                    const { competencia, nome_unidade_consumidora } = this.props.faturaFormularioValues;
                                                    this.props.carregarPdfFaturaAdicional({
                                                        modo_tela: "fatura_adicional",
                                                        registro: {},
                                                        id_produto: 20277,
                                                        nomePdf: `Boleto_Energia_ACL_LP_UC_${nome_unidade_consumidora.split(" - ")[0]}_${String(competencia).substring(
                                                            4,
                                                            6
                                                        )}-${String(competencia).substring(0, 4)}`,
                                                    });
                                                }}
                                            />
                                            <div>Boleto</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ccc" }}>
                                    <td style={{ paddingInline: 50 }}>Custo Energia ACL Curto Prazo</td>
                                    <td style={{ textAlign: "center", verticalAlign: "top" }}>
                                        <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
                                            <i
                                                className={this.props.faturaFormularioValues.arquivo_pdf_custo_energia_acl_cp ? "fas fa-check" : "fas fa-times"}
                                                style={{
                                                    color: this.props.faturaFormularioValues.arquivo_pdf_custo_energia_acl_cp ? "#2cdf2c" : "red",
                                                    position: "absolute",
                                                    top: 27,
                                                    left: "47%",
                                                    fontSize: "0.8rem",
                                                }}
                                            ></i>
                                            <ButtonTable
                                                style={{ margin: 0 }}
                                                title={this.props.faturaFormularioValues.arquivo_pdf_custo_energia_acl_cp ? "Visualizar PDF" : "Incluir PDF"}
                                                type={"warning"}
                                                icon={"fas fa-file-pdf"}
                                                visible={true}
                                                event={() => {
                                                    this.setState({ ...this.state, exibirModalMercadoLivre: false });
                                                    const { competencia, nome_unidade_consumidora } = this.props.faturaFormularioValues;
                                                    this.props.carregarPdfFaturaAdicional({
                                                        modo_tela: "fatura_adicional",
                                                        registro: {},
                                                        nomePdf: `Custo_Energia_ACL_CP_UC_${nome_unidade_consumidora.split(" - ")[0]}_${String(competencia).substring(
                                                            4,
                                                            6
                                                        )}-${String(competencia).substring(0, 4)}`,
                                                        id_produto: 19959,
                                                    });
                                                }}
                                            />
                                            <div>NF-e</div>
                                        </div>
                                    </td>
                                    <td style={{ verticalAlign: "top" }}>
                                        <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
                                            <i
                                                className={this.props.faturaFormularioValues.arquivo_pdf_boleto_energia_acl_cp ? "fas fa-check" : "fas fa-times"}
                                                style={{
                                                    color: this.props.faturaFormularioValues.arquivo_pdf_boleto_energia_acl_cp ? "#2cdf2c" : "red",
                                                    position: "absolute",
                                                    top: 27,
                                                    left: "43%",
                                                    fontSize: "0.8rem",
                                                }}
                                            ></i>
                                            <ButtonTable
                                                style={{ margin: 0 }}
                                                title={this.props.faturaFormularioValues.arquivo_pdf_boleto_energia_acl_cp ? "Visualizar PDF" : "Incluir PDF"}
                                                type={"warning"}
                                                icon={"fas fa-file-pdf"}
                                                visible={true}
                                                event={() => {
                                                    this.setState({ ...this.state, exibirModalMercadoLivre: false });
                                                    const { competencia, nome_unidade_consumidora } = this.props.faturaFormularioValues;
                                                    this.props.carregarPdfFaturaAdicional({
                                                        modo_tela: "fatura_adicional",
                                                        registro: {},
                                                        id_produto: 20278,
                                                        nomePdf: `Boleto_Energia_ACL_CP_UC_${nome_unidade_consumidora.split(" - ")[0]}_${String(competencia).substring(
                                                            4,
                                                            6
                                                        )}-${String(competencia).substring(0, 4)}`,
                                                    });
                                                }}
                                            />
                                            <div>Boleto</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ccc" }}>
                                    <td style={{ paddingInline: 50 }}>Custo Gestão ACL</td>
                                    <td style={{ textAlign: "center", verticalAlign: "top" }}>
                                        <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
                                            <i
                                                className={this.props.faturaFormularioValues.arquivo_pdf_custo_gestao_acl ? "fas fa-check" : "fas fa-times"}
                                                style={{
                                                    color: this.props.faturaFormularioValues.arquivo_pdf_custo_gestao_acl ? "#2cdf2c" : "red",
                                                    position: "absolute",
                                                    top: 27,
                                                    left: "47%",
                                                    fontSize: "0.8rem",
                                                }}
                                            ></i>
                                            <ButtonTable
                                                style={{ margin: 0 }}
                                                title={this.props.faturaFormularioValues.arquivo_pdf_custo_gestao_acl ? "Visualizar PDF" : "Incluir PDF"}
                                                type={"dark"}
                                                icon={"fas fa-file-pdf"}
                                                visible={true}
                                                event={() => {
                                                    this.setState({ ...this.state, exibirModalMercadoLivre: false });
                                                    const { competencia, nome_unidade_consumidora } = this.props.faturaFormularioValues;
                                                    this.props.carregarPdfFaturaAdicional({
                                                        modo_tela: "fatura_adicional",
                                                        registro: {},
                                                        nomePdf: `Custo_Gestão_ACL_UC_${nome_unidade_consumidora.split(" - ")[0]}_${String(competencia).substring(4, 6)}-${String(
                                                            competencia
                                                        ).substring(0, 4)}`,
                                                        id_produto: 19002,
                                                    });
                                                }}
                                            />
                                            <div>NF-e</div>
                                        </div>
                                    </td>
                                    <td style={{ verticalAlign: "top" }}>
                                        <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
                                            <i
                                                className={this.props.faturaFormularioValues.arquivo_pdf_boleto_gestao_acl ? "fas fa-check" : "fas fa-times"}
                                                style={{
                                                    color: this.props.faturaFormularioValues.arquivo_pdf_boleto_gestao_acl ? "#2cdf2c" : "red",
                                                    position: "absolute",
                                                    top: 27,
                                                    left: "43%",
                                                    fontSize: "0.8rem",
                                                }}
                                            ></i>
                                            <ButtonTable
                                                style={{ margin: 0 }}
                                                title={this.props.faturaFormularioValues.arquivo_pdf_boleto_gestao_acl ? "Visualizar PDF" : "Incluir PDF"}
                                                type={"dark"}
                                                icon={"fas fa-file-pdf"}
                                                visible={true}
                                                event={() => {
                                                    this.setState({ ...this.state, exibirModalMercadoLivre: false });
                                                    const { competencia, nome_unidade_consumidora } = this.props.faturaFormularioValues;
                                                    this.props.carregarPdfFaturaAdicional({
                                                        modo_tela: "fatura_adicional",
                                                        registro: {},
                                                        id_produto: 20279,
                                                        nomePdf: `Boleto_Gestão_ACL_UC_${nome_unidade_consumidora.split(" - ")[0]}_${String(competencia).substring(4, 6)}-${String(
                                                            competencia
                                                        ).substring(0, 4)}`,
                                                    });
                                                }}
                                            />
                                            <div>Boleto</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #ccc" }}>
                                    <td style={{ paddingInline: 50 }}>Tx. Contribuição Associativa CCEE</td>
                                    <td style={{ textAlign: "center", verticalAlign: "top" }}>
                                        <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
                                            <i
                                                className={this.props.faturaFormularioValues.arquivo_pdf_contribuicao_associativa_ccee ? "fas fa-check" : "fas fa-times"}
                                                style={{
                                                    color: this.props.faturaFormularioValues.arquivo_pdf_contribuicao_associativa_ccee ? "#2cdf2c" : "red",
                                                    position: "absolute",
                                                    top: 27,
                                                    left: "47%",
                                                    fontSize: "0.8rem",
                                                }}
                                            ></i>
                                            <ButtonTable
                                                style={{ margin: 0 }}
                                                title={this.props.faturaFormularioValues.arquivo_pdf_contribuicao_associativa_ccee ? "Visualizar PDF" : "Incluir PDF"}
                                                type={"info"}
                                                icon={"fas fa-file-pdf"}
                                                visible={true}
                                                event={() => {
                                                    this.setState({ ...this.state, exibirModalMercadoLivre: false });
                                                    const { competencia, nome_unidade_consumidora } = this.props.faturaFormularioValues;
                                                    this.props.carregarPdfFaturaAdicional({
                                                        modo_tela: "fatura_adicional",
                                                        registro: {},
                                                        nomePdf: `Custo_Contribuição_Associativa_CCEE_UC_${nome_unidade_consumidora.split(" - ")[0]}_${String(
                                                            competencia
                                                        ).substring(4, 6)}-${String(competencia).substring(0, 4)}`,
                                                        id_produto: 19003,
                                                    });
                                                }}
                                            />
                                            <div>NF-e</div>
                                        </div>
                                    </td>
                                    <td style={{ verticalAlign: "top" }}>
                                        <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center" }}>
                                            <i
                                                className={this.props.faturaFormularioValues.arquivo_pdf_boleto_contribuicao_associativa_ccee ? "fas fa-check" : "fas fa-times"}
                                                style={{
                                                    color: this.props.faturaFormularioValues.arquivo_pdf_boleto_contribuicao_associativa_ccee ? "#2cdf2c" : "red",
                                                    position: "absolute",
                                                    top: 27,
                                                    left: "43%",
                                                    fontSize: "0.8rem",
                                                }}
                                            ></i>
                                            <ButtonTable
                                                style={{ margin: 0 }}
                                                title={this.props.faturaFormularioValues.arquivo_pdf_boleto_contribuicao_associativa_ccee ? "Visualizar PDF" : "Incluir PDF"}
                                                type={"info"}
                                                icon={"fas fa-file-pdf"}
                                                visible={true}
                                                event={() => {
                                                    this.setState({ ...this.state, exibirModalMercadoLivre: false });
                                                    const { competencia, nome_unidade_consumidora } = this.props.faturaFormularioValues;
                                                    this.props.carregarPdfFaturaAdicional({
                                                        modo_tela: "fatura_adicional",
                                                        registro: {},
                                                        id_produto: 20280,
                                                        nomePdf: `Custo_Contribuição_Associativa_CCEE_UC_${nome_unidade_consumidora.split(" - ")[0]}_${String(
                                                            competencia
                                                        ).substring(4, 6)}-${String(competencia).substring(0, 4)}`,
                                                    });
                                                }}
                                            />
                                            <div>Boleto</div>
                                        </div>
                                    </td>
                                </tr>
                                {this.props.usuarioLogado.acesso_cliente || !(this.props.faturaFormularioValues.erros_mercado_livre || []).length ? null : (
                                    <tr style={{ borderBottom: "1px solid #ccc", color: "red" }}>
                                        <td colSpan={3} style={{ paddingInline: 50 }}>
                                            <b>Erros de Importação</b>
                                            <ul style={{ padding: 0 }}>
                                                {(this.props.faturaFormularioValues.erros_mercado_livre || []).map((item) => (
                                                    <li>{item}</li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </ModalAlert>
                ) : null}
                <Content>
                    {this.props.modoTela === "fatura_adicional" || this.props.modoTela === "fatura_adicional_exclusao" ? null : (
                        <Row>
                            <Grid cols="12 12 12 12">
                                <label>Itens</label>
                            </Grid>
                        </Row>
                    )}
                    {this.props.modoTela === "fatura_adicional" ? <FormFaturaAdicional onSubmit={this.props.salvarFaturaAdicional} /> : null}
                    {this.props.modoTela === "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela === "importar" || this.props.modoTela === "refaturar" ? this.importar() : null}
                    {this.props.modoTela === "limpar" ? this.limpar() : null}
                    {this.props.modoTela === "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {this.props.modoTela === "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    lista() {
        const itemRefaturamentoExiste = this.props.lista.find((item) => item.id_produto == 19722); //item referente a refaturamento
        const usuarioLogado = this.props.usuarioLogado || {};
        const usuarioEdicao = usuarioLogado.acesso_fatura_editar || usuarioLogado.acesso_fatura_editar_fechado;
        const usuarioLogadoAdmin = [1, 2].includes(this.props.usuarioLogado.id); //só permite para TI ou para o marthielo
        let valorTotal = 0;
        const ucFatura = this.props.listaUnidadeConsumidora.find((uc) => uc.id == this.props.faturaFormularioValues.id_unidade_consumidora) || {};
        return (
            <ContentCard>
                {!this.props.visualizacao ? (
                    <ContentCardHeader>
                        <Row>
                            <Grid cols="12 12 12 12" style={{ display: "flex", flexWrap: "wrap" }}>
                                <Grid cols="12 12 4 4">
                                    <Button
                                        text="Adicionar"
                                        type={"success"}
                                        disabled={this.props.faturaFormularioValues.importacao_validada || !usuarioEdicao}
                                        icon={"fa fa-plus"}
                                        event={() => {
                                            this.props.setModoTela("cadastro", {
                                                id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                            });
                                            this.props.initForm({
                                                id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid cols="12 12 4 4">
                                    <Button
                                        text="Importar"
                                        type={"primary"}
                                        disabled={this.props.faturaFormularioValues.importacao_validada || !usuarioEdicao}
                                        icon={"fa fa-file-import"}
                                        event={() => {
                                            this.props.setModoTela("importar", {
                                                id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                            });
                                        }}
                                    />
                                </Grid>
                                {ucFatura.id_tipo_mercado == 2 ? (
                                    <Grid cols="12 12 4 4">
                                        <Button
                                            style={{ display: "flex", height: 39, flexGrow: 1, justifyContent: "center", alignItems: "center" }}
                                            text="Importar Custos Mercado Livre"
                                            type={"info"}
                                            disabled={!usuarioLogado.acesso_fatura_adicional_adicionar} //validar permissão
                                            icon={"fa fa-folder-open"}
                                            event={() => {
                                                this.setState({ ...this.state, exibirModalMercadoLivre: true });
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                                {ucFatura.geracao ? (
                                    <Grid cols="12 12 4 4">
                                        <Button
                                            text="Produção Gerador Solar (kWh)"
                                            type={"success"}
                                            disabled={this.props.faturaFormularioValues.importacao_validada || !usuarioEdicao}
                                            icon={"fa fa-plus"}
                                            event={() => {
                                                this.props.setModoTela("cadastro", {
                                                    id_fatura:
                                                        this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                                    id_produto: 659,
                                                    id_unidade_medida: 12,
                                                });
                                                this.props.initForm({
                                                    id_fatura:
                                                        this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                                    id_produto: 659,
                                                    id_unidade_medida: 12,
                                                });
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                                <Grid cols="12 12 4 4">
                                    <Button
                                        text="Refaturar"
                                        type={"success"}
                                        disabled={
                                            !this.props.faturaFormularioValues.arquivo_pdf ||
                                            this.props.faturaFormularioValues.importacao_validada ||
                                            !usuarioLogado.acesso_fatura_refaturar ||
                                            itemRefaturamentoExiste
                                        }
                                        icon={"fa fa-file-invoice-dollar"}
                                        event={() => {
                                            this.props.setModoTela("refaturar", {
                                                id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid cols="12 12 4 4">
                                    <Button
                                        text="Limpar"
                                        type={"danger"}
                                        disabled={this.props.faturaFormularioValues.importacao_validada || !usuarioEdicao}
                                        icon={"fa fa-minus"}
                                        event={() => {
                                            this.props.setModoTela("limpar", {
                                                id_fatura: this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Row>
                    </ContentCardHeader>
                ) : null}
                <ContentCardBody>
                    <Table responsive>
                        <THead>
                            <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#F5F5F5"}>
                                <Th sticky>Produto</Th>
                                <Th sticky>Tipo</Th>
                                <Th sticky>Unid Medida</Th>
                                <Th sticky alignRight>
                                    Qtd Registrada
                                </Th>
                                <Th sticky alignRight>
                                    Qtd Faturada
                                </Th>
                                <Th sticky alignRight>
                                    Tarifa
                                </Th>
                                <Th sticky alignRight>
                                    Vlr Imposto
                                </Th>
                                <Th sticky alignRight>
                                    Vlr Total
                                </Th>
                            </NewTr>
                        </THead>
                        <TBody>
                            {this.props.lista.map((item, i) => {
                                //não contabiliza no total dos itens o produto "Informação de Escassez Hídrica" nem "Valores Refaturados sem precisar realizar pagamentos"
                                if (![19349, 19722].includes(item.id_produto)) {
                                    valorTotal += parseFloat(item.valor_total || 0);
                                }
                                const botoesItens = [
                                    {
                                        type: "warning",
                                        icon: "fas fa-pencil-alt",
                                        disabled: this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao,
                                        event: () => {
                                            let registro = {
                                                ...item,
                                                quantidade_registrada: String(item.quantidade_registrada || 0)
                                                    .split(".")
                                                    .join(","),
                                                quantidade_faturada: String(item.quantidade_faturada || 0)
                                                    .split(".")
                                                    .join(","),
                                                tarifa: String(item.tarifa || 0)
                                                    .split(".")
                                                    .join(","),
                                                valor_imposto: String(item.valor_imposto || 0)
                                                    .split(".")
                                                    .join(","),
                                                valor_total: String(item.valor_total || 0)
                                                    .split(".")
                                                    .join(","),
                                            };
                                            this.props.setModoTela("cadastro", registro);
                                            this.props.initForm(registro);
                                        },
                                    },
                                    {
                                        type: "danger",
                                        icon: "fas fa-trash-alt",
                                        disabled: this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao || !usuarioEdicao,
                                        event: () => {
                                            let registro = {
                                                ...item,
                                                quantidade_registrada: String(item.quantidade_registrada || 0)
                                                    .split(".")
                                                    .join(","),
                                                quantidade_faturada: String(item.quantidade_faturada || 0)
                                                    .split(".")
                                                    .join(","),
                                                tarifa: String(item.tarifa || 0)
                                                    .split(".")
                                                    .join(","),
                                                valor_imposto: String(item.valor_imposto || 0)
                                                    .split(".")
                                                    .join(","),
                                                valor_total: String(item.valor_total || 0)
                                                    .split(".")
                                                    .join(","),
                                            };
                                            this.props.setModoTela("exclusao", registro);
                                            this.props.initForm(registro);
                                        },
                                    },
                                ];

                                return (
                                    <>
                                        {i > 0 ? null : (
                                            <>
                                                <NewTr
                                                    key={this.props.faturaFormularioValues.id + "-aliquota_icms"}
                                                    backgroundColorTr={"#FFFFFF"}
                                                    backgroundColorTd={"#F5F5F5"}
                                                    botoes={[
                                                        {
                                                            type: "warning",
                                                            icon: "fas fa-pencil-alt",
                                                            disabled: this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao,
                                                            event: this.props.exibirModalAliquotaIcms,
                                                        },
                                                    ]}
                                                >
                                                    <Td>Alíquota de ICMS Importada</Td>
                                                    <Td></Td>
                                                    <Td>%</Td>
                                                    <Td alignRight>
                                                        {String(this.props.faturaFormularioValues.icms_importado || 0)
                                                            .split(".")
                                                            .join(",")}
                                                    </Td>
                                                    <Td></Td>
                                                    <Td></Td>
                                                    <Td></Td>
                                                    <Td></Td>
                                                </NewTr>
                                                <NewTr
                                                    key={this.props.faturaFormularioValues.id + "-valor_icms"}
                                                    backgroundColorTr={"#FFFFFF"}
                                                    backgroundColorTd={"#F5F5F5"}
                                                    botoes={[
                                                        {
                                                            type: "warning",
                                                            icon: "fas fa-pencil-alt",
                                                            disabled: this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao,
                                                            event: this.props.exibirModalValorIcms,
                                                        },
                                                    ]}
                                                >
                                                    <Td>Valor do ICMS Importado</Td>
                                                    <Td></Td>
                                                    <Td>R$</Td>
                                                    <Td></Td>
                                                    <Td></Td>
                                                    <Td></Td>
                                                    <Td></Td>
                                                    <Td alignRight>
                                                        {String(this.props.faturaFormularioValues.valor_icms || 0)
                                                            .split(".")
                                                            .join(",")}
                                                    </Td>
                                                </NewTr>
                                            </>
                                        )}
                                        <NewTr
                                            key={i + "-" + item.id}
                                            backgroundColorTr={"#FFFFFF"}
                                            backgroundColorTd={"#F5F5F5"}
                                            botoes={[47, 48, 49].includes(item.id_tipo) ? [] : botoesItens}
                                        >
                                            <Td>{item.nome_produto}</Td>
                                            <Td>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        padding: 5,
                                                        marginRight: 80,
                                                        borderRadius: "0.3rem",
                                                        border: this.state.linhaSelecionada == item.id ? "1px solid #ccc" : "1px solid transparent",
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        //se não for um dos admin não exibe os botões
                                                        if (!usuarioLogadoAdmin) return;
                                                        this.setState({ ...this.state, linhaSelecionada: this.state.idItemAlteracaoTipo ? null : item.id });
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        this.setState({ ...this.state, linhaSelecionada: null });
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "nowrap",
                                                            flexGrow: 1,
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {this.state.idItemAlteracaoTipo == item.id ? (
                                                            <>
                                                                <Select
                                                                    options={this.props.listaProdutoTipo.filter((prod) => {
                                                                        //não mostra os produtos relacionados a mercado livre,
                                                                        //pois são definidos apenas pela tela de inclusão de fatura adicional
                                                                        return ![47, 48, 49].includes(prod.id_tipo);
                                                                    })}
                                                                    cols="12 12 12 12"
                                                                    placeholder="Selecione o tipo"
                                                                    value={this.state.id_tipo}
                                                                    onChange={(value) => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            id_tipo: value,
                                                                        });
                                                                    }}
                                                                />
                                                                <button
                                                                    style={{
                                                                        borderRadius: "0.3rem",
                                                                        marginTop: 5,
                                                                        padding: "5px 10px",
                                                                        color: "#FFF",
                                                                        backgroundColor: "#F44",
                                                                        height: "fit-content",
                                                                        outline: "none",
                                                                    }}
                                                                    disabled={
                                                                        this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao || !usuarioLogadoAdmin
                                                                    }
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            idItemAlteracaoTipo: null,
                                                                            id_tipo: null,
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="fas fa-times"></i>
                                                                </button>
                                                                <button
                                                                    style={{
                                                                        borderRadius: "0.3rem",
                                                                        marginTop: 5,
                                                                        padding: "5px 8px",
                                                                        color: "#FFF",
                                                                        backgroundColor: "#39d139",
                                                                        height: "fit-content",
                                                                        outline: "none",
                                                                    }}
                                                                    disabled={
                                                                        this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao || !usuarioLogadoAdmin
                                                                    }
                                                                    onClick={() => {
                                                                        this.props.redefinirTipo({
                                                                            id: item.id_produto,
                                                                            id_tipo: this.state.id_tipo,
                                                                            id_fatura: this.props.faturaFormularioValues.id,
                                                                        });
                                                                        this.setState({
                                                                            ...this.state,
                                                                            idItemAlteracaoTipo: null,
                                                                            id_tipo: null,
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="fas fa-check"></i>
                                                                </button>
                                                            </>
                                                        ) : (
                                                            item.nome_tipo
                                                        )}
                                                    </div>

                                                    {this.state.idItemAlteracaoTipo || [47, 48, 49].includes(item.id_tipo) ? null : (
                                                        <button
                                                            style={{
                                                                visibility: this.state.linhaSelecionada == item.id ? "visible" : "hidden",
                                                                borderRadius: "0.3rem",
                                                                outline: "none",
                                                                padding: "5px 8px",
                                                                color: `${item.id_tipo ? "#000" : "#FFF"}`,
                                                                backgroundColor: `${item.id_tipo ? "#Fd2" : "#05F"}`,
                                                            }}
                                                            disabled={this.props.faturaFormularioValues.importacao_validada || this.props.visualizacao || !usuarioLogadoAdmin}
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    idItemAlteracaoTipo: item.id,
                                                                    id_tipo: item.id_tipo,
                                                                    linhaSelecionada: null,
                                                                });
                                                            }}
                                                        >
                                                            <i className={`fas ${item.id_tipo ? "fa-pencil-alt" : "fa-plus"}`}></i>
                                                        </button>
                                                    )}
                                                </div>
                                            </Td>
                                            <Td>{item.nome_unidade_medida}</Td>
                                            <Td alignRight>
                                                {String(item.quantidade_registrada || 0)
                                                    .split(".")
                                                    .join(",")}
                                            </Td>
                                            <Td alignRight>
                                                {String(item.quantidade_faturada || 0)
                                                    .split(".")
                                                    .join(",")}
                                            </Td>
                                            <Td alignRight>
                                                {String(item.tarifa || 0)
                                                    .split(".")
                                                    .join(",")}
                                            </Td>
                                            <Td alignRight>
                                                {String(item.valor_imposto || 0)
                                                    .split(".")
                                                    .join(",")}
                                            </Td>
                                            <Td alignRight>
                                                {String(item.valor_total || 0)
                                                    .split(".")
                                                    .join(",")}
                                            </Td>
                                        </NewTr>
                                    </>
                                );
                            })}

                            <Tr>
                                <Td>Total</Td>
                                <Td></Td>
                                <Td></Td>
                                <Td alignRight></Td>
                                <Td alignRight></Td>
                                <Td alignRight></Td>
                                <Td alignRight></Td>
                                <Td alignRight>{(valorTotal || 0).toFixed(5).split(".").join(",")}</Td>
                                <Td alignRight minWidth={100}></Td>
                            </Tr>
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }

    importar() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <div className="form-group">
                        <div className="custom-file">
                            <input
                                className="custom-file-input"
                                type="file"
                                id="customFile"
                                accept="application/pdf"
                                value={this.state.file}
                                onChange={(event) => {
                                    event.preventDefault();
                                    let reader = new FileReader();
                                    let file = event.target.files[0];

                                    if (!file.type.includes("application/pdf")) {
                                        //toastr.error('Erro', 'Selecione apenas PDF.');
                                        return;
                                    }

                                    reader.onloadend = () => {
                                        this.props.importar(
                                            this.props.faturaFormularioValues && this.props.faturaFormularioValues.id ? this.props.faturaFormularioValues.id : null,
                                            reader.result
                                        );
                                    };

                                    reader.readAsDataURL(file);
                                }}
                            />
                            <label className="custom-file-label" for="customFile">
                                Selecione
                            </label>
                        </div>
                    </div>
                    <Row alignCenter>
                        <Grid cols="6 6 4 3">
                            <Button text="Cancelar" type={"danger"} icon={"fa fa-times"} event={() => this.props.setModoTela("lista")} />
                        </Grid>
                    </Row>
                </ContentCardBody>
            </ContentCard>
        );
    }

    limpar() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <span>Ao confirmar a exclusão, todos os itens dessa fatura serão removidos. Deseja realmente limpar os itens da fatura?</span>
                </ContentCardBody>
                <ContentCardFooter>
                    <Row alignCenter>
                        <Grid cols="6 6 4 3">
                            <Button
                                text="Confirmar Exclusão"
                                type={"danger"}
                                icon={"fa fa-trash"}
                                event={() => {
                                    this.props.limpar(this.props.lista);
                                }}
                            />
                        </Grid>

                        <Grid cols="6 6 4 3">
                            <Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
                        </Grid>
                    </Row>
                </ContentCardFooter>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    modoTela: state.faturaItem.modoTela,
    lista: state.faturaItem.lista,
    listaUnidadeConsumidora: state.fatura.listaUnidadeConsumidora,
    listaProdutoTipo: state.fatura.listaProdutoTipo,
    faturaFormularioValues: getFormValues("faturaForm")(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            carregarPdfFaturaAdicional,
            salvar,
            salvarFaturaAdicional,
            excluirFaturaAdicional,
            excluir,
            getLista,
            getListaProduto,
            getListaUnidadeMedida,
            importar,
            limpar,
            redefinirTipo,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(FaturaItem);
