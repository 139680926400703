export default {
     API_URL: "https://api-eficience.infinitum-ti.com/api",
     OAPI_URL: "https://api-eficience.infinitum-ti.com/oapi",
     ARQUIVOS_URL: "https://arquivo.gradios.com.br",
    //API_URL: "http://localhost:2385/api",
    //OAPI_URL: "http://localhost:2385/oapi",
    //ARQUIVOS_URL: "http://localhost:2385",
    // API_URL: "http://192.168.2.127:2385/api",
    // OAPI_URL: "http://192.168.2.127:2385/oapi",
    // ARQUIVOS_URL: "http://192.168.2.127:2376",
};
