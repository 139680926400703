import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import "react-circular-progressbar/dist/styles.css";

import InfoBox from "../common/template/infoBox";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Tr from "../common/table/tr";
import Select from "../common/form/select";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";

import somNotificacaoArquivo from "../assets/sounds/notificacao.wav";
import graphMonitor from "../assets/images/graphMonitor.png";
import plugInversor from "../assets/images/plugInversor.png";
import consumedEnergy from "../assets/images/consumedEnergy.png";
import calendarCheck from "../assets/images/calendar-check.svg";
import capacitor from "../assets/images/capacitor.png";
import volts from "../assets/images/volts.png";

import {
    setFiltro,
    setAguardando,
    setModoTela,
    setDataInicialGrafico,
    setDataFinalGrafico,
    getDemandaContagem,
    carregarListas,
    getListaGraficoDia,
    getListaGraficoTodasIntegracoesAgrupadasPorHora,
    setStatusVisualizado,
} from "./monitoramentoConsumoActions";

import { setEmitirAlertaSonoro } from "../usuario/usuarioActions";

import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar";
import { Loading } from "../common/loading/loading";
import GraficoMonitoramentoConsumo from "./graficoMonitoramentoConsumo";
import GraficosPeQ from "./graficosPeQ";
import DateFormat from "../common/dateFormat/DateFormat";

import AlertIcon from "../common/alertIcon/alertIcon";
import NumberFormat from "react-number-format";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import { useRef } from "react";

// Variáveis de controle dentro da função principal
let sleepTimeoutId = null;
let idIntervaloNotificacao = null;
let idIntervaloBusca = null;

function MonitoramentoConsumo({
    usuarioLogado,
    aguardando,
    modoTela,
    filtro,
    listaCliente,
    listaUnidadeConsumidora,
    listaDemandaContagem,
    listaDemandaContratoTipo,
    dataInicialGrafico,
    dataFinalGrafico,
    setFiltro,
    setModoTela,
    getDemandaContagem,
    carregarListas,
    getListaGraficoDia,
    setStatusVisualizado,
    setEmitirAlertaSonoro,
}) {
    const [abaSazonalSelcionada, setAbaSazonalSelcionada] = useState(true);
    const [dataHoraUltimaAtualizacao, setDataHoraUltimaAtualizacao] = useState(true);
    const [filtroLista, setFiltroLista] = useState({ apenasOnline: false, apenasOffline: false, apenasDemandasComUltrapassagem: false, apenasFatorPotenciaBaixo: false });
    const [qtdPorFiltro, setQtdPorFiltro] = useState({ qtdOnline: 0, qtdOffline: 0, qtdDemandasComUltrapassagem: 0, qtdFatorPotenciaBaixo: 0 });
    let filtros = { qtdOnline: 0, qtdOffline: 0, qtdDemandasComUltrapassagem: 0, qtdFatorPotenciaBaixo: 0 };

    function zerarFiltros() {
        filtros = { qtdOnline: 0, qtdOffline: 0, qtdDemandasComUltrapassagem: 0, qtdFatorPotenciaBaixo: 0 };
        setQtdPorFiltro({ ...filtros });
    }

    useEffect(() => {
        carregarListas(); //cli, uc, tipo
        getDemandaContagem();
        emitirAlertaSonoro();
        atualizarDadosCada15Min();
        return () => {
            cancelarTimersAlertaSonoro();
            cancelarTimerBuscaLista15min();
        };
    }, []);

    useEffect(() => {
        const exibirAbaSazonal = listaDemandaContagem.find((item) => item.contrato_demanda_sazonal);
        const exibirAbaNaoSazonal = listaDemandaContagem.find((item) => !item.contrato_demanda_sazonal);

        if (exibirAbaSazonal && !exibirAbaNaoSazonal && !abaSazonalSelcionada) {
            setAbaSazonalSelcionada(true);
        }

        if (!exibirAbaSazonal && exibirAbaNaoSazonal && abaSazonalSelcionada) {
            setAbaSazonalSelcionada(false);
        }
    }, [listaDemandaContagem, abaSazonalSelcionada]);

    useEffect(() => {
        emitirAlertaSonoro();
    }, [JSON.stringify(listaDemandaContagem), usuarioLogado.emitir_alerta_sonoro]);

    return (
        <div>
            {aguardando && <Loading />}

            <Content>
                {modoTela === "lista" && listar()}
                {modoTela.includes("grafico") && <GraficosPeQ />}
            </Content>
        </div>
    );

    function buscarDadosTela() {
        setDataHoraUltimaAtualizacao(DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()));
        getDemandaContagem();
    }

    function atualizarDadosCada15Min() {
        const quinzeMinutosMs = 1000 * 60 * 15;
        buscarDadosTela();
        if (!idIntervaloBusca) {
            console.log("iniciando intervalo busca");
            idIntervaloBusca = setInterval(() => buscarDadosTela(), quinzeMinutosMs);
        }
    }

    function cancelarTimerBuscaLista15min() {
        if (idIntervaloBusca !== null) {
            console.log("Cancelando intervalo busca tela");
            clearInterval(idIntervaloBusca);
            idIntervaloBusca = null; // Redefine para garantir que só há um intervalo ativo
        }
    }

    function cancelarTimersAlertaSonoro() {
        // Cancela qualquer timeout ou intervalo em andamento antes de iniciar um novo
        if (sleepTimeoutId !== null) {
            console.log("Cancelando timer alerta anterior");
            clearTimeout(sleepTimeoutId);
            sleepTimeoutId = null; // Redefine para garantir que só há um timer ativo
        }
        if (idIntervaloNotificacao !== null) {
            console.log("Cancelando intervalo alerta anterior");
            clearInterval(idIntervaloNotificacao);
            idIntervaloNotificacao = null; // Redefine para garantir que só há um intervalo ativo
        }
    }

    function emitirAlertaSonoro() {
        // Função interna para iniciar o processo de alerta
        const iniciarAlerta = () => {
            console.log({ sleepTimeoutId, idIntervaloNotificacao });
            cancelarTimersAlertaSonoro();
            // Define o tempo de espera de 2 minutos
            const doisMinutos = 1000 * 60 * 2;

            // Inicia um novo timer de 2 minutos
            sleepTimeoutId = setTimeout(async () => {
                const listaFiltrada = listaDemandaContagem.filter((item) => (abaSazonalSelcionada ? item.contrato_demanda_sazonal : !item.contrato_demanda_sazonal));

                // Verifica leituras com erro
                const leiturasComErro = listaFiltrada.find((item) => {
                    //valida erro fp

                    const fatorPotencia = calcularFatorPotencia({
                        p: item.consumoLeitura.kw,
                        q: item.consumoLeitura.kvar_ind,
                    });

                    let exibirAlertaPkwVermelho = false;
                    //1 fora ponta e 2 ponta
                    if (filtro.id_tipo_contrato_demanda == 1) {
                        exibirAlertaPkwVermelho = parseFloat(item.consumoLeitura.kw) > parseFloat(item.demanda_fora_ponta_contratada_mes_atual) * 1.05;
                    } else if (filtro.id_tipo_contrato_demanda == 2) {
                        exibirAlertaPkwVermelho = parseFloat(item.consumoLeitura.kw) > parseFloat(item.demanda_ponta_contratada_mes_atual) * 1.05;
                    }

                    const erroFatorPotencia = !isNaN(fatorPotencia) && fatorPotencia < 0.92 && !item.consumoLeitura.status_visualizado_fp;
                    const erroKw = exibirAlertaPkwVermelho && !item.consumoLeitura.status_visualizado_kw;

                    return erroFatorPotencia || erroKw;
                });

                // Emite o alerta se todas as condições estiverem corretas
                if (!!leiturasComErro && usuarioLogado.emitir_alerta_sonoro) {
                    console.log("deve emitir");
                    if (!idIntervaloNotificacao) {
                        console.log("Emitindo alerta sonoro");
                        idIntervaloNotificacao = setInterval(async () => {
                            try {
                                const somNotificacao = new Audio(somNotificacaoArquivo);
                                await somNotificacao.play();
                            } catch (erro) {
                                console.log("Erro ao tentar emitir som: ", erro);
                            }
                        }, 3000);
                    }
                }
            }, doisMinutos); // Espera de 2 minutos (120000 ms)

            console.log("Novo timer iniciado:", sleepTimeoutId);
        };

        // Inicia o alerta
        iniciarAlerta();
    }

    function listarUCsFiltradas() {
        return listaUnidadeConsumidora.filter((item) => {
            const filtroClienteValidado = usuarioLogado.acesso_cliente ? item.id_cliente == usuarioLogado.id_cliente : !filtro.id_cliente || item.id_cliente == filtro.id_cliente;
            return filtroClienteValidado && [1, 4].includes(parseInt(item.id_grupo_faturamento));
        });
    }

    function TooltipoPeriodoTeste({ item }) {
        const periodoTeste = item.periodo_teste || [];
        if (!periodoTeste.length) return null;
        const mesInicio = "" + JSON.parse(periodoTeste[0]).mes;
        const anoInicio = "" + JSON.parse(periodoTeste[0]).ano;
        const mesFim = "" + JSON.parse(periodoTeste[periodoTeste.length - 1]).mes;
        const anoFim = "" + JSON.parse(periodoTeste[periodoTeste.length - 1]).ano;
        const inicioTeste = mesInicio + "/" + anoInicio;
        const fimTeste = mesFim + "/" + anoFim;

        return (
            <div
                style={{
                    color: "#FFF",
                    backgroundColor: "#257bfb",
                    padding: "10px 8px",
                    borderRadius: "0.3rem",
                    fontSize: "0.7rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "fit-content",
                    textWrap: "nowrap",
                    flexGrow: 1,
                    margin: 3,
                }}
                title={`Período de Testes ativo: Início ${inicioTeste} - Fim ${fimTeste}`}
            >
                <b>PERÍODO TESTE</b>
            </div>
        );
    }

    function TooltipAgendamento({ item }) {
        if (!item.agendamentos.length) return null;

        return (
            <div
                style={{
                    backgroundColor: "#f1cf1a",
                    color: "#111",
                    padding: "10px 8px",
                    borderRadius: "0.3rem",
                    fontSize: "0.7rem",
                    display: "flex",
                    justifyContent: "center",
                    textWrap: "nowrap",
                    minWidth: "fit-content",
                    flexGrow: 1,
                    margin: 3,
                }}
                title={`Agendamento de Alteração de Demanda:\n${item.agendamentos.map((agendamentoDemanda) => agendamentoDemanda.mensagem_tooltip)}`}
            >
                <b>AGENDAMENTO</b>
            </div>
        );
    }
    function listar() {
        // VALORES DOS CARDS DO CABEÇALHO --------------------------------------
        const totalUsinas = 0;
        const potenciaTotalInstalada = (0).toFixed(2).replace(".", ",") + " MW";

        const energiaTotalDia = (0).toFixed(2).replace(".", ",") + " MWh";
        const energiaTotalMes = (0).toFixed(2).replace(".", ",") + " MWh";

        const totalUsinasProducaoModerada = 0;
        const totalUsinasProducaoBaixa = 0;
        //----------------------------------------------------------------------

        //CARDS

        const cardsTotalizadores = (
            <>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox text="Número de Unidades" number={totalUsinas} style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }} minHeight={50}>
                        <img
                            src={graphMonitor}
                            style={{
                                height: 30,
                                width: 30,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox
                        text="Potência Total Instalada"
                        number={potenciaTotalInstalada}
                        style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }}
                        minHeight={50}
                    >
                        <img
                            src={plugInversor}
                            style={{
                                height: 40,
                                width: 40,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox
                        text="Energia Total Consumida no Dia"
                        number={energiaTotalDia}
                        style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }}
                        minHeight={50}
                    >
                        <img
                            src={consumedEnergy}
                            style={{
                                height: 40,
                                width: 40,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox
                        text="Energia Total Consumida no Mês"
                        number={energiaTotalMes}
                        style={{ height: window.innerWidth < 992 ? null : 50, fontSize: "0.9rem" }}
                        minHeight={50}
                    >
                        <img
                            src={calendarCheck}
                            style={{
                                height: 30,
                                width: 30,
                                filter: "brightness(0) invert(1)",
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
            </>
        );

        const cardsStatusProducao = (
            <>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox
                        icon="fas fa-check"
                        text="Operando Normalmente"
                        number={qtdPorFiltro.qtdOnline}
                        twoColors="#3EFF3E"
                        minHeight={50}
                        checked={filtroLista.apenasOnline}
                        onClick={() => {
                            zerarFiltros();
                            setFiltroLista({
                                ...filtroLista,
                                apenasOnline: !filtroLista.apenasOnline,
                            });
                        }}
                    />
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                    <InfoBox
                        icon="fas fa-exclamation-triangle"
                        text="Sem Comunicação ou Desligado"
                        number={qtdPorFiltro.qtdOffline}
                        twoColors="#F56969"
                        minHeight={50}
                        checked={filtroLista.apenasOffline}
                        onClick={() => {
                            zerarFiltros();
                            setFiltroLista({
                                ...filtroLista,
                                apenasOffline: !filtroLista.apenasOffline,
                            });
                        }}
                    />
                </Grid>
                <Grid cols="12 6 6 6" style={{ cursor: "pointer", paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                    <InfoBox
                        text="Adv. por Fator de Potência Baixo"
                        textBold
                        number={qtdPorFiltro.qtdFatorPotenciaBaixo}
                        twoColors="#34c6ff"
                        minHeight={50}
                        checked={filtroLista.apenasFatorPotenciaBaixo}
                        onClick={() => {
                            zerarFiltros();
                            setFiltroLista({
                                ...filtroLista,
                                apenasFatorPotenciaBaixo: !filtroLista.apenasFatorPotenciaBaixo,
                            });
                        }}
                    >
                        <img
                            src={capacitor}
                            style={{
                                height: 40,
                                width: 40,
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ cursor: "pointer", paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                    <InfoBox
                        text="Adv. por Ultrapassagem de Demanda"
                        textBold
                        number={qtdPorFiltro.qtdDemandasComUltrapassagem}
                        twoColors="#ff360e"
                        minHeight={50}
                        checked={filtroLista.apenasDemandasComUltrapassagem}
                        icon="fas fa-bolt"
                        onClick={() => {
                            zerarFiltros();
                            setFiltroLista({
                                ...filtroLista,
                                apenasDemandasComUltrapassagem: !filtroLista.apenasDemandasComUltrapassagem,
                            });
                        }}
                    />
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                    <InfoBox text="Advertência por Tensão Baixa" textBold number={totalUsinasProducaoModerada} twoColors="#ffe53b" minHeight={50}>
                        <img
                            src={volts}
                            style={{
                                height: 45,
                                width: 45,
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
                <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null, fontSize: "0.8rem" }}>
                    <InfoBox text="Advertência por Tensão Alta" textBold number={totalUsinasProducaoBaixa} twoColors="#ffa600" minHeight={50}>
                        <img
                            src={volts}
                            style={{
                                height: 50,
                                width: 50,
                            }}
                            alt=""
                        />
                    </InfoBox>
                </Grid>
            </>
        );
        //----------------------------------------------------------------------

        const corAbaAtiva = "#ffffff";
        const corAbaInativa = "#ebebeb";
        const corTextoAbaAtiva = "#000000";
        const corTextoAbaInativa = "#cccccc";

        const exibirAbaSazonal = listaDemandaContagem.find((item) => item.contrato_demanda_sazonal);
        const exibirAbaNaoSazonal = listaDemandaContagem.find((item) => !item.contrato_demanda_sazonal);

        return (
            <ContentCard>
                <ContentCardBody>
                    <Row
                        style={{
                            marginBottom: 5,
                            justifyContent: "space-between",
                            paddingInline: 10,
                        }}
                    >
                        <GraficoMonitoramentoConsumo />
                        <Grid cols="12 12 12 6">
                            {window.innerWidth > 768 ? (
                                <Row>
                                    {cardsTotalizadores}
                                    {cardsStatusProducao}
                                </Row>
                            ) : (
                                <details
                                    open={usuarioLogado && !usuarioLogado.acesso_cliente}
                                    style={{
                                        marginTop: 20,
                                        padding: 20,
                                        fontSize: "1.1rem",
                                        border: "1px solid #ddd",
                                        borderRadius: 5,
                                    }}
                                >
                                    <summary>
                                        <b>Exibir totalizadores</b>
                                    </summary>
                                    <Row style={{ marginTop: 10 }}>
                                        {cardsTotalizadores}
                                        {cardsStatusProducao}
                                    </Row>
                                </details>
                            )}
                        </Grid>
                    </Row>
                    <Row>
                        {usuarioLogado && usuarioLogado.acesso_cliente ? null : (
                            <Select
                                style={{ margin: 0 }}
                                name="id_cliente"
                                options={listaCliente}
                                label="Cliente"
                                cols="12 12 3 3"
                                placeholder="Selecione o Cliente"
                                value={filtro.id_cliente}
                                onChange={(value) => {
                                    setFiltro({
                                        ...filtro,
                                        id_cliente: value,
                                        id_unidade_consumidora: null,
                                    });
                                    // buscarDadosTela();
                                }}
                            />
                        )}
                        <Select
                            style={{ margin: 0 }}
                            options={listarUCsFiltradas()}
                            label="Unidades Consumidoras"
                            cols={usuarioLogado && usuarioLogado.acesso_cliente ? "12 12 6 6" : "12 12 3 3"}
                            placeholder="Selecione as UCs"
                            isMulti={true}
                            value={filtro.unidades_consumidoras}
                            onChange={(ucsSelecionadas) => {
                                const ucQualquerDoFiltro = ucsSelecionadas[0] || {};
                                const ucCompleta = listaUnidadeConsumidora.find((uc) => ucQualquerDoFiltro.value == uc.id) || { id_cliente: null };
                                setFiltro({
                                    ...filtro,
                                    unidades_consumidoras: [...ucsSelecionadas],
                                    id_cliente: filtro.id_cliente || ucCompleta.id_cliente,
                                });
                            }}
                        />
                        <Select
                            style={{ margin: 0 }}
                            name="demanda_contrato_tipos"
                            options={listaDemandaContratoTipo.filter((tipo) => {
                                if (!filtro.id_cliente && tipo.id != 3) return true;
                                const ucFiltradaPeloCliente = listaUnidadeConsumidora.filter((uc) => uc.id_cliente == filtro.id_cliente);
                                const condicaoForaPonta = tipo.id == 1;
                                const condicaoPonta = tipo.id == 2 && !!ucFiltradaPeloCliente.find((uc) => uc.id_modalidade_tarifaria == 2); //alguma uc do cliente selecionado tem q ser horosazonal azul
                                return condicaoForaPonta || condicaoPonta;
                            })}
                            label="Tipo de Contrato de Demanda"
                            cols="12 12 3 3"
                            placeholder="Selecione o tipo de contrato de demanda"
                            value={filtro.id_tipo_contrato_demanda}
                            isClearable={false}
                            isSearchable={false}
                            onChange={(id_tipo_contrato_demanda) => {
                                setFiltro({
                                    ...filtro,
                                    id_tipo_contrato_demanda,
                                });
                            }}
                        />

                        <Grid
                            cols="12 12 1 1"
                            style={{
                                paddingTop: 15,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                paddingLeft: window.innerWidth < 992 ? 0 : null,
                            }}
                        >
                            <Button
                                solid={true}
                                style={{
                                    marginTop: 13,
                                    color: "#fff",
                                    transform: "scaleX(-1)",
                                }}
                                text={
                                    usuarioLogado.emitir_alerta_sonoro ? (
                                        <i style={{ fontSize: "1.2rem" }} className="far fa-bell" />
                                    ) : (
                                        <i style={{ fontSize: "1.2rem" }} className="far fa-bell-slash" />
                                    )
                                }
                                type={"warning"}
                                event={setEmitirAlertaSonoro}
                            />
                        </Grid>
                        <Grid cols="12 12 2 2" style={{ paddingTop: 29, paddingRight: 15 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={getDemandaContagem} />
                        </Grid>
                        <Grid cols="12 12 12 12" style={{ textAlign: "right", paddingRight: 15 }}>
                            <span style={{ textAlign: "right", width: "100%", color: "#777" }}>
                                <i>
                                    {"Atualizada em "}
                                    {dataHoraUltimaAtualizacao}
                                </i>
                            </span>
                        </Grid>
                    </Row>
                </ContentCardBody>

                <ContentCardBody>
                    <Row>
                        {exibirAbaSazonal ? (
                            <Grid
                                cols="5 4 4 4"
                                style={{
                                    padding: "15px 0px 5px 10px",
                                    marginLeft: 7,
                                    backgroundColor: abaSazonalSelcionada ? corAbaAtiva : corAbaInativa,
                                    color: abaSazonalSelcionada ? corTextoAbaAtiva : corTextoAbaInativa,
                                    marginBottom: 0,
                                    zIndex: abaSazonalSelcionada ? 1 : 0,
                                    borderRadius: "10px 10px 0 0",
                                    border: `solid 1px ${abaSazonalSelcionada ? "#ddd" : corAbaInativa}`,
                                    cursor: "pointer",
                                    textAlign: "center",
                                }}
                                onClick={() => {
                                    setAbaSazonalSelcionada(true);
                                }}
                            >
                                <h6>Sazonal</h6>
                            </Grid>
                        ) : null}
                        {exibirAbaNaoSazonal ? (
                            <Grid
                                cols="5 4 4 4"
                                style={{
                                    padding: "15px 0px 5px 10px",
                                    backgroundColor: abaSazonalSelcionada ? corAbaInativa : corAbaAtiva,
                                    color: abaSazonalSelcionada ? corTextoAbaInativa : corTextoAbaAtiva,
                                    marginBottom: 0,
                                    zIndex: abaSazonalSelcionada ? 0 : 1,
                                    borderRadius: "10px 10px 0 0",
                                    border: `solid 1px ${abaSazonalSelcionada ? corAbaInativa : "#ddd"}`,
                                    cursor: "pointer",
                                    textAlign: "center",
                                }}
                                onClick={() => {
                                    setAbaSazonalSelcionada(false);
                                }}
                            >
                                <h6>Não Sazonal</h6>
                            </Grid>
                        ) : null}
                    </Row>
                    <Table
                        responsive
                        style={{
                            paddingTop: 0,
                            minWidth: 890,
                        }}
                    >
                        <THead>
                            <Tr>
                                <Th backgroundColor={"#FFFFFF"} sticky alignCenter colSpan={abaSazonalSelcionada ? "4" : "2"}>
                                    Atingidas
                                </Th>
                                {usuarioLogado.administrador ? (
                                    <Th backgroundColor={"#FFFFFF"} sticky>
                                        Cliente
                                    </Th>
                                ) : null}
                                <Th colSpan={2} backgroundColor={"#FFFFFF"} sticky>
                                    Unidade Consumidora
                                </Th>
                                <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    Data/Hora
                                </Th>
                                <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    Gráfico
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>P</p>(kW)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>Q</p>(kVAr)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>S</p>(kVA)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>I</p>(Ampere)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>V</p>(volts)
                                </Th>
                                <Th minWidth={50} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>FP</p>
                                </Th>
                                <Th minWidth={100} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th colSpan={4} style={{ border: "none" }}>
                                                    P (kW) Máx. no Mês (Demanda)
                                                </th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style={{
                                                        border: "1px solid #ddd",
                                                        padding: 4,
                                                        textAlign: "center",
                                                        minWidth: 90,
                                                        textWrap: "nowrap",
                                                        fontSize: "0.8rem",
                                                    }}
                                                >
                                                    Descrição
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid #ddd",
                                                        padding: 4,
                                                        textAlign: "center",
                                                        minWidth: 100,
                                                        textWrap: "nowrap",
                                                        fontSize: "0.8rem",
                                                    }}
                                                >
                                                    Data/Hora
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid #ddd",
                                                        padding: 4,
                                                        textAlign: "center",
                                                        minWidth: 60,
                                                        textWrap: "nowrap",
                                                        fontSize: "0.8rem",
                                                    }}
                                                >
                                                    Demanda
                                                </th>
                                                <th
                                                    style={{
                                                        border: "1px solid #ddd",
                                                        padding: 4,
                                                        textAlign: "center",
                                                        minWidth: 80,
                                                        textWrap: "nowrap",
                                                        fontSize: "0.8rem",
                                                    }}
                                                >
                                                    Consumo
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </Th>
                                <Th minWidth={150} backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    <p style={{ margin: 0 }}>Valor de Demanda</p>
                                    <p style={{ margin: 0 }}>Contratada do</p>
                                    <p style={{ margin: 0 }}>Mês Atual (kW)</p>
                                </Th>
                                {abaSazonalSelcionada ? (
                                    <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                        Ciclo Sazonal
                                    </Th>
                                ) : (
                                    <>
                                        <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                            Primeiro Mês Não Sazonal
                                        </Th>
                                        <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                            Nº Meses Sem Sazonalidade
                                        </Th>
                                    </>
                                )}

                                <Th backgroundColor={"#FFFFFF"} sticky alignCenter>
                                    Sazonalidade
                                </Th>
                            </Tr>
                        </THead>
                        <TBody>
                            {listaDemandaContagem
                                .filter((item) => (abaSazonalSelcionada ? item.contrato_demanda_sazonal : !item.contrato_demanda_sazonal))
                                .map((item, i, listaAtual) => (
                                    <LinhaMonitoramentoConsumo
                                        item={item}
                                        ultimaLinha={i === listaAtual.length - 1}
                                        filtro={filtro}
                                        abaSazonalSelcionada={abaSazonalSelcionada}
                                        filtroLista={filtroLista}
                                        filtros={filtros}
                                        setQtdPorFiltro={setQtdPorFiltro}
                                    />
                                ))}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }

    function LinhaMonitoramentoConsumo({ item, ultimaLinha, filtro, filtroLista, filtros, setQtdPorFiltro }) {
        // Estado local para manter kvar_ind_simulado
        const [kvarIndSimulado, setKvarIndSimulado] = useState(item.consumoLeitura.kvar_ind_simulado || 0);

        const sazonalidadeDiferenteDaUC = item.contrato_demanda_sazonal != item.sazonal;

        let restantes = item.competencias - item.lidas;
        let cor = "#222";
        if (item.atingidas < 3) {
            if (restantes <= 3 && item.atingidas <= 1) {
                cor = "#c92e2b";
            } else if (restantes <= 4 && item.atingidas <= 2) {
                cor = "#f7e11b";
            }
        }

        const avisoSazonalidadeDiferente = (
            <div
                style={{
                    backgroundColor: "#fd5959",
                    color: "#ffffff",
                    padding: 5,
                    borderRadius: "0.3rem",
                    textAlign: "center",
                }}
            >
                <div style={{ width: "100%", textWrap: "nowrap" }}>A sazonalidade da UC é diferente do contrato</div>
                <div>
                    (UC:{item.contrato_demanda_sazonal ? " Sazonal " : " Não Sazonal "} - Contrato:
                    {item.sazonal ? " Sazonal " : " Não Sazonal "})
                </div>
            </div>
        );
        //----------------------------------------------------------------------------------------------------------------------------------
        const horosazonalVerde = item.id_modalidade_tarifaria == 1;
        const horosazonalAzul = item.id_modalidade_tarifaria == 2;

        //item.demanda_fora_ponta_contratada_mes_atual

        //DEFINIR COR DE ATINGIDA E ULTRAPASSAGEM
        //inclui 4 atributos com cores indefinidas
        item.consumoLeitura.cor_maior_demanda_mes = null;
        item.consumoLeitura.cor_demanda_ponta = null;
        item.consumoLeitura.cor_demanda_fora_ponta = null;
        item.consumoLeitura.cor_demanda_reservado = null;

        let corTabelaDemanda = "#ddd";

        //HOROSAZONAL VERDE pega o maior dos 3 e compara com o fora ponta
        if (horosazonalVerde) {
            corTabelaDemanda = "#00d85f";
            item.consumoLeitura.cor_maior_demanda_mes = calcularCorDemanda(item.consumoLeitura.maior_demanda_mes, item.demanda_fora_ponta_contratada_mes_atual);
            const valores = [parseFloat(item.consumoLeitura.demanda_ponta), parseFloat(item.consumoLeitura.demanda_fora_ponta), parseFloat(item.consumoLeitura.demanda_reservado)];
            const maior = Math.max(...valores);
            const indiceMaior = valores.indexOf(maior);
            switch (indiceMaior) {
                case 0:
                    item.consumoLeitura.cor_demanda_ponta = calcularCorDemanda(item.consumoLeitura.demanda_ponta, item.demanda_fora_ponta_contratada_mes_atual);
                    break;
                case 1:
                    item.consumoLeitura.cor_demanda_fora_ponta = calcularCorDemanda(item.consumoLeitura.demanda_fora_ponta, item.demanda_fora_ponta_contratada_mes_atual);
                    break;
                case 2:
                    item.consumoLeitura.cor_demanda_reservado = calcularCorDemanda(item.consumoLeitura.demanda_reservado, item.demanda_fora_ponta_contratada_mes_atual);
                    break;
                default:
                    break;
            }
        }

        //HOROSAZONAL AZUL compara ponta com ponta e fora ponta com fora ponta
        if (horosazonalAzul) {
            corTabelaDemanda = "#34cbff";
            item.consumoLeitura.cor_demanda_ponta = calcularCorDemanda(item.consumoLeitura.demanda_ponta, item.demanda_ponta_contratada_mes_atual);
            item.consumoLeitura.cor_demanda_fora_ponta = calcularCorDemanda(item.consumoLeitura.demanda_fora_ponta, item.demanda_fora_ponta_contratada_mes_atual);
        }

        const fatorPotencia = calcularFatorPotencia({
            p: item.consumoLeitura.kw,
            q: item.consumoLeitura.kvar_ind,
        });

        const fatorPotenciaSimulado = calcularFatorPotencia({
            p: item.consumoLeitura.kw,
            q: kvarIndSimulado,
        });

        //define se está online ou offline ---------------------------------------------
        const dataHoraLeitura = new Date(item.consumoLeitura.data_hora);
        const dataHoraAtualMenos30Min = new Date();
        dataHoraAtualMenos30Min.setMinutes(dataHoraAtualMenos30Min.getMinutes() - 30);
        const onlineUltimos30Min = dataHoraLeitura >= dataHoraAtualMenos30Min;
        //--------------------------------------------------------------------------------

        const naoCorrespondeFiltroDemandaUltrapassagem =
            filtroLista.apenasDemandasComUltrapassagem &&
            item.consumoLeitura.cor_demanda_ponta !== "#ff6666" &&
            item.consumoLeitura.cor_demanda_fora_ponta !== "#ff6666" &&
            item.consumoLeitura.cor_demanda_reservado !== "#ff6666";

        const naoCorrespondeFiltroFP = filtroLista.apenasFatorPotenciaBaixo && (isNaN(fatorPotencia) || fatorPotencia >= 0.92);
        const naoCorrespondeFiltroOnline = filtroLista.apenasOnline && !onlineUltimos30Min;
        const naoCorrespondeFiltroOffline = filtroLista.apenasOffline && onlineUltimos30Min;

        const ignorarLinha = naoCorrespondeFiltroDemandaUltrapassagem || naoCorrespondeFiltroFP || naoCorrespondeFiltroOnline || naoCorrespondeFiltroOffline;

        //---------------------------------------------------INCREMENTA OS TOTAIS DE CADA FILTRO------------------------------------------------------------
        //só contabiliza se estiver ok com os filtros
        if (!ignorarLinha) {
            if (
                filtros.qtdDemandasComUltrapassagem >= 0 &&
                (item.consumoLeitura.cor_demanda_ponta === "#ff6666" ||
                    item.consumoLeitura.cor_demanda_fora_ponta === "#ff6666" ||
                    item.consumoLeitura.cor_demanda_reservado === "#ff6666")
            ) {
                filtros.qtdDemandasComUltrapassagem++;
            }

            if (filtros.qtdFatorPotenciaBaixo >= 0 && !isNaN(fatorPotencia) && fatorPotencia < 0.92) {
                filtros.qtdFatorPotenciaBaixo++;
            }

            if (onlineUltimos30Min && filtros.qtdOnline >= 0) {
                filtros.qtdOnline++;
            }
            if (!onlineUltimos30Min && filtros.qtdOffline >= 0) {
                filtros.qtdOffline++;
            }
        }

        //----------------------------------------------------------------------------------------------------------------------------------------------------

        //altera o state só na última vez, depois que já incrementou todos valores
        if (ultimaLinha) {
            const filtroAtualizado = { ...qtdPorFiltro };

            filtroAtualizado.qtdOnline = filtros.qtdOnline || 0;
            filtroAtualizado.qtdOffline = filtros.qtdOffline || 0;
            filtroAtualizado.qtdDemandasComUltrapassagem = filtros.qtdDemandasComUltrapassagem || 0;
            filtroAtualizado.qtdFatorPotenciaBaixo = filtros.qtdFatorPotenciaBaixo || 0;

            filtros.qtdOnline = null; //reseta o contator pra nulo, dai ele falha no teste que faz incrementar
            filtros.qtdOffline = null; //reseta o contator pra nulo, dai ele falha no teste que faz incrementar
            filtros.qtdDemandasComUltrapassagem = null; //reseta o contator pra nulo, dai ele falha no teste que faz incrementar
            filtros.qtdFatorPotenciaBaixo = null; //reseta o contator pra nulo, dai ele falha no teste que faz incrementar

            if (JSON.stringify(qtdPorFiltro) !== JSON.stringify(filtroAtualizado)) {
                setQtdPorFiltro(filtroAtualizado);
            }
        }

        //retorna null quando existe filtro aplicado e a linha atual não se aplica a algum deles--------------------------------------------------------------

        if (ignorarLinha) return null;

        //----------------------------------------------------------------------------------------------------------------------------------------------------

        let exibirAlertaPkwVermelho = false;
        let exibirAlertaPkwVerde = false;

        //1 fora ponta e 2 ponta
        if (filtro.id_tipo_contrato_demanda == 1) {
            exibirAlertaPkwVermelho = parseFloat(item.consumoLeitura.kw) > parseFloat(item.demanda_fora_ponta_contratada_mes_atual) * 1.05;
            exibirAlertaPkwVerde =
                parseFloat(item.consumoLeitura.kw) >= parseFloat(item.demanda_fora_ponta_contratada_mes_atual) &&
                parseFloat(item.consumoLeitura.kw) <= parseFloat(item.demanda_fora_ponta_contratada_mes_atual) * 1.05;
        } else if (filtro.id_tipo_contrato_demanda == 2) {
            exibirAlertaPkwVermelho = parseFloat(item.consumoLeitura.kw) > parseFloat(item.demanda_ponta_contratada_mes_atual) * 1.05;
            exibirAlertaPkwVerde =
                parseFloat(item.consumoLeitura.kw) >= parseFloat(item.demanda_ponta_contratada_mes_atual) &&
                parseFloat(item.consumoLeitura.kw) <= parseFloat(item.demanda_ponta_contratada_mes_atual) * 1.05;
        }

        const valorQAlterado = parseFloat(item.consumoLeitura.kvar_ind) && kvarIndSimulado && kvarIndSimulado !== parseFloat(item.consumoLeitura.kvar_ind);
        return (
            <>
                <Tr key={item.id}>
                    {abaSazonalSelcionada ? (
                        <>
                            <Td
                                borderLeft="1px solid #81c784"
                                borderRight="1px solid #81c784"
                                borderBottom={ultimaLinha ? "1px solid #81c784" : null}
                                color={"#fff"}
                                minWidth={40}
                                backgroundColor={item.atingidas >= 1 ? "#28a745" : "#fff"}
                                alignCenter
                            ></Td>
                            <Td
                                minWidth={40}
                                borderLeft="1px solid #81c784"
                                borderRight="1px solid #81c784"
                                borderBottom={ultimaLinha ? "1px solid #81c784" : null}
                                color={item.atingidas >= 2 ? "#fff" : "#000"}
                                backgroundColor={item.atingidas >= 2 ? "#28a745" : "#fff"}
                                alignCenter
                            >
                                {item.atingidas}
                            </Td>
                            <Td
                                borderLeft="1px solid #81c784"
                                borderRight="1px solid #81c784"
                                borderBottom={ultimaLinha ? "1px solid #81c784" : null}
                                color={"#fff"}
                                minWidth={40}
                                backgroundColor={item.atingidas >= 3 ? "#28a745" : "#fff"}
                                alignCenter
                            ></Td>
                            <Td
                                color={"#fff"}
                                title={`Total de ultrapassagens:${parseInt(item.ultrapassagens || 0)}`}
                                minWidth={40}
                                backgroundColor={parseInt(item.ultrapassagens) ? "#fa3c3c" : "black"}
                                borderBottom={ultimaLinha ? `1px solid ${parseInt(item.ultrapassagens) ? "#fa3c3c" : "black"}` : null}
                                alignCenter
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        flexDirection: "column",
                                        borderRadius: 3,
                                    }}
                                >
                                    <div style={{ fontSize: "1.4rem", fontWeight: "bold" }} className="info-box-icon">
                                        <i className="fas fa-angle-double-up"></i>
                                    </div>
                                    <div style={{ fontSize: "0.9rem", fontWeight: "bold" }} className="info-box-number">
                                        {item.ultrapassagens}
                                    </div>
                                </div>
                            </Td>
                        </>
                    ) : (
                        <>
                            <Td>
                                <div
                                    style={{
                                        margin: 0,
                                        flexDirection: "column",
                                        alignItems: "center",
                                        padding: "5px 5px 0px 5px",
                                        minHeight: "fit-content",
                                    }}
                                    className="info-box bg-green"
                                    title="Atingidas sem Ultrapassagens"
                                >
                                    <span
                                        className="info-box-icon"
                                        style={{
                                            width: window.innerWidth < 576 ? 20 : 70,
                                            fontSize: "1.3rem",
                                        }}
                                    >
                                        <i className="fas fa-angle-up"></i>
                                    </span>
                                    <span style={{ margin: 0 }} className="info-box-number">
                                        {item.atingidas - (item.ultrapassagens || 0)}
                                    </span>
                                </div>
                            </Td>
                            <Td>
                                <div
                                    style={{
                                        margin: 0,
                                        flexDirection: "column",
                                        alignItems: "center",
                                        padding: "5px 5px 0px 5px",
                                        minHeight: "fit-content",
                                    }}
                                    className="info-box bg-red"
                                    title="Atingidas com Ultrapassagens"
                                >
                                    <span
                                        className="info-box-icon"
                                        style={{
                                            width: window.innerWidth < 576 ? 20 : 70,
                                            fontSize: "1.3rem",
                                        }}
                                    >
                                        <i className="fas fa-angle-double-up"></i>
                                    </span>
                                    <span style={{ margin: 0 }} className="info-box-number">
                                        {item.ultrapassagens || 0}
                                    </span>
                                </div>
                            </Td>
                        </>
                    )}
                    {usuarioLogado.administrador ? (
                        <Td title={item.nome_cliente}>{item.nome_cliente.length > 25 ? item.nome_cliente.substring(0, 25) + "..." : item.nome_cliente}</Td>
                    ) : null}
                    <Td minWidth={140} title={item.nome_unidade_consumidora}>
                        {item.nome_unidade_consumidora}
                    </Td>
                    {sazonalidadeDiferenteDaUC ? (
                        <Td>{avisoSazonalidadeDiferente}</Td>
                    ) : (
                        <Td padding={10}>
                            <TooltipAgendamento item={item} />
                            <TooltipoPeriodoTeste item={item} />
                        </Td>
                    )}

                    <Td alignCenter fontSize="1rem">
                        <div className="d-flex flex-column justify-content-center align-items-center" style={{ position: "relative" }}>
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    fontSize: "1rem",
                                    color: onlineUltimos30Min ? "#64db64" : "#f66",
                                }}
                            >
                                <i className="fas fa-wifi"></i>
                            </div>
                            {!onlineUltimos30Min && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        fontSize: "1rem",
                                        color: "#f66",
                                    }}
                                >
                                    <i className="fas fa-slash"></i>
                                </div>
                            )}
                            <span
                                style={{
                                    fontSize: "0.7rem",
                                    paddingTop: 18,
                                    color: onlineUltimos30Min ? "#64db64" : "#f66",
                                }}
                            >
                                {onlineUltimos30Min ? <b>online</b> : <b>offline</b>}
                            </span>
                            {item.consumoLeitura.data_hora ? (
                                <div style={{ padding: 5 }}>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.data_hora)}</div>
                            ) : (
                                "-"
                            )}
                        </div>
                    </Td>
                    <Td alignCenter minWidth={80}>
                        <Button
                            style={{ width: 80, height: 80 }}
                            title="Ver o gráfico do dia"
                            text={
                                <div>
                                    <div>
                                        <i style={{ fontSize: "2rem" }} className="fas fa-chart-line"></i>
                                    </div>
                                    <b>P e Q</b>
                                </div>
                            }
                            type={"info"}
                            event={() => {
                                getListaGraficoDia({
                                    id_consumo_integracao: item.consumoLeitura.id_consumo_integracao,
                                    dataInicial: dataInicialGrafico,
                                    dataFinal: dataFinalGrafico,
                                });
                                setModoTela("graficoDia", item);
                            }}
                        />
                    </Td>
                    <Td alignCenter fontSize="1rem">
                        <div
                            style={{
                                lineHeight: "1.1rem",
                                color: exibirAlertaPkwVermelho ? "#f66" : exibirAlertaPkwVerde ? "#64db64" : null,
                            }}
                        >
                            {exibirAlertaPkwVermelho && (
                                <AlertIcon
                                    animationClass={item.consumoLeitura.status_visualizado_kw ? "alerta-triangulo-visualizado" : "alerta-triangulo"}
                                    title="A demanda medida em kW está igual ou superior à contratada"
                                    event={() => {
                                        if (window.confirm('A demanda medida em kW está igual ou superior à contratada, deseja marcar alerta como "visualizado"?')) {
                                            setStatusVisualizado({
                                                id: item.consumoLeitura.id,
                                                coluna: "status_visualizado_kw",
                                            });
                                            item.consumoLeitura.status_visualizado_kw = true;
                                        }
                                    }}
                                />
                            )}
                            <div
                                style={{
                                    padding: exibirAlertaPkwVermelho ? "2px 2px 12px 2px" : "17px 2px 2px 2px",
                                }}
                            >
                                {!isNaN(parseFloat(item.consumoLeitura.kw)) ? <b title="P(kW)">{adicionarPontosMilhar(item.consumoLeitura.kw)}</b> : "-"}
                            </div>
                        </div>
                    </Td>
                    <Td alignCenter fontSize="1rem">
                        <div
                            style={{
                                position: "relative",
                                padding: "17px 2px 2px 2px",
                                textDecoration: valorQAlterado ? "line-through" : "none",
                            }}
                        >
                            {valorQAlterado ? <i className="fas fa-arrow-down display-6" style={{ position: "absolute", left: "40%", top: 34, color: "#25f655" }} /> : null}
                            {!isNaN(parseFloat(item.consumoLeitura.kvar_ind)) ? adicionarPontosMilhar(item.consumoLeitura.kvar_ind) : "-"}
                        </div>
                        {valorQAlterado ? <div>{adicionarPontosMilhar(kvarIndSimulado)}</div> : null}
                    </Td>
                    <Td alignCenter title="S = Raiz Quadrada de (P² + Q²)" fontSize="1rem">
                        <div style={{ padding: "17px 2px 2px 2px" }}>
                            {!isNaN(parseFloat(item.consumoLeitura.kw)) && !isNaN(parseFloat(item.consumoLeitura.kvar_ind))
                                ? adicionarPontosMilhar(Math.sqrt(Math.pow(parseFloat(item.consumoLeitura.kw), 2) + Math.pow(parseFloat(item.consumoLeitura.kvar_ind), 2)))
                                : "-"}
                        </div>
                    </Td>
                    <Td alignCenter fontSize="1rem">
                        <div style={{ padding: "17px 2px 2px 2px" }}>?</div>
                    </Td>
                    <Td alignCenter fontSize="1rem">
                        <div style={{ padding: "17px 2px 2px 2px" }}>?</div>
                    </Td>
                    <Td alignCenter fontSize="1rem">
                        {!isNaN(fatorPotencia) ? (
                            <div
                                style={{
                                    lineHeight: "1.1rem",
                                    color: fatorPotencia < 0.92 ? "#f66" : "#64db64",
                                }}
                            >
                                {fatorPotencia < 0.92 && (
                                    <AlertIcon
                                        animationClass={item.consumoLeitura.status_visualizado_fp ? "alerta-triangulo-visualizado" : "alerta-triangulo"}
                                        title="FP está abaixo de 0,92"
                                        event={() => {
                                            if (window.confirm('FP está abaixo de 0,92, deseja marcar alerta como "visualizado"?')) {
                                                setStatusVisualizado({
                                                    id: item.consumoLeitura.id,
                                                    coluna: "status_visualizado_fp",
                                                });
                                                item.consumoLeitura.status_visualizado_fp = true;
                                            }
                                        }}
                                    />
                                )}
                                <div
                                    style={{
                                        padding: fatorPotencia < 0.92 ? "2px 2px 12px 2px" : "17px 2px 2px 2px",
                                    }}
                                >
                                    <b title="FP = P / S">{adicionarPontosMilhar(fatorPotencia)}</b>
                                </div>
                            </div>
                        ) : (
                            "-"
                        )}
                    </Td>
                    <Td alignCenter>
                        <table>
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                        }}
                                    >
                                        Maior do Mês
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 100,
                                        }}
                                    >
                                        {item.consumoLeitura.datahora_maior_demanda_mes ? (
                                            <div>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.datahora_maior_demanda_mes)}</div>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 60,
                                            backgroundColor: item.consumoLeitura.cor_maior_demanda_mes,
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.consumoLeitura.maior_demanda_mes) || "-"}
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 80,
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.consumoLeitura.energia_total) || "-"}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                        }}
                                    >
                                        Ponta
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 100,
                                        }}
                                    >
                                        {item.consumoLeitura.datahora_demanda_ponta ? (
                                            <div>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.datahora_demanda_ponta)}</div>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 60,
                                            backgroundColor: horosazonalAzul ? item.consumoLeitura.cor_demanda_ponta : null, //se for azul pinta, senao só deixa em negrito na cor definida
                                            fontWeight: horosazonalVerde && item.consumoLeitura.cor_demanda_ponta ? "bold" : "normal",
                                            color: horosazonalVerde && item.consumoLeitura.cor_demanda_ponta ? item.consumoLeitura.cor_demanda_ponta : null,
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.consumoLeitura.demanda_ponta) || "-"}
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 80,
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.consumoLeitura.energia_ponta) || "-"}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                        }}
                                    >
                                        Fora Ponta
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 100,
                                        }}
                                    >
                                        {item.consumoLeitura.datahora_demanda_fora_ponta ? (
                                            <div>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.datahora_demanda_fora_ponta)}</div>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 60,
                                            backgroundColor: horosazonalAzul ? item.consumoLeitura.cor_demanda_fora_ponta : null, //se for azul pinta, senao só deixa em negrito na cor definida
                                            fontWeight: horosazonalVerde && item.consumoLeitura.cor_demanda_fora_ponta ? "bold" : "normal",
                                            color: horosazonalVerde && item.consumoLeitura.cor_demanda_fora_ponta ? item.consumoLeitura.cor_demanda_fora_ponta : null,
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.consumoLeitura.demanda_fora_ponta) || "-"}
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 80,
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.consumoLeitura.energia_fora_ponta) || "-"}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                        }}
                                    >
                                        Reservado
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 100,
                                        }}
                                    >
                                        {item.consumoLeitura.datahora_demanda_reservado ? (
                                            <div>{DateFormat.formatarDataHoraSqlParaTelaShortYear(item.consumoLeitura.datahora_demanda_reservado)}</div>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 60,
                                            backgroundColor: horosazonalAzul ? item.consumoLeitura.cor_demanda_reservado : null, //se for azul pinta, senao só deixa em negrito na cor definida
                                            fontWeight: horosazonalVerde && item.consumoLeitura.cor_demanda_reservado ? "bold" : "normal",
                                            color: horosazonalVerde && item.consumoLeitura.cor_demanda_reservado ? item.consumoLeitura.cor_demanda_reservado : null,
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.consumoLeitura.demanda_reservado) || "-"}
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            textAlign: "center",
                                            padding: "2px 5px",
                                            fontSize: "0.8rem",
                                            textWrap: "nowrap",
                                            minWidth: 80,
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.consumoLeitura.energia_reservado) || "-"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Td>
                    <Td alignCenter minWidth={160}>
                        <table>
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            padding: 4,
                                            textAlign: "center",
                                            minWidth: 60,
                                            textWrap: "nowrap",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        Ponta
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            padding: 4,
                                            textAlign: "center",
                                            minWidth: 60,
                                            textWrap: "nowrap",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.demanda_ponta_contratada_mes_atual) || "-"}
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            padding: 4,
                                            textAlign: "center",
                                            minWidth: 60,
                                            textWrap: "nowrap",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        Fora Ponta
                                    </td>
                                    <td
                                        style={{
                                            border: `1px solid ${corTabelaDemanda}`,
                                            padding: 4,
                                            textAlign: "center",
                                            minWidth: 60,
                                            textWrap: "nowrap",
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {adicionarPontosMilhar(item.demanda_fora_ponta_contratada_mes_atual) || "-"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Td>
                    {sazonalidadeDiferenteDaUC ? <Td alignCenter>-</Td> : null}
                    {abaSazonalSelcionada ? (
                        <Td padding={10} alignCenter>
                            <table>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                border: "1px solid #ddd",
                                                textAlign: "center",
                                                padding: 4,
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            Início
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #ddd",
                                                textAlign: "center",
                                                padding: 4,
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            {sazonalidadeDiferenteDaUC
                                                ? "-"
                                                : `${String(item.competencia_inicio).substring(4, 6)}/${String(item.competencia_inicio).substring(0, 4)}`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                border: "1px solid #ddd",
                                                textAlign: "center",
                                                padding: 4,
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            Fim
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #ddd",
                                                textAlign: "center",
                                                padding: 4,
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            {sazonalidadeDiferenteDaUC ? "-" : `${String(item.competencia_fim).substring(4, 6)}/${String(item.competencia_fim).substring(0, 4)}`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                border: "1px solid #ddd",
                                                textAlign: "center",
                                                padding: 4,
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            Ciclo
                                        </td>
                                        <td
                                            style={{
                                                border: "1px solid #ddd",
                                                textAlign: "center",
                                                padding: 4,
                                                fontSize: "0.8rem",
                                            }}
                                        >
                                            <div
                                                style={{ textWrap: "nowrap" }}
                                                color={cor}
                                                fontWeight="bold"
                                                title={
                                                    item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3
                                                        ? null
                                                        : item.competencias - item.lidas == 3 - item.atingidas
                                                        ? `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e resta${
                                                              item.competencias - item.lidas > 1 ? "m" : ""
                                                          } ${item.competencias - item.lidas} fatura${item.competencias - item.lidas > 1 ? "s" : ""} no ciclo sazonal`
                                                        : `É necessário atingir ${3 - item.atingidas} demanda${3 - item.atingidas > 1 ? "s" : ""} e ${
                                                              item.competencias == item.lidas
                                                                  ? "o ciclo sazonal já está completo"
                                                                  : `resta${item.competencias - item.lidas > 1 ? "m" : ""} ${item.competencias - item.lidas} fatura${
                                                                        item.competencias - item.lidas > 1 ? "s" : ""
                                                                    } no ciclo sazonal`
                                                          }`
                                                }
                                                backgroundColor={
                                                    item.competencias - item.lidas > 3 - item.atingidas || item.atingidas >= 3
                                                        ? null
                                                        : item.competencias - item.lidas == 3 - item.atingidas
                                                        ? "#ffb74d"
                                                        : "#e57373"
                                                }
                                                alignCenter
                                            >
                                                {sazonalidadeDiferenteDaUC ? "-" : `${item.lidas || 1} de ${item.competencias}`}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Td>
                    ) : (
                        <Td padding={10} alignCenter>
                            {sazonalidadeDiferenteDaUC
                                ? "-"
                                : `${String(item.competencia_base_sazonalidade).substring(4, 6)}/${String(item.competencia_base_sazonalidade).substring(0, 4)}`}
                        </Td>
                    )}
                    {item.sazonal ? null : (
                        <Td padding={10} alignCenter>
                            {item.meses_nao_sazonal || 1}
                        </Td>
                    )}

                    <Td color="#fff" backgroundColor="#40838f" alignCenter>
                        {sazonalidadeDiferenteDaUC ? "-" : parseFloat(item.sazonalidade).toFixed(2).replace(".", ",")}
                    </Td>
                </Tr>
                <Tr key={item.id + "-simulado"} style={{ backgroundColor: "#f4f4f4" }}>
                    {abaSazonalSelcionada ? (
                        <>
                            <Td colSpan={5} style={{ borderTop: "none" }}>
                                <div className="d-flex pl-2">
                                    <i class="fas fa-level-up-alt" style={{ transform: "rotate(90deg)", fontSize: "1.2rem" }}></i>
                                    <div className="pl-4">
                                        <b>Simular banco de capacitor</b>
                                    </div>
                                </div>
                            </Td>
                        </>
                    ) : (
                        <>
                            <Td colSpan={3} style={{ borderTop: "none" }}>
                                <div className="d-flex pl-2">
                                    <i class="fas fa-level-up-alt" style={{ transform: "rotate(90deg)", fontSize: "1.2rem" }}></i>
                                    <div className="pl-4">
                                        <b>Simular banco de capacitor</b>
                                    </div>
                                </div>
                            </Td>
                        </>
                    )}

                    <Td alignCenter colSpan={usuarioLogado.administrador ? 4 : 3} style={{ borderTop: "none" }}>
                        <div className="d-flex align-items-center">
                            <div style={{ width: "100%", height: 2, backgroundColor: "#24c3de" }}></div>
                            <div style={{ height: 10, width: 10, borderRadius: "100%", border: "2px solid #24c3de" }}></div>
                        </div>
                    </Td>
                    <Td alignCenter style={{ borderTop: "none" }}></Td>
                    <Td alignCenter fontSize="1rem" style={{ borderTop: "none" }}>
                        {!isNaN(parseFloat(item.consumoLeitura.kvar_ind)) ? (
                            <Row style={{ width: 90, paddingInline: 5, justifyContent: "center" }}>
                                <NumberFormat
                                    className="form-control"
                                    placeholder="simular"
                                    decimalSeparator={","}
                                    decimalScale={2}
                                    fixedDecimalScale={2}
                                    allowNegative={false}
                                    onChange={(e) => {
                                        const valorFormatado = parseFloat(e.target.value.replace(",", ".")) || 0;
                                        setKvarIndSimulado(item.consumoLeitura.kvar_ind - valorFormatado);
                                    }}
                                />
                            </Row>
                        ) : (
                            "-"
                        )}
                    </Td>
                    <Td alignCenter colSpan={3} style={{ borderTop: "none" }}>
                        {valorQAlterado ? (
                            <div className="d-flex align-items-center justify-content-center">
                                <div style={{ width: "70%", height: 2, backgroundColor: "#25f655" }}></div>
                                <div style={{ color: "#25f655" }}>
                                    <i className="fas fa-arrow-right" />
                                </div>
                            </div>
                        ) : null}
                    </Td>

                    <Td alignCenter style={{ borderTop: "none" }}>
                        {!valorQAlterado || isNaN(fatorPotenciaSimulado) ? (
                            " - "
                        ) : (
                            <div className="d-flex align-items-center">
                                <i
                                    style={{ color: fatorPotenciaSimulado < 0.92 ? "#f66" : "#64db64" }}
                                    className={`fas fa-${fatorPotenciaSimulado < 0.92 ? "times" : "check"} mr-2`}
                                />
                                <div>
                                    <span
                                        title={
                                            fatorPotenciaSimulado < 0.92 ? "Fator de potência ainda está abaixo, recomenda-se adicionar mais capacitores" : "Fator de potência Ok"
                                        }
                                    >
                                        {adicionarPontosMilhar(fatorPotenciaSimulado)}
                                    </span>
                                </div>
                            </div>
                        )}
                    </Td>
                    <Td alignCenter style={{ borderTop: "none" }}></Td>
                    <Td alignCenter minWidth={160} style={{ borderTop: "none" }}></Td>
                    {sazonalidadeDiferenteDaUC ? <Td alignCenter style={{ borderTop: "none" }}></Td> : null}
                    {abaSazonalSelcionada ? <Td padding={10} alignCenter style={{ borderTop: "none" }}></Td> : <Td padding={10} alignCenter style={{ borderTop: "none" }}></Td>}
                    {item.sazonal ? null : <Td padding={10} alignCenter style={{ borderTop: "none" }}></Td>}

                    <Td color="#fff" backgroundColor="#40838f" alignCenter style={{ borderTop: "none" }}></Td>
                </Tr>
            </>
        );
    }

    function calcularFatorPotencia({ p, q }) {
        const fp = parseFloat((parseFloat(p) / Math.sqrt(Math.pow(parseFloat(p), 2) + Math.pow(parseFloat(q), 2))).toFixed(2));
        return fp;
    }

    function calcularCorDemanda(valorLido, demandaContratada) {
        if (isNaN(parseFloat(valorLido))) return null;
        return parseFloat(valorLido) > parseFloat(demandaContratada) * 1.05 ? "#ff6666" : parseFloat(valorLido) >= parseFloat(demandaContratada) ? "#64db64" : null;
    }
}

const mapStateToProps = (state) => {
    return {
        usuarioLogado: state.auth.usuarioLogado,
        aguardando: state.monitoramentoConsumo.aguardando,
        modoTela: state.monitoramentoConsumo.modoTela,
        filtro: state.monitoramentoConsumo.filtro,
        listaCliente: state.monitoramentoConsumo.listaCliente,
        listaUnidadeConsumidora: state.monitoramentoConsumo.listaUnidadeConsumidora,
        listaDemandaContagem: state.monitoramentoConsumo.listaDemandaContagem,
        listaDemandaContratoTipo: state.monitoramentoConsumo.listaDemandaContratoTipo,
        dataInicialGrafico: state.monitoramentoConsumo.dataInicialGrafico,
        dataFinalGrafico: state.monitoramentoConsumo.dataFinalGrafico,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setFiltro,
            setAguardando,
            setModoTela,
            setDataInicialGrafico,
            setDataFinalGrafico,
            getDemandaContagem,
            carregarListas,
            getListaGraficoDia,
            getListaGraficoTodasIntegracoesAgrupadasPorHora,
            setStatusVisualizado,
            setEmitirAlertaSonoro,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(MonitoramentoConsumo);
