import axios from "axios";
import consts from "../consts";
import { reset as resetForm, initialize } from "redux-form";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import confirmDialog from "../common/dialog/confirmDialog";
import { adicionarBloqueioUsuarioImplantacao } from "../utils/adicionarBloqueioUsuarioImplantacao";
import { getUcValidarUsuarioImplantacao } from "../cliente/clienteActions";

export function setModoTela(modo, registro = {}) {
    return {
        type: "CONSUMO_INTEGRACAO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setAguardando(aguardando) {
    return {
        type: "CONSUMO_INTEGRACAO_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function initForm(registro = {}) {
    return [initialize("consumoIntegracaoForm", registro)];
}

export function setFiltro(filtro) {
    return {
        type: "CONSUMO_INTEGRACAO_FILTRO",
        payload: filtro,
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        if (!registro.id) {
            dispatch(setAguardando(true));
            axios
                .post(`${consts.API_URL}/consumoIntegracao`, registro)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                    dispatch(initForm(registro));
                })
                .finally(() => {
                    dispatch(setAguardando(false));
                });
        } else {
            confirmDialog("Deseja realmente alterar?", () => {
                dispatch(setAguardando(true));

                axios
                    .put(`${consts.API_URL}/consumoIntegracao`, registro)
                    .then((resp) => {
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getLista());
                        dispatch(setModoTela("lista"));
                    })
                    .catch((e) => {
                        setErroAPI(e);
                        dispatch(initForm(registro));
                    })
                    .finally(() => {
                        dispatch(setAguardando(false));
                    });
            });
        }
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        confirmDialog("Deseja realmente excluir?", () => {
            axios
                .delete(`${consts.API_URL}/consumoIntegracao?id=${registro.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        try {

            const usuarioLogado = getState().auth.usuarioLogado;
            if(!usuarioLogado) return {};

            dispatch(setAguardando(true));
            await dispatch(getUcValidarUsuarioImplantacao());

            const idCliente = getState().consumoIntegracao.filtro.id_cliente || null;
            const idUc = getState().consumoIntegracao.filtro.id_unidade_consumidora || null;
            const resp = await axios.get(`${consts.API_URL}/consumoIntegracao?id_usuario_logado=${usuarioLogado.id}&id_cliente=${idCliente}&id_unidade_consumidora=${idUc}`);
            
            //busca lista de UC
            const {listaUcValidarUsuarioImplantacao} = getState().cliente;

            const listaFiltrada = adicionarBloqueioUsuarioImplantacao(usuarioLogado, listaUcValidarUsuarioImplantacao, resp.data);
            dispatch({
                type: "CONSUMO_INTEGRACAO_LISTADO",
                payload: { data: listaFiltrada },
            });
        } catch (e) {
            setError("Não foi possível carregar a lista de integrações");
        } finally {
            dispatch(setAguardando(false));
        }

      
    };
}

export function getListaCliente() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};
            
            const request = axios.get(`${consts.API_URL}/cliente/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "CONSUMO_INTEGRACAO_CLIENTE_SELECT_LISTADO",
                payload: request,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export function getListaUnidadeConsumidora() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "CONSUMO_INTEGRACAO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
                payload: request,
            });
        } catch (e) {
            setErroAPI(e);
        }
    };
}

export function getListaConsumoIntegracaoTipos() {
    const request = axios.get(`${consts.API_URL}/consumoIntegracaoTipo/listarSelect`);
    return {
        type: "CONSUMO_INTEGRACAO_TIPOS_SELECT_LISTADO",
        payload: request,
    };
}
