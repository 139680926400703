import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../../common/template/contentCard";
import ContentCardHeader from "../../common/template/contentCardHeader";
import ContentCardBody from "../../common/template/contentCardBody";
import Row from "../../common/layout/row";
import Grid from "../../common/layout/grid";
import Table from "../../common/table/table";
import THead from "../../common/table/tHead";
import Tr from "../../common/table/tr";
import Th from "../../common/table/th";
import TBody from "../../common/table/tBody";
import Td from "../../common/table/td";
import Button from "../../common/button/button";
import Select from "../../common/form/select";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

import { setError } from "../../common/toast/toast.jsx";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    getDemanda,
    getListaGraficoCustoAnual,
    getListaCustoPorUnidadePeriodo,
    getListaGraficoCustoPorUnidadeMensal,
    getListaGraficoCustoPorUnidadeAcumuladoAno,
    getListarGraficoCustoJurosPorAtrasoPagamentoAnual,
    getListarGraficoCustoJurosPorAtrasoPagamentoMensal,
    getListaGraficoCustoPorUnidadeAnual,
    setFiltro,
} from "../analiseActions";

import { gerarRelatorio } from "../../fatura/faturaActions.js";

import { montarGraficoColumn } from "../../common/graficos/montarGraficoColumn.js";
import { montarGraficoGroupColumn } from "../../common/graficos/montarGraficoGroupColumn.js";
import { montarGraficoClusteredColumnMensalSafra } from "../../common/graficos/montarGraficoClusteredColumnMensalSafra.js";
import { montarGraficoClusteredColumnMensal } from "../../common/graficos/montarGraficoClusteredColumnMensal.js";
import { montarGraficoClusteredColumnAnual } from "../../common/graficos/montarGraficoClusteredColumnAnual.js";
import { montarGraficoClusteredColumnGrupo } from "../../common/graficos/montarGraficoClusteredColumnGrupo.js";
import { montarGraficoHorizontalBar } from "../../common/graficos/montarGraficoHorizontalBar.js";
import { adicionarPontosMilhar } from "../../utils/adicionarPontosMilhar.js";
import { ModalAlert } from "../../common/dialog/modalAlert.jsx";
import LabelAndCheckbox from "../../common/form/labelAndCheckbox.jsx";
import { convertToBase64 } from "../../utils/convertToBase64.js";

import { Loading } from "../../common/loading/loading";

function analiseCusto(props) {
    const {
        state,
        listar,
        contratoDemanda,
        listaGraficoCustoAnual,
        listaGraficoCustoPorUnidadeAcumuladoAno,
        listaGraficoCustoJurosPorAtrasoPagamentoAnual,
        listaGraficoCustoJurosPorAtrasoPagamentoMensal,
        listaUnidadeConsumidora,
        listaGraficoCustoUnidadeAnual,
        listaGraficoCustoUnidadeMensal,
        listaCustoPorUnidadePeriodo,
        filtro,
        getDemanda,
        getListaGraficoCustoAnual,
        getListaCustoPorUnidadePeriodo,
        getListaGraficoCustoPorUnidadeMensal,
        getListaGraficoCustoPorUnidadeAcumuladoAno,
        getListarGraficoCustoJurosPorAtrasoPagamentoAnual,
        getListarGraficoCustoJurosPorAtrasoPagamentoMensal,
        getListaGraficoCustoPorUnidadeAnual,
        setAguardando,
        gerarRelatorio,
        setFiltro,
        listaAnos,
    } = props;

    const contentCardRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [modalExportacaoAberto, setModalExportacaoAberto] = useState(false);
    const [graficoCustoAnual, setGraficoCustoAnual] = useState(null);
    const [graficoCustoMensal, setGraficoCustoMensal] = useState(null);
    const [graficoCustoUnidadeAnual, setGraficoCustoUnidadeAnual] = useState(null);
    const [graficoCustoUnidadeMensal, setGraficoCustoUnidadeMensal] = useState(null);
    const [graficoCustoPorUnidadeAcumuladoAno, setGraficoCustoPorUnidadeAcumuladoAno] = useState(null);
    const [graficoCustoJurosPorAtrasoPagamentoAnual, setGraficoCustoJurosPorAtrasoPagamentoAnual] = useState(null);
    const [graficoCustoJurosPorAtrasoPagamentoMensal, setGraficoCustoJurosPorAtrasoPagamentoMensal] = useState(null);

    useEffect(() => {
        atualizarTodosGraficos();
        return desmontarTodosGraficos;
    }, []);

    useEffect(() => {
        const buscarDados = async () => {
            if (!state.aguardando) return;

            await Promise.all([
                getDemanda(),
                getListaGraficoCustoAnual(),
                getListaCustoPorUnidadePeriodo(),
                getListaGraficoCustoPorUnidadeMensal(),
                getListaGraficoCustoPorUnidadeAcumuladoAno(),
                getListarGraficoCustoJurosPorAtrasoPagamentoAnual(),
                getListarGraficoCustoJurosPorAtrasoPagamentoMensal(),
                getListaGraficoCustoPorUnidadeAnual(),
            ]);

            setAguardando(false);
        };

        buscarDados();
        if (!state.aguardando) {
            atualizarTodosGraficos();
        }
    }, [state.aguardando]);

    function atualizarTodosGraficos() {
        atualizarListaGraficoCustoAnual();
        atualizarListaGraficoCustoPorUnidadeAnual();
        atualizarListaGraficoCustoPorUnidadeMensal();
        atualizarListaGraficoCustoPorUnidadeAcumuladoAno();
        atualizarListaGraficoCustoJurosPorAtrasoPagamentoAnual();
        atualizarListaGraficoCustoJurosPorAtrasoPagamentoMensal();
    }
    function desmontarTodosGraficos() {
        if (graficoCustoAnual) graficoCustoAnual.dispose();
        if (graficoCustoMensal) graficoCustoMensal.dispose();
        if (graficoCustoUnidadeAnual) graficoCustoUnidadeAnual.dispose();
        if (graficoCustoUnidadeMensal) graficoCustoUnidadeMensal.dispose();
        if (graficoCustoPorUnidadeAcumuladoAno) graficoCustoPorUnidadeAcumuladoAno.dispose();
        if (graficoCustoJurosPorAtrasoPagamentoAnual) graficoCustoJurosPorAtrasoPagamentoAnual.dispose();
        if (graficoCustoJurosPorAtrasoPagamentoMensal) graficoCustoJurosPorAtrasoPagamentoMensal.dispose();
    }

    function atualizarListaGraficoCustoAnual() {
        const graficoCustoAnualAtualizado = montarGraficoClusteredColumnAnual(state, graficoCustoAnual, "graficoCustoAnual", listaGraficoCustoAnual, "Custo Total (R$)", "R$", "");
        setGraficoCustoAnual(graficoCustoAnualAtualizado);

        const montarGraficoMensal = filtro.custo_ano_safra ? montarGraficoClusteredColumnMensalSafra : montarGraficoClusteredColumnMensal;
        const graficoCustoMensalAtualizado = montarGraficoMensal(state, graficoCustoMensal, "graficoCustoMensal", listaGraficoCustoAnual, "Custo Mensal (R$)", "R$", "");
        setGraficoCustoMensal(graficoCustoMensalAtualizado);
    }

    function atualizarListaGraficoCustoPorUnidadeAnual() {
        const graficoCustoUnidadeAnualAtualizado = montarGraficoClusteredColumnGrupo(
            state,
            graficoCustoUnidadeAnual,
            "graficoCustoUnidadeAnual",
            listaGraficoCustoUnidadeAnual.filter(
                (item) => !(filtro.unidades_custo_anual || []).length || (filtro.unidades_custo_anual || []).find((itemFiltro) => itemFiltro.label == item.agrupador)
            ),
            "Custo Total por Unidade (R$)",
            "R$",
            ""
        );
        setGraficoCustoUnidadeAnual(graficoCustoUnidadeAnualAtualizado);
    }
    function atualizarListaGraficoCustoPorUnidadeMensal() {
        const montarGrafico = filtro.custo_ano_safra ? montarGraficoClusteredColumnMensalSafra : montarGraficoClusteredColumnMensal;
        const graficoCustoUnidadeMensalAtuaizado = montarGrafico(
            state,
            graficoCustoUnidadeMensal,
            "graficoCustoUnidadeMensal",
            listaGraficoCustoUnidadeMensal.map((item) => ({
                mes: item.mes,
                anos: item.tipos
                    .filter((tipo) => !(filtro.unidades_custo_anual || []).length || (filtro.unidades_custo_anual || []).find((itemFiltro) => itemFiltro.label == tipo.tipo))
                    .map((tipo) => ({
                        ano: tipo.tipo.substring(0, 20) + (tipo.tipo.length > 20 ? "..." : ""),
                        valor: tipo.valor || 0,
                    })),
            })),
            "Custo Mensal por Unidade (R$)",
            "R$",
            ""
        );
        setGraficoCustoUnidadeMensal(graficoCustoUnidadeMensalAtuaizado);
    }

    function mapearListaGraficoColunasAgrupadas(lista) {
        const listaMapeada = [];
        const nomeColuna = filtro.custo_ano_safra ? "ano_inicio_fim_safra" : "ano";
        const anos = [];

        try {
            //monta uma lista com todos os anos existentes em todos meses
            for (const registro of lista) {
                const listaAnoValor = registro.itens.filter((item) => item[nomeColuna]); //remove registro com ano nulo
                for (const item of listaAnoValor) {
                    const ano = item[nomeColuna];
                    if (!anos.includes(ano)) {
                        anos.push(ano);
                    }
                }
            }
            anos.sort((a, b) => {
                return filtro.custo_ano_safra ? parseInt(a.split("/")[0]) - parseInt(b.split("/")[0]) : parseInt(a) - parseInt(b);
            });
            //percorre todos os meses
            for (const registro of lista) {
                //se encontrar o ano do mes na lista de anos, pega o respectivo valor, senao define o valor zero para o ano
                const colunas = { mes: registro.mes };
                for (const ano of anos) {
                    const anoExisteNoMes = registro.itens.find((item) => item[nomeColuna] == ano);
                    if (anoExisteNoMes && parseFloat(anoExisteNoMes.valor)) {
                        colunas[ano] = parseFloat(anoExisteNoMes.valor);
                    } else {
                        colunas[ano] = 0;
                    }
                }
                listaMapeada.push(colunas);
            }
            return {
                dados: listaMapeada,
                valuesAndNames: anos.map((item) => ({ value: item, name: item })),
            };
        } catch (erro) {
            console.log(erro);
            setError("Erro ao gerar gráficos de custo");
            return {
                dados: [],
                valuesAndNames: [],
            };
        }
    }

    function atualizarListaGraficoCustoJurosPorAtrasoPagamentoAnual() {
        const graficoCustoJurosPorAtrasoPagamentoAtualAtualizado = montarGraficoColumn({
            state,
            component: graficoCustoJurosPorAtrasoPagamentoAnual,
            nome: "graficoCustoJurosPorAtrasoPagamentoAnual",
            dados: listaGraficoCustoJurosPorAtrasoPagamentoAnual,
            category: filtro.custo_ano_safra ? "ano_inicio_fim_safra" : "ano",
            value: "valor",
            titulo: `Custo de Juros por Atraso de Pagamento por ${filtro.custo_ano_safra ? "Safra" : "Ano"} (R$)`,
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoJurosPorAtrasoPagamentoAnual(graficoCustoJurosPorAtrasoPagamentoAtualAtualizado);
    }
    function atualizarListaGraficoCustoJurosPorAtrasoPagamentoMensal() {
        const { dados, valuesAndNames } = mapearListaGraficoColunasAgrupadas(listaGraficoCustoJurosPorAtrasoPagamentoMensal);
        const graficoCustoJurosPorAtrasoPagamentoMensalAtualizado = montarGraficoGroupColumn({
            state,
            component: graficoCustoJurosPorAtrasoPagamentoMensal,
            nome: "graficoCustoJurosPorAtrasoPagamentoMensal",
            dados,
            category: "mes",
            valuesAndNames,
            titulo: "Custo de Juros por Atraso de Pagamento (R$)",
            prefixo: "R$",
            sufixo: "",
            dimensions: "3D",
            mostrarLegenda: true,
            chartBackground: "#30303d",
        });
        setGraficoCustoJurosPorAtrasoPagamentoMensal(graficoCustoJurosPorAtrasoPagamentoMensalAtualizado);
    }

    function atualizarListaGraficoCustoPorUnidadeAcumuladoAno() {
        const graficoCustoPorUnidadeAcumuladoAnoAtualizado = montarGraficoHorizontalBar({
            component: graficoCustoPorUnidadeAcumuladoAno,
            chartDivId: "graficoCustoPorUnidadeAcumuladoAno",
            category: "agrupador",
            value: "valor",
            bullet: "valor",
            bulletPosition: "out",
            bulletColor: "#fff",
            lista: [...listaGraficoCustoPorUnidadeAcumuladoAno].sort((a, b) => a.valor - b.valor),
            titulo: "Custo Acumulado Anual por UC (R$)",
            prefixo: "R$",
            sufixo: "",
        });
        setGraficoCustoPorUnidadeAcumuladoAno(graficoCustoPorUnidadeAcumuladoAnoAtualizado);
    }

    function renderizarCelulasComZeros(corCiclo, listaDados) {
        let listaMeses = [];
        for (let i = listaDados.length; i < 12; i++) {
            listaMeses.push(i);
        }

        return listaMeses.map((mes) => (
            <Td key={mes} alignCenter color="#fff" backgroundColor={corCiclo}>
                0
            </Td>
        ));
    }

    function renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal }) {
        const janeiroAteMaio = (
            <>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "01" ? "#66bb6a" : demandaMesFinal == "01" ? "#ef5350" : null) : null,
                    }}
                >
                    Janeiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "01" ? " (I)" : demandaMesFinal == "01" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "02" ? "#66bb6a" : demandaMesFinal == "02" ? "#ef5350" : null) : null,
                    }}
                >
                    Fevereiro
                    {ucFiltradaSazonal ? (demandaMesInicial == "02" ? " (I)" : demandaMesFinal == "02" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "03" ? "#66bb6a" : demandaMesFinal == "03" ? "#ef5350" : null) : null,
                    }}
                >
                    Março
                    {ucFiltradaSazonal ? (demandaMesInicial == "03" ? " (I)" : demandaMesFinal == "03" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "04" ? "#66bb6a" : demandaMesFinal == "04" ? "#ef5350" : null) : null,
                    }}
                >
                    Abril
                    {ucFiltradaSazonal ? (demandaMesInicial == "04" ? " (I)" : demandaMesFinal == "04" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "05" ? "#66bb6a" : demandaMesFinal == "05" ? "#ef5350" : null) : null,
                    }}
                >
                    Maio
                    {ucFiltradaSazonal ? (demandaMesInicial == "05" ? " (I)" : demandaMesFinal == "05" ? " (F)" : "") : ""}
                </Th>{" "}
            </>
        );
        const junhoAteDez = (
            <>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "06" ? "#66bb6a" : demandaMesFinal == "06" ? "#ef5350" : null) : null,
                    }}
                >
                    Junho
                    {ucFiltradaSazonal ? (demandaMesInicial == "06" ? " (I)" : demandaMesFinal == "06" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "07" ? "#66bb6a" : demandaMesFinal == "07" ? "#ef5350" : null) : null,
                    }}
                >
                    Julho
                    {ucFiltradaSazonal ? (demandaMesInicial == "07" ? " (I)" : demandaMesFinal == "07" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "08" ? "#66bb6a" : demandaMesFinal == "08" ? "#ef5350" : null) : null,
                    }}
                >
                    Agosto
                    {ucFiltradaSazonal ? (demandaMesInicial == "08" ? " (I)" : demandaMesFinal == "08" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "09" ? "#66bb6a" : demandaMesFinal == "09" ? "#ef5350" : null) : null,
                    }}
                >
                    Setembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "09" ? " (I)" : demandaMesFinal == "09" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "10" ? "#66bb6a" : demandaMesFinal == "10" ? "#ef5350" : null) : null,
                    }}
                >
                    Outubro
                    {ucFiltradaSazonal ? (demandaMesInicial == "10" ? " (I)" : demandaMesFinal == "10" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "11" ? "#66bb6a" : demandaMesFinal == "11" ? "#ef5350" : null) : null,
                    }}
                >
                    Novembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "11" ? " (I)" : demandaMesFinal == "11" ? " (F)" : "") : ""}
                </Th>
                <Th
                    alignCenter
                    style={{
                        backgroundColor: ucFiltradaSazonal ? (demandaMesInicial == "12" ? "#66bb6a" : demandaMesFinal == "12" ? "#ef5350" : null) : null,
                    }}
                >
                    Dezembro
                    {ucFiltradaSazonal ? (demandaMesInicial == "12" ? " (I)" : demandaMesFinal == "12" ? " (F)" : "") : ""}
                </Th>
            </>
        );

        return filtro.custo_ano_safra ? (
            <>
                {junhoAteDez}
                {janeiroAteMaio}
            </>
        ) : (
            <>
                {janeiroAteMaio}
                {junhoAteDez}
            </>
        );
    }

    async function handleExport(graficosAtivos) {
        setLoading(true);
        const getTamanhoBase = () => {
            const zoomAtual = Math.floor(window.devicePixelRatio * 100);
            let tamanhoBase = 1750;
            if (window.innerWidth >= 768) {
                if (zoomAtual > 80 && zoomAtual <= 100) {
                    tamanhoBase = 1820;
                } else if (zoomAtual > 75 && zoomAtual <= 80) {
                    tamanhoBase = 1850;
                } else if (zoomAtual >= 70 && zoomAtual <= 75) {
                    tamanhoBase = 1900;
                } else if (zoomAtual >= 60 && zoomAtual <= 70) {
                    tamanhoBase = 2050;
                } else if (zoomAtual >= 50 && zoomAtual <= 60) {
                    tamanhoBase = 2450;
                } else if (zoomAtual < 50) {
                    return alert("Ajuste o zoom do navegador para 100% e tente novamente");
                }

                if (zoomAtual > 100 && zoomAtual <= 110) {
                    tamanhoBase = 1850;
                } else if (zoomAtual > 110 && zoomAtual <= 125) {
                    tamanhoBase = 1870;
                } else if (zoomAtual > 125 && zoomAtual <= 150) {
                    tamanhoBase = 2500;
                } else if (zoomAtual > 150 && zoomAtual <= 175) {
                    tamanhoBase = 4900;
                } else if (zoomAtual > 175) {
                    return alert("Ajuste o zoom do navegador para 100% e tente novamente");
                }
            }
            return tamanhoBase;
        };
        const calcularWidthGrafico = ({ el, desconto = 0 }) => {
            if (window.innerWidth < 768) {
                el.style.width = 900 - desconto + "px";
            } else {
                el.style.width = `${(window.devicePixelRatio < 1 ? 1 + (1 - window.devicePixelRatio) : 2 - window.devicePixelRatio) * getTamanhoBase() - desconto}px`;
                if (getTamanhoBase() == 2500) {
                    el.style.paddingLeft = "70px";
                    el.style.width = 1180 - desconto + "px";
                }
                if (getTamanhoBase() == 4900) {
                    el.style.paddingLeft = "120px";
                    el.style.width = 1105 - desconto + "px";
                }
            }
        };

        const calcularWidthTabela = ({ el, desconto = 0 }) => {
            if (window.innerWidth < 768) {
                el.style.fontSize = "0.7rem";
                el.style.width = 900 - desconto + "px";
            } else {
                el.style.minWidth = `${(window.devicePixelRatio < 1 ? 1 + (1 - window.devicePixelRatio) : 2 - window.devicePixelRatio) * getTamanhoBase() - desconto}px`;
            }
        };

        const formatarGrafico = ({ el }) => {
            if (el) {
                el.style.fontSize = "0.8rem";
                calcularWidthGrafico({ el });
                el.style.backgroundColor = "#30303d";
                el.style.overflowY = "hidden";
            }
        };
        const formatarTabela = ({ el }) => {
            if (el) {
                calcularWidthTabela({ el });
                el.style.color = "#FFFFFF";
                el.style.backgroundColor = "#30303d";
                el.style.paddingTop = "50px";
                el.style.paddingBottom = "50px";
                el.style.borderCollapse = "collapse";
                el.style.border = "1px solid #666682";

                // Aplica bordas também às células (th, td)
                const celulas = el.querySelectorAll("th, td");
                celulas.forEach((celula) => {
                    celula.style.border = "1px solid #666682";
                    celula.style.padding = "8px"; // Adiciona padding para melhorar a aparência
                    celula.style.textAlign = "left";
                });
            }
        };
        const exibirTagOculta = ({ el, textAlign = "left" }) => {
            if (el) {
                calcularWidthGrafico({ el, desconto: 20 });
                el.style.color = "#FFFFFF";
                el.style.fontSize = "1rem";
                el.style.fontWeight = "bold";
                el.style.backgroundColor = "#30303d";
                el.style.visibility = "visible";
                el.style.marginBottom = "0px";
                el.style.marginTop = "20px";
                el.style.padding = "10px";
                el.style.textAlign = textAlign;
            }
        };

        if (contentCardRef.current) {
            // Altera a largura e cor de fundo do gráfico antes de renderizar o conteúdo
            const graficoCustoAnual = contentCardRef.current.querySelector("#graficoCustoAnual");
            const graficoCustoMensal = contentCardRef.current.querySelector("#graficoCustoMensal");
            const tabelaCustoMensal = contentCardRef.current.querySelector("#tabelaCustoMensal");
            const graficoCustoJurosPorAtrasoPagamentoAnual = contentCardRef.current.querySelector("#graficoCustoJurosPorAtrasoPagamentoAnual");
            const tabelaCustoJurosPorAtrasoPagamentoAnual = contentCardRef.current.querySelector("#tabelaCustoJurosPorAtrasoPagamentoAnual");
            const graficoCustoJurosPorAtrasoPagamentoMensal = contentCardRef.current.querySelector("#graficoCustoJurosPorAtrasoPagamentoMensal");
            const periodoGraficoCustoUnidadeAnual = contentCardRef.current.querySelector("#periodoGraficoCustoUnidadeAnual");
            const graficoCustoUnidadeAnual = contentCardRef.current.querySelector("#graficoCustoUnidadeAnual");
            const graficoCustoUnidadeMensal = contentCardRef.current.querySelector("#graficoCustoUnidadeMensal");
            const tabelaCustoUnidadeMensal = contentCardRef.current.querySelector("#tabelaCustoUnidadeMensal");
            const tituloTabelaComparativa = contentCardRef.current.querySelector("#tituloTabelaComparativa");
            const tabelaComparativa = contentCardRef.current.querySelector("#tabelaComparativa");
            const periodoCustoPorUnidadeAcumuladoAno = contentCardRef.current.querySelector("#periodoCustoPorUnidadeAcumuladoAno");
            const graficoCustoPorUnidadeAcumuladoAno = contentCardRef.current.querySelector("#graficoCustoPorUnidadeAcumuladoAno");

            formatarGrafico({ el: graficoCustoAnual });
            formatarGrafico({ el: graficoCustoMensal });
            formatarTabela({ el: tabelaCustoMensal });
            formatarGrafico({ el: graficoCustoJurosPorAtrasoPagamentoAnual });
            formatarTabela({ el: tabelaCustoJurosPorAtrasoPagamentoAnual });
            formatarGrafico({ el: graficoCustoJurosPorAtrasoPagamentoMensal });
            exibirTagOculta({ el: periodoGraficoCustoUnidadeAnual });
            formatarGrafico({ el: graficoCustoUnidadeAnual, marginTop: 0 });
            formatarGrafico({ el: graficoCustoUnidadeMensal });
            formatarTabela({ el: tabelaCustoUnidadeMensal });
            exibirTagOculta({ el: tituloTabelaComparativa, textAlign: "center" });
            formatarTabela({ el: tabelaComparativa, marginTop: 0 });
            exibirTagOculta({ el: periodoCustoPorUnidadeAcumuladoAno });
            formatarGrafico({ el: graficoCustoPorUnidadeAcumuladoAno, marginTop: 0 });

            const base64Cabecalho = await convertToBase64("/images/cabecalho_relatorio.png");
            const htmlCabecalho = `<div style="width:100%; height:10%;">
                    <img src="${base64Cabecalho}" width="100%" />
                </div>`;

            const base64Rodape = await convertToBase64("/images/rodape_relatorio.png");
            const htmlRodape = `<div style="width:100%; height:5%;">
                        <img src="${base64Rodape}" width="100%" />
                    </div>`;

            // Captura o HTML com os estilos atualizados
            const htmlGraficoCustoAnual =
                graficosAtivos.graficoCustoAnual && graficoCustoAnual
                    ? `<div style="width:100%; height:42%; display:flex; flex-direction:column; align-items:center; justify-content:center; margin-bottom:10px; background-color:#30303d">` +
                      graficoCustoAnual.outerHTML +
                      "</div>"
                    : "";
            const htmlGraficoCustoMensal =
                graficosAtivos.graficoCustoMensal && graficoCustoMensal && tabelaCustoMensal
                    ? `<div style="width:100%; height:42%; display:flex; flex-direction:column; align-items:center; justify-content:center; margin-bottom:10px; background-color:#30303d">` +
                      graficoCustoMensal.outerHTML +
                      tabelaCustoMensal.outerHTML +
                      "</div>"
                    : "";
            const htmlGraficoCustoJurosPorAtrasoPagamentoAnual =
                graficosAtivos.graficoCustoJurosPorAtrasoPagamentoAnual && graficoCustoJurosPorAtrasoPagamentoAnual && tabelaCustoJurosPorAtrasoPagamentoAnual
                    ? `<div style="width:100%; height:42%; display:flex; flex-direction:column; align-items:center; justify-content:center; margin-bottom:10px; background-color:#30303d">` +
                      graficoCustoJurosPorAtrasoPagamentoAnual.outerHTML +
                      tabelaCustoJurosPorAtrasoPagamentoAnual.outerHTML +
                      "</div>"
                    : "";
            const htmlGraficoCustoJurosPorAtrasoPagamentoMensal =
                graficosAtivos.graficoCustoJurosPorAtrasoPagamentoMensal && graficoCustoJurosPorAtrasoPagamentoMensal
                    ? `<div style="width:100%; height:42%; display:flex; flex-direction:column; align-items:center; justify-content:center; margin-bottom:10px; background-color:#30303d">` +
                      graficoCustoJurosPorAtrasoPagamentoMensal.outerHTML +
                      "</div>"
                    : "";
            const htmlGraficoCustoUnidadeAnual =
                graficosAtivos.graficoCustoUnidadeAnual && periodoGraficoCustoUnidadeAnual && graficoCustoUnidadeAnual
                    ? `<div style="width:100%; height:42%; display:flex; flex-direction:column; align-items:center; justify-content:center; margin-bottom:10px; background-color:#30303d">` +
                      periodoGraficoCustoUnidadeAnual.outerHTML +
                      graficoCustoUnidadeAnual.outerHTML +
                      "</div>"
                    : "";
            const htmlGraficoCustoUnidadeMensal =
                graficosAtivos.graficoCustoUnidadeMensal && graficoCustoUnidadeMensal && tabelaCustoUnidadeMensal
                    ? `<div style="width:100%; height:42%; display:flex; flex-direction:column; align-items:center; justify-content:center; margin-bottom:10px; background-color:#30303d">` +
                      graficoCustoUnidadeMensal.outerHTML +
                      tabelaCustoUnidadeMensal.outerHTML +
                      "</div>"
                    : "";
            const htmlTituloTabelaComparativa =
                graficosAtivos.tabelaComparativa && tituloTabelaComparativa && tabelaComparativa
                    ? `<div style="width:100%; height:42%; display:flex; flex-direction:column; align-items:center; justify-content:center; margin-bottom:10px; background-color:#30303d">` +
                      tituloTabelaComparativa.outerHTML +
                      tabelaComparativa.outerHTML +
                      "</div>"
                    : "";
            const htmlGraficoCustoPorUnidadeAcumuladoAno =
                graficosAtivos.graficoCustoPorUnidadeAcumuladoAno && periodoCustoPorUnidadeAcumuladoAno && graficoCustoPorUnidadeAcumuladoAno
                    ? `<div style="width:100%; height:42%; display:flex; flex-direction:column; align-items:center; justify-content:center; margin-bottom:10px; background-color:#30303d">` +
                      periodoCustoPorUnidadeAcumuladoAno.outerHTML +
                      graficoCustoPorUnidadeAcumuladoAno.outerHTML +
                      "</div>"
                    : "";

            const listaGraficos = [
                htmlGraficoCustoAnual,
                htmlGraficoCustoMensal,
                htmlGraficoCustoJurosPorAtrasoPagamentoAnual,
                htmlGraficoCustoJurosPorAtrasoPagamentoMensal,
                htmlGraficoCustoUnidadeAnual,
                htmlGraficoCustoUnidadeMensal,
                htmlTituloTabelaComparativa,
                htmlGraficoCustoPorUnidadeAcumuladoAno,
            ].filter((g) => g);

            let html = "";
            const graficosPorPagina = 2;
            for (let i = 0; i < listaGraficos.length; i += graficosPorPagina) {
                html += htmlCabecalho + listaGraficos[i] + (listaGraficos[i + 1] || `<div style="width:100%; height:42%"></div>`) + htmlRodape;
            }

            // Gera o relatório com o HTML atualizado

            await gerarRelatorio({
                html,
                landscape: false,
                scale: window.innerWidth < 768 ? 0.82 : window.devicePixelRatio * 0.4,
            });
            setLoading(false);
            setAguardando(true);
            setAguardando(false);
        } else {
            console.error("ContentCard não está acessível.");
        }
    }

    let ucFiltradaSazonal = false;
    let demandaMesInicial = "";
    let demandaMesFinal = "";

    //se é não sazonal, pega os últimos 12 meses,
    const mesAtualDate = new Date().getMonth(); //não soma 1 pq o mes atual não deve ser contabilizado
    const anoAtual = String(new Date().getFullYear());
    //AQUI É A LÓGICA PARA PINTAR AS CÉLULAS DE CINZA
    if (contratoDemanda) {
        const mesLimite = mesAtualDate < 10 ? "0" + mesAtualDate : String(mesAtualDate);
        const mesesDescontar = Number(mesLimite) - 11; //diminui 11 meses para pegar o mes inicial dos últimos 12 meses
        const anoInicialCalculado = mesesDescontar > 0 ? anoAtual : anoAtual - 1; //se descontando 11 meses der negativo, considera ano anterior
        const mesInicialCalculado = Number(mesLimite) + 1 > 12 ? "01" : Number(mesLimite) + 1; //mes atual 10, inicia em 10+1 do ano anterior
        const competenciaInicialCalculada = Number(String(anoInicialCalculado) + String(mesInicialCalculado)); //usada pra comparar com competencia base
        //se competencia base for maior considera ela como inicial, senão considera a calculada
        const competenciaBaseSazonalidade =
            contratoDemanda.competencia_base_sazonalidade > competenciaInicialCalculada ? contratoDemanda.competencia_base_sazonalidade : competenciaInicialCalculada;

        ucFiltradaSazonal = contratoDemanda.unidade_consumidora_sazonal;
        demandaMesInicial = ucFiltradaSazonal ? String(contratoDemanda.competencia_inicio).substring(4, 6) : String(competenciaBaseSazonalidade).substring(4, 6);
        demandaMesFinal = ucFiltradaSazonal ? String(contratoDemanda.competencia_fim).substring(4, 6) : mesLimite;
    }

    let corCiclo = "#30303d";

    let anosCusto = [];
    listaGraficoCustoAnual.forEach((item) => {
        item.anos.forEach((ano) => {
            if (anosCusto.filter((itemAno) => itemAno == ano.ano).length <= 0) {
                anosCusto.push(ano.ano);
            }
        });
    });

    anosCusto = anosCusto.sort((a, b) => parseInt(String(a).substring(0, 4)) - parseInt(String(b).substring(0, 4)));

    let valorUnidadeAnualTotal = 0;
    listaGraficoCustoPorUnidadeAcumuladoAno.forEach((item) => {
        valorUnidadeAnualTotal = parseFloat(item.valor) > valorUnidadeAnualTotal ? parseFloat(item.valor) : valorUnidadeAnualTotal;
    });

    let listaNomesUCs = [];
    listaGraficoCustoUnidadeMensal.forEach((item) =>
        item.tipos.forEach((uc) => {
            if (!listaNomesUCs.includes(uc.tipo)) listaNomesUCs.push(uc.tipo);
        })
    );

    const listaTabelaCustoJurosPorAtrasoPagamentoAnual = graficoCustoJurosPorAtrasoPagamentoAnual ? graficoCustoJurosPorAtrasoPagamentoAnual._data : [];
    const periodoCustoJurosPorAtrasoPagamentoAnual = Object.keys(listaGraficoCustoJurosPorAtrasoPagamentoAnual[0] || {})[0];
    return (
        <div ref={contentCardRef}>
            {loading && <Loading />}
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <Row alignRight style={{ paddingRight: 5 }}>
                        <Grid cols="12 12 2 2" style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                style={{ width: 120 }}
                                text={<span style={{ fontSize: "clamp(0.6rem, 2vw, 0.8rem)", whiteSpace: "nowrap" }}>Exportar</span>}
                                title="Exportar"
                                type={"light"}
                                icon={"fa fa-file-export"}
                                event={() => setModalExportacaoAberto(true)}
                            />
                        </Grid>
                    </Row>
                    {modalExportacaoAberto ? (
                        <ModalAlert handleClose={() => setModalExportacaoAberto(false)}>
                            <ExportAnalise />
                        </ModalAlert>
                    ) : null}
                    <div
                        id="graficoCustoAnual"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div
                        id="graficoCustoMensal"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive id="tabelaCustoMensal">
                            <THead>
                                <Tr>
                                    <Th>Ano</Th>
                                    {renderizarCabecalhoMeses({ ucFiltradaSazonal, demandaMesInicial, demandaMesFinal })}
                                </Tr>
                            </THead>
                            <TBody>
                                {anosCusto.map((ano) => {
                                    return (
                                        <Tr key={ano}>
                                            <Td color="#fff">{ano}</Td>

                                            {listaGraficoCustoAnual.map((mes) => {
                                                if (mes.anos.find((anoMes) => anoMes.ano == ano)) {
                                                    return mes.anos
                                                        .filter((anoMes) => anoMes.ano == ano)
                                                        .map((anoMes) => {
                                                            return (
                                                                <Td key={anoMes.ano} alignCenter color="#fff">
                                                                    {parseFloat(anoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={ano} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}

                                            {renderizarCelulasComZeros(corCiclo, listaGraficoCustoAnual)}
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div
                        id="graficoCustoJurosPorAtrasoPagamentoAnual"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                </ContentCardBody>
                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll" }}>
                    <div style={{ minWidth: "890px" }}>
                        <Table responsive id="tabelaCustoJurosPorAtrasoPagamentoAnual">
                            <THead>
                                <Tr>
                                    <Th width={250}></Th>
                                    {listaTabelaCustoJurosPorAtrasoPagamentoAnual.map((item) => (
                                        <Th alignCenter>{item[periodoCustoJurosPorAtrasoPagamentoAnual]}</Th>
                                    ))}
                                </Tr>
                            </THead>
                            <TBody>
                                <Tr>
                                    <Td color="#fff">R$ Total</Td>
                                    {listaTabelaCustoJurosPorAtrasoPagamentoAnual.map((item) => (
                                        <Td key={item[periodoCustoJurosPorAtrasoPagamentoAnual]} alignCenter color="#fff">
                                            {parseFloat(item.valor_fatura || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">R$ Juros por Atraso de Pagamento</Td>
                                    {listaTabelaCustoJurosPorAtrasoPagamentoAnual.map((item) => (
                                        <Td key={item[periodoCustoJurosPorAtrasoPagamentoAnual]} alignCenter color="#fff">
                                            {parseFloat(item.valor || 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                        </Td>
                                    ))}
                                </Tr>
                                <Tr>
                                    <Td color="#fff">Rel. %</Td>
                                    {listaTabelaCustoJurosPorAtrasoPagamentoAnual.map((item) => (
                                        <Td key={item[periodoCustoJurosPorAtrasoPagamentoAnual]} alignCenter color="#fff">
                                            {parseFloat(item.valor_fatura > 0 ? (item.valor * 100) / item.valor_fatura : 0)
                                                .toFixed(2)
                                                .replace(".", ",")}
                                            %
                                        </Td>
                                    ))}
                                </Tr>
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ color: "#fff", padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div
                        id="graficoCustoJurosPorAtrasoPagamentoMensal"
                        style={{
                            width: "100%",
                            minWidth: "890px",
                            height: "400px",
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader style={{}}>
                    <Row>
                        <Select
                            options={listaUnidadeConsumidora.filter((item) => !filtro.id_cliente || item.id_cliente == filtro.id_cliente)}
                            label="Unidades Consumidoras"
                            cols="12 12 12 12"
                            placeholder="Selecione as UCs"
                            isMulti={true}
                            value={filtro.unidades_custo_anual}
                            onChange={(value) => {
                                setFiltro({
                                    ...filtro,
                                    unidades_custo_anual: value,
                                });
                            }}
                        />
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="De"
                                    autoOk
                                    value={
                                        filtro.competencia_inicial_unidade_custo
                                            ? `${String(filtro.competencia_inicial_unidade_custo).substring(0, 4)}/${String(filtro.competencia_inicial_unidade_custo).substring(
                                                  4,
                                                  6
                                              )}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_inicial_unidade_custo: value
                                                ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                <DatePicker
                                    variant="inline"
                                    openTo="year"
                                    views={["year", "month"]}
                                    label="Até"
                                    autoOk
                                    value={
                                        filtro.competencia_final_unidade_custo
                                            ? `${String(filtro.competencia_final_unidade_custo).substring(0, 4)}/${String(filtro.competencia_final_unidade_custo).substring(
                                                  4,
                                                  6
                                              )}/01 12:00:00`
                                            : null
                                    }
                                    onChange={(value) => {
                                        setFiltro({
                                            ...filtro,
                                            competencia_final_unidade_custo: value
                                                ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                : null,
                                        });
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Limpar Período"
                                type={"primary"}
                                icon={"fa fa-minus"}
                                event={async () => {
                                    setFiltro({
                                        ...filtro,
                                        competencia_inicial_unidade_custo: null,
                                        competencia_final_unidade_custo: null,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid cols="6 4 3 2" style={{ paddingTop: 10 }}>
                            <Button text="Atualizar" type={"primary"} icon={"fa fa-sync"} event={listar} />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <div id="periodoGraficoCustoUnidadeAnual" style={{ visibility: "hidden" }}>
                        De {String(filtro.competencia_inicial_unidade_custo).substring(4, 6)}/{String(filtro.competencia_inicial_unidade_custo).substring(0, 4)} até{" "}
                        {String(filtro.competencia_final_unidade_custo).substring(4, 6)}/{String(filtro.competencia_final_unidade_custo).substring(0, 4)}
                    </div>
                    <div id="graficoCustoUnidadeAnual" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardBody
                    style={{
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                        color: "#fff",
                    }}
                >
                    <div id="graficoCustoUnidadeMensal" style={{ width: "100%", minWidth: "890px", height: "400px" }}></div>{" "}
                    <div style={{ minWidth: "890px" }}>
                        <Table responsive id="tabelaCustoUnidadeMensal">
                            <THead>
                                <Tr>
                                    <Th width={200}>UC</Th>
                                    {renderizarCabecalhoMeses({})}
                                </Tr>
                            </THead>
                            <TBody>
                                {listaNomesUCs
                                    .sort((a, b) => a - b)
                                    .filter(
                                        (nomeUc) =>
                                            !(filtro.unidades_custo_anual || []).length || (filtro.unidades_custo_anual || []).find((itemFiltro) => itemFiltro.label == nomeUc)
                                    )
                                    .map((nomeUc) => (
                                        <Tr key={nomeUc}>
                                            <Td color="#fff">{nomeUc}</Td>
                                            {listaGraficoCustoUnidadeMensal.map((mes) => {
                                                if (mes.tipos.find((tipoMes) => tipoMes.tipo == nomeUc)) {
                                                    return mes.tipos
                                                        .filter((tipoMes) => tipoMes.tipo == nomeUc)
                                                        .map((tipoMes) => {
                                                            return (
                                                                <Td key={tipoMes.tipo} alignCenter color="#fff">
                                                                    {parseFloat(tipoMes.valor || 0)
                                                                        .toFixed(2)
                                                                        .replace(".", ",")}
                                                                </Td>
                                                            );
                                                        });
                                                } else {
                                                    return (
                                                        <Td key={nomeUc} alignCenter color="#fff">
                                                            0,00
                                                        </Td>
                                                    );
                                                }
                                            })}
                                            {renderizarCelulasComZeros(corCiclo, listaGraficoCustoUnidadeMensal)}
                                        </Tr>
                                    ))}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="12 6 5 5">
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Período A</label>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="De"
                                            autoOk
                                            value={
                                                filtro.competencia_inicial_custo_comparativo_antes
                                                    ? `${String(filtro.competencia_inicial_custo_comparativo_antes).substring(0, 4)}/${String(
                                                          filtro.competencia_inicial_custo_comparativo_antes
                                                      ).substring(4, 6)}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_inicial_custo_comparativo_antes: value
                                                        ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                        : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Até"
                                            autoOk
                                            value={
                                                filtro.competencia_final_custo_comparativo_antes
                                                    ? `${String(filtro.competencia_final_custo_comparativo_antes).substring(0, 4)}/${String(
                                                          filtro.competencia_final_custo_comparativo_antes
                                                      ).substring(4, 6)}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_final_custo_comparativo_antes: value
                                                        ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                        : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Row>
                        </Grid>
                        <Grid cols="12 6 5 5">
                            <Row>
                                <Grid cols="12 12 12 12">
                                    <label>Período B</label>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="De"
                                            autoOk
                                            value={
                                                filtro.competencia_inicial_custo_comparativo_depois
                                                    ? `${String(filtro.competencia_inicial_custo_comparativo_depois).substring(0, 4)}/${String(
                                                          filtro.competencia_inicial_custo_comparativo_depois
                                                      ).substring(4, 6)}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_inicial_custo_comparativo_depois: value
                                                        ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                        : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid cols="6 6 6 6">
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                                        <DatePicker
                                            variant="inline"
                                            openTo="year"
                                            views={["year", "month"]}
                                            label="Até"
                                            autoOk
                                            value={
                                                filtro.competencia_final_custo_comparativo_depois
                                                    ? `${String(filtro.competencia_final_custo_comparativo_depois).substring(0, 4)}/${String(
                                                          filtro.competencia_final_custo_comparativo_depois
                                                      ).substring(4, 6)}/01 12:00:00`
                                                    : null
                                            }
                                            onChange={(value) => {
                                                setFiltro({
                                                    ...filtro,
                                                    competencia_final_custo_comparativo_depois: value
                                                        ? parseInt(`${value.getFullYear()}${value.getMonth() < 9 ? "0" : ""}${value.getMonth() + 1}`)
                                                        : null,
                                                });
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Row>
                        </Grid>

                        <Grid cols="12 12 2 2" style={{ paddingTop: 10 }}>
                            <Button
                                text="Atualizar"
                                type={"primary"}
                                icon={"fa fa-sync"}
                                event={() => {
                                    listar();
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody
                    style={{
                        color: "#fff",
                        backgroundColor: "#30303d",
                        overflowX: "scroll",
                    }}
                >
                    <p id="tituloTabelaComparativa" className="text-center" style={{ fontSize: 16 }}>
                        Tabela Comparativa para Diferentes Períodos
                    </p>
                    <div
                        style={{
                            minWidth: "890px",
                        }}
                    >
                        <Table responsive id="tabelaComparativa">
                            <THead>
                                <Tr>
                                    <Th>Unidade</Th>
                                    <Th alignCenter>
                                        {filtro.competencia_inicial_custo_comparativo_antes && filtro.competencia_final_custo_comparativo_antes
                                            ? `De ${String(filtro.competencia_inicial_custo_comparativo_antes).substring(4, 6)}/${String(
                                                  filtro.competencia_inicial_custo_comparativo_antes
                                              ).substring(0, 4)} até ${String(filtro.competencia_final_custo_comparativo_antes).substring(4, 6)}/${String(
                                                  filtro.competencia_final_custo_comparativo_antes
                                              ).substring(0, 4)}`
                                            : ""}
                                    </Th>
                                    <Th alignCenter>Comparativo</Th>
                                    <Th alignCenter>
                                        {filtro.competencia_inicial_custo_comparativo_depois && filtro.competencia_final_custo_comparativo_depois
                                            ? `De ${String(filtro.competencia_inicial_custo_comparativo_depois).substring(4, 6)}/${String(
                                                  filtro.competencia_inicial_custo_comparativo_depois
                                              ).substring(0, 4)} até ${String(filtro.competencia_final_custo_comparativo_depois).substring(4, 6)}/${String(
                                                  filtro.competencia_final_custo_comparativo_depois
                                              ).substring(0, 4)}`
                                            : ""}
                                    </Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {listaCustoPorUnidadePeriodo.map((item, i) => {
                                    function calcularComparativo(item) {
                                        let comparativo =
                                            parseFloat(item.valor_atual) > 0 && parseFloat(item.valor_anterior) > 0
                                                ? (parseFloat(item.valor_anterior) * 100) / parseFloat(item.valor_atual)
                                                : 0;
                                        if (comparativo > 0) {
                                            comparativo = 100 - comparativo;
                                        }
                                        return comparativo;
                                    }

                                    const objetoTotais = listaCustoPorUnidadePeriodo.reduce(
                                        (objetoTotais, elemento) => {
                                            objetoTotais.valor_anterior += parseFloat(elemento.valor_anterior || 0);
                                            objetoTotais.valor_atual += parseFloat(elemento.valor_atual || 0);
                                            return objetoTotais;
                                        },
                                        { valor_anterior: 0, valor_atual: 0 }
                                    );

                                    const comparativo = calcularComparativo(item);
                                    const comparativoTotais = calcularComparativo(objetoTotais);

                                    const linhaTotais = (
                                        <Tr key={"Todas Unidades"}>
                                            <Td color="#fff" backgroundColor="#257bfb">
                                                <b>Todas Unidades</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor="#257bfb">
                                                <b>R$ {adicionarPontosMilhar(parseFloat(objetoTotais.valor_anterior || 0).toFixed(2))}</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor={comparativoTotais == 0 ? "#257bfb" : comparativoTotais > 0 ? "#ef5350" : "#28a745"}>
                                                <b>{parseFloat(objetoTotais.valor_atual) == 0 ? `100,00` : comparativoTotais.toFixed(2).replace(".", ",")}%</b>
                                            </Td>
                                            <Td alignCenter color="#fff" backgroundColor="#257bfb">
                                                <b>R$ {adicionarPontosMilhar(parseFloat(objetoTotais.valor_atual || 0).toFixed(2))}</b>
                                            </Td>
                                        </Tr>
                                    );

                                    return (
                                        <>
                                            {i == 0 ? linhaTotais : null}
                                            <Tr key={item.nome_unidade_consumidora}>
                                                <Td color="#fff">{item.nome_unidade_consumidora}</Td>
                                                <Td alignCenter color="#fff">
                                                    R$ {adicionarPontosMilhar(parseFloat(item.valor_anterior || 0).toFixed(2))}
                                                </Td>
                                                <Td alignCenter color="#fff" backgroundColor={comparativo == 0 ? null : comparativo > 0 ? "#ef5350" : "#28a745"}>
                                                    {parseFloat(item.valor_atual) == 0 ? `100,00` : comparativo.toFixed(2).replace(".", ",")}%
                                                </Td>
                                                <Td alignCenter color="#fff">
                                                    R$ {adicionarPontosMilhar(parseFloat(item.valor_atual || 0).toFixed(2))}
                                                </Td>
                                            </Tr>
                                        </>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </div>
                </ContentCardBody>
            </ContentCard>
            <ContentCard style={{ padding: 8 }}>
                <ContentCardHeader>
                    <Select
                        name="ano"
                        options={listaAnos}
                        label="Ano"
                        cols="12 6 3"
                        placeholder="Selecione o ano"
                        value={filtro.ano_acumulado_custo}
                        isClearable={false}
                        isSearchable={false}
                        onChange={(value) => {
                            setFiltro({
                                ...filtro,
                                ano_acumulado_custo: value,
                            });
                            listar();
                        }}
                    />
                </ContentCardHeader>

                <ContentCardBody style={{ backgroundColor: "#30303d", overflowX: "scroll", maxHeight: 400 }}>
                    <div id="periodoCustoPorUnidadeAcumuladoAno" style={{ visibility: "hidden" }}>
                        Ano: {filtro.ano_acumulado_custo}
                    </div>
                    <div
                        id="graficoCustoPorUnidadeAcumuladoAno"
                        style={{
                            width: "100%",
                            minWidth: 900,
                            height: (listaGraficoCustoPorUnidadeAcumuladoAno || []).length * 50 < 400 ? 400 : listaGraficoCustoPorUnidadeAcumuladoAno.length * 50,
                        }}
                    ></div>
                </ContentCardBody>
            </ContentCard>
        </div>
    );

    function ExportAnalise() {
        const [graficosAtivos, setGraficosAtivos] = useState({
            graficoCustoAnual: true,
            graficoCustoMensal: true,
            tabelaCustoMensal: true,
            graficoCustoJurosPorAtrasoPagamentoAnual: true,
            graficoCustoJurosPorAtrasoPagamentoMensal: true,
            graficoCustoUnidadeAnual: true,
            graficoCustoUnidadeMensal: true,
            tabelaComparativa: true,
            graficoCustoPorUnidadeAcumuladoAno: true,
        });
        return (
            <>
                <div className="d-flex flex-column justify-content-center align-items-center text-dark">
                    <p>Selecione os gráficos que deseja exportar</p>
                </div>
                <LabelAndCheckbox
                    style={{ color: "#000", fontSize: window.innerWidth < 768 ? "0.7rem" : "1rem" }}
                    id="graficoCustoAnualCheckbox"
                    label="Custo Total (R$)"
                    onChange={(e) =>
                        setGraficosAtivos({
                            ...graficosAtivos,
                            graficoCustoAnual: e.target.checked,
                        })
                    }
                    value={!!graficosAtivos.graficoCustoAnual}
                />
                <LabelAndCheckbox
                    style={{ color: "#000", fontSize: window.innerWidth < 768 ? "0.7rem" : "1rem" }}
                    id="graficoCustoMensalCheckbox"
                    label="Custo Mensal (R$)"
                    onChange={(e) =>
                        setGraficosAtivos({
                            ...graficosAtivos,
                            graficoCustoMensal: e.target.checked,
                        })
                    }
                    value={!!graficosAtivos.graficoCustoMensal}
                />
                <LabelAndCheckbox
                    style={{ color: "#000", fontSize: window.innerWidth < 768 ? "0.7rem" : "1rem" }}
                    id="graficoCustoJurosPorAtrasoPagamentoAnualCheckbox"
                    label="Custo de Juros por Atraso de Pagamento por Ano (R$)"
                    onChange={(e) =>
                        setGraficosAtivos({
                            ...graficosAtivos,
                            graficoCustoJurosPorAtrasoPagamentoAnual: e.target.checked,
                        })
                    }
                    value={!!graficosAtivos.graficoCustoJurosPorAtrasoPagamentoAnual}
                />
                <LabelAndCheckbox
                    style={{ color: "#000", fontSize: window.innerWidth < 768 ? "0.7rem" : "1rem" }}
                    id="graficoCustoJurosPorAtrasoPagamentoMensalCheckbox"
                    label="Custo de Juros por Atraso de Pagamento (R$)"
                    onChange={(e) =>
                        setGraficosAtivos({
                            ...graficosAtivos,
                            graficoCustoJurosPorAtrasoPagamentoMensal: e.target.checked,
                        })
                    }
                    value={!!graficosAtivos.graficoCustoJurosPorAtrasoPagamentoMensal}
                />
                <LabelAndCheckbox
                    style={{ color: "#000", fontSize: window.innerWidth < 768 ? "0.7rem" : "1rem" }}
                    id="graficoCustoUnidadeAnualCheckbox"
                    label="Custo Total por Unidade (R$)"
                    onChange={(e) =>
                        setGraficosAtivos({
                            ...graficosAtivos,
                            graficoCustoUnidadeAnual: e.target.checked,
                        })
                    }
                    value={!!graficosAtivos.graficoCustoUnidadeAnual}
                />
                <LabelAndCheckbox
                    style={{ color: "#000", fontSize: window.innerWidth < 768 ? "0.7rem" : "1rem" }}
                    id="graficoCustoUnidadeMensalCheckbox"
                    label="Custo Mensal por Unidade (R$)"
                    onChange={(e) =>
                        setGraficosAtivos({
                            ...graficosAtivos,
                            graficoCustoUnidadeMensal: e.target.checked,
                        })
                    }
                    value={!!graficosAtivos.graficoCustoUnidadeMensal}
                />
                <LabelAndCheckbox
                    style={{ color: "#000", fontSize: window.innerWidth < 768 ? "0.7rem" : "1rem" }}
                    id="tabelaComparativaCheckbox"
                    label="Tabela Comparativa para Diferentes Períodos"
                    onChange={(e) =>
                        setGraficosAtivos({
                            ...graficosAtivos,
                            tabelaComparativa: e.target.checked,
                        })
                    }
                    value={!!graficosAtivos.tabelaComparativa}
                />
                <LabelAndCheckbox
                    style={{ color: "#000", fontSize: window.innerWidth < 768 ? "0.7rem" : "1rem" }}
                    id="graficoCustoPorUnidadeAcumuladoAnoCheckbox"
                    label="Custo Acumulado por UC (R$)"
                    onChange={(e) =>
                        setGraficosAtivos({
                            ...graficosAtivos,
                            graficoCustoPorUnidadeAcumuladoAno: e.target.checked,
                        })
                    }
                    value={!!graficosAtivos.graficoCustoPorUnidadeAcumuladoAno}
                />

                <Row alignRight style={{ paddingRight: 5 }}>
                    <Grid cols="6 6 6 6">
                        <Button
                            text={<span style={{ fontSize: "clamp(0.6rem, 2vw, 1rem)", whiteSpace: "nowrap" }}>Exportar</span>}
                            type={"info"}
                            icon={"fas fa-check"}
                            event={(e) => {
                                handleExport(graficosAtivos);
                                setModalExportacaoAberto(false);
                            }}
                        />
                    </Grid>

                    <Grid cols="6 6 6 6">
                        <Button
                            text={<span style={{ fontSize: "clamp(0.6rem, 2vw, 1rem)", whiteSpace: "nowrap" }}>Cancelar</span>}
                            type={"danger"}
                            event={() => setModalExportacaoAberto(false)}
                        />
                    </Grid>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    filtro: state.analise.filtro,
    contratoDemanda: state.analise.contratoDemanda,
    listaGraficoCustoAnual: state.analise.listaGraficoCustoAnual,
    listaCustoPorUnidadePeriodo: state.analise.listaCustoPorUnidadePeriodo,
    listaGraficoCustoUnidadeAnual: state.analise.listaGraficoCustoUnidadeAnual,
    listaGraficoCustoUnidadeMensal: state.analise.listaGraficoCustoUnidadeMensal,
    listaGraficoCustoPorUnidadeAcumuladoAno: state.analise.listaGraficoCustoPorUnidadeAcumuladoAno,
    listaGraficoCustoJurosPorAtrasoPagamentoAnual: state.analise.listaGraficoCustoJurosPorAtrasoPagamentoAnual,
    listaGraficoCustoJurosPorAtrasoPagamentoMensal: state.analise.listaGraficoCustoJurosPorAtrasoPagamentoMensal,
    listaUnidadeConsumidora: state.analise.listaUnidadeConsumidora,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDemanda,
            getListaGraficoCustoAnual,
            getListaCustoPorUnidadePeriodo,
            getListaGraficoCustoPorUnidadeMensal,
            getListaGraficoCustoPorUnidadeAcumuladoAno,
            getListarGraficoCustoJurosPorAtrasoPagamentoAnual,
            getListarGraficoCustoJurosPorAtrasoPagamentoMensal,
            getListaGraficoCustoPorUnidadeAnual,
            setFiltro,
            gerarRelatorio,
        },
        dispatch
    );
const AnaliseCusto = connect(mapStateToProps, mapDispatchToProps)(analiseCusto);
export { AnaliseCusto };
