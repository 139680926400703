import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Form from "./redeForm";
import Select from "../common/form/select";
import { DisplayPDF } from "../displayPDF/displayPDF";
import { Loading } from "../common/loading/loading";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";

import {
    setModoTela,
    initForm,
    setFiltro,
    salvar,
    excluir,
    getLista,
    getListaCliente,
    getListaUnidadeConsumidora,
    buscarPdfUnifilarRedeInterna,
    buscarPdfProjetoRedeInterna,
    buscarProjetoRedeInternaGoogleEarth,
} from "./redeActions";

class RedeVisualizacao extends Component {
    componentWillMount() {
        if (this.props.usuarioLogado && this.props.usuarioLogado.administrador) {
            this.props.getListaCliente();
        } else if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.id_cliente,
            });
        }
        this.props.getLista();
        this.props.getListaUnidadeConsumidora();
    }

    render() {
        if (this.props.pdf) {
            return (
                <DisplayPDF
                    closePdf={() => this.props.setArquivoPDF()}
                    pdfBase64={this.props.pdf}
                    nomePdf={this.props.nomePdf}
                />
            );
        }
        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                <Content>
                    {this.props.modoTela === "visualizacao" && <Form onSubmit={() => {}} visualizacao />}
                    {this.props.modoTela === "cadastro" && <Form onSubmit={this.props.salvar} />}
                    {this.props.modoTela === "exclusao" && <Form excluir onSubmit={this.props.excluir} />}
                    {this.props.modoTela === "lista" && this.lista()}
                </Content>
            </div>
        );
    }

    lista() {
        return (
            <ContentCard>
                <ContentCardBody>
                    <Row>
                        {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                            <Select
                                name="id_cliente"
                                options={this.props.listaCliente.filter((cliente) =>
                                    this.props.lista.find((rede) => rede.id_cliente === cliente.id)
                                )}
                                label="Cliente"
                                cols="12 6 4"
                                placeholder="Selecione o Cliente"
                                value={this.props.filtro.id_cliente}
                                onChange={(value) => {
                                    this.props.setFiltro({
                                        ...this.props.filtro,
                                        id_cliente: value,
                                        id_unidade_consumidora: null,
                                    });
                                    this.props.getLista();
                                }}
                            />
                        ) : null}
                        <Select
                            name="id_unidade_consumidora"
                            options={this.props.listaUnidadeConsumidora.filter(
                                (item) =>
                                    ((!this.props.filtro.id_cliente &&
                                        this.props.usuarioLogado &&
                                        this.props.usuarioLogado.administrador) ||
                                        item.id_cliente == this.props.filtro.id_cliente) &&
                                    this.props.lista.find((rede) => rede.id_unidade_consumidora === item.id)
                            )}
                            label="Unidade Consumidora"
                            cols="12 6 4"
                            placeholder="Selecione a UC"
                            value={this.props.filtro.id_unidade_consumidora}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_unidade_consumidora: value,
                                });
                                this.props.getLista();
                            }}
                        />
                    </Row>

                    <Table responsive>
                        <THead>
                            <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#F5F5F5"}>
                                <Th minWidth={100} sticky>
                                    Cliente
                                </Th>
                                <Th minWidth={200} sticky>
                                    UC
                                </Th>
                                <Th minWidth={100} sticky alignCenter>
                                    Unifilar de Rede Interna PDF
                                </Th>
                                <Th minWidth={100} sticky alignCenter>
                                    Projeto Rede Interna PDF
                                </Th>
                                <Th minWidth={100} sticky alignCenter>
                                    Projeto Rede Interna Google Earth
                                </Th>
                            </NewTr>
                        </THead>
                        <TBody>
                            {this.props.lista.map((item) => {
                                const numeroUC = `UC-${item.nome_unidade_consumidora.split("-")[0].trim()}`;
                                return (
                                    <NewTr
                                        key={item.id}
                                        backgroundColorTr={"#FFFFFF"}
                                        backgroundColorTd={"#F5F5F5"}
                                        botoes={[]}
                                    >
                                        <Td>{item.nome_cliente}</Td>
                                        <Td>{item.nome_unidade_consumidora}</Td>
                                        <Td alignCenter minWidth={50}>
                                            <ButtonTable
                                                title="Visualizar"
                                                type={"primary"}
                                                icon={"fas fa-file-pdf"}
                                                visible={!!item.arquivo3}
                                                disabled={!this.props.usuarioLogado.acesso_redes_visualizar}
                                                event={() => {
                                                    this.props.buscarPdfUnifilarRedeInterna({
                                                        id: item.id,
                                                        nome: `Mapa_unifilar_rede_interna_${numeroUC}`,
                                                    });
                                                    this.props.setArquivoPDF();
                                                }}
                                            />
                                        </Td>
                                        <Td alignCenter minWidth={50}>
                                            <ButtonTable
                                                title="Visualizar"
                                                type={"primary"}
                                                icon={"fas fa-file-pdf"}
                                                visible={!!item.arquivo1}
                                                disabled={!this.props.usuarioLogado.acesso_redes_visualizar}
                                                event={() => {
                                                    this.props.buscarPdfProjetoRedeInterna({
                                                        id: item.id,
                                                        nome: `Projeto_rede_interna_${numeroUC}`,
                                                    });
                                                    this.props.setArquivoPDF();
                                                }}
                                            />
                                        </Td>
                                        <Td alignCenter minWidth={50}>
                                            <ButtonTable
                                                title="Baixar"
                                                type={"primary"}
                                                icon={"fas fa-file-pdf"}
                                                visible={!!item.arquivo2}
                                                disabled={!this.props.usuarioLogado.acesso_redes_visualizar}
                                                event={() => {
                                                    this.props.buscarProjetoRedeInternaGoogleEarth({
                                                        id: item.id,
                                                        nome: `Google_earth_projeto_rede_interna_${numeroUC}`,
                                                    });
                                                }}
                                            />
                                        </Td>
                                    </NewTr>
                                );
                            })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    modoTela: state.rede.modoTela,
    formularioValues: getFormValues("redeForm")(state),
    aguardando: state.rede.aguardando,
    filtro: state.rede.filtro,
    lista: state.rede.lista,
    listaCliente: state.rede.listaCliente,
    listaUnidadeConsumidora: state.rede.listaUnidadeConsumidora,
    listaMeses: state.rede.listaMeses,
    pdf: state.displayPDF.pdf,
    nomePdf: state.displayPDF.nomePdf,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            setArquivoPDF,
            initForm,
            setFiltro,
            salvar,
            excluir,
            getLista,
            getListaCliente,
            getListaUnidadeConsumidora,
            buscarPdfUnifilarRedeInterna,
            buscarPdfProjetoRedeInterna,
            buscarProjetoRedeInternaGoogleEarth,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(RedeVisualizacao);
