import React from "react";
import InfoBox from "../common/template/infoBox";
import Grid from "../common/layout/grid";
import charging90 from "../assets/images/battery-level-90-charging.svg";
import charging50 from "../assets/images/battery-level-50-charging.svg";
import charging20 from "../assets/images/battery-level-20-charging.svg";
import Select from "react-select";

const optionsDesempenho = [
    { value: "potencia_instantanea", label: "Instantâneo" },
    { value: "energia_dia", label: "Diário" },
    { value: "energia_mes", label: "Mensal" },
    { value: "energia_ano", label: "Anual" },
];

export function GeracaoLeituraCardsStatusProducao({
    telaDetalhesAtiva,
    usuarioLogado,
    lista,
    filtroDesempenhoOtimo,
    setFiltroDesempenhoOtimo,
    filtroDesempenhoModerado,
    setFiltroDesempenhoModerado,
    filtroDesempenhoBaixo,
    setFiltroDesempenhoBaixo,
}) {
    const totalUsinasOperandoNormalmente = lista.filter((item) => {
        //se NÃO estiver na tela de detalhes considera também a hora pra validar o status
        return telaDetalhesAtiva
            ? item.status && item.potencia_instantanea > 0 && (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente)
            : item.status &&
                  item.potencia_instantanea > 0 &&
                  (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) &&
                  new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora);
    }).length;
    const totalUsinasSemComunicacao = lista
        .filter((item) => !usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente)
        .filter((item) => {
            //se NÃO estiver na tela de detalhes considera também a hora pra validar o status
            return telaDetalhesAtiva
                ? !(item.status && item.potencia_instantanea > 0)
                : !(item.status && item.potencia_instantanea > 0 && new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora));
        }).length;

    const totalUsinasProducaoOtima = lista.filter(
        (item) =>
            (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) &&
            (filtroDesempenhoOtimo.valor !== "potencia_instantanea" || item.statusVerificado) &&
            item["desempenho_" + filtroDesempenhoOtimo.valor] > 80
    ).length;

    const totalUsinasProducaoModerada = lista.filter(
        (item) =>
            (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) &&
            (filtroDesempenhoModerado.valor !== "potencia_instantanea" || item.statusVerificado) &&
            item["desempenho_" + filtroDesempenhoModerado.valor] >= 30 &&
            item["desempenho_" + filtroDesempenhoModerado.valor] <= 80
    ).length;
    const totalUsinasProducaoBaixa = lista.filter(
        (item) =>
            (!usuarioLogado.id_cliente || item.id_cliente == usuarioLogado.id_cliente) &&
            (filtroDesempenhoBaixo.valor !== "potencia_instantanea" || item.statusVerificado) &&
            item["desempenho_" + filtroDesempenhoBaixo.valor] < 30
    ).length;

    //----------------------------------------------------------------------
    return (
        <>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox icon="fas fa-check" text="Operando Normalmente" number={totalUsinasOperandoNormalmente} twoColors="#3EFF3E" minHeight={50} />
            </Grid>
            <Grid cols="12 6 6 6" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox icon="fas fa-exclamation-triangle" text="Sem Comunicação ou Desligado" number={totalUsinasSemComunicacao} twoColors="#F56969" minHeight={50} />
            </Grid>
            <Grid cols="12 12 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox
                    text={
                        <span>
                            Desempenho Ótimo (<b>{totalUsinasProducaoOtima}</b>)
                        </span>
                    }
                    twoColors="#4AE30E"
                    minHeight={50}
                    childrenColumn={true}
                    checked={filtroDesempenhoOtimo.ativado}
                    onClick={() => {
                        setFiltroDesempenhoBaixo({ ...filtroDesempenhoBaixo, ativado: false });
                        setFiltroDesempenhoModerado({ ...filtroDesempenhoModerado, ativado: false });
                        setFiltroDesempenhoOtimo({ valor: "potencia_instantanea", ativado: !filtroDesempenhoOtimo.ativado });
                    }}
                >
                    <img
                        src={charging90}
                        style={{
                            height: 30,
                            width: 30,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                    <div style={{ minWidth: "85%" }}>
                        <Select
                            value={optionsDesempenho.find((item) => item.value === filtroDesempenhoOtimo.valor)}
                            isDisabled={!filtroDesempenhoOtimo.ativado}
                            isClearable={false}
                            isSearchable={false}
                            isMulti={false}
                            onChange={(item) => setFiltroDesempenhoOtimo({ ...filtroDesempenhoOtimo, valor: item.value })}
                            options={optionsDesempenho}
                        />
                    </div>
                </InfoBox>
            </Grid>
            <Grid cols="12 12 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox
                    text={
                        <span>
                            Desempenho Moderado (<b>{totalUsinasProducaoModerada}</b>)
                        </span>
                    }
                    twoColors="#FFE53C"
                    minHeight={50}
                    childrenColumn={true}
                    checked={filtroDesempenhoModerado.ativado}
                    onClick={() => {
                        setFiltroDesempenhoBaixo({ ...filtroDesempenhoBaixo, ativado: false });
                        setFiltroDesempenhoOtimo({ ...filtroDesempenhoOtimo, ativado: false });
                        setFiltroDesempenhoModerado({ valor: "potencia_instantanea", ativado: !filtroDesempenhoModerado.ativado });
                    }}
                >
                    <img
                        src={charging50}
                        style={{
                            height: 30,
                            width: 30,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                    <div style={{ minWidth: "85%" }}>
                        <Select
                            value={optionsDesempenho.find((item) => item.value === filtroDesempenhoModerado.valor)}
                            isDisabled={!filtroDesempenhoModerado.ativado}
                            isClearable={false}
                            isSearchable={false}
                            isMulti={false}
                            onChange={(item) => setFiltroDesempenhoModerado({ ...filtroDesempenhoModerado, valor: item.value })}
                            options={optionsDesempenho}
                        />
                    </div>
                </InfoBox>
            </Grid>
            <Grid cols="12 12 4 4" style={{ paddingLeft: window.innerWidth < 992 ? 0 : null }}>
                <InfoBox
                    text={
                        <span>
                            Desempenho Baixo (<b>{totalUsinasProducaoBaixa}</b>)
                        </span>
                    }
                    twoColors="#FF3838"
                    minHeight={50}
                    childrenColumn={true}
                    checked={filtroDesempenhoBaixo.ativado}
                    onClick={() => {
                        setFiltroDesempenhoModerado({ ...filtroDesempenhoModerado, ativado: false });
                        setFiltroDesempenhoOtimo({ ...filtroDesempenhoOtimo, ativado: false });
                        setFiltroDesempenhoBaixo({ valor: "potencia_instantanea", ativado: !filtroDesempenhoBaixo.ativado });
                    }}
                >
                    <img
                        src={charging20}
                        style={{
                            height: 30,
                            width: 30,
                            filter: "brightness(0) invert(1)",
                        }}
                        alt=""
                    />
                    <div style={{ minWidth: "85%" }}>
                        <Select
                            value={optionsDesempenho.find((item) => item.value === filtroDesempenhoBaixo.valor)}
                            isDisabled={!filtroDesempenhoBaixo.ativado}
                            isClearable={false}
                            isSearchable={false}
                            isMulti={false}
                            onChange={(item) => setFiltroDesempenhoBaixo({ ...filtroDesempenhoBaixo, valor: item.value })}
                            options={optionsDesempenho}
                        />
                    </div>
                </InfoBox>
            </Grid>
        </>
    );
}
