import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./geracaoLeitura.css";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Select from "../common/form/select";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import noEnergy from "../assets/images/no-energy.png";
import DateFormat from "../common/dateFormat/DateFormat";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import DivBox from "../common/divBox/divBox";
import { adicionarPontosMilhar } from "../utils/adicionarPontosMilhar.js";
import somNotificacaoArquivo from "../assets/sounds/notificacao.wav";
import { weatherIcons } from "../assets/weatherIcons.js";
import { SemiCircleProgressBar } from "../common/semiCircleProgressBar/semiCircleProgressBar.jsx";
import { GeracaoLeituraInversores } from "./geracaoLeituraInversores.jsx";
import { GeracaoLeituraCardsTotalizadores } from "./geracaoLeituraCardsTotalizadores.jsx";
import { GeracaoLeituraCardsStatusProducao } from "./geracaoLeituraCardsStatusProducao.jsx";
import { GeracaoLeituraGraficoGeral } from "./geracaoLeituraGraficoGeral.jsx";
import {
    setModoTela,
    setFiltro,
    setDataGrafico,
    getLista,
    getModulos,
    getListaCliente,
    getListaUnidadeConsumidora,
    getListaGraficoTodasIntegracoesAgrupadasPorHora,
    getListaGraficoEnergiaDia,
    getListaGraficoEnergiaDiasMes,
    getListaGraficoEnergiaMesesAno,
    setStatusVisualizado,
    setAguardando,
} from "./geracaoLeituraActions";

import { getListaGeracaoIntegracaoTipo } from "../geracaoIntegracao/geracaoIntegracaoActions.js";

import { setEmitirAlertaSonoro } from "../usuario/usuarioActions";

import { Loading } from "../common/loading/loading";
import { montarGraficoLineEnergiaDia } from "./montarGraficoEnergiaLineDia.js";
import { montarGraficoColunasEnergiaDiasMes } from "./montarGraficoColunasEnergiaDiasMes.js";
import { montarGraficoColunasEnergiaMesesAno } from "./montarGraficoColunasEnergiaMesesAno.js";
import { GeracaoLeituraModulos } from "./geracaoLeituraModulos.jsx";
import { montarGraficoLineTodasIntegracoesAgrupadasPorHora } from "./montarGraficoLineTodasIntegracoesAgrupadasPorHora.js";
import IndicadorParcialRendimentoMensal from "./indicadorParcialRendimentoMensal.jsx";

// Variáveis de controle dentro da função principal
let sleepTimeoutId = null;
let idIntervaloNotificacao = null;
let idIntervaloBusca = null;

class GeracaoLeitura extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listaTiposSemFiltros: [],
            dispositivos: null,
            // Coisas do gráfico
            mobile: window.innerWidth < 500,
            graficoEnergiaDia: null,
            graficoEnergiaDiasMes: null,
            graficoEnergiaMesesAno: null,
            ultima_atualizacao_lista: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
            apenasIndisponiveis: false,
            filtroDesempenhoOtimo: { valor: "potencia_instantanea", ativado: false },
            filtroDesempenhoModerado: { valor: "potencia_instantanea", ativado: false },
            filtroDesempenhoBaixo: { valor: "potencia_instantanea", ativado: false },
        };
        // Binding filtrarLista in the constructor
        this.filtrarLista = this.filtrarLista.bind(this);
        this.emitirAlertaSonoro = this.emitirAlertaSonoro.bind(this);
        this.setChart = this.setChart.bind(this);
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
        this.cancelarTimersAlertaSonoro();
        this.cancelarTimerBuscaLista5min();
    }
    componentWillMount() {
        this.props.setFiltro({
            ...this.props.filtro,
            id_cliente: this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente ? this.props.usuarioLogado.id_cliente : null,
        });
        this.props.getListaCliente();
        this.props.getListaGeracaoIntegracaoTipo();
        this.props.getListaUnidadeConsumidora();
        this.atualizarDadosCada5Min();
        this.emitirAlertaSonoro();
    }

    atualizarDadosCada5Min() {
        const cincoMinutosMs = 1000 * 60 * 5;
        this.buscarDadosTela();
        if (!idIntervaloBusca) {
            console.log("iniciando intervalo busca");
            idIntervaloBusca = setInterval(() => this.buscarDadosTela(), cincoMinutosMs);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        window.scrollTo(0, 0);

        if (!this.state.listaTiposSemFiltros.length && this.props.lista.length) {
            const listaTiposSemFiltros = this.props.listaGeracaoIntegracaoTipo.filter((t) =>
                this.filtrarLista().find(
                    (item) => item.id_geracao_integracao_tipo === t.id || (Array.isArray(item.id_geracao_integracao_tipo) && item.id_geracao_integracao_tipo.includes(t.id))
                )
            );
            this.setState({
                ...this.state,
                listaTiposSemFiltros,
            });
        }

        if (
            this.props.modoTela === "graficoEnergiaDia" &&
            (prevState.graficoEnergiaDia !== this.state.graficoEnergiaDia || JSON.stringify(prevProps.listaGraficoEnergiaDia) !== JSON.stringify(this.props.listaGraficoEnergiaDia))
        ) {
            this.exibirGrafico("graficoEnergiaDia");
        }
        if (
            this.props.modoTela === "graficoEnergiaDiasMes" &&
            (prevState.graficoEnergiaDiasMes !== this.state.graficoEnergiaDiasMes ||
                JSON.stringify(prevProps.listaGraficoEnergiaDiasMes) !== JSON.stringify(this.props.listaGraficoEnergiaDiasMes))
        ) {
            this.exibirGrafico("graficoEnergiaDiasMes");
        }
        if (
            this.props.modoTela === "graficoEnergiaMesesAno" &&
            (prevState.graficoEnergiaMesesAno !== this.state.graficoEnergiaMesesAno ||
                JSON.stringify(prevProps.listaGraficoEnergiaMesesAno) !== JSON.stringify(this.props.listaGraficoEnergiaMesesAno))
        ) {
            this.exibirGrafico("graficoEnergiaMesesAno");
        }

        if (
            prevProps.modoTela !== this.props.modoTela ||
            JSON.stringify(prevProps.listaGraficoTodasIntegracoesAgrupadasPorHora) !== JSON.stringify(this.props.listaGraficoTodasIntegracoesAgrupadasPorHora) ||
            !this.chart ||
            prevState.ultima_atualizacao_lista !== this.state.ultima_atualizacao_lista
        ) {
            if (this.chart) this.chart.dispose();
            montarGraficoLineTodasIntegracoesAgrupadasPorHora({
                id: "graficoTodasIntegracoesAgrupadasPorHora",
                lista: this.props.listaGraficoTodasIntegracoesAgrupadasPorHora,
                setChart: this.setChart,
            });
        }

        if (
            JSON.stringify(prevProps.lista) !== JSON.stringify(this.props.lista) ||
            prevProps.usuarioLogado.emitir_alerta_sonoro !== this.props.usuarioLogado.emitir_alerta_sonoro
        ) {
            this.emitirAlertaSonoro();
        }
    }

    cancelarTimerBuscaLista5min() {
        if (idIntervaloBusca !== null) {
            console.log("Cancelando intervalo busca tela");
            clearInterval(idIntervaloBusca);
            idIntervaloBusca = null; // Redefine para garantir que só há um intervalo ativo
        }
    }

    cancelarTimersAlertaSonoro() {
        // Cancela qualquer timeout ou intervalo em andamento antes de iniciar um novo
        if (sleepTimeoutId !== null) {
            console.log("Cancelando timer alerta anterior");
            clearTimeout(sleepTimeoutId);
            sleepTimeoutId = null; // Redefine para garantir que só há um timer ativo
        }
        if (idIntervaloNotificacao !== null) {
            console.log("Cancelando intervalo alerta anterior");
            clearInterval(idIntervaloNotificacao);
            idIntervaloNotificacao = null; // Redefine para garantir que só há um intervalo ativo
        }
    }

    emitirAlertaSonoro() {
        // Função interna para iniciar o processo de alerta
        const iniciarAlerta = () => {
            console.log({ sleepTimeoutId, idIntervaloNotificacao });
            this.cancelarTimersAlertaSonoro();
            // Define o tempo de espera de 2 minutos
            const doisMinutos = 1000 * 60 * 2;

            // Inicia um novo timer de 2 minutos
            sleepTimeoutId = setTimeout(async () => {
                const listaFiltrada = this.filtrarLista(); // Certifique-se de que filtrarLista é acessível aqui
                let horarioValido = false;
                const dataHoraAtual = new Date();
                const mes = dataHoraAtual.getMonth() + 1;
                const hora = dataHoraAtual.getHours();
                const minutos = dataHoraAtual.getMinutes();

                // Lógica de verificação do horário
                if ((mes >= 11 || mes <= 2) && ((hora > 7 && hora < 18) || (hora === 7 && minutos >= 30) || (hora === 18 && minutos === 0))) {
                    horarioValido = true;
                } else if (mes >= 3 && mes <= 5 && ((hora > 8 && hora < 17) || (hora === 8 && minutos >= 30) || (hora === 17 && minutos === 0))) {
                    horarioValido = true;
                } else if (mes >= 6 && mes <= 10 && ((hora >= 9 && hora < 17) || (hora === 17 && minutos === 0))) {
                    horarioValido = true;
                }

                // Verifica leituras com erro
                const leiturasComErro = listaFiltrada.find((item) => {
                    const nuvemOuChuvaValidada = hora < 9 || (hora === 17 && minutos > 0) || hora > 17 ? !item.nuvemOuChuva : true;
                    return (
                        nuvemOuChuvaValidada &&
                        !item.status_visualizado &&
                        !this.props.filtro.id_agrupador &&
                        [this.props.STATUS_COR_AMARELA, this.props.STATUS_COR_VERMELHA].includes(item.corStatus)
                    );
                });

                // Emite o alerta se todas as condições estiverem corretas
                if (!!leiturasComErro && horarioValido && this.props.usuarioLogado.emitir_alerta_sonoro) {
                    console.log("deve emitir");
                    if (!idIntervaloNotificacao) {
                        console.log("Emitindo alerta sonoro");
                        idIntervaloNotificacao = setInterval(async () => {
                            try {
                                const somNotificacao = new Audio(somNotificacaoArquivo);
                                await somNotificacao.play();
                            } catch (erro) {
                                console.log("Erro ao tentar emitir som: ", erro);
                            }
                        }, 3000);
                    }
                }
            }, doisMinutos); // Espera de 2 minutos (120000 ms)

            console.log("Novo timer iniciado:", sleepTimeoutId);
        };

        // Inicia o alerta
        iniciarAlerta();
    }

    filtrarLista() {
        const lista = this.props.lista.filter((item) => {
            const usuarioLogadoAcessoCliente = this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente;
            const filtroClienteValidado = !usuarioLogadoAcessoCliente || item.id_cliente == this.props.usuarioLogado.id_cliente;
            const filtroUcValidado = !this.props.filtro.id_unidade_consumidora || item.id_unidade_consumidora == this.props.filtro.id_unidade_consumidora;
            const filtroIndisponiveis = !this.state.apenasIndisponiveis || !item.statusVerificado;
            const filtroTipo =
                !this.props.filtro.id_geracao_integracao_tipo ||
                this.props.filtro.id_geracao_integracao_tipo == item.id_geracao_integracao_tipo ||
                (Array.isArray(item.id_geracao_integracao_tipo) && item.id_geracao_integracao_tipo.includes(parseInt(this.props.filtro.id_geracao_integracao_tipo)));

            //pra potencia instantanea só considera os online
            const filtroDesempenhoBaixo =
                !this.state.filtroDesempenhoBaixo.ativado ||
                (item.statusVerificado && this.state.filtroDesempenhoBaixo.valor === "potencia_instantanea" && item.desempenho_potencia_instantanea < 30) ||
                (this.state.filtroDesempenhoBaixo.valor === "energia_dia" && item.desempenho_energia_dia < 30) ||
                (this.state.filtroDesempenhoBaixo.valor === "energia_mes" && item.desempenho_energia_mes < 30) ||
                (this.state.filtroDesempenhoBaixo.valor === "energia_ano" && item.desempenho_energia_ano < 30);
            const filtroDesempenhoModerado =
                !this.state.filtroDesempenhoModerado.ativado ||
                (item.statusVerificado &&
                    this.state.filtroDesempenhoModerado.valor === "potencia_instantanea" &&
                    item.desempenho_potencia_instantanea >= 30 &&
                    item.desempenho_potencia_instantanea <= 80) ||
                (this.state.filtroDesempenhoModerado.valor === "energia_dia" && item.desempenho_energia_dia >= 30 && item.desempenho_energia_dia <= 80) ||
                (this.state.filtroDesempenhoModerado.valor === "energia_mes" && item.desempenho_energia_mes >= 30 && item.desempenho_energia_mes <= 80) ||
                (this.state.filtroDesempenhoModerado.valor === "energia_ano" && item.desempenho_energia_ano >= 30 && item.desempenho_energia_ano <= 80);
            const filtroDesempenhoOtimo =
                !this.state.filtroDesempenhoOtimo.ativado ||
                (item.statusVerificado && this.state.filtroDesempenhoOtimo.valor === "potencia_instantanea" && item.desempenho_potencia_instantanea > 80) ||
                (this.state.filtroDesempenhoOtimo.valor === "energia_dia" && item.desempenho_energia_dia > 80) ||
                (this.state.filtroDesempenhoOtimo.valor === "energia_mes" && item.desempenho_energia_mes > 80) ||
                (this.state.filtroDesempenhoOtimo.valor === "energia_ano" && item.desempenho_energia_ano > 80);
            return (
                (filtroClienteValidado && filtroUcValidado && filtroIndisponiveis && filtroTipo && filtroDesempenhoBaixo && filtroDesempenhoModerado && filtroDesempenhoOtimo) ||
                this.props.filtro.id_agrupador
            );
        });
        return lista;
    }

    filtroDesempenho(nome_campo) {
        return (
            (this.state.filtroDesempenhoBaixo.ativado && this.state.filtroDesempenhoBaixo.valor === nome_campo) ||
            (this.state.filtroDesempenhoModerado.ativado && this.state.filtroDesempenhoModerado.valor === nome_campo) ||
            (this.state.filtroDesempenhoOtimo.ativado && this.state.filtroDesempenhoOtimo.valor === nome_campo)
        );
    }

    render() {
        // Sem acessos
        if (!this.props.usuarioLogado || (!this.props.usuarioLogado.acesso_solar_visualizar && !this.props.usuarioLogado.acesso_solar_editar)) {
            return <div />;
        }
        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}
                <Content>
                    {this.props.modoTela === "lista" ? this.lista() : null}
                    {this.props.modoTela === "dispositivos" ? <GeracaoLeituraInversores item={this.props.registro} voltar={() => this.props.setModoTela("lista", {})} /> : null}
                    {this.props.modoTela === "modulos" ? <GeracaoLeituraModulos modulos={this.props.modulos} voltar={() => this.props.setModoTela("lista", {})} /> : null}
                    {this.props.modoTela.includes("grafico") ? this.exibirGrafico(this.props.modoTela) : null}
                </Content>
            </div>
        );
    }

    lista() {
        const telaDetalhesAtiva = !!this.props.filtro.id_agrupador;

        return (
            <ContentCard>
                <ContentCardBody>
                    {!this.props.filtro.id_agrupador && (
                        <>
                            <Row
                                style={{
                                    marginBottom: 5,
                                    justifyContent: "space-between",
                                    paddingInline: 10,
                                }}
                            >
                                <Grid
                                    cols="12 12 12 6"
                                    style={{
                                        backgroundColor: "#1e3e49",
                                        borderRadius: 8,
                                        marginTop: 10,
                                        height: "fit-content",
                                    }}
                                >
                                    {this.props.modoTela === "lista" ? (
                                        <GeracaoLeituraGraficoGeral
                                            atualizarLista={this.props.getListaGraficoTodasIntegracoesAgrupadasPorHora}
                                            filtros={{
                                                id_cliente: this.props.filtro.id_cliente,
                                                id_unidade_consumidora: this.props.filtro.id_unidade_consumidora,
                                                id_geracao_integracao_tipo: this.props.filtro.id_geracao_integracao_tipo,
                                            }}
                                        />
                                    ) : null}
                                </Grid>
                                <Grid cols="12 12 12 6">
                                    {window.innerWidth > 768 ? (
                                        <Row>
                                            <GeracaoLeituraCardsTotalizadores usuarioLogado={this.props.usuarioLogado} lista={this.props.lista} />
                                            <GeracaoLeituraCardsStatusProducao
                                                usuarioLogado={this.props.usuarioLogado}
                                                lista={this.props.lista}
                                                telaDetalhesAtiva={telaDetalhesAtiva}
                                                filtroDesempenhoOtimo={this.state.filtroDesempenhoOtimo}
                                                filtroDesempenhoModerado={this.state.filtroDesempenhoModerado}
                                                filtroDesempenhoBaixo={this.state.filtroDesempenhoBaixo}
                                                setFiltroDesempenhoOtimo={(valor) => this.setState({ ...this.state, filtroDesempenhoOtimo: valor })}
                                                setFiltroDesempenhoModerado={(valor) => this.setState({ ...this.state, filtroDesempenhoModerado: valor })}
                                                setFiltroDesempenhoBaixo={(valor) => this.setState({ ...this.state, filtroDesempenhoBaixo: valor })}
                                            />
                                        </Row>
                                    ) : (
                                        <details
                                            open={this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente}
                                            style={{
                                                marginTop: 20,
                                                padding: 20,
                                                fontSize: "1.1rem",
                                                border: "1px solid #ddd",
                                                borderRadius: 5,
                                            }}
                                        >
                                            <summary>
                                                <b>Exibir totalizadores</b>
                                            </summary>
                                            <Row style={{ marginTop: 10 }}>
                                                <GeracaoLeituraCardsTotalizadores usuarioLogado={this.props.usuarioLogado} lista={this.props.lista} />
                                                <GeracaoLeituraCardsStatusProducao
                                                    usuarioLogado={this.props.usuarioLogado}
                                                    lista={this.props.lista}
                                                    telaDetalhesAtiva={telaDetalhesAtiva}
                                                    filtroDesempenhoOtimo={this.state.filtroDesempenhoOtimo}
                                                    filtroDesempenhoModerado={this.state.filtroDesempenhoModerado}
                                                    filtroDesempenhoBaixo={this.state.filtroDesempenhoBaixo}
                                                    setFiltroDesempenhoOtimo={(valor) => this.setState({ ...this.state, filtroDesempenhoOtimo: valor })}
                                                    setFiltroDesempenhoModerado={(valor) => this.setState({ ...this.state, filtroDesempenhoModerado: valor })}
                                                    setFiltroDesempenhoBaixo={(valor) => this.setState({ ...this.state, filtroDesempenhoBaixo: valor })}
                                                />
                                            </Row>
                                        </details>
                                    )}
                                </Grid>
                            </Row>
                        </>
                    )}
                    <Row>
                        {this.props.filtro.id_agrupador ? null : (
                            <>
                                {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                    <Select
                                        name="id_cliente"
                                        options={this.props.listaCliente.filter((item) =>
                                            this.props.listaUnidadeConsumidora.find((uc) => uc.id_cliente == item.id && uc.geracao_integracao)
                                        )}
                                        label="Cliente"
                                        cols="12 5 5 5"
                                        placeholder="Selecione o Cliente"
                                        value={this.props.filtro.id_cliente}
                                        onChange={(value) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                id_cliente: value,
                                                id_unidade_consumidora: null,
                                            });
                                            this.buscarDadosTela();
                                        }}
                                    />
                                ) : null}
                                {this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente && window.innerWidth <= 768 ? null : (
                                    <Select
                                        name="id_unidade_consumidora"
                                        options={this.props.listaUnidadeConsumidora.filter((item) => {
                                            if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
                                                return this.props.usuarioLogado.id_cliente == item.id_cliente && item.geracao_integracao;
                                            } else {
                                                return (!this.props.filtro.id_cliente || this.props.filtro.id_cliente == item.id_cliente) && item.geracao_integracao;
                                            }
                                        })}
                                        label="Unidade Consumidora"
                                        cols={this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente ? "12 12 12 12" : "12 5 5 5"}
                                        placeholder="Selecione a UC"
                                        value={this.props.filtro.id_unidade_consumidora}
                                        onChange={(value) => {
                                            const ucSelecionada = this.props.listaUnidadeConsumidora.find((uc) => uc.id == value) || {};
                                            const acessoCliente = this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente;
                                            const idCliente = ucSelecionada.id_cliente || this.props.filtro.id_cliente;
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                id_cliente: acessoCliente ? this.props.usuarioLogado.id_cliente : idCliente,
                                                id_unidade_consumidora: value,
                                            });
                                            this.buscarDadosTela();
                                        }}
                                    />
                                )}
                                {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                    <Select
                                        name="id_geracao_integracao_tipo"
                                        options={this.state.listaTiposSemFiltros}
                                        label="Tipo"
                                        cols={"12 2 2 2"}
                                        placeholder="Selecione o Tipo"
                                        value={this.props.filtro.id_geracao_integracao_tipo}
                                        onChange={(value) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                id_geracao_integracao_tipo: value,
                                            });
                                            this.buscarDadosTela();
                                        }}
                                    />
                                ) : null}
                                <Grid
                                    cols="12 4 4 4"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingTop: 11,
                                    }}
                                >
                                    <label style={this.getStyleSlimCard("#17a2b8", "#ffffff")} htmlFor="filtro-agrupar-por-uc">
                                        <LabelAndCheckbox
                                            style={{ justifyContent: "center" }}
                                            readOnly={this.props.aguardando}
                                            modoCard={true}
                                            colorIcon="#FFF"
                                            id="filtro-agrupar-por-uc"
                                            label="Agrupar por UC"
                                            onChange={(e) => {
                                                this.props.setFiltro({
                                                    ...this.props.filtro,
                                                    agrupar_por_uc: !this.props.filtro.agrupar_por_uc,
                                                });
                                                this.buscarDadosTela();
                                            }}
                                            value={!!this.props.filtro.agrupar_por_uc}
                                        />
                                    </label>
                                </Grid>
                                <Grid
                                    cols="12 1 1 1"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // fontSize: "1.7rem",
                                        paddingLeft: window.innerWidth < 992 ? 0 : null,
                                    }}
                                >
                                    <Button
                                        solid={true}
                                        style={{
                                            marginTop: 13,
                                            color: "#fff",

                                            transform: "scaleX(-1)",
                                        }}
                                        text={
                                            this.props.usuarioLogado.emitir_alerta_sonoro ? (
                                                <i style={{ fontSize: "1.2rem" }} className="far fa-bell" />
                                            ) : (
                                                <i style={{ fontSize: "1.2rem" }} className="far fa-bell-slash" />
                                            )
                                        }
                                        type={"warning"}
                                        event={this.props.setEmitirAlertaSonoro}
                                    />
                                </Grid>
                                {this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente && window.innerWidth <= 768 ? null : (
                                    <Grid
                                        cols="12 4 4 4"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingTop: 11,
                                        }}
                                    >
                                        <label style={this.getStyleSlimCard("#f56969", "#ffffff")} htmlFor="filtro-apenas-indisponiveis">
                                            <LabelAndCheckbox
                                                style={{ justifyContent: "center" }}
                                                readOnly={this.props.aguardando}
                                                modoCard={true}
                                                colorIcon="#FFF"
                                                id="filtro-apenas-indisponiveis"
                                                label="Apenas Indisponíveis"
                                                onChange={(e) =>
                                                    this.setState({
                                                        ...this.state,
                                                        apenasIndisponiveis: !this.state.apenasIndisponiveis,
                                                    })
                                                }
                                                value={!!this.state.apenasIndisponiveis}
                                            />
                                        </label>
                                    </Grid>
                                )}
                                <Grid
                                    cols={"12 3 3 3"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingBottom: 8,
                                    }}
                                >
                                    <span style={{ textAlign: "right", width: "100%", color: "#777" }}>
                                        <i>
                                            {window.innerWidth > 990 && window.innerWidth < 1600 ? "At. " : "Atualizada em "}
                                            {this.state.ultima_atualizacao_lista}
                                        </i>
                                    </span>
                                    <Button
                                        text="Recarregar"
                                        type={"primary"}
                                        event={() => {
                                            this.buscarDadosTela();
                                        }}
                                    />
                                </Grid>
                            </>
                        )}
                    </Row>
                    {telaDetalhesAtiva ? (
                        <Row alignRight>
                            <Grid
                                cols="12 6 4"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "15px",
                                }}
                            >
                                <Button
                                    text="Voltar"
                                    type={"warning"}
                                    icon={"fa fa-chevron-left"}
                                    event={() => {
                                        this.props.setModoTela("lista", {});
                                        this.props.setFiltro({
                                            ...this.props.filtro,
                                            id_agrupador: null,
                                        });
                                        this.buscarDadosTela();
                                    }}
                                />
                            </Grid>
                        </Row>
                    ) : null}
                    <Table responsive>
                        <THead>
                            <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#FFFFFF"}>
                                <Th sticky alignCenter verticalAlign="top">
                                    Unidade Consumidora
                                </Th>
                                <Th sticky alignCenter verticalAlign="top" maxWidth={70}>
                                    Status
                                </Th>
                                <Th sticky alignCenter verticalAlign="top">
                                    Data e Hora
                                </Th>
                                <Th sticky alignCenter verticalAlign="top">
                                    Tipo
                                </Th>
                                <Th sticky alignCenter verticalAlign="top">
                                    Potência Instantânea
                                </Th>
                                <Th colSpan="2" sticky alignCenter verticalAlign="top">
                                    Potência Instalada
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div style={{ display: "flex", justifyContent: "center", width: "49%" }}>Módulos</div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "2%" }}>|</div>
                                        <div style={{ display: "flex", justifyContent: "center", width: "49%" }}>Inversores</div>
                                    </div>
                                </Th>

                                {this.props.filtro.id_agrupador ? (
                                    <Th sticky alignCenter verticalAlign="top">
                                        Energia Instantânea
                                    </Th>
                                ) : (
                                    <>
                                        <Th sticky alignCenter verticalAlign="top">
                                            Energia do Dia
                                        </Th>
                                        <Th sticky alignCenter verticalAlign="top">
                                            Energia do Mês
                                        </Th>
                                        <Th sticky alignCenter verticalAlign="top">
                                            Energia do Ano
                                        </Th>
                                    </>
                                )}

                                {this.props.filtro.id_agrupador ? null : (
                                    <>
                                        <Th colSpan="6" sticky alignCenter verticalAlign="top">
                                            Dados Meteorológicos
                                        </Th>
                                        <Th sticky alignCenter verticalAlign="top">
                                            Detalhes das últimas 24h
                                        </Th>
                                    </>
                                )}
                            </NewTr>
                        </THead>
                        <TBody>
                            {this.filtrarLista().map((item, index) => {
                                return (
                                    <NewTr key={index} backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#FFFFFF"} botoes={[]}>
                                        <Td minWidth={120}>{item.nome_unidade_consumidora}</Td>
                                        <Td alignCenter minWidth={60}>
                                            <div
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    borderRadius: "100%",
                                                    boxShadow: "inset 0 0 3px #777",
                                                    backgroundColor:
                                                        item.corStatus === this.props.STATUS_COR_AMARELA
                                                            ? "#FFCC22"
                                                            : item.corStatus === this.props.STATUS_COR_VERMELHA
                                                            ? "#FF1040"
                                                            : item.corStatus === this.props.STATUS_COR_VERDE
                                                            ? "#4AE30E"
                                                            : "",
                                                }}
                                                className={
                                                    this.props.filtro.id_agrupador
                                                        ? null
                                                        : item.corStatus === this.props.STATUS_COR_AMARELA && !item.status_visualizado
                                                        ? "geracao-leitura-status-alerta-amarela"
                                                        : item.corStatus === this.props.STATUS_COR_VERMELHA && !item.status_visualizado
                                                        ? "geracao-leitura-status-alerta-vermelha"
                                                        : null
                                                }
                                                title={
                                                    item.corStatus === this.props.STATUS_COR_AMARELA
                                                        ? "Alguns inversores estão indisponíveis"
                                                        : item.corStatus === this.props.STATUS_COR_VERMELHA
                                                        ? "Indisponível"
                                                        : item.corStatus === this.props.STATUS_COR_VERDE
                                                        ? "Funcionando"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    if (
                                                        !item.status_visualizado &&
                                                        !this.props.filtro.id_agrupador &&
                                                        [this.props.STATUS_COR_AMARELA, this.props.STATUS_COR_VERMELHA].includes(item.corStatus)
                                                    ) {
                                                        const idOuListaIds = this.props.filtro.agrupar_por_uc ? item.ids_agrupados : item.id;
                                                        this.props.setStatusVisualizado(idOuListaIds);
                                                    }
                                                }}
                                            />
                                        </Td>
                                        <Td alignCenter>
                                            {item.data_hora
                                                ? new Date(item.data_hora)
                                                      .toLocaleString({
                                                          dateStyle: "short",
                                                          timeStyle: "medium",
                                                      })
                                                      .replace(",", "")
                                                : "Nenhuma leitura"}
                                        </Td>
                                        <Td alignCenter>
                                            {item.tipo instanceof Array
                                                ? item.tipo.map((tipo, i) => (
                                                      <p key={i} style={{ margin: 0 }}>
                                                          {tipo}
                                                      </p>
                                                  ))
                                                : item.tipo}
                                        </Td>

                                        <Td alignCenter backgroundColor={this.filtroDesempenho("potencia_instantanea") ? "#b7c2c3" : null}>
                                            {[this.props.STATUS_COR_VERDE, this.props.STATUS_COR_AMARELA].includes(item.corStatus) ? (
                                                <div
                                                    style={{
                                                        width: 85,
                                                        height: 85,
                                                        padding: "5px",
                                                        fontSize: "1rem",
                                                    }}
                                                >
                                                    <CircularProgressbarWithChildren
                                                        styles={buildStyles({
                                                            pathColor:
                                                                item.desempenho_potencia_instantanea < 30
                                                                    ? "#fb425c"
                                                                    : item.desempenho_potencia_instantanea > 80
                                                                    ? "#51fd6d"
                                                                    : "#fff127",
                                                            textColor: "#000",
                                                        })}
                                                        value={item.desempenho_potencia_instantanea || 0}
                                                        // text={`${Number(item.potencia_instantanea).toFixed(1).replace(".", ",")} kW\n
                                                        // 		${item.desempenho_potencia_instantanea} %`}
                                                    >
                                                        <div style={{ zIndex: 0 }}>
                                                            <p
                                                                style={{
                                                                    margin: "11px 0 0 0",
                                                                    fontSize: "0.88rem",
                                                                }}
                                                            >{`${Number(item.potencia_instantanea).toFixed(1).replace(".", ",")} kW`}</p>
                                                            <p style={{ margin: "-3px 0 0 0", fontSize: "0.7rem" }}>{`${Math.round(item.desempenho_potencia_instantanea)} %`}</p>
                                                        </div>
                                                    </CircularProgressbarWithChildren>
                                                </div>
                                            ) : (
                                                <div
                                                    title="Sem Geração de Energia"
                                                    style={{
                                                        borderRadius: "50%",
                                                        border: "5px solid #e3e3e3",
                                                        height: 72,
                                                        width: 73,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <img alt="" src={noEnergy} width={30} height={30} />
                                                </div>
                                            )}
                                        </Td>
                                        <Td alignCenter>
                                            <Button
                                                title={"Visualizar módulos"}
                                                style={{
                                                    width: 80,
                                                    height: 80,
                                                    padding: 0,
                                                }}
                                                text={
                                                    <div>
                                                        <p style={{ marginTop: 0, marginBottom: 0 }}>
                                                            <i className="fas fa-solar-panel" style={{ fontSize: "1.1rem", padding: 10 }}></i>
                                                        </p>
                                                        <p style={{ marginBottom: 0 }}>
                                                            {item.modulos_potencia_total && parseFloat(item.modulos_potencia_total) >= 0
                                                                ? parseFloat(item.modulos_potencia_total).toFixed(1).replace(".", ",") + " kW"
                                                                : "-"}
                                                        </p>
                                                        <p style={{ fontSize: "0.7rem", margin: 0 }}>Detalhar</p>
                                                    </div>
                                                }
                                                type={"info"}
                                                event={() => {
                                                    this.props.getModulos(item.id_unidade_consumidora);
                                                    this.props.setModoTela("modulos", item);
                                                }}
                                            />
                                        </Td>
                                        <Td alignCenter>
                                            <Button
                                                title={"Visualizar dispositivos"}
                                                style={{
                                                    width: 80,
                                                    height: 80,
                                                    padding: 0,
                                                }}
                                                text={
                                                    <div>
                                                        <p style={{ marginTop: 5, marginBottom: 8 }}>
                                                            <i
                                                                style={{
                                                                    fontSize: "1.1rem",
                                                                    verticalAlign: "middle",
                                                                }}
                                                                className="fas fa-charging-station"
                                                            />
                                                        </p>
                                                        <p style={{ marginBottom: 0 }}>
                                                            {item.inversor_potencia_total && parseFloat(item.inversor_potencia_total) >= 0
                                                                ? parseFloat(item.inversor_potencia_total).toFixed(1).replace(".", ",") + " kW"
                                                                : "-"}
                                                        </p>
                                                        <p style={{ fontSize: "0.7rem", margin: 0 }}>Detalhar</p>
                                                    </div>
                                                }
                                                type={"info"}
                                                event={() => {
                                                    this.props.setModoTela("dispositivos", item);
                                                }}
                                            />
                                        </Td>

                                        {this.props.filtro.id_agrupador ? (
                                            <Td alignCenter minWidth={80}>
                                                <span style={{ fontSize: "0.9rem" }}>{parseFloat(item.energia_atual).toFixed(1).replace(".", ",")} kWh</span>
                                            </Td>
                                        ) : (
                                            <>
                                                <Td alignCenter backgroundColor={this.filtroDesempenho("energia_dia") ? "#b7c2c3" : null}>
                                                    <Button
                                                        style={{ width: 80, height: 80, padding: 0, margin: 2 }}
                                                        title={`Ver o gráfico do dia\n${
                                                            parseFloat(item.energia_dia).toFixed(1).replace(".", ",") +
                                                            " kWh de " +
                                                            parseFloat(item.prod_max_esperada_dia_atual).toFixed(1).replace(".", ",")
                                                        } kWh (${item.desempenho_energia_dia.toFixed(1).replace(".", ",")}%)`}
                                                        text={
                                                            <SemiCircleProgressBar width={80} height={80} progress={item.desempenho_energia_dia} classColor={"info"}>
                                                                <div style={{ fontSize: "0.8rem" }}>{adicionarPontosMilhar(item.energia_dia, 1)}</div>
                                                                <div style={{ fontSize: "0.8rem", lineHeight: 0.9 }}>kWh</div>
                                                                <div style={{ fontSize: "0.7rem" }}>{item.desempenho_energia_dia.toFixed(1).replace(".", ",")}%</div>
                                                            </SemiCircleProgressBar>
                                                        }
                                                        type={"info"}
                                                        event={() => {
                                                            this.props.getListaGraficoEnergiaDia({
                                                                id_agrupador: this.props.filtro.agrupar_por_uc ? item.id_unidade_consumidora : item.id_geracao_integracao,
                                                                data: this.props.dataGrafico,
                                                            });
                                                            this.props.setModoTela("graficoEnergiaDia", item);
                                                        }}
                                                    />
                                                </Td>
                                                <Td alignCenter backgroundColor={this.filtroDesempenho("energia_mes") ? "#b7c2c3" : null}>
                                                    <Button
                                                        style={{ width: 80, height: 80, padding: 0, margin: 2 }}
                                                        title={`Ver o gráfico do mês\n${
                                                            parseFloat(item.energia_mes).toFixed(1).replace(".", ",") +
                                                            " kWh de " +
                                                            parseFloat(item.prod_max_esperada_mes_atual).toFixed(1).replace(".", ",")
                                                        } kWh (${item.desempenho_energia_mes.toFixed(1).replace(".", ",")}%)`}
                                                        text={
                                                            <SemiCircleProgressBar width={80} height={80} progress={item.desempenho_energia_mes} classColor={"info"}>
                                                                <div style={{ fontSize: "0.8rem" }}>{adicionarPontosMilhar(item.energia_mes, 1)}</div>
                                                                <div style={{ fontSize: "0.8rem", lineHeight: 0.9 }}>kWh</div>
                                                                <div style={{ fontSize: "0.7rem" }}>{item.desempenho_energia_mes.toFixed(1).replace(".", ",")}%</div>
                                                            </SemiCircleProgressBar>
                                                        }
                                                        type={"info"}
                                                        event={() => {
                                                            this.props.getListaGraficoEnergiaDiasMes({
                                                                id_agrupador: this.props.filtro.agrupar_por_uc ? item.id_unidade_consumidora : item.id_geracao_integracao,
                                                                data: this.props.dataGrafico,
                                                            });
                                                            this.props.setModoTela("graficoEnergiaDiasMes", item);
                                                        }}
                                                    />
                                                </Td>
                                                <Td alignCenter backgroundColor={this.filtroDesempenho("energia_ano") ? "#b7c2c3" : null}>
                                                    <Button
                                                        style={{ width: 80, height: 80, padding: 0, margin: 2 }}
                                                        title={`Ver o gráfico do ano\n${
                                                            parseFloat(item.energia_ano).toFixed(1).replace(".", ",") +
                                                            " kWh de " +
                                                            parseFloat(item.prod_max_esperada_ano_atual).toFixed(1).replace(".", ",")
                                                        } kWh (${item.desempenho_energia_ano.toFixed(1).replace(".", ",")}%)`}
                                                        text={
                                                            <SemiCircleProgressBar width={80} height={80} progress={item.desempenho_energia_ano} classColor={"info"}>
                                                                <div style={{ fontSize: "0.8rem" }}>
                                                                    {adicionarPontosMilhar(parseFloat(item.energia_ano) ? parseFloat(item.energia_ano) / 1000 : 0, 1)}
                                                                </div>
                                                                <div style={{ fontSize: "0.8rem", lineHeight: 0.9 }}>MWh</div>
                                                                <div style={{ fontSize: "0.7rem" }}>{item.desempenho_energia_ano.toFixed(1).replace(".", ",")}%</div>
                                                            </SemiCircleProgressBar>
                                                        }
                                                        type={"info"}
                                                        event={() => {
                                                            this.props.getListaGraficoEnergiaMesesAno({
                                                                id_agrupador: this.props.filtro.agrupar_por_uc ? item.id_unidade_consumidora : item.id_geracao_integracao,
                                                                data: this.props.dataGrafico,
                                                            });
                                                            this.props.setModoTela("graficoEnergiaMesesAno", item);
                                                        }}
                                                    />
                                                </Td>
                                                {this.props.filtro.id_agrupador ? null : (
                                                    <>
                                                        <Td alignCenter>
                                                            <DivBox borderColor="#dedede">
                                                                {item.nome_icone ? (
                                                                    <img alt="" src={weatherIcons[item.nome_icone]} width={40} height={40}></img>
                                                                ) : (
                                                                    <i
                                                                        className="fas fa-question"
                                                                        style={{
                                                                            color: "#17a2b8",
                                                                            fontSize: "1.5rem",
                                                                        }}
                                                                    ></i>
                                                                )}
                                                            </DivBox>
                                                        </Td>
                                                        <Td alignCenter>
                                                            <DivBox borderColor="#dedede">
                                                                <i className="fas fa-cloud" style={{ fontSize: "1.1rem", padding: 10 }}></i>
                                                                {parseInt(item.nebulosidade) >= 0 ? parseInt(item.nebulosidade) + " %" : "-"}
                                                            </DivBox>
                                                        </Td>
                                                        <Td alignCenter>
                                                            <DivBox borderColor="#dedede">
                                                                <i className="fas fa-cloud-showers-heavy" style={{ fontSize: "1.1rem", padding: 10 }}></i>
                                                                {parseInt(item.quantidade_precipitacao) >= 0 ? parseInt(item.quantidade_precipitacao) + " mm" : "-"}
                                                            </DivBox>
                                                        </Td>
                                                        <Td alignCenter>
                                                            <DivBox borderColor="#dedede">
                                                                <i className="fas fa-wind" style={{ fontSize: "1.1rem", padding: 10 }}></i>
                                                                {parseInt(item.velocidade_vento) >= 0 ? parseInt(item.velocidade_vento * 3.6) + " km/h" : "-"}
                                                            </DivBox>
                                                        </Td>
                                                        <Td alignCenter>
                                                            <DivBox borderColor="#dedede">
                                                                <i className="fas fa-tint" style={{ fontSize: "1.1rem", padding: 10 }}></i>
                                                                {parseInt(item.umidade_relativa_ar) >= 0 ? parseInt(item.umidade_relativa_ar) + " %" : "-"}
                                                            </DivBox>
                                                        </Td>
                                                        <Td alignCenter>
                                                            <DivBox borderColor="#dedede">
                                                                <i className="fas fa-temperature-low" style={{ fontSize: "1.1rem", padding: 10 }}></i>
                                                                {item.temperatura_ar != null ? parseInt(item.temperatura_ar) + " °C" : "-"}
                                                            </DivBox>
                                                        </Td>
                                                    </>
                                                )}
                                                <Td alignCenter>
                                                    {item.amarelo_nos_ultimos_2_dias ? (
                                                        <p
                                                            style={{
                                                                width: 200,
                                                                backgroundColor: "#ffc107",
                                                                borderRadius: "0.3rem",
                                                                padding: "0.2rem 0.4rem",
                                                                lineHeight: "1rem",
                                                                fontSize: "0.8rem",
                                                                marginBottom: 2,
                                                                cursor: "default",
                                                            }}
                                                        >
                                                            Há mais de 2 dias com parte dos inversores sem produção!
                                                        </p>
                                                    ) : item.energia_zerada_ultimos_2_dias ? (
                                                        <p
                                                            style={{
                                                                width: 200,
                                                                backgroundColor: "#ffc107",
                                                                borderRadius: "0.3rem",
                                                                padding: "0.2rem 1rem",
                                                                lineHeight: "1rem",
                                                                fontSize: "0.8rem",
                                                                marginBottom: 2,
                                                                cursor: "default",
                                                            }}
                                                        >
                                                            Há mais de 2 dias sem produção!
                                                        </p>
                                                    ) : null}
                                                    <Button
                                                        style={{
                                                            height: item.energia_zerada_ultimos_2_dias || item.amarelo_nos_ultimos_2_dias ? "" : 80,
                                                            width: 200,
                                                            marginLeft: 0,
                                                        }}
                                                        text={<span style={{ fontSize: "0.9rem" }}>Detalhes</span>}
                                                        type={"info"}
                                                        event={() => {
                                                            this.props.setModoTela("lista", {});
                                                            this.props.setFiltro({
                                                                ...this.props.filtro,
                                                                id_agrupador: this.props.filtro.agrupar_por_uc ? item.id_unidade_consumidora : item.id_geracao_integracao,
                                                            });
                                                            this.buscarDadosTela();
                                                        }}
                                                    />
                                                </Td>
                                            </>
                                        )}
                                    </NewTr>
                                );
                            })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }

    buscarDadosTela() {
        this.setState({
            ...this.state,
            ultima_atualizacao_lista: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
        });
        this.props.setDataGrafico(DateFormat.getDataAtual().replace(/\//g, "-"));
        this.props.getLista();
    }

    atualizarListasGraficos(data) {
        const filtro = {
            id_agrupador: this.props.filtro.agrupar_por_uc ? this.props.registro.id_unidade_consumidora : this.props.registro.id_geracao_integracao,
            data,
        };
        if (this.props.modoTela === "graficoEnergiaDia") {
            this.props.getListaGraficoEnergiaDia(filtro);
        }
        if (this.props.modoTela === "graficoEnergiaDiasMes") {
            this.props.getListaGraficoEnergiaDiasMes(filtro);
        }
        if (this.props.modoTela === "graficoEnergiaMesesAno") {
            this.props.getListaGraficoEnergiaMesesAno(filtro);
        }
    }

    voltarAvancarDataGrafico({ dataGrafico, tempo, modoTela }) {
        const novaData = new Date(dataGrafico);

        switch (modoTela) {
            case "graficoEnergiaDia":
                novaData.setUTCDate(novaData.getUTCDate() + tempo);
                break;
            case "graficoEnergiaDiasMes":
                novaData.setUTCMonth(novaData.getUTCMonth() + tempo);
                break;
            case "graficoEnergiaMesesAno":
                novaData.setUTCFullYear(novaData.getUTCFullYear() + tempo);
                break;
            default:
                break;
        }

        this.props.setDataGrafico(novaData.toISOString().substring(0, 10));
        this.atualizarListasGraficos(novaData.toISOString().substring(0, 10));
    }

    setChart(chart) {
        this.chart = chart;
    }
    exibirGrafico(nomeGrafico) {
        if (this.chart) {
            this.chart.dispose();
        }

        const graficoAtivo = {
            graficoEnergiaDia: null,
            graficoEnergiaDiasMes: null,
            graficoEnergiaMesesAno: null,
        };

        graficoAtivo[nomeGrafico] = true;

        if (this.state.graficoEnergiaDia) {
            montarGraficoLineEnergiaDia({
                id: "graficoEnergiaDia",
                lista: this.props.listaGraficoEnergiaDia,
                setChart: this.setChart,
                subtitulo: this.props.registro.nome_unidade_consumidora,
            });
        }
        if (this.state.graficoEnergiaDiasMes) {
            montarGraficoColunasEnergiaDiasMes({
                id: "graficoEnergiaDiasMes",
                lista: this.props.listaGraficoEnergiaDiasMes,
                setChart: this.setChart,
                subtitulo: this.props.registro.nome_unidade_consumidora,
            });
        }
        if (this.state.graficoEnergiaMesesAno) {
            montarGraficoColunasEnergiaMesesAno({
                id: "graficoEnergiaMesesAno",
                lista: this.props.listaGraficoEnergiaMesesAno,
                setChart: this.setChart,
                subtitulo: this.props.registro.nome_unidade_consumidora,
            });
        }

        return (
            <ContentCard style={{ backgroundColor: "#f0f1f5", paddingLeft: 0 }}>
                <ContentCardBody padding="0px">
                    <Row
                        style={{
                            // overflow: "scroll",
                            height: "fit-content",
                            paddingLeft: 15,
                            paddingRight: 7,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "no-wrap",
                                // minWidth: "1150px",
                                width: "100%",
                                paddingTop: 15,
                                marginBottom: -1,
                                boxShadow: "inset 0 0 2px #ddd",
                                backgroundColor: "##fff",
                            }}
                        >
                            <Grid
                                cols="3 3 3 3"
                                style={{
                                    padding: 0,
                                    backgroundColor: "#fff",
                                    margin: "0px 5px 5px 0px",
                                    borderRadius: "0.3rem",
                                }}
                            >
                                <Button
                                    style={{ margin: 0 }}
                                    text="Voltar"
                                    type={"warning"}
                                    icon={"fa fa-chevron-left"}
                                    event={() => {
                                        this.props.setModoTela("lista", {});
                                        this.setState({
                                            ...this.state,
                                            [nomeGrafico]: null,
                                        });
                                        this.props.setDataGrafico(DateFormat.getDataAtual().replace(/\//g, "-"));
                                        // this.buscarDadosTela();
                                    }}
                                />
                            </Grid>
                            <Grid
                                cols="3 3 3 3"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: this.props.modoTela === "graficoEnergiaDia" ? "#fff" : "#f5f5f5",
                                    cursor: "pointer",
                                    boxShadow: this.props.modoTela === "graficoEnergiaDia" ? "inset 0 2px 2px 1px #e7e7e7" : "#e9e4e4 0px 2px 5px 1px inset",
                                    marginTop: this.props.modoTela === "graficoEnergiaDia" ? -4 : null,
                                    borderRadius: "15px 15px 0 0",
                                    borderBottom: `1px solid ${this.props.modoTela === "graficoEnergiaDia" ? "#fff" : "#ddd"} `,
                                }}
                                title="Ver o gráfico do dia"
                                onClick={() => {
                                    this.props.getListaGraficoEnergiaDia({
                                        id_agrupador: this.props.filtro.agrupar_por_uc ? this.props.registro.id_unidade_consumidora : this.props.registro.id_geracao_integracao,
                                        data: this.props.dataGrafico,
                                    });
                                    this.props.setModoTela("graficoEnergiaDia", this.props.registro);
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    Dia
                                </span>
                            </Grid>

                            <Grid
                                cols="3 3 3 3"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: this.props.modoTela === "graficoEnergiaDiasMes" ? "#fff" : "#f5f5f5",
                                    cursor: "pointer",
                                    boxShadow: this.props.modoTela === "graficoEnergiaDiasMes" ? "inset 0 2px 2px 1px #e7e7e7" : "#e9e4e4 0px 2px 5px 1px inset",
                                    marginTop: this.props.modoTela === "graficoEnergiaDiasMes" ? -4 : null,
                                    borderRadius: "15px 15px 0 0",
                                    borderBottom: `1px solid ${this.props.modoTela === "graficoEnergiaDiasMes" ? "#fff" : "#ddd"} `,
                                }}
                                title="Ver o gráfico do mês"
                                onClick={() => {
                                    this.props.getListaGraficoEnergiaDiasMes({
                                        id_agrupador: this.props.filtro.agrupar_por_uc ? this.props.registro.id_unidade_consumidora : this.props.registro.id_geracao_integracao,
                                        data: this.props.dataGrafico,
                                    });
                                    this.props.setModoTela("graficoEnergiaDiasMes", this.props.registro);
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    Mês
                                </span>
                            </Grid>

                            <Grid
                                cols="3 3 3 3"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: this.props.modoTela === "graficoEnergiaMesesAno" ? "#fff" : "#f5f5f5",
                                    cursor: "pointer",
                                    boxShadow: this.props.modoTela === "graficoEnergiaMesesAno" ? "inset 0 2px 2px 1px #e7e7e7" : "#e9e4e4 0px 2px 5px 1px inset",
                                    marginTop: this.props.modoTela === "graficoEnergiaMesesAno" ? -4 : null,
                                    borderRadius: "15px 15px 0 0",
                                    borderBottom: `1px solid ${this.props.modoTela === "graficoEnergiaMesesAno" ? "#fff" : "#ddd"} `,
                                }}
                                title="Ver o gráfico do ano"
                                onClick={() => {
                                    this.props.getListaGraficoEnergiaMesesAno({
                                        id_agrupador: this.props.filtro.agrupar_por_uc ? this.props.registro.id_unidade_consumidora : this.props.registro.id_geracao_integracao,
                                        data: this.props.dataGrafico,
                                    });
                                    this.props.setModoTela("graficoEnergiaMesesAno", this.props.registro);
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "0.9rem",
                                    }}
                                >
                                    Ano
                                </span>
                            </Grid>
                        </div>
                        <Row
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                backgroundColor: "#fff",
                                borderTop: "1px solid #ddd",
                                paddingTop: 25,
                                alignItems: "center",
                                justifyContent: window.innerWidth < 1340 ? "center" : "start",
                            }}
                        >
                            {this.props.modoTela === "graficoEnergiaDiasMes" ? (
                                <Grid
                                    cols="12 6 4 4"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        minWidth: "fit-content",
                                        paddingTop: 28,
                                    }}
                                >
                                    <IndicadorParcialRendimentoMensal listaDiasMes={this.props.listaGraficoEnergiaDiasMes} dataGrafico={this.props.dataGrafico} />
                                </Grid>
                            ) : null}
                            <Grid
                                cols={this.props.modoTela === "graficoEnergiaDiasMes" ? "12 6 4 4" : "12 12 12 12"}
                                style={{
                                    display: "flex",
                                    minWidth: "fit-content",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <button
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "1.5rem",
                                        fontWeight: 500,
                                        boxShadow: this.desabilitarBotaoVoltarData() ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                        color: "#0070cc",
                                        width: 40,
                                        height: 40,
                                        borderRadius: "100%",
                                        backgroundColor: "#fff",
                                        border: "none",
                                        marginInline: 20,
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.outline = "none";
                                    }}
                                    onClick={() => {
                                        const dataGrafico = this.props.dataGrafico;
                                        const modoTela = this.props.modoTela;
                                        const tempo = -1;
                                        this.voltarAvancarDataGrafico({
                                            dataGrafico,
                                            tempo,
                                            modoTela,
                                        });
                                    }}
                                    disabled={this.desabilitarBotaoVoltarData()}
                                >
                                    {"<"}
                                </button>
                                {this.state.graficoEnergiaDia && (
                                    <input
                                        style={{
                                            fontSize: "1.2rem",
                                            padding: 7,
                                            border: "1px solid #999",
                                            borderRadius: 10,
                                        }}
                                        required
                                        type="date"
                                        value={this.props.dataGrafico}
                                        max={DateFormat.getDataAtual().replace(/\//g, "-")}
                                        onFocus={(e) => (e.target.style.outline = "none")}
                                        onChange={(e) => {
                                            const valorData = e.target.value || DateFormat.getDataAtual().replace(/\//g, "-");

                                            this.props.setDataGrafico(valorData);
                                            this.atualizarListasGraficos(valorData);
                                        }}
                                    ></input>
                                )}
                                {this.state.graficoEnergiaDiasMes && (
                                    <select
                                        style={{
                                            fontSize: "1.2rem",
                                            padding: 7,
                                            border: "1px solid #999",
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                        }}
                                        value={this.props.dataGrafico.substring(5, 7)}
                                        onFocus={(e) => (e.target.style.outline = "none")}
                                        onChange={(e) => {
                                            const dataAtual = this.props.dataGrafico;
                                            const novaData = dataAtual.substring(0, 5) + e.target.value + "-01";

                                            const dataMaiorQueAtual = new Date(novaData).getTime() > new Date().getTime();
                                            if (!dataMaiorQueAtual) {
                                                this.props.setDataGrafico(novaData);
                                                this.atualizarListasGraficos(novaData);
                                            }
                                        }}
                                    >
                                        <option value="01">Janeiro</option>
                                        <option value="02">Fevereiro</option>
                                        <option value="03">Março</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Maio</option>
                                        <option value="06">Junho</option>
                                        <option value="07">Julho</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Setembro</option>
                                        <option value="10">Outubro</option>
                                        <option value="11">Novembro</option>
                                        <option value="12">Dezembro</option>
                                    </select>
                                )}
                                {(this.state.graficoEnergiaDiasMes || this.state.graficoEnergiaMesesAno) && (
                                    <select
                                        style={{
                                            marginLeft: this.state.graficoEnergiaDiasMes ? 5 : 0,
                                            fontSize: "1.2rem",
                                            padding: 7,
                                            border: "1px solid #999",
                                            backgroundColor: "#fff",
                                            borderRadius: 10,
                                        }}
                                        value={this.props.dataGrafico.substring(0, 4)}
                                        onFocus={(e) => (e.target.style.outline = "none")}
                                        onChange={(e) => {
                                            const novaData = e.target.value + "-01-01";
                                            this.props.setDataGrafico(novaData);
                                            this.atualizarListasGraficos(novaData);
                                        }}
                                    >
                                        {(() => {
                                            let options = [];
                                            let anoInicioLeituras = 2023;
                                            for (let i = anoInicioLeituras; i <= new Date().getFullYear(); i++) {
                                                options.push(
                                                    <option key={i} value={String(i)}>
                                                        {i}
                                                    </option>
                                                );
                                            }
                                            return options;
                                        })()}
                                    </select>
                                )}
                                <button
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "1.5rem",
                                        fontWeight: 500,
                                        boxShadow: this.desabilitarBotaoAvancarData() ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                        color: "#0070cc",
                                        width: 40,
                                        height: 40,
                                        borderRadius: "100%",
                                        backgroundColor: "#fff",
                                        border: "none",
                                        marginInline: 20,
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.outline = "none";
                                    }}
                                    onClick={() => {
                                        const dataGrafico = this.props.dataGrafico;
                                        const modoTela = this.props.modoTela;
                                        const tempo = 1;
                                        this.voltarAvancarDataGrafico({
                                            dataGrafico,
                                            tempo,
                                            modoTela,
                                        });
                                    }}
                                    disabled={this.desabilitarBotaoAvancarData()}
                                >
                                    {">"}
                                </button>
                            </Grid>
                        </Row>

                        <div
                            id={`${nomeGrafico}`}
                            style={{
                                width: "100%",
                                paddingTop: 15,
                                backgroundColor: "#fff",
                                height: "500px",
                            }}
                        ></div>

                        {this.state[nomeGrafico] ? null : this.setState({ ...this.state, ...graficoAtivo })}
                    </Row>
                </ContentCardBody>
            </ContentCard>
        );
    }

    desabilitarBotaoVoltarData() {
        if (this.props.modoTela === "graficoEnergiaMesesAno" && this.props.dataGrafico.substring(0, 4) === "2023") return true;
        return false;
    }

    desabilitarBotaoAvancarData() {
        if (this.props.modoTela === "graficoEnergiaDia" && this.props.dataGrafico === new Date().toISOString().substring(0, 10)) return true;
        if (this.props.modoTela === "graficoEnergiaDiasMes" && this.props.dataGrafico.substring(0, 7) === new Date().toISOString().substring(0, 7)) return true;
        if (this.props.modoTela === "graficoEnergiaMesesAno" && this.props.dataGrafico.substring(0, 4) === new Date().toISOString().substring(0, 4)) return true;
        return false;
    }

    getStyleSlimCard(bgColor, textColor) {
        return {
            borderRadius: 8,
            cursor: "pointer",
            backgroundColor: bgColor,
            color: textColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 10px 0px 10px",
            width: "100%",
            minHeight: "40px",
            margin: 5,
            boxShadow: "#9b9b9b 0px 1px 2px 1px",
        };
    }
}

const mapStateToProps = (state) => {
    return {
        sessao: state.auth.usuario,
        usuarioLogado: state.auth.usuarioLogado,
        modoTela: state.geracaoLeitura.modoTela,
        aguardando: state.geracaoLeitura.aguardando,
        dataGrafico: state.geracaoLeitura.dataGrafico,
        filtro: state.geracaoLeitura.filtro,
        registro: state.geracaoLeitura.registro,
        lista: state.geracaoLeitura.lista,
        modulos: state.geracaoLeitura.modulos,
        listaCliente: state.geracaoLeitura.listaCliente,
        listaUnidadeConsumidora: state.geracaoLeitura.listaUnidadeConsumidora,
        listaGeracaoIntegracaoTipo: state.geracaoIntegracao.listaGeracaoIntegracaoTipo,
        listaGraficoTodasIntegracoesAgrupadasPorHora: state.geracaoLeitura.listaGraficoTodasIntegracoesAgrupadasPorHora,
        listaGraficoEnergiaDia: state.geracaoLeitura.listaGraficoEnergiaDia,
        listaGraficoEnergiaDiasMes: state.geracaoLeitura.listaGraficoEnergiaDiasMes,
        listaGraficoEnergiaMesesAno: state.geracaoLeitura.listaGraficoEnergiaMesesAno,
        STATUS_COR_AMARELA: state.geracaoLeitura.AMARELA,
        STATUS_COR_VERMELHA: state.geracaoLeitura.VERMELHA,
        STATUS_COR_VERDE: state.geracaoLeitura.VERDE,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setEmitirAlertaSonoro,
            setDataGrafico,
            setModoTela,
            setFiltro,
            getLista,
            getModulos,
            getListaCliente,
            getListaUnidadeConsumidora,
            getListaGraficoTodasIntegracoesAgrupadasPorHora,
            getListaGraficoEnergiaDia,
            getListaGraficoEnergiaDiasMes,
            getListaGraficoEnergiaMesesAno,
            setStatusVisualizado,
            setAguardando,
            getListaGeracaoIntegracaoTipo,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(GeracaoLeitura);
