import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";

import { setModoTela } from "./usuarioActions";
import { getLista as getListaCliente } from "../cliente/clienteActions";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import TBody from "../common/table/tBody";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import Td from "../common/table/td";

class UsuarioForm extends Component {
  componentWillMount() {
    this.props.getListaCliente();
  }

  render() {
    let readOnly = this.props.excluir ? "readOnly" : "";

    return (
      <ContentCard>
        <Form event={this.props.handleSubmit}>
          <ContentCardBody>
            <Field
              name="nome"
              component={LabelAndInput}
              label="Nome *"
              placeholder="Informe o Nome"
              cols="12 12 12 12"
              readOnly={readOnly}
            />

            <Field
              name="login"
              component={LabelAndInput}
              label="Login *"
              placeholder="Informe o Usuário"
              cols="12 12 12 12"
              readOnly={readOnly}
            />

            <Field
              name="senha"
              component={LabelAndInput}
              type="password"
              label="Senha *"
              placeholder="Informe a Senha"
              cols="12 12 12 12"
              readOnly={readOnly}
            />

            <Field
              style={{ marginRight: 10 }}
              name="usuario_implantacao"
              component={LabelAndCheckbox}
              label="Usuário Implantação *"
              cols="12 12 12 12"
              readOnly={readOnly}
            />
          </ContentCardBody>
          <ContentCardFooter style={{ background: "transparent" }}>
            <Row alignCenter>
              <Grid cols="6 6 4 3">
                {this.props.excluir ? (
                  <Button
                    text="Excluir"
                    submit
                    type={"danger"}
                    icon={"fa fa-trash"}
                  />
                ) : (
                  <Button
                    text="Salvar"
                    submit
                    type={"success"}
                    icon={"fa fa-check"}
                  />
                )}
              </Grid>
              <Grid cols="6 6 4 3">
                <Button
                  text="Voltar"
                  type={"warning"}
                  icon={"fa fa-chevron-left"}
                  event={() => this.props.setModoTela("lista")}
                />
              </Grid>
            </Row>
          </ContentCardFooter>
        </Form>
        {this.props.registro.usuario_implantacao && (
          <>
            <b style={{ margin: 15 }}>
              Clientes e Unidades do Usuário de Implantação
            </b>
            <Table>
              <THead>
                <Tr>
                  <Th style={{ paddingInline: 20 }}>Cliente</Th>
                  <Th style={{ paddingInline: 20 }}>Unidade Consumidora</Th>
                </Tr>
              </THead>
              {this.props.listaClientes.map(
                (cliente) =>
                  !!cliente.itens.length && (
                    <TBody>
                      <Tr>
                        <Td
                          paddingInline={15}
                          rowspan={cliente.itens.length + 1}
                        >
                          {cliente.nome}
                        </Td>
                      </Tr>
                      {cliente.itens.map((uc, i) =>
                        i === 0 ? (
                          <Tr>
                            <Td padding={15}>{uc.nome}</Td>
                          </Tr>
                        ) : (
                          <Tr>
                            <Td
                              padding={15}
                              style={{ borderTop: "1px #ddd solid" }}
                            >
                              {uc.nome}
                            </Td>
                          </Tr>
                        )
                      )}
                    </TBody>
                  )
              )}
            </Table>
          </>
        )}
      </ContentCard>
    );
  }
}

UsuarioForm = reduxForm({ form: "usuarioForm", destroyOnUnmount: false })(
  UsuarioForm
);
const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  registro: state.usuario.registro,
  listaClientes: state.usuario.registro.id
    ? filtrarClientesUsuarioImplantacao(
        state.usuario.registro,
        state.cliente.lista
      )
    : [],
});
function filtrarClientesUsuarioImplantacao(usuario, listaClientes) {
  const clientesFiltrados = listaClientes.filter((cliente) =>
    cliente.itens.find((uc) => uc.id_usuario_implantacao === usuario.id)
  );
  if (!clientesFiltrados.length) return [];
  const ucFiltradas = clientesFiltrados.map((cliente) => {
    const unidadesConsumidoras = cliente.itens.filter(
      (uc) => uc.id_usuario_implantacao === usuario.id
    );
    return { ...cliente, itens: unidadesConsumidoras };
  });
  return ucFiltradas;
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setModoTela, getListaCliente }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
