import React from "react";
import triangleImage from "../../assets/images/triangle.png";

export default function AlertIcon({ event, title, animationClass, width = 30, height = 30 }) {
    return (
        <div className={animationClass}>
            <img src={triangleImage} width={width} height={height} title={title} alt={title} onClick={event} />
        </div>
    );
}
