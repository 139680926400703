import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Select from "../common/form/select";
import Grid from "../common/layout/grid";
import Row from "../common/layout/row";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";
import DateFormat from "../common/dateFormat/DateFormat";

function GraficoMonitoramentoConsumo(props) {
    const [grafico, setGrafico] = useState(null);
    const [lista, setLista] = useState([
        { value: 10, datetime: new Date("2024-10-22T10:30:00") },
        { value: 12, datetime: new Date("2024-10-22T11:30:00") },
        { value: 20, datetime: new Date("2024-10-22T12:30:00") },
        { value: 22, datetime: new Date("2024-10-22T13:30:00") },
        { value: 11, datetime: new Date("2024-10-22T14:30:00") },
    ]);
    const [intervaloTempo, setIntervaloTempo] = useState(15);
    const [dataGrafico, setDataGrafico] = useState(DateFormat.getDataAtual().replace(/\//g, "-"));

    const corGrafico = "#1e3e49";

    useEffect(() => {
        montarGrafico();
        return () => desmontarGrafico();
    }, []);

    return (
        <Grid cols="12 12 12 6" style={{ backgroundColor: corGrafico, borderRadius: 8, marginTop: 10, height: "fit-content" }}>
            <Row>
                <Grid
                    cols="8 8 8 8"
                    style={{
                        display: "flex",
                        minWidth: "fit-content",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingTop: 10,
                    }}
                >
                    <button
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.2rem",
                            fontWeight: 500,
                            boxShadow: desabilitarBotaoVoltarData() ? "inset 0 0 16px 1px #1a2f2a" : "0 0 10px 1px #1a2f2a",
                            color: "#fff",
                            width: 40,
                            height: 40,
                            borderRadius: "100%",
                            backgroundColor: corGrafico,
                            border: "none",
                            marginInline: 20,
                        }}
                        onFocus={(e) => {
                            e.target.style.outline = "none";
                        }}
                        onClick={() => voltarAvancarDataGrafico(-1)}
                        disabled={desabilitarBotaoVoltarData()}
                    >
                        {"<"}
                    </button>
                    <input
                        style={{
                            fontSize: "0.9rem",
                            padding: 7,
                            // border: "1px solid #999",
                            border: "1px solid #1a2f2a",
                            backgroundColor: corGrafico,
                            color: "#fff",
                            borderRadius: 10,
                        }}
                        required
                        type="date"
                        value={dataGrafico}
                        max={DateFormat.getDataAtual().replace(/\//g, "-")}
                        onFocus={(e) => (e.target.style.outline = "none")}
                        onChange={(e) => {
                            const valorData = e.target.value || DateFormat.getDataAtual().replace(/\//g, "-");
                            setDataGrafico(valorData);
                            // getListaGraficoTodasIntegracoesAgrupadasPorHora();
                        }}
                    ></input>
                    <button
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "1.2rem",
                            fontWeight: 500,
                            boxShadow: desabilitarBotaoAvancarData() ? "inset 0 0 16px 1px #1a2f2a" : "0 0 10px 1px #1a2f2a",
                            color: "#fff",
                            width: 40,
                            height: 40,
                            borderRadius: "100%",
                            backgroundColor: corGrafico,
                            border: "none",
                            marginInline: 20,
                        }}
                        onFocus={(e) => {
                            e.target.style.outline = "none";
                        }}
                        onClick={() => voltarAvancarDataGrafico(1)}
                        disabled={desabilitarBotaoAvancarData()}
                    >
                        {">"}
                    </button>
                </Grid>
                <Grid
                    cols="4 4 4 4"
                    style={{
                        display: "flex",
                        minWidth: "fit-content",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Select
                        style={{ backgroundColor: corGrafico, color: "#FFF", margin: 0 }}
                        name="intervalo_tempo"
                        options={[
                            { id: 5, valor: "5 minutos" },
                            { id: 10, valor: "10 minutos" },
                            { id: 15, valor: "15 minutos" },
                            { id: 30, valor: "30 minutos" },
                            { id: 60, valor: "60 minutos" },
                        ]}
                        label="Intervalo de Tempo"
                        cols="12 12 12 12"
                        isSearchable={false}
                        isClearable={false}
                        value={intervaloTempo}
                        onChange={(value) => {
                            // setFiltro({
                            //     ...filtro,
                            //     intervalo_tempo: value,
                            // });
                            // this.buscarDadosTela();
                        }}
                    />
                </Grid>
            </Row>
            <div
                id="grafico"
                style={{
                    width: "100%",
                    backgroundColor: corGrafico,
                    height: "238px",
                    borderRadius: "0 0 8px 8px",
                    overflow: "hidden",
                }}
            ></div>
        </Grid>
    );

    function desabilitarBotaoVoltarData() {
        return false;
    }

    function desabilitarBotaoAvancarData() {
        return dataGrafico === new Date().toISOString().substring(0, 10);
    }
    function voltarAvancarDataGrafico(tempo) {
        const novaData = new Date(dataGrafico);

        novaData.setUTCDate(novaData.getUTCDate() + tempo);

        setDataGrafico(novaData.toISOString().substring(0, 10));
    }
    function desmontarGrafico() {
        if (grafico) grafico.dispose();
    }

    function montarGrafico() {
        desmontarGrafico();
        am4core.useTheme(am4themes_animated);

        let chart = am4core.create("grafico", am4charts.XYChart);
        const title = chart.titles.create();
        title.text = "Gráfico Indefinido";
        title.fontWeight = "bold";
        title.fontSize = "0.9rem";
        // title.fill = am4core.color("#000");
        title.fill = am4core.color("#fff");
        title.marginBottom = 10;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.items = [
            {
                label: "...",
                menu: [
                    {
                        type: "png",
                        label: "PNG",
                    },
                ],
            },
        ];
        chart.language.locale = locale;
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";
        chart.background.fill = corGrafico;
        chart.background.opacity = 1;

        // Create axes
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;
        dateAxis.dateFormats.setKey("minute", "HH:mm");
        // dateAxis.periodChangeDateFormats.setKey("minute", "hh:mm");
        dateAxis.baseInterval = {
            timeUnit: "minute",
            count: 5,
        };
        // dateAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo x
        dateAxis.renderer.labels.template.fill = am4core.color("#fff"); //cor do texto eixo x
        dateAxis.renderer.maxLabelPosition = 0.95;
        dateAxis.renderer.minGridDistance = 40;
        dateAxis.renderer.minLabelPosition = 0.05;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (chart.yAxes.indexOf(valueAxis) != 0) {
            valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        }
        valueAxis.min = 0;
        // valueAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
        valueAxis.renderer.labels.template.fill = am4core.color("#fff"); //cor do texto eixo y
        // Create series
        const series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "datetime";
        series.yAxis = valueAxis;
        series.name = "Valor Indefinido";
        series.tooltipText = `Valor às {dateX.formatDate("HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} kW[/]`;
        series.strokeWidth = 2;
        series.tensionX = 1;
        series.stacked = true;
        series.stroke = am4core.color("#017acd");
        series.fill = am4core.color("#017acd");
        series.fillOpacity = 0.2;

        //Adiciona cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;

        //configura a cor do tooltip x
        const dateAxisTooltip = dateAxis.tooltip;
        dateAxisTooltip.background.fill = am4core.color("#0070cc");
        dateAxisTooltip.stroke = am4core.color("#fff");
        dateAxisTooltip.background.strokeWidth = 0;
        dateAxisTooltip.background.cornerRadius = 3;
        dateAxisTooltip.background.pointerLength = 0;
        dateAxisTooltip.dy = 5;

        //configura a cor do tooltip y
        const valueAxisTooltip = valueAxis.tooltip;
        valueAxisTooltip.background.fill = am4core.color("#0070cc");
        valueAxisTooltip.stroke = am4core.color("#fff");
        valueAxisTooltip.background.strokeWidth = 0;
        valueAxisTooltip.background.cornerRadius = 3;
        valueAxisTooltip.background.pointerLength = 0;
        valueAxisTooltip.dy = 5;

        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;

        chart.data = lista;
        setGrafico(chart);
    }
}

const mapStateToProps = (state) => {
    return {
        // usuarioLogado: state.auth.usuarioLogado,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            // adicionar actions
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(GraficoMonitoramentoConsumo);
