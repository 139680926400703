import axios from "axios";
import consts from "../consts";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";
import confirmDialog from "../common/dialog/confirmDialog";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";

export function setModoTela(modo, registro = {}) {
    return {
        type: "REDE_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setAguardando(aguardando) {
    return {
        type: "REDE_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function initForm(registro = {}) {
    return [initialize("redeForm", registro)];
}

export function setFiltro(filtro) {
    return {
        type: "REDE_FILTRO",
        payload: filtro,
    };
}

export function inicializarFormulario({id,modo}) {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            dispatch(setAguardando(true));
            const resp = await axios.get(
                `${consts.API_URL}/rede?id_usuario_logado=${usuarioLogado.id}&id=${id}`
            );

            if(resp.data){
                dispatch(initForm(resp.data))
                dispatch(setModoTela(modo, resp.data))
            }else{
                setError("Não foi possível carregar os dados no formulário")
            }
           
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}
export function getLista() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const idCliente = usuarioLogado.acesso_cliente
                ? usuarioLogado.id_cliente
                : getState().rede.filtro.id_cliente;

            const idUc = getState().rede.filtro.id_unidade_consumidora;

            dispatch(setAguardando(true));
            const resp = await axios.get(
                `${consts.API_URL}/rede?id_usuario_logado=${usuarioLogado.id}&id_cliente=${idCliente}&id_unidade_consumidora=${idUc}`
            );
            dispatch({
                type: "REDE_LISTADO",
                payload: resp,
            });
        } catch (e) {
            setErroAPI(e);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        if (!registro.id) {
            dispatch(setAguardando(true));

            axios
                .post(`${consts.API_URL}/rede`, {
                    ...registro,
                    id_usuario_inclusao: getState().auth.usuario.id,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                    dispatch(
                        initForm({
                            ...registro,
                            id: resp.data.id,
                        })
                    );
                    dispatch(setAguardando(false));
                })
                .catch((e) => {
                    setErroAPI(e);
                    dispatch(setAguardando(false));
                });
        } else {
            confirmDialog("Deseja realmente alterar?", () => {
                dispatch(setAguardando(true));

                axios
                    .put(`${consts.API_URL}/rede`, {
                        ...registro,
                        id_usuario_alteracao: getState().auth.usuario.id,
                    })
                    .then((resp) => {
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getLista());
                        dispatch(setModoTela("lista"));
                        dispatch(setAguardando(false));
                    })
                    .catch((e) => {
                        setErroAPI(e);
                        dispatch(setAguardando(false));
                    });
            });
        }
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        confirmDialog("Deseja realmente excluir?", () => {
            axios
                .delete(`${consts.API_URL}/rede?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function getListaCliente() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const request = axios.get(`${consts.API_URL}/cliente/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "REDE_CLIENTE_SELECT_LISTADO",
                payload: request,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export function getListaUnidadeConsumidora() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const request = axios.get(
                `${consts.API_URL}/unidadeConsumidora/listarSelect?id_usuario_logado=${usuarioLogado.id}`
            );
            dispatch({
                type: "REDE_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
                payload: request,
            });
        } catch (e) {
            setErroAPI(e);
        }
    };
}

export function buscarPdfUnifilarRedeInterna({id, nome}) {
    return async (dispatch, getState) => {
        try {
            const resp = await axios.get(`${consts.API_URL}/rede/buscarPdfUnifilarRedeInterna?id=${id}`);
            if (resp.data && resp.data.arquivo3) {
                let base64 = resp.data.arquivo3;
                const indiceInicioExtensao = base64.indexOf("/") + 1;
                const indiceFimExtensao = base64.indexOf(";");
                const extensao = base64.substring(indiceInicioExtensao, indiceFimExtensao);

                dispatch(setArquivoPDF(resp.data.arquivo3, `${nome}.${extensao}`, "landscape"));
            }
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export function buscarPdfProjetoRedeInterna({id, nome}) {
    return async (dispatch, getState) => {
        try {
            const resp = await axios.get(`${consts.API_URL}/rede/buscarPdfProjetoRedeInterna?id=${id}`);
            if (resp.data && resp.data.arquivo1) {
                let base64 = resp.data.arquivo1;
                const indiceInicioExtensao = base64.indexOf("/") + 1;
                const indiceFimExtensao = base64.indexOf(";");
                const extensao = base64.substring(indiceInicioExtensao, indiceFimExtensao);

                dispatch(setArquivoPDF(resp.data.arquivo1, `${nome}.${extensao}`, "landscape"));
            }
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export function buscarProjetoRedeInternaGoogleEarth({ id, nome }) {
    return async (dispatch, getState) => {
        try {
            const resp = await axios.get(`${consts.API_URL}/rede/buscarProjetoRedeInternaGoogleEarth?id=${id}`);
            if (resp.data && resp.data.arquivo2) {
                const base64 = resp.data.arquivo2;

                // Extraindo a extensão do arquivo
                const extensao = base64.substring(base64.indexOf("/") + 1, base64.indexOf(";"));

                // Convertendo Base64 para Blob
                const blob = base64ToBlob(base64, `application/${extensao}`);

                // Criando URL do Blob
                const url = URL.createObjectURL(blob);

                // Criando nome do arquivo
                const fileName = `${nome}.${extensao.replace("+xml", "")}`;

                // Criando link de download
                const downloadLink = document.createElement("a");
                downloadLink.href = url;
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

                // Revogando URL para evitar vazamento de memória
                URL.revokeObjectURL(url);
            }
        } catch (ex) {
            console.error("Erro ao buscar projeto:", ex);
        }
    };
}

// Função para converter Base64 para Blob
function base64ToBlob(base64, contentType) {
    const base64Data = base64.split(",")[1]; // Removendo o prefixo "data:mime/type;base64,"
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays, { type: contentType });
}

