import React, { Component, useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Select from "../common/form/select";
import Td from "../common/table/td";
import Button from "../common/button/button";
import Form from "./usuarioForm";
import { Loading } from "../common/loading/loading";

import {
    setModoTela,
    initForm,
    salvar,
    excluir,
    getLista,
    setPermissao,
    setPermissaoUnidadeConsumidora,
    getListaCliente,
} from "./usuarioActions";

import { buscarSessao } from "../auth/authActions";

class Usuario extends Component {
    state = { id_cliente: null };

    componentWillUnmount() {
        this.props.buscarSessao();
    }

    componentWillMount() {
        this.props.getListaCliente();
        this.props.getLista();
    }

    render() {
        return (
            <div style={{ paddingBottom: "calc(100vh - 100%)" }}>
                <Content>
                    {this.props.modoTela === "cadastro" && <Form onSubmit={this.props.salvar} />}
                    {this.props.modoTela === "exclusao" && <Form excluir onSubmit={this.props.excluir} />}
                    {this.props.modoTela === "lista" && this.lista()}
                </Content>
            </div>
        );
    }

    lista() {
        return (
            <ContentCard>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="6 6 4 2">
                            <Button
                                text="Adicionar"
                                type={"success"}
                                icon={"fa fa-plus"}
                                disabled={!this.props.usuarioLogado.acesso_usuario_adicionar}
                                event={() => {
                                    this.props.setModoTela("cadastro", {});
                                    this.props.initForm({
                                        administrador: !!this.props.usuarioLogado.administrador,
                                        acesso_cliente: !!this.props.usuarioLogado.acesso_cliente,
                                        id_cliente: this.props.usuarioLogado.id_cliente,
                                    });
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody>
                    {this.props.aguardando ? <Loading /> : null}
                    {this.props.usuarioLogado && this.props.usuarioLogado.administrador ? (
                        <Row>
                            <Select
                                name="id_cliente"
                                options={this.props.listaCliente}
                                label="Cliente"
                                cols="12 6 4"
                                placeholder="Selecione o Cliente"
                                value={this.state.id_cliente}
                                onChange={(value) => {
                                    this.setState({
                                        ...this.state,
                                        id_cliente: value,
                                    });
                                }}
                            />
                        </Row>
                    ) : null}

                    <Table responsive>
                        <THead>
                            <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#F5F5F5"}>
                                <Th sticky>Nome</Th>
                                <Th sticky>Login</Th>
                                {this.props.usuarioLogado && this.props.usuarioLogado.administrador ? (
                                    <Th sticky>Senha</Th>
                                ) : null}
                                {this.props.usuarioLogado && this.props.usuarioLogado.administrador ? (
                                    <Th sticky>Cliente</Th>
                                ) : null}
                                {this.props.usuarioLogado &&
                                (this.props.usuarioLogado.acesso_permissao_uc_visualizar ||
                                    this.props.usuarioLogado.acesso_permissao_uc_editar) ? (
                                    <Th
                                        sticky
                                        title="Seleciona quais UCs cada usuário terá acesso"
                                        style={{ cursor: "help" }}
                                    >
                                        UCs Permitidas
                                    </Th>
                                ) : null}
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o cadastro de usuários"
                                    style={{ cursor: "help" }}
                                >
                                    Usuários
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza visualizar a lista de usuários logados ao clicar nos cards do dashboard"
                                    style={{ cursor: "help" }}
                                >
                                    Usuários Logados
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o cadastro de clientes"
                                    style={{ cursor: "help" }}
                                >
                                    Cliente
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza vizualizar/editar quais UCs serão permitidas para cada usuário"
                                    style={{ cursor: "help" }}
                                >
                                    Permissão UC
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o cadastro de concessionárias"
                                    style={{ cursor: "help" }}
                                >
                                    Concessionária
                                </Th>
                                <Th
                                    sticky
                                    maxWidth={110}
                                    alignCenter
                                    title="Autoriza alterar as credenciais de acesso à concessionária e mercado livre"
                                    style={{ cursor: "help" }}
                                >
                                    Credenciais Acesso Faturas (Concessinária/Mercado Livre)
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o cadastro de empenhos"
                                    style={{ cursor: "help" }}
                                >
                                    Empenho
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o módulo de gestão de tarefas, no menu ADM (Eficience)"
                                    style={{ cursor: "help" }}
                                >
                                    Gestão Tarefas
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza incluir/modificar o andamento de registros do módulo de gestão de tarefas, no menu ADM (Eficience)"
                                    style={{ cursor: "help" }}
                                >
                                    Gestão Tarefas Andamento
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o cadastro de contratos de demandas"
                                    style={{ cursor: "help" }}
                                >
                                    Demanda
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza incluir/alterar o período de teste, dentro do formulário de cadastro de contratos de demanda"
                                    style={{ cursor: "help" }}
                                >
                                    Período Teste Demanda
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o cadastro de micro e mini gerador"
                                    style={{ cursor: "help" }}
                                >
                                    Geração
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o cadastro de tributação"
                                    style={{ cursor: "help" }}
                                >
                                    Tributação
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o módulo de faturas, no menu ADM (Eficience)"
                                    style={{ cursor: "help" }}
                                >
                                    Fatura
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title='Autoriza acessar as fatura de mercado livre, através do botão "Importar Custos Mercado Livre", dentro do cadastro da fatura'
                                    style={{ cursor: "help" }}
                                >
                                    Faturas Adicionais M.L.
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o módulo de identificador de multas, no menu Verificador de Faturas"
                                    style={{ cursor: "help" }}
                                >
                                    Identificador Multas (Energia)
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o módulo de verificador de ICMS e subsídios de irrigante, no menu Verificador de Faturas"
                                    style={{ cursor: "help" }}
                                >
                                    Verificador ICMS (Energia)
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o módulo de verificador de consumo de água, no menu Verificador de Faturas"
                                    style={{ cursor: "help" }}
                                >
                                    Verificador Consumo (Água)
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o módulo de análises"
                                    style={{ cursor: "help" }}
                                >
                                    Análises
                                </Th>
                                <Th
                                    sticky
                                    minWidth={70}
                                    alignCenter
                                    title="Autoriza acessar o módulo de relatórios"
                                    style={{ cursor: "help" }}
                                >
                                    Relatório
                                </Th>
                                <Th
                                    sticky
                                    minWidth={80}
                                    alignCenter
                                    title="Autoriza acessar o módulo de documentos do cliente"
                                    style={{ cursor: "help" }}
                                >
                                    Documento
                                </Th>
                                <Th
                                    sticky
                                    minWidth={80}
                                    alignCenter
                                    title="Autoriza acessar o módulo de documentos Eficience, no menu ADM (Eficience)"
                                    style={{ cursor: "help" }}
                                >
                                    Documento Eficience
                                </Th>
                                <Th
                                    sticky
                                    minWidth={60}
                                    alignCenter
                                    title="Autoriza acessar o módulo de redes elétricas, no menu ADM (Eficience)"
                                    style={{ cursor: "help" }}
                                >
                                    Redes
                                </Th>
                                <Th
                                    sticky
                                    minWidth={60}
                                    alignCenter
                                    title="Autoriza acessar o módulo de monitoramento solar"
                                    style={{ cursor: "help" }}
                                >
                                    Monit. Solar
                                </Th>
                                <Th
                                    sticky
                                    minWidth={60}
                                    alignCenter
                                    title="Autoriza acessar o módulo de monitoramento de consumo"
                                    style={{ cursor: "help" }}
                                >
                                    Monit. Consumo
                                </Th>
                            </NewTr>
                        </THead>

                        <TBody>
                            {this.props.lista
                                .filter(
                                    (item) =>
                                        !this.state.id_cliente ||
                                        (this.state.id_cliente == "admin" && !item.id_cliente) ||
                                        item.id_cliente == this.state.id_cliente
                                )
                                .map((item) => {
                                    return (
                                        <NewTr
                                            key={item.id}
                                            backgroundColorTr={"#FFFFFF"}
                                            backgroundColorTd={"#F5F5F5"}
                                            botoes={[
                                                {
                                                    type: "warning",
                                                    icon: "fas fa-pencil-alt",
                                                    disabled: !this.props.usuarioLogado.acesso_usuario_editar,
                                                    event: () => {
                                                        this.props.setModoTela("cadastro", {
                                                            ...item,
                                                            senha: null,
                                                        });
                                                        this.props.initForm({
                                                            ...item,
                                                            senha: null,
                                                        });
                                                    },
                                                },
                                                {
                                                    type: "danger",
                                                    icon: "fas fa-trash-alt",
                                                    disabled: !this.props.usuarioLogado.acesso_usuario_excluir,
                                                    event: () => {
                                                        this.props.setModoTela("exclusao", {
                                                            ...item,
                                                            senha: null,
                                                        });
                                                        this.props.initForm({
                                                            ...item,
                                                            senha: null,
                                                        });
                                                    },
                                                },
                                            ]}
                                        >
                                            <Td verticalAlign="top" paddingBlock={10}>
                                                {item.nome}
                                            </Td>
                                            <Td verticalAlign="top" paddingBlock={10}>
                                                {item.login}
                                            </Td>
                                            {this.props.usuarioLogado && this.props.usuarioLogado.administrador ? (
                                                <Td verticalAlign="top" paddingBlock={10}>
                                                    {item.senha_cliente}
                                                </Td>
                                            ) : null}
                                            {this.props.usuarioLogado && this.props.usuarioLogado.administrador ? (
                                                <Td verticalAlign="top" paddingBlock={10}>
                                                    <div className="d-flex flex-column">
                                                        <div>{item.nome_cliente}</div>
                                                        {!item.cliente_ativo && (
                                                            <div
                                                                style={{
                                                                    color: "#FFF",
                                                                    backgroundColor: "#f44141",
                                                                    maxWidth: "fit-content",
                                                                    padding: "2px 5px",
                                                                    borderRadius: 5,
                                                                }}
                                                            >
                                                                Cliente Excluído
                                                            </div>
                                                        )}
                                                    </div>
                                                </Td>
                                            ) : null}

                                            {this.props.usuarioLogado &&
                                            (this.props.usuarioLogado.acesso_permissao_uc_visualizar ||
                                                this.props.usuarioLogado.acesso_permissao_uc_editar) ? (
                                                <Td minWidth={400} verticalAlign="top" paddingBlock={10}>
                                                    <PermissaoUC
                                                        readOnly={
                                                            this.props.usuarioLogado &&
                                                            !this.props.usuarioLogado.acesso_permissao_uc_editar
                                                        }
                                                        setPermissaoUnidadeConsumidora={
                                                            this.props.setPermissaoUnidadeConsumidora
                                                        }
                                                        id_usuario={item.id}
                                                        unidades_consumidoras_usuario={
                                                            item.unidades_consumidoras_usuario
                                                        }
                                                    />
                                                </Td>
                                            ) : null}
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_usuario_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_usuario_visualizar: !item.acesso_usuario_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_usuario_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_usuario_adicionar: !item.acesso_usuario_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_usuario_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_usuario_editar: !item.acesso_usuario_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite excluir"
                                                        colorCondition={item.acesso_usuario_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_usuario_excluir: !item.acesso_usuario_excluir,
                                                        }}
                                                        icon="fa fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_usuario_logado}
                                                        newValue={{
                                                            ...item,
                                                            acesso_usuario_logado: !item.acesso_usuario_logado,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_cadastro_cliente_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_cliente_visualizar:
                                                                !item.acesso_cadastro_cliente_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_cadastro_cliente_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_cliente_adicionar:
                                                                !item.acesso_cadastro_cliente_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_cadastro_cliente_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_cliente_editar:
                                                                !item.acesso_cadastro_cliente_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite excluir"
                                                        colorCondition={item.acesso_cadastro_cliente_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_cliente_excluir:
                                                                !item.acesso_cadastro_cliente_excluir,
                                                        }}
                                                        icon="fa fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_permissao_uc_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_permissao_uc_visualizar:
                                                                !item.acesso_permissao_uc_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_permissao_uc_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_permissao_uc_editar:
                                                                !item.acesso_permissao_uc_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_concessionaria_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_concessionaria_visualizar:
                                                                !item.acesso_concessionaria_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_concessionaria_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_concessionaria_adicionar:
                                                                !item.acesso_concessionaria_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_concessionaria_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_concessionaria_editar:
                                                                !item.acesso_concessionaria_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite excluir"
                                                        colorCondition={item.acesso_concessionaria_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_concessionaria_excluir:
                                                                !item.acesso_concessionaria_excluir,
                                                        }}
                                                        icon="fa fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_credenciais_concessionaria_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_credenciais_concessionaria_editar:
                                                                !item.acesso_credenciais_concessionaria_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_empenho_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_empenho_visualizar: !item.acesso_empenho_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_empenho_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_empenho_adicionar: !item.acesso_empenho_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_empenho_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_empenho_editar: !item.acesso_empenho_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite excluir"
                                                        colorCondition={item.acesso_empenho_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_empenho_excluir: !item.acesso_empenho_excluir,
                                                        }}
                                                        icon="fa fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_gestao_processo_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_gestao_processo_visualizar:
                                                                !item.acesso_gestao_processo_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_gestao_processo_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_gestao_processo_adicionar:
                                                                !item.acesso_gestao_processo_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_gestao_processo_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_gestao_processo_editar:
                                                                !item.acesso_gestao_processo_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite excluir"
                                                        colorCondition={item.acesso_gestao_processo_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_gestao_processo_excluir:
                                                                !item.acesso_gestao_processo_excluir,
                                                        }}
                                                        icon="fa fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite reabrir"
                                                        colorCondition={item.acesso_gestao_processo_reabrir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_gestao_processo_reabrir:
                                                                !item.acesso_gestao_processo_reabrir,
                                                        }}
                                                        icon="fa fa-lock-open"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_gestao_processo_andamento_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_gestao_processo_andamento_adicionar:
                                                                !item.acesso_gestao_processo_andamento_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_gestao_processo_andamento_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_gestao_processo_andamento_editar:
                                                                !item.acesso_gestao_processo_andamento_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_cadastro_demanda_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_demanda_visualizar:
                                                                !item.acesso_cadastro_demanda_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_cadastro_demanda_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_demanda_adicionar:
                                                                !item.acesso_cadastro_demanda_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_cadastro_demanda_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_demanda_editar:
                                                                !item.acesso_cadastro_demanda_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite excluir"
                                                        colorCondition={item.acesso_cadastro_demanda_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_demanda_excluir:
                                                                !item.acesso_cadastro_demanda_excluir,
                                                        }}
                                                        icon="fa fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_periodo_teste_demanda_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_periodo_teste_demanda_visualizar:
                                                                !item.acesso_periodo_teste_demanda_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_periodo_teste_demanda_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_periodo_teste_demanda_adicionar:
                                                                !item.acesso_periodo_teste_demanda_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_periodo_teste_demanda_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_periodo_teste_demanda_editar:
                                                                !item.acesso_periodo_teste_demanda_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite excluir"
                                                        colorCondition={item.acesso_periodo_teste_demanda_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_periodo_teste_demanda_excluir:
                                                                !item.acesso_periodo_teste_demanda_excluir,
                                                        }}
                                                        icon="fa fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_cadastro_geracao_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_geracao_visualizar:
                                                                !item.acesso_cadastro_geracao_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_cadastro_geracao_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_geracao_adicionar:
                                                                !item.acesso_cadastro_geracao_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_cadastro_geracao_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_geracao_editar:
                                                                !item.acesso_cadastro_geracao_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite excluir"
                                                        colorCondition={item.acesso_cadastro_geracao_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_cadastro_geracao_excluir:
                                                                !item.acesso_cadastro_geracao_excluir,
                                                        }}
                                                        icon="fa fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_tributacao_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_tributacao_visualizar:
                                                                !item.acesso_tributacao_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite adicionar"
                                                        colorCondition={item.acesso_tributacao_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_tributacao_adicionar:
                                                                !item.acesso_tributacao_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_tributacao_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_tributacao_editar: !item.acesso_tributacao_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite excluir"
                                                        colorCondition={item.acesso_tributacao_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_tributacao_excluir: !item.acesso_tributacao_excluir,
                                                        }}
                                                        icon="fa fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "73px",
                                                        padding: 0,
                                                        flexWrap: "wrap",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_fatura_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_fatura_visualizar: !item.acesso_fatura_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite adicionar, importar, editar e excluir (apenas faturas abertas)"
                                                        colorCondition={item.acesso_fatura_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_fatura_editar: !item.acesso_fatura_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar faturas fechadas"
                                                        colorCondition={item.acesso_fatura_editar_fechado}
                                                        newValue={{
                                                            ...item,
                                                            acesso_fatura_editar_fechado:
                                                                !item.acesso_fatura_editar_fechado,
                                                        }}
                                                        icon="fa fa-edit"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite adicionar e remover data de pagamento das faturas"
                                                        colorCondition={item.acesso_fatura_data_pagamento_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_fatura_data_pagamento_editar:
                                                                !item.acesso_fatura_data_pagamento_editar,
                                                        }}
                                                        icon="fas fa-dollar-sign"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite refaturar faturas"
                                                        colorCondition={item.acesso_fatura_refaturar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_fatura_refaturar: !item.acesso_fatura_refaturar,
                                                        }}
                                                        icon="fas fa-file-invoice-dollar"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite adicionar faturas adicionais de Mercado Livre"
                                                        colorCondition={item.acesso_fatura_adicional_adicionar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_fatura_adicional_adicionar:
                                                                !item.acesso_fatura_adicional_adicionar,
                                                        }}
                                                        icon="fa fa-plus"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar faturas adicionais de Mercado Livre"
                                                        colorCondition={item.acesso_fatura_adicional_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_fatura_adicional_editar:
                                                                !item.acesso_fatura_adicional_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite excluir faturas adicionais de Mercado Livre"
                                                        colorCondition={item.acesso_fatura_adicional_excluir}
                                                        newValue={{
                                                            ...item,
                                                            acesso_fatura_adicional_excluir:
                                                                !item.acesso_fatura_adicional_excluir,
                                                        }}
                                                        icon="fas fa-trash"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>

                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_multa_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_multa_visualizar: !item.acesso_multa_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite editar (apenas faturas abertas)"
                                                        colorCondition={item.acesso_multa_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_multa_editar: !item.acesso_multa_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite editar faturas fechadas"
                                                        colorCondition={item.acesso_multa_editar_fechado}
                                                        newValue={{
                                                            ...item,
                                                            acesso_multa_editar_fechado:
                                                                !item.acesso_multa_editar_fechado,
                                                        }}
                                                        icon="fa fa-edit"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_icms_energia_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_icms_energia_visualizar:
                                                                !item.acesso_icms_energia_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_consumo_agua_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_consumo_agua_visualizar:
                                                                !item.acesso_consumo_agua_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_analise}
                                                        newValue={{
                                                            ...item,
                                                            acesso_analise: !item.acesso_analise,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_relatorio}
                                                        newValue={{
                                                            ...item,
                                                            acesso_relatorio: !item.acesso_relatorio,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite enviar e-mail"
                                                        colorCondition={item.acesso_relatorio_email}
                                                        newValue={{
                                                            ...item,
                                                            acesso_relatorio_email: !item.acesso_relatorio_email,
                                                        }}
                                                        icon="fa fa-envelope"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_documento_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_documento_visualizar:
                                                                !item.acesso_documento_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_documento_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_documento_editar: !item.acesso_documento_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_documento_adm_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_documento_adm_visualizar:
                                                                !item.acesso_documento_adm_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_documento_adm_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_documento_adm_editar:
                                                                !item.acesso_documento_adm_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_redes_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_redes_visualizar: !item.acesso_redes_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />

                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_redes_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_redes_editar: !item.acesso_redes_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_solar_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_solar_visualizar: !item.acesso_solar_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_solar_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_solar_editar: !item.acesso_solar_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                            <Td alignCenter verticalAlign="top" paddingBlock={10}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <BotaoPermissao
                                                        title="Permite visualizar"
                                                        colorCondition={item.acesso_monitoramento_consumo_visualizar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_monitoramento_consumo_visualizar:
                                                                !item.acesso_monitoramento_consumo_visualizar,
                                                        }}
                                                        icon="fa fa-eye"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                    <BotaoPermissao
                                                        title="Permite editar"
                                                        colorCondition={item.acesso_monitoramento_consumo_editar}
                                                        newValue={{
                                                            ...item,
                                                            acesso_monitoramento_consumo_editar:
                                                                !item.acesso_monitoramento_consumo_editar,
                                                        }}
                                                        icon="fa fa-pencil-alt"
                                                        usuarioLogado={this.props.usuarioLogado}
                                                        setPermissao={this.props.setPermissao}
                                                    />
                                                </div>
                                            </Td>
                                        </NewTr>
                                    );
                                })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    modoTela: state.usuario.modoTela,
    lista: state.usuario.lista,
    aguardando: state.usuario.aguardando,
    listaCliente: state.usuario.listaCliente,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            salvar,
            excluir,
            getLista,
            setPermissao,
            setPermissaoUnidadeConsumidora,
            getListaCliente,
            buscarSessao,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Usuario);

//componente interno
function BotaoPermissao(props) {
    return (
        <button
            title={props.title}
            style={{
                width: 30,
                height: 30,
                marginBottom: "2px",
                padding: props.icon === "fa fa-eye" ? 4 : 0,
                ...props.style,
            }}
            disabled={!props.usuarioLogado.administrador}
            type="button"
            className={`btn btn-${!!props.colorCondition ? "success" : "default"} btn-sm ${
                !props.usuarioLogado.administrador ? "disabled" : ""
            }`}
            onClick={() => {
                props.setPermissao({
                    ...props.newValue,
                });
            }}
        >
            {props.icon ? <i className={`${props.icon}`}></i> : null}
            {props.text}
        </button>
    );
}

function PermissaoUC(props) {
    const [filtro, setFiltro] = useState("");
    const [lista, setLista] = useState(props.unidades_consumidoras_usuario);
    useEffect(() => {
        setLista(props.unidades_consumidoras_usuario);
    }, [props.unidades_consumidoras_usuario]);
    const containerRef = useRef();
    const handleMouseLeaveUCsUsuario = () => {
        //  Itera sobre os refs e reseta o scroll de cada div
        if (containerRef && containerRef.current) {
            containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    if (!lista.length) return null;

    return (
        <div>
            <div className="p-2">
                <input
                    className="form-control"
                    type="text"
                    placeholder="Filtrar Nº UC"
                    onChange={(e) => setFiltro(e.target.value)}
                    value={filtro}
                />
                <button
                    className="form-control btn btn-danger mx-0 mt-2"
                    onClick={() => {
                        const listaAtualizada = lista.map((item) => ({
                            ...item,
                            permitida: false,
                        }));
                        setLista(listaAtualizada);
                        window.confirm(
                            "A lista de UCs permitidas não pode ficar vazia, selecione pelo menos uma UC para salvar as alterações"
                        );
                    }}
                >
                    Desmarcar Todas
                </button>
            </div>
            <div
                ref={containerRef}
                onMouseLeave={() => {
                    handleMouseLeaveUCsUsuario();
                    setFiltro("");
                }}
                className="d-flex flex-column"
                style={{ border: "1px solid #DDD", borderRadius: 5, height: 120, padding: 10, overflowY: "scroll" }}
            >
                {lista
                    .filter((ucu) => {
                        const numero = ucu.numero_nome.split(" - ")[0];
                        return !filtro || numero.includes(filtro);
                    })
                    .sort((a, b) => a.id_unidade_consumidora - b.id_unidade_consumidora)
                    .map((ucu) => (
                        <div
                            className={`btn small ${ucu.permitida ? "btn-success" : "btn-light"} ${
                                props.readOnly ? "disabled" : ""
                            } p-1 m-1`}
                            onClick={() => {
                                if (props.readOnly) return;

                                const listaAtualizada = lista.map((item) => ({
                                    ...item,
                                    permitida:
                                        ucu.id_unidade_consumidora == item.id_unidade_consumidora
                                            ? !item.permitida
                                            : item.permitida,
                                }));
                                if (listaAtualizada.find((item) => item.permitida)) {
                                    props.setPermissaoUnidadeConsumidora({
                                        id_usuario: props.id_usuario,
                                        unidades_consumidoras_usuario: listaAtualizada,
                                    });
                                } else {
                                    setLista(listaAtualizada);
                                    window.confirm(
                                        "A lista de UCs permitidas não pode ficar vazia, selecione pelo menos uma UC para salvar as alterações"
                                    );
                                }
                            }}
                        >
                            {ucu.numero_nome}
                        </div>
                    ))}
            </div>
        </div>
    );
}
