import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

import { customizeChartGrip } from "../utils/customizeChartGrip";

import DateFormat from "../common/dateFormat/DateFormat";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import { setModoTela, setDataInicialGrafico, setDataFinalGrafico, getListaGraficoDia } from "./monitoramentoConsumoActions";

function GraficosPeQ({
    nomeGrafico,
    registro,
    dataInicialGrafico,
    dataFinalGrafico,
    listaGraficoDia,
    setModoTela,
    setDataInicialGrafico,
    setDataFinalGrafico,
    getListaGraficoDia,
}) {
    const [grafico, setGrafico] = useState(null);
    const [graficoFP, setGraficoFP] = useState(null);
    const [zoomFP, setZoomFP] = useState(false);
    const [nomeGraficoAtivo, setNomeGraficoAtivo] = useState("graficoDia");

    const [valorDescontoQ, setValorDescontoQ] = useState();
    const [simular, setSimular] = useState(false);
    const [simulacaoAtiva, setSimulacaoAtiva] = useState(false);

    useEffect(() => {
        return () => desmontarTodosGraficos();
    }, []);

    useEffect(() => {
        atualizarListasGraficos({ dataInicialGrafico, dataFinalGrafico, nomeGrafico });
    }, [dataInicialGrafico, dataFinalGrafico, registro]);

    useEffect(() => {
        if (simulacaoAtiva) return;
        //só monta se já tiver a div com o id de referencia renderizado
        switch (nomeGraficoAtivo) {
            case "graficoDia":
                desmontarTodosGraficos();
                montarGraficoDia({ id: "graficoDia", lista: listaGraficoDia });
                montarGraficoDiaFP({ id: "graficoDiaFP", lista: listaGraficoDia, zoomFP });
                break;
            default:
                break;
        }
    }, [nomeGraficoAtivo, JSON.stringify(listaGraficoDia), zoomFP, simulacaoAtiva]);

    useEffect(() => {
        if (!simulacaoAtiva) return;
        switch (nomeGraficoAtivo) {
            case "graficoDia":
                desmontarTodosGraficos();
                const listaGraficoSimulada = listaGraficoDia.map((item) => ({
                    ...item,
                    q_simulado: new Date(item.data_hora).getTime() <= new Date().getTime() ? item.q - valorDescontoQ : null,
                }));
                montarGraficoDia({ id: "graficoDia", lista: listaGraficoSimulada });
                montarGraficoDiaFP({ id: "graficoDiaFP", lista: listaGraficoSimulada, zoomFP });
                break;
            default:
                break;
        }
    }, [simular, zoomFP, simulacaoAtiva]);

    return (
        <ContentCard style={{ backgroundColor: "#f0f1f5", paddingLeft: 0 }}>
            <ContentCardBody padding="0px">
                <Row
                    style={{
                        // overflow: "scroll",
                        height: "fit-content",
                        paddingLeft: 15,
                        paddingRight: 7,
                    }}
                >
                    <Row
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            backgroundColor: "#fff",
                            borderTop: "1px solid #ddd",
                            paddingTop: 25,
                            alignItems: "center",
                            justifyContent: window.innerWidth < 1340 ? "center" : "start",
                        }}
                    >
                        <Row style={{ width: "100%", justifyContent: "flex-end", paddingBottom: 10 }}>
                            <Grid cols="12 3 3 3">
                                <Button
                                    style={{ margin: 0 }}
                                    text="Voltar"
                                    type={"warning"}
                                    icon={"fa fa-chevron-left"}
                                    event={() => {
                                        desmontarTodosGraficos();
                                        setModoTela("lista", {});
                                        setDataInicialGrafico(DateFormat.getDataAtual().replace(/\//g, "-"));
                                        setDataFinalGrafico(DateFormat.getDataAtual().replace(/\//g, "-"));
                                    }}
                                />
                            </Grid>
                        </Row>
                        <Row style={{ width: "100%", justifyContent: "center" }}>
                            <Grid
                                cols={"12 12 4 4"}
                                style={{
                                    display: "flex",
                                    minWidth: "fit-content",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <button
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "1.5rem",
                                        fontWeight: 500,
                                        boxShadow: "0 0 10px 1px #ccc",
                                        color: "#0070cc",
                                        width: 40,
                                        height: 40,
                                        borderRadius: "100%",
                                        backgroundColor: "#fff",
                                        border: "none",
                                        marginInline: 20,
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.outline = "none";
                                    }}
                                    onClick={() => {
                                        voltarAvancarDataGrafico({
                                            dataGrafico: dataInicialGrafico,
                                            tempo: -1,
                                            nomeGrafico,
                                            setDataGrafico: setDataInicialGrafico,
                                        });
                                    }}
                                    disabled={false}
                                >
                                    {"<"}
                                </button>
                                {nomeGrafico === "graficoDia" && (
                                    <input
                                        style={{
                                            fontSize: "1.2rem",
                                            padding: 7,
                                            border: "1px solid #999",
                                            borderRadius: 10,
                                        }}
                                        required
                                        type="date"
                                        value={dataInicialGrafico}
                                        max={dataFinalGrafico}
                                        onFocus={(e) => (e.target.style.outline = "none")}
                                        onChange={(e) => {
                                            const valorData = e.target.value || dataFinalGrafico;
                                            setDataInicialGrafico(valorData);
                                            atualizarListasGraficos({
                                                dataInicialGrafico: valorData,
                                                dataFinalGrafico,
                                                nomeGrafico,
                                            });
                                        }}
                                    ></input>
                                )}
                                <button
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "1.5rem",
                                        fontWeight: 500,
                                        boxShadow: dataInicialGrafico === dataFinalGrafico ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                        color: "#0070cc",
                                        width: 40,
                                        height: 40,
                                        borderRadius: "100%",
                                        backgroundColor: "#fff",
                                        border: "none",
                                        marginInline: 20,
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.outline = "none";
                                    }}
                                    onClick={() => {
                                        voltarAvancarDataGrafico({
                                            dataGrafico: dataInicialGrafico,
                                            tempo: 1,
                                            nomeGrafico,
                                            setDataGrafico: setDataInicialGrafico,
                                        });
                                    }}
                                    disabled={dataInicialGrafico === dataFinalGrafico}
                                >
                                    {">"}
                                </button>
                            </Grid>
                            <div
                                style={{
                                    display: "flex",
                                    minWidth: "fit-content",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "1.2rem",
                                    padding: 15,
                                }}
                            >
                                Até
                            </div>
                            <Grid
                                cols={"12 12 4 4"}
                                style={{
                                    display: "flex",
                                    minWidth: "fit-content",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <button
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "1.5rem",
                                        fontWeight: 500,
                                        boxShadow: dataInicialGrafico === dataFinalGrafico ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                        color: "#0070cc",
                                        width: 40,
                                        height: 40,
                                        borderRadius: "100%",
                                        backgroundColor: "#fff",
                                        border: "none",
                                        marginInline: 20,
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.outline = "none";
                                    }}
                                    onClick={() => {
                                        voltarAvancarDataGrafico({
                                            dataGrafico: dataFinalGrafico,
                                            tempo: -1,
                                            nomeGrafico,
                                            setDataGrafico: setDataFinalGrafico,
                                        });
                                    }}
                                    disabled={dataInicialGrafico === dataFinalGrafico}
                                >
                                    {"<"}
                                </button>
                                {nomeGrafico === "graficoDia" && (
                                    <input
                                        style={{
                                            fontSize: "1.2rem",
                                            padding: 7,
                                            border: "1px solid #999",
                                            borderRadius: 10,
                                        }}
                                        required
                                        type="date"
                                        value={dataFinalGrafico}
                                        min={dataInicialGrafico}
                                        max={DateFormat.getDataAtual().replace(/\//g, "-")}
                                        onFocus={(e) => (e.target.style.outline = "none")}
                                        onChange={(e) => {
                                            const valorData = e.target.value || DateFormat.getDataAtual().replace(/\//g, "-");
                                            setDataFinalGrafico(valorData);
                                            atualizarListasGraficos({
                                                dataInicialGrafico,
                                                dataFinalGrafico: valorData,
                                                nomeGrafico,
                                            });
                                        }}
                                    ></input>
                                )}
                                <button
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "1.5rem",
                                        fontWeight: 500,
                                        boxShadow: dataFinalGrafico === DateFormat.getDataAtual().replace(/\//g, "-") ? "inset 0 0 16px 1px #ccc" : "0 0 10px 1px #ccc",
                                        color: "#0070cc",
                                        width: 40,
                                        height: 40,
                                        borderRadius: "100%",
                                        backgroundColor: "#fff",
                                        border: "none",
                                        marginInline: 20,
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.outline = "none";
                                    }}
                                    onClick={() => {
                                        voltarAvancarDataGrafico({
                                            dataGrafico: dataFinalGrafico,
                                            tempo: 1,
                                            nomeGrafico,
                                            setDataGrafico: setDataFinalGrafico,
                                        });
                                    }}
                                    disabled={dataFinalGrafico === DateFormat.getDataAtual().replace(/\//g, "-")}
                                >
                                    {">"}
                                </button>
                            </Grid>
                        </Row>
                        <Row
                            style={{
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingLeft: 20,
                                marginTop: 20,
                            }}
                        >
                            <LabelAndInputNumber
                                name="valor-desconto-q"
                                label="Digitar potência de capacitor a ser instalado"
                                placeholder="Digitar potência de capacitor a ser instalado"
                                cols="12 12 3 3"
                                casas={2}
                                allowNegative={false}
                                onChange={(e) => {
                                    const valorFormatado = parseFloat(e.target.value.replace(",", ".")) || 0;
                                    setValorDescontoQ(valorFormatado);
                                }}
                            />
                            <Grid cols="12 12 3 2" style={{ marginTop: 15 }}>
                                <Button
                                    text="Simular"
                                    type={"info"}
                                    icon={"fa fa-calculator"}
                                    event={() => {
                                        setSimulacaoAtiva(true);
                                        setSimular(!simular);
                                    }}
                                />
                            </Grid>
                            <Grid cols="12 12 3 2" style={{ marginTop: 15 }}>
                                <Button
                                    text="Desfazer Simulação"
                                    type={"danger"}
                                    icon={"fa fa-undo"}
                                    event={() => {
                                        setValorDescontoQ(null);
                                        setSimulacaoAtiva(false);
                                    }}
                                />
                            </Grid>
                        </Row>
                    </Row>

                    <div
                        id={`${nomeGrafico}`}
                        style={{
                            width: "100%",
                            backgroundColor: "#fff",
                            height: "400px",
                        }}
                    ></div>

                    <div
                        id={`${nomeGrafico}FP`}
                        style={{
                            width: "100%",
                            backgroundColor: "#fff",
                            height: "350px",
                        }}
                    ></div>
                </Row>
            </ContentCardBody>
        </ContentCard>
    );

    function montarGraficoDia({ id, lista }) {
        am4core.useTheme(am4themes_animated);

        const modoSimulado = lista[0] && !isNaN(parseFloat(lista[0].q_simulado)); //se tem q_simulado a lista está no modo simulada

        let chart = am4core.create(id, am4charts.XYChart);

        // Configura título e subtítulo
        const subtitle = chart.titles.create();
        subtitle.text = registro.nome_unidade_consumidora;
        subtitle.fontWeight = "bold";
        subtitle.fontSize = window.innerWidth < 1150 ? "0.7rem" : "1rem";
        subtitle.fill = am4core.color("#000");
        subtitle.marginBottom = 10;

        const title = chart.titles.create();
        title.text = "Gráfico de Demanda Direta (Consumo)";
        title.fontWeight = "bold";
        title.fontSize = window.innerWidth < 1150 ? "0.8rem" : "1.2rem";
        title.fill = am4core.color("#000");
        title.marginBottom = 20;

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.exporting.menu.items = [
            {
                label: "...",
                menu: [
                    {
                        type: "png",
                        label: "PNG",
                    },
                ],
            },
        ];
        chart.language.locale = locale;
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";

        // Configura eixo X
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;
        // dateAxis.dateFormats.setKey("minute", "HH:mm");

        dateAxis.baseInterval = {
            timeUnit: "minute",
            count: 15,
        };

        // Lógica para ajustar os rótulos com base no intervalo de dados
        if (lista.length) {
            const firstDate = new Date(lista[0].data_hora);
            const lastDate = new Date(lista[lista.length - 1].data_hora);
            const isSingleDay = firstDate.toDateString() === lastDate.toDateString();

            if (isSingleDay) {
                // Exibe apenas horas se os dados forem de um único dia
                dateAxis.dateFormats.setKey("hour", "HH:mm");
                dateAxis.dateFormats.setKey("minute", "HH:mm");
            } else {
                // Exibe dias para múltiplos dias
                dateAxis.dateFormats.setKey("day", "dd/MM\nEEE");
            }
        }

        // Centraliza os rótulos no eixo
        dateAxis.renderer.labels.template.textAlign = "middle";
        dateAxis.renderer.labels.template.fill = am4core.color("#000");
        dateAxis.renderer.maxLabelPosition = 0.95;
        dateAxis.renderer.minGridDistance = 40;
        dateAxis.renderer.minLabelPosition = 0.05;

        // Configura eixo Y compartilhado para "P (kW)" e "Q (kVAr)"
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "P (kW) / Q (kVAr)";
        valueAxis.renderer.labels.template.fill = am4core.color("#000");
        valueAxis.renderer.marginLeft = 30;
        valueAxis.renderer.marginRight = 20;
        // valueAxis.renderer.labels.template.fontSize = 12;
        // valueAxis.min = 0;

        // Série para "P (kW)"
        const seriesP = chart.series.push(new am4charts.LineSeries());
        seriesP.dataFields.valueY = "p"; // Campo para "P (kW)"
        seriesP.dataFields.dateX = "data_hora";
        seriesP.name = "P (kW)";
        seriesP.tooltipText = `P (kW) às {dateX.formatDate("dd/MM/yyyy HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} kW[/]`;
        seriesP.strokeWidth = 2;
        seriesP.tensionX = 1;
        seriesP.stroke = am4core.color("#017acd");
        seriesP.fill = am4core.color("#017acd");
        seriesP.fillOpacity = 0.2;

        // Série para "Q (kVAr)"
        const seriesQ = chart.series.push(new am4charts.LineSeries());
        seriesQ.dataFields.valueY = "q"; // Campo para "Q (kVAr)"
        seriesQ.dataFields.dateX = "data_hora";
        seriesQ.name = "Q (kVAr)";
        seriesQ.tooltipText = `Q (kVAr) às {dateX.formatDate("dd/MM/yyyy HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} kVAr[/]`;
        seriesQ.strokeWidth = 2;
        seriesQ.tensionX = 1;
        seriesQ.stroke = am4core.color("#1bd07c");
        seriesQ.fill = am4core.color("#1bd07c");
        seriesQ.fillOpacity = 0.2;

        // Adiciona cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = [seriesP, seriesQ];

        if (modoSimulado) {
            // Série para "Q (kVAr) Simulado"
            const seriesQSimulado = chart.series.push(new am4charts.LineSeries());
            seriesQSimulado.dataFields.valueY = "q_simulado"; // Campo para "Q (kVAr)"
            seriesQSimulado.dataFields.dateX = "data_hora";
            seriesQSimulado.name = "Q (kVAr) Simulado";
            seriesQSimulado.tooltipText = `Q (kVAr) Simulado às {dateX.formatDate("dd/MM/yyyy HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} kVAr[/]`;
            seriesQSimulado.strokeWidth = 2;
            seriesQSimulado.tensionX = 1;
            seriesQSimulado.stroke = am4core.color("#ff0077");
            seriesQSimulado.fill = am4core.color("#ff0077");
            seriesQSimulado.fillOpacity = 0.2;

            // Adiciona cursor
            chart.cursor.snapToSeries.push(seriesQSimulado);
        }

        // Scrollbar
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(seriesP);
        chart.scrollbarX.series.push(seriesQ);
        chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
        chart.scrollbarX.unselectedOverlay.fillOpacity = 0.7;
        chart.scrollbarX.unselectedOverlay.stroke = am4core.color("#fff");

        chart.scrollbarX.background.fill = am4core.color("#fff");
        chart.scrollbarX.thumb.background.fill = am4core.color("#2190ff"); //cor do trecho selecionado
        chart.scrollbarX.thumb.background.fillOpacity = 0.3; //opacidade da cor do trecho selecionado
        chart.scrollbarX.stroke = am4core.color("#003040"); // cor do texto dentro do trecho seleionado
        chart.scrollbarX.strokeWidth = 0;

        //Cor da scrollbar ao passar o mouse
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fillOpacity = 0.3;

        //Adiciona legenda
        chart.legend = new am4charts.Legend();
        chart.legend.labels.template.fill = am4core.color("#000");

        // Configura a cor do tooltip do eixo X
        const dateAxisTooltip = dateAxis.tooltip;
        dateAxisTooltip.background.fill = am4core.color("#0070cc");
        dateAxisTooltip.stroke = am4core.color("#fff");
        dateAxisTooltip.background.strokeWidth = 0;
        dateAxisTooltip.background.cornerRadius = 3;
        dateAxisTooltip.background.pointerLength = 0;
        dateAxisTooltip.dy = 5;

        // Configura a cor do tooltip do eixo Y compartilhado
        const valueAxisTooltip = valueAxis.tooltip;
        valueAxisTooltip.background.fill = am4core.color("#0070cc");
        valueAxisTooltip.stroke = am4core.color("#fff");
        valueAxisTooltip.background.strokeWidth = 0;
        valueAxisTooltip.background.cornerRadius = 3;
        valueAxisTooltip.background.pointerLength = 0;
        valueAxisTooltip.dy = 5;
        // valueAxis.renderer.line.strokeOpacity = 1;
        // valueAxis.renderer.line.strokeWidth = 1;

        // Configurações adicionais para garantir boa visualização
        valueAxis.tooltip.disabled = false; // Garante que o tooltip seja exibido
        dateAxis.tooltip.disabled = false; // Garante que o tooltip seja exibido

        // Configura dados
        chart.data = lista.map((leitura) => {
            const { p, q, q_simulado, data_hora } = leitura;
            return { p, q, q_simulado, data_hora: new Date(data_hora) };
        });

        // Customiza botões da scrollbar
        customizeChartGrip(chart.scrollbarX.startGrip);
        customizeChartGrip(chart.scrollbarX.endGrip);

        return setGrafico(chart);
    }

    function montarGraficoDiaFP({ id, lista, zooFP }) {
        const corPrimaria = "#13b4d1";
        am4core.useTheme(am4themes_animated);

        const modoSimulado = lista[0] && !isNaN(parseFloat(lista[0].q_simulado)); //se tem q_simulado a lista está no modo simulada

        let chart = am4core.create(id, am4charts.XYChart);

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Gráfico";
        chart.exporting.menu.align = "right";
        chart.exporting.menu.verticalAlign = "top";
        chart.language.locale = locale;
        chart.language.locale["_decimalSeparator"] = ",";
        chart.language.locale["_thousandSeparator"] = ".";
        chart.numberFormatter.numberFormat = "###,###,###,###.00";

        // Configuração do eixo X (tempo)
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;
        // dateAxis.baseInterval = { timeUnit: "minute", count: 15 };

        if (lista.length) {
            const firstDate = new Date(lista[0].data_hora);
            const lastDate = new Date(lista[lista.length - 1].data_hora);
            const isSingleDay = firstDate.toDateString() === lastDate.toDateString();

            if (isSingleDay) {
                dateAxis.dateFormats.setKey("hour", "HH:mm");
                dateAxis.dateFormats.setKey("minute", "HH:mm");
            } else {
                dateAxis.dateFormats.setKey("day", "dd/MM\nEEE");
            }
        }

        dateAxis.renderer.labels.template.textAlign = "middle";

        // Eixo Y para FP positiva (metade superior)
        const valueAxisPositivo = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxisPositivo.title.text = "FP";
        valueAxisPositivo.renderer.labels.template.fill = am4core.color("#000");
        valueAxisPositivo.renderer.minGridDistance = 30;
        valueAxisPositivo.min = zooFP ? 0.9 : 0;
        valueAxisPositivo.max = 1;
        valueAxisPositivo.strictMinMax = true;
        valueAxisPositivo.extraMin = 0; // Restringe à metade superior
        valueAxisPositivo.extraMax = 1; // Evita ocupar todo o espaço
        valueAxisPositivo.renderer.opposite = false;
        valueAxisPositivo.renderer.inversed = true;
        valueAxisPositivo.marginTop = 0;

        // **Ocultar labels fora da faixa -1 a 0**
        valueAxisPositivo.renderer.labels.template.adapter.add("text", function (text, target) {
            let value = target.dataItem.value;
            return value >= 0 && value <= 1 && value != 0.9 ? text : "";
        });

        // Eixo Y para FP negativa (metade inferior)
        const valueAxisNegativo = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxisNegativo.renderer.labels.template.fill = am4core.color("#000");
        valueAxisNegativo.renderer.minGridDistance = 30;
        valueAxisNegativo.min = -1;
        valueAxisNegativo.max = zoomFP ? -0.9 : 0;
        valueAxisNegativo.strictMinMax = true;
        valueAxisNegativo.extraMin = 1; // Evita ocupar todo o espaço
        valueAxisNegativo.extraMax = 0; // Restringe à metade inferior
        valueAxisNegativo.renderer.opposite = false;
        valueAxisNegativo.renderer.inversed = true;
        valueAxisNegativo.marginBottom = 0;

        // **Ocultar labels fora da faixa -1 a 0**
        valueAxisNegativo.renderer.labels.template.adapter.add("text", function (text, target) {
            let value = target.dataItem.value;
            return value <= 0 && value > -1 && value != -0.9 ? text : "";
        });

        valueAxisPositivo.renderer.labels.template.dx = 30; // Ajuste fino conforme necessário
        valueAxisNegativo.renderer.labels.template.dx = -17; // Mesmo valor para ambos os eixos

        valueAxisPositivo.renderer.grid.template.width = valueAxisNegativo.renderer.grid.template.width;

        // Criar a faixa no eixo para destacar 0.92
        let range1 = valueAxisPositivo.axisRanges.create();
        let range2 = valueAxisNegativo.axisRanges.create();

        range1.value = 0.92;
        range2.value = -0.92;
        range1.grid.strokeOpacity = 0.2; // Mantém uma linha visível na posição
        range2.grid.strokeOpacity = 0.2;
        range1.label.text = "0,92";
        range2.label.text = "-0,92";

        // Criar a faixa no eixo para destacar -0.92
        if (!zooFP) {
            range1.label.dy = 8; // Move um pouco para baixo
            range1.label.verticalCenter = "bottom"; // Alinha a parte superior do texto com a linha
            range1.label.fontSize = 12; // Ajusta o tamanho do texto para melhor visibilidade

            range2.label.dy = -8; // Move um pouco para baixo
            range2.label.verticalCenter = "top"; // Alinha a parte superior do texto com a linha
            range2.label.fontSize = 12;
        }

        // Criando a faixa no background (guide)
        let rangeP = valueAxisPositivo.axisRanges.create();
        rangeP.value = 0.92; // Início da faixa
        rangeP.endValue = 1; // Fim da faixa
        rangeP.axisFill.fill = am4core.color("#ffa1a0"); // Cor da faixa
        rangeP.axisFill.fillOpacity = 0.5; // Define a transparência
        rangeP.grid.disabled = true; // Remove linhas extras para evitar poluição visual

        // Criando a faixa no background (guide)
        let rangeN = valueAxisNegativo.axisRanges.create();
        rangeN.value = -0.92; // Início da faixa
        rangeN.endValue = -1; // Fim da faixa
        rangeN.axisFill.fill = am4core.color("#ffa1a0"); // Cor da faixa
        rangeN.axisFill.fillOpacity = 0.5; // Define a transparência
        rangeN.grid.disabled = true; // Remove linhas extras para evitar poluição visual

        // Série para FP positiva
        const seriesPositivo = chart.series.push(new am4charts.LineSeries());
        seriesPositivo.dataFields.valueY = "fpPositivo";
        seriesPositivo.dataFields.dateX = "data_hora";
        seriesPositivo.name = "Fator de Potência";
        seriesPositivo.tooltipText = `Fator de Potência (FP) às {dateX.formatDate("dd/MM/yyyy HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} [/]`;
        seriesPositivo.strokeWidth = 2;
        seriesPositivo.stroke = am4core.color(corPrimaria);
        seriesPositivo.fill = am4core.color(corPrimaria);
        seriesPositivo.yAxis = valueAxisPositivo;
        seriesPositivo.baseValue = 0;
        seriesPositivo.connect = false;

        // Exibe apenas a legenda da série positiva
        seriesPositivo.showOnLegend = true; // A série positiva aparece na legenda

        // Série para FP negativa
        const seriesNegativo = chart.series.push(new am4charts.LineSeries());
        seriesNegativo.dataFields.valueY = "fpNegativo";
        seriesNegativo.dataFields.dateX = "data_hora";
        seriesNegativo.name = "Fator de Potência";
        seriesNegativo.tooltipText = `Fator de Potência (FP) às {dateX.formatDate("dd/MM/yyyy HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} [/]`;
        seriesNegativo.strokeWidth = 2;
        seriesNegativo.stroke = am4core.color("#d11313");
        seriesNegativo.fill = am4core.color("#d11313");
        seriesNegativo.yAxis = valueAxisNegativo;
        seriesNegativo.baseValue = 0;
        seriesNegativo.connect = false;
        seriesNegativo.showOnLegend = false; // A série negativa não aparece na legenda

        // Adiciona cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = [seriesPositivo, seriesNegativo];
        chart.cursor.behavior = "none"; // Desativa o zoom ao arrastar
        chart.chartContainer.wheelable = false; // Bloqueia zoom pelo scroll do mouse

        //-------------------------------------------------------------------USADO NO SIMULADOR---------------------------------------------------------------------
        if (modoSimulado) {
            // Série para FP positiva
            const seriesPositivoSimulado = chart.series.push(new am4charts.LineSeries());
            seriesPositivoSimulado.dataFields.valueY = "fpPositivoSimulado";
            seriesPositivoSimulado.dataFields.dateX = "data_hora";
            seriesPositivoSimulado.name = "Fator de Potência Simulado";
            seriesPositivoSimulado.tooltipText = `Fator de Potência (FP) às {dateX.formatDate("dd/MM/yyyy HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} [/]`;
            seriesPositivoSimulado.strokeWidth = 2;
            seriesPositivoSimulado.stroke = am4core.color("#ff0077");
            seriesPositivoSimulado.fill = am4core.color("#ff0077");
            seriesPositivoSimulado.yAxis = valueAxisPositivo;
            seriesPositivoSimulado.baseValue = 0;
            seriesPositivoSimulado.connect = false;
            seriesPositivoSimulado.showOnLegend = true; // A série positiva aparece na legenda

            // Série para FP negativa
            const seriesNegativoSimulado = chart.series.push(new am4charts.LineSeries());
            seriesNegativoSimulado.dataFields.valueY = "fpNegativoSimulado";
            seriesNegativoSimulado.dataFields.dateX = "data_hora";
            seriesNegativoSimulado.name = "Fator de Potência Simulado";
            seriesNegativoSimulado.tooltipText = `Fator de Potência (FP) às {dateX.formatDate("dd/MM/yyyy HH:mm")}h: [bold]{valueY.formatNumber("###,###,###,###.00")} [/]`;
            seriesNegativoSimulado.strokeWidth = 2;
            seriesNegativoSimulado.stroke = am4core.color("#ff0077");
            seriesNegativoSimulado.fill = am4core.color("#ff0077");
            seriesNegativoSimulado.yAxis = valueAxisNegativo;
            seriesNegativoSimulado.baseValue = 0;
            seriesNegativoSimulado.connect = false;
            seriesNegativoSimulado.showOnLegend = false; // A série negativa não aparece na legenda

            chart.cursor.snapToSeries.push(seriesPositivoSimulado, seriesNegativoSimulado);
        }

        //----------------------------------------------------------------------------------------------------------------------------------------------------------

        // Prepara os dados normalizando corretamente
        chart.data = lista.map((leitura, i) => {
            const { p, q, q_simulado, data_hora } = leitura;
            const fp = p / Math.sqrt(Math.pow(p, 2) + Math.pow(q, 2));
            const fpSimulado = p / Math.sqrt(Math.pow(p, 2) + Math.pow(q_simulado, 2));

            return {
                fpPositivo: fp >= 0 ? fp : null,
                fpNegativo: fp < 0 ? fp : null,
                fpPositivoSimulado: fpSimulado >= 0 ? fpSimulado : null,
                fpNegativoSimulado: fpSimulado < 0 ? fpSimulado : null,
                data_hora: new Date(data_hora),
            };
        });

        // Scrollbar
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(seriesPositivo);
        chart.scrollbarX.series.push(seriesNegativo);
        chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
        chart.scrollbarX.unselectedOverlay.fillOpacity = 0.7;
        chart.scrollbarX.unselectedOverlay.stroke = am4core.color("#fff");

        chart.scrollbarX.background.fill = am4core.color("#fff");
        chart.scrollbarX.thumb.background.fill = am4core.color(corPrimaria);
        chart.scrollbarX.thumb.background.fillOpacity = 0.3;
        chart.scrollbarX.stroke = am4core.color(corPrimaria);
        chart.scrollbarX.strokeWidth = 0;

        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color(corPrimaria);
        chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;
        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color(corPrimaria);
        chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color(corPrimaria);
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fillOpacity = 0.3;

        // Adiciona legenda
        chart.legend = new am4charts.Legend();
        chart.legend.labels.template.fill = am4core.color("#000");
        chart.legend.dx = 100;

        // Configura a cor do tooltip do eixo X
        const dateAxisTooltip = dateAxis.tooltip;
        dateAxisTooltip.background.fill = am4core.color("#0070cc");
        dateAxisTooltip.stroke = am4core.color("#fff");
        dateAxisTooltip.background.strokeWidth = 0;
        dateAxisTooltip.background.cornerRadius = 3;
        dateAxisTooltip.background.pointerLength = 0;
        // dateAxisTooltip.dy = 5;

        // Configura a cor do tooltip do eixo Y compartilhado
        const valueAxisTooltip = valueAxisPositivo.tooltip;
        valueAxisTooltip.background.fill = am4core.color("#0070cc");
        valueAxisTooltip.stroke = am4core.color("#fff");
        valueAxisTooltip.background.strokeWidth = 0;
        valueAxisTooltip.background.cornerRadius = 3;
        valueAxisTooltip.background.pointerLength = 0;
        valueAxisTooltip.dy = 5;
        // valueAxisPositivo.renderer.line.strokeOpacity = 1;
        // valueAxisPositivo.renderer.line.strokeWidth = 1;

        // A legenda deve ser configurada após as séries
        chart.legend.itemContainers.template.adapter.add("visible", function (visible, target) {
            // Só mostrar a legenda se a série estiver visível
            return target.dataItem && target.dataItem.dataContext.showOnLegend ? visible : false;
        });

        // Desabilitar o clique na legenda
        chart.legend.itemContainers.template.clickable = false;
        chart.legend.itemContainers.template.focusable = false;
        chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;

        chart.chartContainer.events.on("wheel", function (ev) {
            if (ev.event.deltaY > 0) {
                // Rolando para baixo (scroll para frente)
                setZoomFP(false);
            } else {
                // Rolando para cima (scroll para trás)
                setZoomFP(true);
            }
        });

        dateAxis.start = 0;
        dateAxis.end = 1;
        dateAxis.keepSelection = true; // Impede que o usuário altere o zoom

        valueAxisPositivo.start = 0;
        valueAxisPositivo.end = 1;
        valueAxisPositivo.keepSelection = true;

        valueAxisNegativo.start = 0;
        valueAxisNegativo.end = 1;
        valueAxisNegativo.keepSelection = true;

        // Customiza botões da scrollbar
        customizeChartGrip(chart.scrollbarX.startGrip, corPrimaria);
        customizeChartGrip(chart.scrollbarX.endGrip, corPrimaria);

        return setGraficoFP(chart);
    }

    function voltarAvancarDataGrafico({ dataGrafico, tempo, nomeGrafico, setDataGrafico }) {
        const novaData = new Date(dataGrafico);
        switch (nomeGrafico) {
            case "lista": //usado no gráfico admin que é diário também
                novaData.setUTCDate(novaData.getUTCDate() + tempo);
                break;
            case "graficoDia":
                novaData.setUTCDate(novaData.getUTCDate() + tempo);
                setDataGrafico(novaData.toISOString().substring(0, 10));
                break;
            default:
                break;
        }
    }

    function atualizarListasGraficos({ dataInicialGrafico, dataFinalGrafico, nomeGrafico }) {
        if (!(registro.consumoLeitura || {}).id_consumo_integracao) return;
        const filtro = {
            id_consumo_integracao: registro.consumoLeitura.id_consumo_integracao,
            dataInicial: dataInicialGrafico,
            dataFinal: dataFinalGrafico,
        };
        switch (nomeGrafico) {
            case "graficoDia":
                getListaGraficoDia(filtro);
                break;
            default:
                break;
        }
    }

    function desmontarTodosGraficos() {
        if (grafico) {
            console.log("desmontando grafico");
            grafico.dispose();
        }
        if (graficoFP) {
            console.log("desmontando graficoFP");
            graficoFP.dispose();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        nomeGrafico: state.monitoramentoConsumo.modoTela,
        registro: state.monitoramentoConsumo.registro,
        dataInicialGrafico: state.monitoramentoConsumo.dataInicialGrafico,
        dataFinalGrafico: state.monitoramentoConsumo.dataFinalGrafico,
        listaGraficoDia: state.monitoramentoConsumo.listaGraficoDia,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            setDataInicialGrafico,
            setDataFinalGrafico,
            getListaGraficoDia,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(GraficosPeQ);
