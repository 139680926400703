import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

const { customizeChartGrip } = require("../utils/customizeChartGrip");

export function montarGraficoColunasEnergiaMesesAno({ id, lista, setChart, subtitulo }) {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(id, am4charts.XYChart);
    const subtitle = chart.titles.create();
    subtitle.text = subtitulo;
    subtitle.fontWeight = "bold";
    subtitle.fontSize = window.innerWidth < 1150 ? "0.7rem" : "1rem";
    subtitle.fill = am4core.color("#000");
    subtitle.marginBottom = 30;

    const title = chart.titles.create();
    title.text = "Energia Gerada no Ano";
    title.fontWeight = "bold";
    title.fontSize = window.innerWidth < 1150 ? "0.8rem" : "1.2rem";
    title.fill = am4core.color("#000");

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = "Gráfico";
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
        {
            label: "...",
            menu: [
                {
                    type: "png",
                    label: "PNG",
                },
            ],
        },
    ];
    chart.language.locale = locale;
    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";
    chart.language.locale["_date_month"] = "MMM";
    chart.numberFormatter.numberFormat = "###,###,###,###.00";
    chart.paddingTop = 20; // Ajuste conforme necessário

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.startLocation = 0;
    dateAxis.endLocation = 1;
    dateAxis.dateFormats.setKey("month", "MMM");
    dateAxis.periodChangeDateFormats.setKey("month", "MMM");
    dateAxis.baseInterval = {
        timeUnit: "month",
        count: 1,
    };
    // dateAxis.renderer.labels.template.fontSize = 20;
    dateAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo x

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (chart.yAxes.indexOf(valueAxis) != 0) {
        valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
    }

    valueAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
    valueAxis.min = 0;
    valueAxis.extraMax = 0.2; // Adiciona espaço extra no topo

    const serieProducaoEsperadaDia = chart.series.push(new am4charts.LineSeries());
    serieProducaoEsperadaDia.dataFields.valueY = "projetado";
    serieProducaoEsperadaDia.dataFields.dateX = "data";
    serieProducaoEsperadaDia.yAxis = valueAxis;
    serieProducaoEsperadaDia.name = "Produção esperada do mês";
    serieProducaoEsperadaDia.tooltipText = `Produção esperada: [bold]{valueY.formatNumber("###,###,###,###.00")} kWh[/]`;
    serieProducaoEsperadaDia.stroke = am4core.color("#cd2236");
    serieProducaoEsperadaDia.fill = am4core.color("#cd2236");
    serieProducaoEsperadaDia.strokeWidth = 2;

    // Create series
    const serieEnergiaMes = chart.series.push(new am4charts.ColumnSeries());
    serieEnergiaMes.dataFields.valueY = "energia_mes";
    serieEnergiaMes.dataFields.dateX = "data";
    serieEnergiaMes.yAxis = valueAxis;
    serieEnergiaMes.name = "Energia gerada por mês";
    serieEnergiaMes.tooltipText = `Energia gerada em {dateX.formatDate("MMM yyyy")}: [bold]{valueY.formatNumber("###,###,###,###.00")} kWh[/]`;
    serieEnergiaMes.stroke = am4core.color("#017acd");
    serieEnergiaMes.fill = am4core.color("#017acd");
    serieEnergiaMes.fillOpacity = 0.4;

    //Estilo da scrollbar
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.series.push(serieProducaoEsperadaDia);
    chart.scrollbarX.series.push(serieEnergiaMes);
    chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
    chart.scrollbarX.unselectedOverlay.fillOpacity = 0.7;
    chart.scrollbarX.unselectedOverlay.stroke = am4core.color("#fff");

    chart.scrollbarX.background.fill = am4core.color("#fff");
    chart.scrollbarX.thumb.background.fill = am4core.color("#2190ff"); //cor do trecho selecionado
    chart.scrollbarX.thumb.background.fillOpacity = 0.3; //opacidade da cor do trecho selecionado
    chart.scrollbarX.stroke = am4core.color("#003040"); // cor do texto dentro do trecho seleionado
    chart.scrollbarX.strokeWidth = 0;

    //Cor da scrollbar ao passar o mouse
    chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
    chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

    chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
    chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fillOpacity = 0.3;

    chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#2190ff");
    chart.scrollbarX.thumb.background.states.getKey("hover").properties.fillOpacity = 0.3;

    //Adiciona legenda
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color("#000");

    //Adiciona cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = [serieEnergiaMes,serieProducaoEsperadaDia];

    //configura a cor do tooltip x
    const dateAxisTooltip = dateAxis.tooltip;
    dateAxisTooltip.background.fill = am4core.color("#0070cc");
    dateAxisTooltip.stroke = am4core.color("#fff");
    dateAxisTooltip.background.strokeWidth = 0;
    dateAxisTooltip.background.cornerRadius = 3;
    dateAxisTooltip.background.pointerLength = 0;
    dateAxisTooltip.dy = 5;

    //configura a cor do tooltip y
    const valueAxisTooltip = valueAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#0070cc");
    valueAxisTooltip.stroke = am4core.color("#fff");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.background.pointerLength = 0;
    valueAxisTooltip.dy = 5;

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;

    chart.data = lista;

    chart.data = lista.map(item => ({
        ...item,
        desempenho: item.projetado ? (item.energia_mes * 100 / item.projetado).toFixed(2) : 0
    }));
    
    const labelBullet = serieEnergiaMes.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{desempenho}%";
    labelBullet.label.fill = am4core.color("#222"); // Cor do texto para contrastar com a barra
    labelBullet.label.fontSize = 14; // Define o tamanho da fonte
    labelBullet.label.fontWeight = "bold"; // Define o texto como negrito
    labelBullet.label.horizontalCenter = "middle";
    labelBullet.label.verticalCenter = "middle"; // Mantém o label dentro da coluna
    labelBullet.dy = -20; // Ajuste fino se necessário
    

    customizeChartGrip(chart.scrollbarX.startGrip);
    customizeChartGrip(chart.scrollbarX.endGrip);
    setChart(chart);
}
