import React from "react";
import Row from "../common/layout/row";
import { ProgressBar } from "../common/progressBar/progressBar";

export default function indicadorParcialRendimentoMensal({ listaDiasMes, dataGrafico }) {
    //usado no gráfico energia dias do mês
    const percentualProcucaoAcumulada = exibirPercentualProducaoAcumulada({ listaDiasMes, dataGrafico });
    return (
        <div
            style={{
                color: "#ffffff",
                backgroundColor: "#1e3e49",
                borderRadius: 15,
                boxShadow: "0 0 10px 1px #b9b9b9",
                padding: "20px 20px",
                marginBlock: 5,
            }}
        >
            <p style={{ fontSize: window.innerWidth < 450 ? "0.7rem" : "1rem" }}>
                <b>Indicador parcial, rendimento mensal até a data de hoje.</b>
            </p>
            <Row style={{ display: "flex", flexWrap: "nowrap", justifyContent: "center" }}>
                {isNaN(parseFloat(percentualProcucaoAcumulada)) ? (
                    <p
                        style={{
                            fontSize: window.innerWidth < 450 ? "0.7rem" : "1rem",
                            margin: 0,
                        }}
                    >
                        Cadastre a produção esperada para visualizar.
                    </p>
                ) : (
                    <>
                        <ProgressBar valor={percentualProcucaoAcumulada} />
                        <div
                            style={{
                                fontSize: window.innerWidth < 450 ? "0.7rem" : "1rem",
                                display: "flex",
                                alignItems: "center",
                                paddingTop: 3,
                                marginLeft: 5,
                            }}
                        >
                            {Math.round(percentualProcucaoAcumulada)}%
                        </div>
                    </>
                )}
            </Row>
        </div>
    );
}

function exibirPercentualProducaoAcumulada({ listaDiasMes, dataGrafico }) {
    if (!listaDiasMes.length) return 0; //se não tiver itens na lista ainda retorna zero
    const producaoEsperadaDia = (listaDiasMes[0] || {}).producao_esperada_dia;
    const diaAtual = new Date().getDate();
    const mesAtual = new Date().getMonth() + 1;

    //se estiver no mes atual considera todos registros menores que a data atual
    //senão considera todos registros, pois é de um mes anterior

    const listaSemDiaAtual = listaDiasMes.filter((item) => parseInt(item.data.substring(5, 7)) != mesAtual || parseInt(item.data.substring(8,10)) < diaAtual);
    //soma todas energia_dia que a data for menor que a atual
    let somaEnergiaDia = listaSemDiaAtual.reduce((total, atual) => {
        total += parseFloat(atual.energia_dia || 0);
        return total;
    }, 0);

        //Apenas quando olha meses retroativos
    //tem casos que a falta de leitura em alguns dias, faz o total da soma diaria dar diferente do total do mês
    //que consta na última leitura do mês, quando a soma for menor, considera o valor da energia_mes do último registro
    const competenciaGrafico = new Date(dataGrafico);
    let agora = new Date();
    let hojeUTC = new Date(Date.UTC(agora.getUTCFullYear(), agora.getUTCMonth(), agora.getUTCDate()));
    const energiaMesUltimoRegistro = parseFloat([...listaDiasMes].slice(-1)[0].energia_mes) || 0;
    if (competenciaGrafico < hojeUTC && somaEnergiaDia < energiaMesUltimoRegistro) {
        somaEnergiaDia = energiaMesUltimoRegistro; //pega a maior entre a última do mês e a soma dos dias do mês
    }

    const ultimoDiaMesFiltro = new Date(
        new Date(dataGrafico).getFullYear(),
        new Date(dataGrafico).getMonth() + 1,
        0
    ).getDate();

    //se for um mes diferente do atual no filtro, considera o ultimo dia do mes filtrado para calcular o total
    //se for o mes atual, considera o total dias até a data atual (hoje - 1)
    //considera só dias completos, ex: hj é 10/04, considera 9 dias
    const quantidadeDias = parseInt(dataGrafico.substring(5, 7)) != mesAtual ? ultimoDiaMesFiltro : diaAtual - 1;

    //se a lista de dias a serem avaliados for vazia mostra 0% atingido
    if (!quantidadeDias) return 0;

    //se não tiver produção esperada cadastrada exibe msg mandando cadastrar
    if (isNaN(parseFloat(producaoEsperadaDia))) return null;

    let somaProducaoEsperadaDia = producaoEsperadaDia * quantidadeDias;



    const resultado = (somaEnergiaDia / somaProducaoEsperadaDia) * 100;
    return resultado;
}
