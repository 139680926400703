import React from "react";
import "./modalForm.css";

export function ModalAlert({ handleClose, titulo, children, contentStyle = {} }) {
    return (
        <div>
            <div className="modal">
                <div className="modal-content">
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                            className="close-button"
                            style={{ outline: "none" }}
                            type="button"
                            onClick={handleClose}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                    <h5 style={{ textAlign: "center" }}>{titulo}</h5>
                    <div style={contentStyle}>{children}</div>
                </div>
            </div>
        </div>
    );
}
