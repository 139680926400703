import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/web/pdf_viewer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./TextLayer.css";

// Importação correta do worker
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;

export const PdfViewer = ({ pdfBase64, zoom, setZoom }) => {
    const containerRef = useRef(null);
    const [pdf, setPdf] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    useEffect(() => {
        if (!pdfBase64) return;

        const loadPdf = async () => {
            const loadingTask = pdfjsLib.getDocument({ data: atob(pdfBase64.split(",")[1]) });
            const pdfDoc = await loadingTask.promise;
            setPdf(pdfDoc);
        };

        loadPdf();
    }, [pdfBase64]);

    useEffect(() => {
        if (!pdf || !containerRef.current) return;

        const renderPages = async () => {
            if (!pdf || !containerRef.current) return;

            const numPages = pdf.numPages;
            const container = containerRef.current;
            container.innerHTML = ""; // Limpa antes de renderizar

            for (let i = 1; i <= numPages; i++) {
                const page = await pdf.getPage(i);
                const viewport = page.getViewport({ scale: zoom });

                // Criar canvas para cada página
                const canvas = document.createElement("canvas");
                canvas.width = viewport.width;
                canvas.height = viewport.height;

                const context = canvas.getContext("2d");
                const renderContext = { canvasContext: context, viewport };
                await page.render(renderContext).promise;

                // Criar camada de texto
                const textLayerDiv = document.createElement("div");
                textLayerDiv.className = "textLayer";
                textLayerDiv.style.width = `${viewport.width}px`;
                textLayerDiv.style.height = `${viewport.height}px`;
                textLayerDiv.style.position = "absolute";
                textLayerDiv.style.top = "0";
                textLayerDiv.style.left = "0";
                textLayerDiv.style.zIndex = "2"; // Colocar acima do canvas

                const textLayer = await page.getTextContent();
                await pdfjsLib.renderTextLayer({
                    textContent: textLayer,
                    container: textLayerDiv,
                    viewport,
                    textDivs: [],
                });

                // Ajustar transformação da camada de texto
                setTimeout(() => {
                    textLayerDiv.querySelectorAll("span").forEach((span) => {
                        span.style.transform = "scale(0.82)"; // Teste com 1 para ver se melhora a seleção
                        span.style.display = "inline-block";
                    });
                }, 0);

                // Criar wrapper para página
                const pageWrapper = document.createElement("div");
                pageWrapper.style.position = "relative";

                // Ajustar posicionamento da camada de texto
                textLayerDiv.style.position = "absolute";
                textLayerDiv.style.top = "0";
                textLayerDiv.style.left = "0";
                textLayerDiv.style.pointerEvents = "none"; // Evita interferência na seleção

                pageWrapper.appendChild(canvas);
                pageWrapper.appendChild(textLayerDiv);

                container.appendChild(pageWrapper);
            }
        };

        renderPages();
    }, [pdf, zoom]);
    const handleWheel = (e) => {
        if (e.ctrlKey) {
            e.preventDefault();

            const zoomStep = 0.1;
            const newZoom = Math.max(0.5, Math.min(zoom + (e.deltaY < 0 ? zoomStep : -zoomStep), 3));

            if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                const offsetX = e.clientX - rect.left;
                const offsetY = e.clientY - rect.top;

                const scrollXRatio = offsetX / containerRef.current.clientWidth;
                const scrollYRatio = offsetY / containerRef.current.clientHeight;

                const prevScrollLeft = containerRef.current.scrollLeft;
                const prevScrollTop = containerRef.current.scrollTop;

                setZoom(newZoom);

                requestAnimationFrame(() => {
                    const deltaX = containerRef.current.scrollWidth - containerRef.current.clientWidth;
                    const deltaY = containerRef.current.scrollHeight - containerRef.current.clientHeight;

                    containerRef.current.scrollLeft = prevScrollLeft + deltaX * scrollXRatio;
                    containerRef.current.scrollTop = prevScrollTop + deltaY * scrollYRatio;
                });
            }
        }
    };

    const handleMouseDown = (e) => {
        if (!e.ctrlKey || !containerRef.current) return;
        setIsDragging(true);
        setStartX(e.clientX);
        setStartY(e.clientY);
        setScrollLeft(containerRef.current.scrollLeft);
        setScrollTop(containerRef.current.scrollTop);
    };

    const handleMouseMove = (e) => {
        if (!isDragging || !containerRef.current) return;
        const dx = startX - e.clientX;
        const dy = startY - e.clientY;
        containerRef.current.scrollLeft = scrollLeft + dx;
        containerRef.current.scrollTop = scrollTop + dy;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };
    return (
        <div
            ref={containerRef}
            style={{
                overflowY: "hidden",
                overflowX: "hidden",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
            }}
            onWheel={handleWheel}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        />
    );
};
