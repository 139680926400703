const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    totalFaturasImportadas: 0,
    lista: [],
    listaFaturasVencidas: [],
    listaCliente: [],
    listaUnidadeConsumidora: [],
    listaConcessionaria: [],
    listaProdutoTipo: [],
    filtro: {
        ano: (() => {
            if (new Date().getMonth() == 0) {
                return new Date().getFullYear() - 1;
            } else {
                return new Date().getFullYear();
            }
        })(),
        mes: (() => {
            let data = new Date();
            data.setMonth(data.getMonth() - 1);

            return data.getMonth() < 9 ? `0${data.getMonth() + 1}` : data.getMonth() + 1;
        })(),
        id_cliente: null,
        id_unidade_consumidora: null,
        id_concessionaria: null,
        unidades_consumidoras: [],
        id_grupo_faturamento: null,
        irrigante: true,
        naoIrrigante: true,
        id_empenho: null,
        geracao_solar: null,
    },
    listaGraficoCusto: [],
    listaGraficoCustoUnidade: [],
    listaMeses: [
        { id: "01", valor: "Janeiro" },
        { id: "02", valor: "Fevereiro" },
        { id: "03", valor: "Março" },
        { id: "04", valor: "Abril" },
        { id: "05", valor: "Maio" },
        { id: "06", valor: "Junho" },
        { id: "07", valor: "Julho" },
        { id: "08", valor: "Agosto" },
        { id: "09", valor: "Setembro" },
        { id: "10", valor: "Outubro" },
        { id: "11", valor: "Novembro" },
        { id: "12", valor: "Dezembro" },
    ],
    listaProducaoSolar: [
        { id: "GERADORAS", valor: "GERADORAS" },
        { id: "REMOTAS", valor: "REMOTAS" },
        { id: "SEM GERAÇÃO", valor: "SEM GERAÇÃO" },
        { id: "GERADORAS E REMOTAS", valor: "GERADORAS E REMOTAS" },
    ],
    totalFaturaEmpenho: 0,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "FATURA_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "FATURA_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "FATURA_FILTRO":
            return { ...state, filtro: action.payload };

        case "FATURA_RESETAR_FILTRO":
            return {
                ...state,
                filtro: {
                    ano: (() => {
                        if (new Date().getMonth() == 0) {
                            return new Date().getFullYear() - 1;
                        } else {
                            return new Date().getFullYear();
                        }
                    })(),
                    mes: (() => {
                        let data = new Date();
                        data.setMonth(data.getMonth() - 1);

                        return data.getMonth() < 9 ? `0${data.getMonth() + 1}` : data.getMonth() + 1;
                    })(),
                    id_cliente: null,
                    id_unidade_consumidora: null,
                    unidades_consumidoras: [],
                    id_grupo_faturamento: null,
                    irrigante: true,
                    naoIrrigante: true,
                },
            };

        case "TOTAL_FATURAS_IMPORTADAS":
            return { ...state, totalFaturasImportadas: action.payload };

        case "FATURA_LISTADO":
            return {
                ...state,
                lista: action.payload.map((item) => ({
                    ...item,
                    itens: (item.itens || []).filter((uc) => uc), //faz isso pq pode vir do BD [null]
                })),
            };

        case "FATURAS_VENCIDAS_LISTADO":
            return {
                ...state,
                listaFaturasVencidas: action.payload.data,
            };

        case "FATURA_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "FATURA_TOTAL_EMPENHO":
            return {
                ...state,
                totalFaturaEmpenho: action.payload.data || 0,
            };

        case "FATURA_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: action.payload.data,
            };
        case "FATURA_CONCESSIONARIA_SELECT_LISTADO":
            return {
                ...state,
                listaConcessionaria: action.payload.data,
            };

        case "FATURA_PRODUTO_TIPO_SELECT_LISTADO":
            return {
                ...state,
                listaProdutoTipo: action.payload.data,
            };

        case "FATURA_GRAFICO_CUSTO_LISTADO":
            return {
                ...state,
                listaGraficoCusto: action.payload.data,
            };

        case "FATURA_GRAFICO_CUSTO_UNIDADE_LISTADO":
            return {
                ...state,
                listaGraficoCustoUnidade: action.payload.data,
            };

        default:
            return state;
    }
};
