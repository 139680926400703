import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Select from "../common/form/select";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_dark from "@amcharts/amcharts4/themes/dark.js";
import confirmDialog from "../common/dialog/confirmDialog";
import { DisplayPDF } from "../displayPDF/displayPDF";
import LabelAndCheckbox from "../common/form/labelAndCheckbox";
import { AnaliseCusto } from "./abas/AnaliseCusto.jsx";
import { AnaliseConsumo } from "./abas/AnaliseConsumo.jsx";
import { AnaliseTarifa } from "./abas/AnaliseTarifa.jsx";
import { AnaliseAproveitamentoConsumoHorario } from "./abas/AnaliseAproveitamentoConsumoHorario.jsx";
import { AnaliseDemanda } from "./abas/AnaliseDemanda.jsx";
import { AnaliseReativo } from "./abas/AnaliseReativo.jsx";
import { AnalisePeriodoSafra } from "./abas/AnalisePeriodoSafra.jsx";
import { AnaliseMulta } from "./abas/AnaliseMulta.jsx";
import { AnaliseMicroMiniGeracao } from "./abas/AnaliseMicroMiniGeracao.jsx";
import { AnaliseGestaoEmpenho } from "./abas/AnaliseGestaoEmpenho.jsx";
import { AnaliseDevolucaoRessarcimento } from "./abas/AnaliseDevolucaoRessarcimento.jsx";
import DateFormat from "../common/dateFormat/DateFormat";
import {
    setFiltro,
    getDemanda,
    getListaCliente,
    getListaUnidadeConsumidora,
    getListaDemandaContratoTipo,
    getListaAnosEmpenho,
    setTabSelecionada,
    setAbas,
    buscaCusd,
    getListaGraficoCustoAnual,
    getListaGraficoConsumoAnual,
    getListaGraficoValorDevolvidoAnual,
    getListaGraficoCustoMultaAnual,
    getListaGraficoCustoReativoAnual,
    getListaGraficoConsumoForaPonta,
} from "./analiseActions";
import { buscarPdf } from "../demandaContrato/demandaContratoActions";
import { getListaGrupoFaturamento } from "../cliente/clienteActions";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";
import { Loading } from "../common/loading/loading";
import { setError } from "../common/toast/toast.jsx";
import { ModalAlert } from "../common/dialog/modalAlert.jsx";
import ButtonTable from "../common/table/buttonTable.jsx";

class Analise extends Component {
    constructor(props) {
        super(props);
        this.setAguardando = this.setAguardando.bind(this);
        this.buscarPropriedadeUcFiltrada = this.buscarPropriedadeUcFiltrada.bind(this);
    }
    state = {
        exibirModalHistorico: false,
        itemSelecionado: null,
        mobile: window.innerWidth < 500,
        aguardando: false,
        cores: [
            am4core.color("#1976d2"),
            am4core.color("#dfe30b"),
            am4core.color("#d65e33"),
            am4core.color("#00c853"),
            am4core.color("#9575cd"),
            am4core.color("#ff9800"),
        ],
    };

    componentWillMount() {
        this.props.getListaUnidadeConsumidora();
        this.props.getListaCliente();
        this.props.getListaDemandaContratoTipo();
        this.props.getListaGrupoFaturamento();

        if (this.props.usuarioLogado && this.props.usuarioLogado.acesso_cliente) {
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: this.props.usuarioLogado.id_cliente,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filtro.id_cliente != this.props.filtro.id_cliente) {
            if (this.props.tabSelecionada === "empenho") this.props.getListaAnosEmpenho();
            if (this.props.tabSelecionada === "custo") this.props.getListaGraficoCustoAnual();
            if (this.props.tabSelecionada === "consumo") this.props.getListaGraficoConsumoAnual();
            if (this.props.tabSelecionada === "devolucao") this.props.getListaGraficoValorDevolvidoAnual();
            if (this.props.tabSelecionada === "multas") this.props.getListaGraficoCustoMultaAnual();
            if (this.props.tabSelecionada === "reativo") this.props.getListaGraficoCustoReativoAnual();
        }
        //se tava na aba de empenho e selecionou outro cliente que n é prefeitura
        //faz retornar para a aba de consumo, que é a primeira
        if (!this.validarExibicaoAba(this.props.tabSelecionada)) {
            window.alert("O cliente não se enquadra na aba selecionada.");
            this.props.setFiltro({
                ...this.props.filtro,
                id_cliente: null,
                id_unidade_consumidora: null,
                unidades_consumidoras: [],
                unidades_custo_anual: [],
                unidades_custo_anual_periodo_safra: [],
            });
        }
    }

    listar(tabSelecionada, forcarAtualizacao = false) {
        this.props.getDemanda();
        this.props.setTabSelecionada(tabSelecionada);
        if (!forcarAtualizacao) return;

        switch (tabSelecionada) {
            case "custo":
                this.props.setAbas({ ...this.props.abas, custo: true });
                this.setAguardando(true);
                break;
            case "consumo":
                this.props.setAbas({ ...this.props.abas, consumo: true });
                this.setAguardando(true);
                break;
            case "tarifa":
                this.props.setAbas({ ...this.props.abas, tarifa: true });
                this.setAguardando(true);
                break;
            case "horario":
                this.props.setAbas({ ...this.props.abas, horario: true });
                this.setAguardando(true);
                this.props.getListaGraficoConsumoForaPonta(); //busca pra saber quandos anos exibir no filtro
                break;
            case "demanda":
                this.props.setAbas({ ...this.props.abas, demanda: true });
                this.setAguardando(true);
                break;
            case "reativo":
                this.props.setAbas({ ...this.props.abas, reativo: true });
                this.setAguardando(true);
                this.props.getListaGraficoCustoReativoAnual(); //busca pra saber quandos anos exibir no filtro
                break;
            case "periodoSafra":
                this.props.setAbas({ ...this.props.abas, periodoSafra: true });
                this.setAguardando(true);
                break;
            case "multas":
                this.props.setAbas({ ...this.props.abas, multas: true });
                this.setAguardando(true);
                this.props.getListaGraficoCustoMultaAnual(); //busca pra saber quandos anos exibir no filtro
                break;
            case "excedente":
                this.props.setAbas({ ...this.props.abas, excedente: true });
                this.setAguardando(true);
                break;
            case "empenho":
                this.props.setAbas({ ...this.props.abas, empenho: true });
                this.setAguardando(true);
                break;
            case "devolucao":
                this.props.setAbas({ ...this.props.abas, devolucao: true });
                this.setAguardando(true);
                this.props.getListaGraficoValorDevolvidoAnual(); //busca pra saber quandos anos exibir no filtro
                break;
            default:
                break;
        }
    }

    setAguardando(valor) {
        this.setState({ ...this.state, aguardando: valor });
    }

    //UCs que listam no select do primeiro filtro  de UCS
    listarUCsFiltradas() {
        return this.props.listaUnidadeConsumidora.filter((item) => {
            //se não selecionar cliente não traz UCs
            const filtroClienteValidado =
                !this.props.filtro.id_cliente || item.id_cliente == this.props.filtro.id_cliente;

            const filtroGrupoFaturamento = this.props.filtro.grupos_faturamento.map((item) => parseInt(item.value)); //apenas os ids do filtro

            const filtroGrupoFaturamentoValidado =
                !filtroGrupoFaturamento.length || filtroGrupoFaturamento.includes(item.id_grupo_faturamento);
            const filtroIrriganteValidado =
                (this.props.filtro.irrigante && item.irrigante) || (this.props.filtro.naoIrrigante && !item.irrigante);

            if (["reativo", "multas"].includes(this.props.tabSelecionada)) {
                return (
                    filtroClienteValidado &&
                    ([1, 4].includes(parseInt(item.id_grupo_faturamento)) || item.id_grupo_faturamento == 3)
                );
            }

            if (["demanda"].includes(this.props.tabSelecionada)) {
                return filtroClienteValidado && [1, 4].includes(parseInt(item.id_grupo_faturamento));
            }

            if (this.props.tabSelecionada === "horario") {
                return (
                    filtroClienteValidado &&
                    ([1, 4].includes(parseInt(item.id_grupo_faturamento)) ||
                        item.id_grupo_faturamento == 3 ||
                        item.irrigante)
                );
            }

            if (this.props.tabSelecionada === "excedente") {
                return filtroClienteValidado && (item.geracao || item.remota);
            }

            // POR ENQUANTO TRAZ TODAS NA NA PERIODO SAFRA
            // if (this.props.tabSelecionada === "periodoSafra") {
            // 	return filtroClienteValidado && (item.id_atividade == 2 || clienteSelecionado.visualiza_analise_periodo_safra); //2 = atividade rural
            // }

            //se não for nenhum dos anteriores
            return filtroClienteValidado && filtroGrupoFaturamentoValidado && filtroIrriganteValidado;
        });
    }

    buscarPropriedadeUcFiltrada(nomePropriedade) {
        const listaUcFiltro = this.props.listaUnidadeConsumidora.filter((item) =>
            this.props.filtro.unidades_consumidoras.find((uc) => uc.value == item.id)
        );
        //se não veio propriedade quer dizer que vai verificar se existe algum item no filtro
        if (!nomePropriedade) return !!listaUcFiltro.length;

        //se veio propriedade tem que encontrar algum item que possua a propriedade especificada
        const uc = listaUcFiltro.find((uc) => uc[nomePropriedade]);
        if (!uc) return;

        //se encontrar algum item retorna a propriedade
        return uc[nomePropriedade];
    }

    validarExibicaoAba(aba) {
        //deve ter if para todas abas possíveis
        if (!this.props.filtro.id_cliente) return true;
        const clienteSelecionado = this.props.listaCliente.find((cli) => cli.id == this.props.filtro.id_cliente) || {};
        const listaUCsClienteFiltro = this.props.listaUnidadeConsumidora.filter(
            (item) => this.props.filtro.id_cliente && item.id_cliente == this.props.filtro.id_cliente
        );

        if (["demanda", "reativo", "multas"].includes(aba)) {
            return listaUCsClienteFiltro.find(
                (item) => [1, 4].includes(parseInt(item.id_grupo_faturamento)) || item.id_grupo_faturamento == 3
            );
        }

        if (aba === "horario") {
            return listaUCsClienteFiltro.find(
                (item) =>
                    [1, 4].includes(parseInt(item.id_grupo_faturamento)) ||
                    item.id_grupo_faturamento == 3 ||
                    item.irrigante
            );
        }

        if (aba === "excedente") {
            return listaUCsClienteFiltro.find((item) => item.geracao || item.remota);
        }

        if (aba === "periodoSafra") {
            return (
                listaUCsClienteFiltro.find((item) => item.id_atividade == 2) ||
                clienteSelecionado.visualiza_analise_periodo_safra
            ); //2 = atividade rural
        }

        if (aba === "empenho") {
            return this.props.usuarioLogado.cliente_prefeitura || clienteSelecionado.cliente_prefeitura;
        }

        if (aba === "devolucao") {
            return true; //!this.props.usuarioLogado.acesso_cliente;	inicialmente todos tem acesso
        }

        if (aba === "custo") {
            return true; //!this.props.usuarioLogado.acesso_cliente;	inicialmente todos tem acesso
        }

        if (aba === "consumo") {
            return true; //!this.props.usuarioLogado.acesso_cliente;	inicialmente todos tem acesso
        }

        if (aba === "tarifa") {
            return true; //!this.props.usuarioLogado.acesso_cliente;	inicialmente todos tem acesso
        }
    }

    handleChangeFiltroAno(props, value) {
        const modoSafra = props.filtro[props.tabSelecionada + "_ano_safra"];
        let mesAtual = new Date().getMonth() + 1;
        mesAtual = mesAtual < 10 ? "0" + mesAtual : mesAtual;
        switch (props.tabSelecionada) {
            case "custo":
                props.setFiltro({
                    ...props.filtro,
                    ano_custo: value,
                    competencia_inicial_custo: modoSafra ? `${value}06` : `${value}01`,
                    competencia_final_custo: modoSafra ? `${parseInt(value) + 1}05` : `${value}12`,
                    competencia_inicial_unidade_custo: modoSafra ? `${value}06` : `${value}01`,
                    competencia_final_unidade_custo: modoSafra ? `${parseInt(value) + 1}05` : `${value}12`,
                    competencia_inicial_custo_comparativo_antes: modoSafra
                        ? `${parseInt(value) - 1}06`
                        : `${parseInt(value) - 1}01`,
                    competencia_final_custo_comparativo_antes: modoSafra
                        ? `${value}05`
                        : `${parseInt(value) - 1}${mesAtual}`,
                    competencia_inicial_custo_comparativo_depois: modoSafra ? `${value}06` : `${value}01`,
                    competencia_final_custo_comparativo_depois: modoSafra
                        ? `${parseInt(value) + 1}05`
                        : `${value}${mesAtual}`,
                    ano_acumulado_custo: value,
                });
                break;
            case "consumo":
                props.setFiltro({
                    ...props.filtro,
                    ano_consumo: value,
                    competencia_inicial_consumo_comparativo_antes: modoSafra
                        ? `${parseInt(value) - 1}06`
                        : `${parseInt(value) - 1}01`,
                    competencia_final_consumo_comparativo_antes: modoSafra
                        ? `${value}05`
                        : `${parseInt(value) - 1}${mesAtual}`,
                    competencia_inicial_consumo_comparativo_depois: modoSafra ? `${value}06` : `${value}01`,
                    competencia_final_consumo_comparativo_depois: modoSafra
                        ? `${parseInt(value) + 1}05`
                        : `${value}${mesAtual}`,
                    ano_acumulado_consumo: value,
                });
                break;
            case "devolucao":
                props.setFiltro({
                    ...props.filtro,
                    ano_devolucao: value,
                    competencia_inicial_comparativo_devolucao_ressarcimento: modoSafra ? `${value}06` : `${value}01`,
                    competencia_final_comparativo_devolucao_ressarcimento: modoSafra
                        ? `${parseInt(value) + 1}05`
                        : `${value}12`,
                    competencia_inicial_devolucao_por_uc: modoSafra ? `${value}06` : `${value}01`,
                    competencia_final_devolucao_por_uc: modoSafra ? `${parseInt(value) + 1}05` : `${value}12`,
                });
                break;
            case "multas":
                props.setFiltro({
                    ...props.filtro,
                    ano_multas: value,
                    competencia_inicial_comparativo_tipo_multa: modoSafra ? `${value}06` : `${value}01`,
                    competencia_final_comparativo_tipo_multa: modoSafra ? `${parseInt(value) + 1}05` : `${value}12`,
                });
                break;
            case "reativo":
                props.setFiltro({
                    ...props.filtro,
                    ano_reativo: value,
                    competencia_inicial_reativo: modoSafra ? `${value}06` : `${value}01`,
                    competencia_final_reativo: modoSafra ? `${parseInt(value) + 1}05` : `${value}12`,
                });
                break;
            case "horario":
                props.setFiltro({
                    ...props.filtro,
                    ano_horario: value,
                    competencia_inicial_horario: modoSafra ? `${value}06` : `${value}01`,
                    competencia_final_horario: modoSafra ? `${parseInt(value) + 1}05` : `${value}12`,
                });
                break;
            default:
                break;
        }
    }

    handleChangePeriodoVisualizacao(props, modoSafra) {
        let ano = props.filtro["ano_" + props.tabSelecionada];
        let mesAtual = new Date().getMonth() + 1;
        mesAtual = mesAtual < 10 ? "0" + mesAtual : mesAtual;

        switch (props.tabSelecionada) {
            case "custo":
                props.setFiltro({
                    ...props.filtro,
                    custo_ano_safra: modoSafra,
                    competencia_inicial_custo: modoSafra ? `${ano}06` : `${ano}01`,
                    competencia_final_custo: modoSafra ? `${parseInt(ano) + 1}05` : `${ano}12`,
                    competencia_inicial_unidade_custo: modoSafra ? `${ano}06` : `${ano}01`,
                    competencia_final_unidade_custo: modoSafra ? `${parseInt(ano) + 1}05` : `${ano}12`,
                    competencia_inicial_custo_comparativo_antes: modoSafra
                        ? `${parseInt(ano) - 1}06`
                        : `${parseInt(ano) - 1}01`,
                    competencia_final_custo_comparativo_antes: modoSafra
                        ? `${ano}05`
                        : `${parseInt(ano) - 1}${mesAtual}`,
                    competencia_inicial_custo_comparativo_depois: modoSafra ? `${ano}06` : `${ano}01`,
                    competencia_final_custo_comparativo_depois: modoSafra
                        ? `${parseInt(ano) + 1}05`
                        : `${ano}${mesAtual}`,
                    ano_acumulado_custo: ano,
                });
                break;
            case "consumo":
                props.setFiltro({
                    ...props.filtro,
                    consumo_ano_safra: modoSafra,
                    competencia_inicial_consumo_comparativo_antes: modoSafra
                        ? `${parseInt(ano) - 1}06`
                        : `${parseInt(ano) - 1}01`,
                    competencia_final_consumo_comparativo_antes: modoSafra
                        ? `${ano}05`
                        : `${parseInt(ano) - 1}${mesAtual}`,
                    competencia_inicial_consumo_comparativo_depois: modoSafra ? `${ano}06` : `${ano}01`,
                    competencia_final_consumo_comparativo_depois: modoSafra
                        ? `${parseInt(ano) + 1}05`
                        : `${ano}${mesAtual}`,
                    ano_acumulado_consumo: ano,
                });
                break;
            case "devolucao":
                //busca pra ver quantos anos vai listar no filtro
                this.props.getListaGraficoValorDevolvidoAnual();
                props.setFiltro({
                    ...props.filtro,
                    devolucao_ano_safra: modoSafra,
                    competencia_inicial_comparativo_devolucao_ressarcimento: modoSafra ? `${ano}06` : `${ano}01`,
                    competencia_final_comparativo_devolucao_ressarcimento: modoSafra
                        ? `${parseInt(ano) + 1}05`
                        : `${ano}12`,
                    competencia_inicial_devolucao_por_uc: modoSafra ? `${ano}06` : `${ano}01`,
                    competencia_final_devolucao_por_uc: modoSafra ? `${parseInt(ano) + 1}05` : `${ano}12`,
                });
                break;
            case "multas":
                //busca pra ver quantos anos vai listar no filtro
                this.props.getListaGraficoCustoMultaAnual();
                props.setFiltro({
                    ...props.filtro,
                    multas_ano_safra: modoSafra,
                    competencia_inicial_comparativo_tipo_multa: modoSafra ? `${ano}06` : `${ano}01`,
                    competencia_final_comparativo_tipo_multa: modoSafra ? `${parseInt(ano) + 1}05` : `${ano}12`,
                });
                break;
            case "reativo":
                //busca pra ver quantos anos vai listar no filtro
                this.props.getListaGraficoCustoReativoAnual();
                props.setFiltro({
                    ...props.filtro,
                    reativo_ano_safra: modoSafra,
                    competencia_inicial_reativo: modoSafra ? `${ano}06` : `${ano}01`,
                    competencia_final_reativo: modoSafra ? `${parseInt(ano) + 1}05` : `${ano}12`,
                });
                break;
            case "horario":
                //busca pra ver quantos anos vai listar no filtro
                this.props.getListaGraficoConsumoForaPonta();
                props.setFiltro({
                    ...props.filtro,
                    horario_ano_safra: modoSafra,
                    competencia_inicial_horario: modoSafra ? `${ano}06` : `${ano}01`,
                    competencia_final_horario: modoSafra ? `${parseInt(ano) + 1}05` : `${ano}12`,
                });
                break;
            default:
                break;
        }
    }

    getListaAnos(props) {
        //busca uma lista inicial para saber quais anos tem dados para serem exibidos
        //atualmente tem 3 formas de listas para adaptar a lista de anos do select

        const listaOriginal = props["lista_grafico_" + props.tabSelecionada] || [];
        const modoSafra = props.filtro[props.tabSelecionada + "_ano_safra"];
        let nomeCampo = modoSafra ? "ano_inicio_fim_safra" : "ano";

        //se for a aba de custo o nome do campo sempre é ano, mesmo no modo safra
        if (["custo", "consumo"].includes(props.tabSelecionada)) nomeCampo = "ano";

        const lista = [];
        //percorre a listaOriginal e vai incluindo o ano na lista
        for (const item of listaOriginal) {
            let nomeLista = item.anos ? "anos" : item.itens ? "itens" : null;
            //se tem uma lista de anos percorre cada uma e adiciona os anos que ainda não foram incluidos
            if (nomeLista) {
                // {mes:01, anos:[]} ou {mes:01, itens:[]}
                for (const ano of item[nomeLista]) {
                    //add o /proxAno de forma diferente na aba de custo e consumo, pq a antes de clicar em atualizar a lista vem apenas com o ano, e não ano/ano
                    let anoFormatado = ano[nomeCampo];
                    if (
                        modoSafra &&
                        ["custo", "consumo"].includes(props.tabSelecionada) &&
                        !String(ano[nomeCampo]).includes("/")
                    ) {
                        anoFormatado = ano[nomeCampo] + "/" + (ano[nomeCampo] + 1);
                    } else if (
                        !modoSafra &&
                        ["custo", "consumo"].includes(props.tabSelecionada) &&
                        String(ano[nomeCampo]).includes("/")
                    ) {
                        anoFormatado = String(ano[nomeCampo]).split("/")[0];
                    }

                    if (anoFormatado && !lista.includes(String(anoFormatado))) {
                        lista.push(String(anoFormatado));
                    }
                }
            } else {
                // item :
                // {
                //     "ano" ou "ano_periodo_safra": "2019",
                //     "valor_fatura": "158535.3400",
                //     "valor": 2951.37
                // }
                if (item[nomeCampo] && !lista.includes(item[nomeCampo])) lista.push(item[nomeCampo]);
            }
        }

        //ordena a lista de anos
        lista.sort((a, b) => {
            return modoSafra ? a.split("/")[0] - b.split("/")[0] : a - b;
        });
        // console.log(lista);
        return props.filtro.id_cliente && lista.length
            ? lista.map((ano) => ({
                  id: modoSafra ? ano.split("/")[0] : ano,
                  valor: ano,
              }))
            : [
                  {
                      id: `${new Date().getFullYear()}`,
                      valor: modoSafra
                          ? `${new Date().getFullYear()}/${new Date().getFullYear() + 1}`
                          : `${new Date().getFullYear()}`,
                  },
              ];
    }

    render() {
        if (this.props.pdf) {
            return (
                <DisplayPDF
                    closePdf={() => this.props.setArquivoPDF()}
                    pdfBase64={this.props.pdf}
                    nomePdf={this.props.nomePdf}
                />
            );
        }
        am4core.useTheme(am4themes_dark);

        return (
            <div>
                {this.state.aguardando || this.props.aguardando ? <Loading /> : null}
                {this.state.exibirModalHistorico && this.state.itemSelecionado && !this.props.pdf ? (
                    <ModalAlert
                        titulo="Histórico dos Contratos de Demanda"
                        handleClose={() =>
                            this.setState({ ...this.state, exibirModalHistorico: false, itemSelecionado: null })
                        }
                    >
                        <div style={{ maxHeight: 400, overflowY: "auto" }}>
                            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                <thead>
                                    <tr
                                        style={{
                                            position: "sticky",
                                            zIndex: 1,
                                            backgroundColor: "#586269",
                                            color: "#FFF",
                                            height: 40,
                                            top: 0,
                                        }}
                                    >
                                        <th
                                            style={{
                                                paddingInline: 50,
                                            }}
                                        >
                                            Descrição
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            Data/Hora
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            Usuário
                                        </th>
                                        <th
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            Contrato CUSD
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.contratoDemanda.historico.map((h) => (
                                        <tr key={h.id} style={{ borderBlock: "1px solid #ccc", paddingTop: 30 }}>
                                            <td style={{ paddingInline: 50 }}>{h.descricao}</td>
                                            <td style={{ textAlign: "center" }}>
                                                {DateFormat.formatarDataHoraSqlParaTela(h.datahora_inclusao)}
                                            </td>
                                            <td style={{ textAlign: "center" }}>{h.nome_usuario}</td>
                                            <td style={{ verticalAlign: "top" }}>
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: h.arquivo_cusd ? "start" : "center",
                                                        alignItems: "center",
                                                        minHeight: 40,
                                                    }}
                                                >
                                                    {h.arquivo_cusd ? (
                                                        <ButtonTable
                                                            style={{ margin: 0 }}
                                                            title="Visualizar PDF"
                                                            type={"primary"}
                                                            icon={"fas fa-file-pdf"}
                                                            disabled={!h.arquivo_cusd}
                                                            visible={true}
                                                            event={() => {
                                                                this.props.buscarPdf(h.id);
                                                                this.props.setArquivoPDF();
                                                            }}
                                                        />
                                                    ) : (
                                                        <div>-</div>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalAlert>
                ) : null}
                <Content>
                    <ul className="menu-analise-container">
                        {/*todos os graficos ates da pizza*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "custo" ? "-active" : ""}`}
                            data-toggle="tab"
                            onClick={() => this.listar("custo")}
                        >
                            Análise de Custo
                        </li>
                        {/*todos os graficos ates da pizza*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "consumo" ? "-active" : ""}`}
                            data-toggle="tab"
                            onClick={() => this.listar("consumo")}
                        >
                            Análise de Consumo
                        </li>
                        {/*gráficos de colunas com as tarifas*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "tarifa" ? "-active" : ""}`}
                            data-toggle="tab"
                            onClick={() => this.listar("tarifa")}
                        >
                            Análise de Tarifas
                        </li>
                        {/*Graficos pizza e colunas de horarios*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "horario" ? "-active" : ""}`}
                            style={{ display: this.validarExibicaoAba("horario") ? "" : "none" }}
                            onClick={() => this.listar("horario")}
                        >
                            Análise de Aproveitamento de Consumo Horário
                        </li>
                        {/*Demandas*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "demanda" ? "-active" : ""}`}
                            style={{ display: this.validarExibicaoAba("demanda") ? "" : "none" }}
                            onClick={() => this.listar("demanda")}
                        >
                            Análise de Demanda
                        </li>
                        {/*Consumo e gastos reativos*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "reativo" ? "-active" : ""}`}
                            style={{ display: this.validarExibicaoAba("reativo") ? "" : "none" }}
                            onClick={() => this.listar("reativo", true)}
                        >
                            Análise de Reativos
                        </li>
                        {/*Período Safra*/}
                        {/* <li
                            className={`menu-analise-item${this.props.tabSelecionada == "periodoSafra" ? "-active" : ""}`}
                            style={{ display: this.validarExibicaoAba("periodoSafra") ? "" : "none" }}
                            onClick={() => this.listar("periodoSafra")}
                        >
                            Análise do Período Safra
                        </li> */}
                        {/*MUltas*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "multas" ? "-active" : ""}`}
                            style={{ display: this.validarExibicaoAba("multas") ? "" : "none" }}
                            onClick={() => this.listar("multas", true)}
                        >
                            Análise de Multas
                        </li>
                        {/*Mini Geração*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "excedente" ? "-active" : ""}`}
                            tittle="(excedentes e comportamento de saldo)"
                            style={{
                                display: this.validarExibicaoAba("excedente") ? "" : "none",
                            }}
                            onClick={() => this.listar("excedente")}
                        >
                            Análise de Micro/Mini Geração
                        </li>
                        {/*Gestão de empenho*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "empenho" ? "-active" : ""}`}
                            style={{
                                display: this.validarExibicaoAba("empenho") ? "" : "none",
                            }}
                            onClick={() => this.listar("empenho")}
                        >
                            Análise/Gestão de Empenho
                        </li>
                        {/*Gestão de devolução*/}
                        <li
                            className={`menu-analise-item${this.props.tabSelecionada == "devolucao" ? "-active" : ""}`}
                            style={{
                                display: this.validarExibicaoAba("devolucao") ? "" : "none",
                            }}
                            onClick={() => this.listar("devolucao", true)}
                        >
                            Análise de Devoluções/Ressarcimentos
                        </li>
                    </ul>

                    <ContentCard>
                        <ContentCardHeader>
                            <Row>
                                {this.props.usuarioLogado && !this.props.usuarioLogado.acesso_cliente ? (
                                    <Select
                                        name="id_cliente"
                                        options={this.props.listaCliente}
                                        label="Cliente"
                                        cols={this.props.tabSelecionada === "demanda" ? "12 12 8 8" : "12 12 5 5"}
                                        placeholder="Selecione o Cliente"
                                        value={this.props.filtro.id_cliente}
                                        onChange={(value) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                id_cliente: value,
                                                id_unidade_consumidora: null,
                                                unidades_consumidoras: [],
                                                unidades_custo_anual: [],
                                                unidades_custo_anual_periodo_safra: [],
                                            });
                                            if (this.props.tabSelecionada === "empenho")
                                                this.props.getListaAnosEmpenho();
                                            if (this.props.tabSelecionada === "custo")
                                                this.props.getListaGraficoCustoAnual();
                                            if (this.props.tabSelecionada === "consumo")
                                                this.props.getListaGraficoConsumoAnual();
                                            if (this.props.tabSelecionada === "devolucao")
                                                this.props.getListaGraficoValorDevolvidoAnual();
                                            if (this.props.tabSelecionada === "multas")
                                                this.props.getListaGraficoCustoMultaAnual();
                                            if (this.props.tabSelecionada === "reativo")
                                                this.props.getListaGraficoCustoReativoAnual();
                                            if (this.props.tabSelecionada === "horario")
                                                this.props.getListaGraficoConsumoForaPonta();
                                        }}
                                    />
                                ) : null}
                                {this.props.tabSelecionada == "empenho" ? (
                                    <Select
                                        cols="12 12 5 5"
                                        name="ano_referencia"
                                        // VERIFICAR QUAIS ANOS PODEM SER
                                        options={this.props.listaAnosEmpenho}
                                        label="Ano de referência do Empenho"
                                        placeholder="Selecione o ano de referência do empenho"
                                        value={this.props.filtro.ano_referencia}
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(value) => {
                                            this.props.setFiltro({
                                                ...this.props.filtro,
                                                ano_referencia: value,
                                            });
                                        }}
                                    />
                                ) : (
                                    <>
                                        {this.props.tabSelecionada === "demanda" ? (
                                            <Select
                                                name="demanda_contrato_tipos"
                                                options={this.props.listaDemandaContratoTipo.filter((tipo) => {
                                                    const ucFiltradaPeloCliente =
                                                        this.props.listaUnidadeConsumidora.filter(
                                                            (uc) => uc.id_cliente == this.props.filtro.id_cliente
                                                        );
                                                    const condicaoForaPonta = tipo.id == 1;
                                                    const condicaoPonta =
                                                        tipo.id == 2 &&
                                                        !!ucFiltradaPeloCliente.find(
                                                            (uc) => uc.id_modalidade_tarifaria == 2
                                                        ); //alguma uc do cliente selecionado tem q ser horosazonal azul
                                                    const condicaoGeracao =
                                                        tipo.id == 3 &&
                                                        !!ucFiltradaPeloCliente.find(
                                                            (uc) =>
                                                                uc.geracao &&
                                                                [1, 4].includes(parseInt(uc.id_grupo_faturamento))
                                                        ); //alguma uc do cliente selecionado tem q ser geradora e grupo A
                                                    return condicaoForaPonta || condicaoPonta || condicaoGeracao;
                                                })}
                                                label="Tipo de Contrato de Demanda"
                                                cols="12 12 4 4"
                                                placeholder="Selecione o tipo de contrato de demanda"
                                                value={this.props.filtro.id_tipo_contrato_demanda}
                                                isClearable={false}
                                                isSearchable={false}
                                                onChange={(id_tipo_contrato_demanda) => {
                                                    this.props.setFiltro({
                                                        ...this.props.filtro,
                                                        id_tipo_contrato_demanda,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <Select
                                                    name="grupos_faturamento"
                                                    options={this.props.listaGrupoFaturamento}
                                                    label="Tipo de Faturamento"
                                                    isMulti={true}
                                                    cols="12 12 4 4"
                                                    placeholder="Selecione o tipo de faturamento"
                                                    value={this.props.filtro.grupos_faturamento}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    onChange={(grupos_faturamento) => {
                                                        this.props.setFiltro({
                                                            ...this.props.filtro,
                                                            grupos_faturamento,
                                                        });
                                                    }}
                                                />
                                                <Grid cols="12 12 3 3">
                                                    <Row style={{ paddingLeft: 12, paddingBottom: 12 }}>
                                                        <b>Tipo de Atividade</b>
                                                    </Row>
                                                    <Row style={{ display: "flex", alignItems: "center" }}>
                                                        <LabelAndCheckbox
                                                            gridDisplay="flex"
                                                            gridJustifyContent={
                                                                window.innerWidth < 400 ? "start" : "center"
                                                            }
                                                            gridAlignItems="end"
                                                            style={{ marginLeft: 12 }}
                                                            component={LabelAndCheckbox}
                                                            label="Irrigante"
                                                            cols="6 6 6 6"
                                                            value={this.props.filtro.irrigante}
                                                            onChange={() => {
                                                                this.props.setFiltro({
                                                                    ...this.props.filtro,
                                                                    irrigante: !this.props.filtro.irrigante,
                                                                });
                                                            }}
                                                        />
                                                        <LabelAndCheckbox
                                                            gridDisplay="flex"
                                                            gridJustifyContent={
                                                                window.innerWidth < 400 ? "start" : "center"
                                                            }
                                                            gridAlignItems="end"
                                                            style={{ marginLeft: 12 }}
                                                            component={LabelAndCheckbox}
                                                            label="Não-Irrigante"
                                                            cols="6 6 6 6"
                                                            value={this.props.filtro.naoIrrigante}
                                                            onChange={() => {
                                                                this.props.setFiltro({
                                                                    ...this.props.filtro,
                                                                    naoIrrigante: !this.props.filtro.naoIrrigante,
                                                                });
                                                            }}
                                                        />
                                                    </Row>
                                                </Grid>
                                            </>
                                        )}
                                        <Select
                                            options={this.listarUCsFiltradas()}
                                            label="Unidades Consumidoras"
                                            cols={
                                                [
                                                    "custo",
                                                    "consumo",
                                                    "devolucao",
                                                    "multas",
                                                    "reativo",
                                                    "horario",
                                                ].includes(this.props.tabSelecionada)
                                                    ? this.props.usuarioLogado.acesso_cliente
                                                        ? "12 12 6 6"
                                                        : "12 12 5 5"
                                                    : "12 12 10 10"
                                            }
                                            placeholder="Selecione as UCs"
                                            isMulti={true}
                                            value={this.props.filtro.unidades_consumidoras}
                                            onChange={(ucsSelecionadas) => {
                                                const ucQualquerDoFiltro = ucsSelecionadas[0] || {};
                                                const ucCompleta = this.props.listaUnidadeConsumidora.find(
                                                    (uc) => ucQualquerDoFiltro.value == uc.id
                                                ) || { id_cliente: null };
                                                this.props.setFiltro({
                                                    ...this.props.filtro,
                                                    unidades_consumidoras: [...ucsSelecionadas],
                                                    id_cliente: this.props.filtro.id_cliente || ucCompleta.id_cliente,
                                                });
                                            }}
                                        />
                                        {["custo", "consumo", "devolucao", "multas", "reativo", "horario"].includes(
                                            this.props.tabSelecionada
                                        ) ? (
                                            <>
                                                <Select
                                                    style={{ color: "#000" }}
                                                    options={this.getListaAnos(this.props)}
                                                    label="Ano"
                                                    cols={
                                                        this.props.usuarioLogado.acesso_cliente
                                                            ? "12 12 1 1"
                                                            : "12 12 2 2"
                                                    }
                                                    placeholder="Selecione o ano"
                                                    value={this.props.filtro["ano_" + this.props.tabSelecionada]}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    onChange={(value) => this.handleChangeFiltroAno(this.props, value)}
                                                />

                                                <Select
                                                    style={{ color: "#000" }}
                                                    options={[
                                                        "Ano Civil (Janeiro à Dezembro)",
                                                        "Ano Safra (Junho à Maio)",
                                                    ].map((item) => ({
                                                        id: item,
                                                        valor: item,
                                                    }))}
                                                    label="Período de Visualização"
                                                    cols="12 12 3 3"
                                                    placeholder="Selecione o ano"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    value={
                                                        this.props.filtro[this.props.tabSelecionada + "_ano_safra"]
                                                            ? "Ano Safra (Junho à Maio)"
                                                            : "Ano Civil (Janeiro à Dezembro)"
                                                    }
                                                    onChange={(value) => {
                                                        const modoSafra = value === "Ano Safra (Junho à Maio)";
                                                        this.handleChangePeriodoVisualizacao(this.props, modoSafra);
                                                    }}
                                                />
                                            </>
                                        ) : null}
                                    </>
                                )}
                                <Grid cols="12 12 2 2" style={{ paddingTop: 28, paddingRight: 15 }}>
                                    <Button
                                        text="Atualizar"
                                        type={"primary"}
                                        icon={"fa fa-sync"}
                                        event={() => {
                                            this.props.setAbas({
                                                custo: false,
                                                consumo: false,
                                                tarifa: false,
                                                horario: false,
                                                demanda: false,
                                                reativo: false,
                                                excedente: false,
                                                periodoSafra: false,
                                                multas: false,
                                                empenho: false,
                                                devolucao: false,
                                            });
                                            this.listar(this.props.tabSelecionada, true);
                                            const temHorosazonalAzulNoFiltro =
                                                !this.props.filtro.unidades_consumidoras.length ||
                                                this.props.listaUnidadeConsumidora.find((item) =>
                                                    this.props.filtro.unidades_consumidoras.find(
                                                        (uc) => item.id == uc.value && item.id_modalidade_tarifaria == 2
                                                    )
                                                );

                                            const contratoDemandaPontaNoFiltro =
                                                this.props.filtro.id_tipo_contrato_demanda == 2;
                                            if (!temHorosazonalAzulNoFiltro && contratoDemandaPontaNoFiltro) {
                                                setError(
                                                    "Unidades da modalidade Horosazonal Verde não possuem Contrato de Demanda Ponta"
                                                );
                                            }
                                            const temUcsGeradorasNoFiltro =
                                                !this.props.filtro.unidades_consumidoras.length ||
                                                this.props.listaUnidadeConsumidora.find((item) =>
                                                    this.props.filtro.unidades_consumidoras.find(
                                                        (uc) => item.id == uc.value && item.geracao
                                                    )
                                                );
                                            const contratoDemandaGeracaoNoFiltro =
                                                this.props.filtro.id_tipo_contrato_demanda == 3;

                                            if (!temUcsGeradorasNoFiltro && contratoDemandaGeracaoNoFiltro) {
                                                setError(
                                                    "Unidades sem geração não possuem Contrato de Demanda Geração"
                                                );
                                            }
                                        }}
                                    />
                                </Grid>
                                {this.props.tabSelecionada == "demanda" ? (
                                    <Grid cols="12 12 12 12">
                                        <Row>
                                            <Grid cols="12 12 4 3">
                                                <Button
                                                    text="Contrato de Demanda Vigente"
                                                    title="Contrato CUSD"
                                                    type={"primary"}
                                                    icon={"fa fa-check"}
                                                    event={() => {
                                                        if (!this.props.contratoDemanda) {
                                                            return confirmDialog(
                                                                "Selecione uma única UC e clique em atualizar.",
                                                                () => {}
                                                            );
                                                        }
                                                        if (
                                                            this.props.contratoDemanda &&
                                                            !this.props.contratoDemanda.arquivo_cusd
                                                        ) {
                                                            return confirmDialog(
                                                                "Nenhum arquivo de contrato(CUSD) encontrado.",
                                                                () => {}
                                                            );
                                                        }

                                                        if (
                                                            this.props.contratoDemanda &&
                                                            this.props.contratoDemanda.arquivo_cusd
                                                        ) {
                                                            this.props.buscaCusd(
                                                                this.props.contratoDemanda.id,
                                                                "contrato_cusd"
                                                            );
                                                            this.props.setArquivoPDF();
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid cols="12 12 4 3">
                                                <Button
                                                    text="Histórico"
                                                    title="Histórico"
                                                    type={"brown"}
                                                    icon={"fas fa-book"}
                                                    event={() => {
                                                        if (!this.props.contratoDemanda) {
                                                            return confirmDialog(
                                                                "Selecione uma única UC e clique em atualizar.",
                                                                () => {}
                                                            );
                                                        }

                                                        if (
                                                            this.props.contratoDemanda &&
                                                            !this.props.contratoDemanda.historico.length
                                                        ) {
                                                            return confirmDialog(
                                                                "Não há registros no histórico.",
                                                                () => {}
                                                            );
                                                        }

                                                        if (
                                                            this.props.contratoDemanda &&
                                                            this.props.contratoDemanda.historico.length
                                                        ) {
                                                            this.setState({
                                                                ...this.state,
                                                                exibirModalHistorico: true,
                                                                itemSelecionado: this.props.contratoDemanda,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Row>
                                    </Grid>
                                ) : null}
                            </Row>
                        </ContentCardHeader>
                    </ContentCard>

                    {this.props.tabSelecionada === "custo" ? (
                        <AnaliseCusto
                            state={this.state}
                            listaAnos={this.getListaAnos(this.props)}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : this.props.tabSelecionada === "consumo" ? (
                        <AnaliseConsumo
                            state={this.state}
                            listaAnos={this.getListaAnos(this.props)}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : this.props.tabSelecionada === "tarifa" ? (
                        <AnaliseTarifa
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : this.props.tabSelecionada === "horario" ? (
                        <AnaliseAproveitamentoConsumoHorario
                            buscarPropriedadeUcFiltrada={this.buscarPropriedadeUcFiltrada}
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : this.props.tabSelecionada === "demanda" ? (
                        <AnaliseDemanda
                            buscarPropriedadeUcFiltrada={this.buscarPropriedadeUcFiltrada}
                            state={this.state}
                            setAguardando={this.setAguardando}
                        />
                    ) : this.props.tabSelecionada === "reativo" ? (
                        <AnaliseReativo
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : this.props.tabSelecionada === "periodoSafra" ? (
                        <AnalisePeriodoSafra
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : this.props.tabSelecionada === "multas" ? (
                        <AnaliseMulta
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                        />
                    ) : this.props.tabSelecionada === "excedente" ? (
                        <AnaliseMicroMiniGeracao
                            state={this.state}
                            setAguardando={this.setAguardando}
                            listar={() => this.listar(this.props.tabSelecionada, true)}
                            buscarPropriedadeUcFiltrada={this.buscarPropriedadeUcFiltrada}
                        />
                    ) : this.props.tabSelecionada === "empenho" ? (
                        <AnaliseGestaoEmpenho
                            state={this.state}
                            listaAnos={this.getListaAnos(this.props)}
                            setAguardando={this.setAguardando}
                        />
                    ) : this.props.tabSelecionada === "devolucao" ? (
                        <AnaliseDevolucaoRessarcimento state={this.state} setAguardando={this.setAguardando} />
                    ) : null}
                </Content>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    pdf: state.displayPDF.pdf,
    nomePdf: state.displayPDF.nomePdf,
    abas: state.analise.abas,
    filtro: state.analise.filtro,
    modoTela: state.analise.modoTela,
    aguardando: state.analise.aguardando,
    listaCliente: state.analise.listaCliente,
    listaDemandaContratoTipo: state.analise.listaDemandaContratoTipo,
    listaAnosEmpenho: state.analise.listaAnosEmpenho,
    tabSelecionada: state.analise.tabSelecionada,
    contratoDemanda: state.analise.contratoDemanda,
    listaUnidadeConsumidora: state.analise.listaUnidadeConsumidora,
    lista_grafico_custo: state.analise.listaGraficoCustoAnual, //no select de ano usa a lista conforme a aba selecionada
    lista_grafico_consumo: state.analise.listaGraficoConsumoAnual, //no select de ano usa a lista conforme a aba selecionada
    lista_grafico_devolucao: state.analise.listaGraficoValorDevolvidoAnual, //no select de ano usa a lista conforme a aba selecionada
    lista_grafico_multas: state.analise.listaGraficoCustoMulta, //no select de ano usa a lista conforme a aba selecionada
    lista_grafico_reativo: state.analise.listaGraficoCustoReativoAnual, //no select de ano usa a lista conforme a aba selecionada
    lista_grafico_horario: state.analise.listaGraficoConsumoForaPonta, //no select de ano usa a lista conforme a aba selecionada
    listaGrupoFaturamento: state.cliente.listaGrupoFaturamento,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setFiltro,
            getDemanda,
            getListaCliente,
            getListaUnidadeConsumidora,
            getListaAnosEmpenho,
            buscaCusd,
            buscarPdf,
            setArquivoPDF,
            setTabSelecionada,
            setAbas,
            getListaGrupoFaturamento,
            getListaGraficoCustoAnual,
            getListaGraficoConsumoAnual,
            getListaDemandaContratoTipo,
            getListaGraficoValorDevolvidoAnual,
            getListaGraficoCustoMultaAnual,
            getListaGraficoCustoReativoAnual,
            getListaGraficoConsumoForaPonta,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Analise);
