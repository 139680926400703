import React, { useState } from "react";

export default (props) => {
    const [hover, setHover] = useState(false);
    const hoverStyle = hover && props.hoverStyle ? props.hoverStyle : {};
    return (
        <button
            onMouseEnter={() => setHover((prev) => !prev)}
            onMouseLeave={() => setHover((prev) => !prev)}
            title={props.title}
            type={props.submit ? "submit" : "button"}
            className={`btn  ${props.flex ? "" : "btn-block"} btn-lg  btn${props.solid ? "" : "-outline"}-${props.type} ${props.disabled ? 'disabled' : null}`}
            onClick={() => (props.disabled || !props.event ? () => {} : props.event())}
            disabled={props.disabled}
            style={{ ...props.style, ...hoverStyle }}
        >
           {props.icon ? <i className={props.icon || ""} style={{ marginRight: props.text ? 10 : 0 }}></i> : null}
            {props.text}
        </button>
    );
};

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
