import { useEffect } from "react";

export const useDisablePinchZoomEffect = () => {
    useEffect(() => {
        const disablePinchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };

        const disableGlobalZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        document.addEventListener("touchmove", disablePinchZoom, { passive: false });
        document.addEventListener("wheel", disableGlobalZoom, { passive: false });

        return () => {
            document.removeEventListener("touchmove", disablePinchZoom);
            document.removeEventListener("wheel", disableGlobalZoom);
        };
    }, []);
};
