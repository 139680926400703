import axios from "axios";
import consts from "../consts";
import { initialize } from "redux-form";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import confirmDialog from "../common/dialog/confirmDialog";
import { adicionarBloqueioUsuarioImplantacao } from "../utils/adicionarBloqueioUsuarioImplantacao";
import { getUcValidarUsuarioImplantacao } from "../cliente/clienteActions";

export function setModoTela(modo, registro = {}) {
    return {
        type: "GERACAO_INTEGRACAO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setAguardando(aguardando) {
    return {
        type: "GERACAO_INTEGRACAO_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function initForm(registro = {}) {
    return [initialize("geracaoIntegracaoForm", registro)];
}

export function setFiltro(filtro) {
    return {
        type: "GERACAO_INTEGRACAO_FILTRO",
        payload: filtro,
    };
}

// id|nome
// --+---------------------
//  1|BYD (ENERGY CLOUD)
//  2|EMA APP (Renovigi)
//  3|FRONIUS
//  4|FUSION SOLAR (Huawei)
//  5|GROWATT
//  6|ISOLARCLOUD (Sungrow)
//  7|RENOCLIENT (Renovigi)
//  8|SICESSOLAR
//  9|SOLAREDGE
// 10|SOLARMAN
// 11|SOLARVIEW
// 12|SOLIS
// 13|WEG

export function salvar(registro) {
    return (dispatch, getState) => {
        if (registro.id_geracao_integracao_tipo == 12) {
            if (String(registro.id_sistema_fotovoltaico).includes("/")) {
                const partesURL = registro.id_sistema_fotovoltaico.split("/");
                const ultimaPosicao = partesURL.length - 1;
                registro.id_sistema_fotovoltaico = String(partesURL[ultimaPosicao]).trim();
            }

            registro.chave_api = JSON.stringify({
                KeyId: registro.id_chave_api,
                KeySecret: registro.segredo_chave_api,
                APIURL: registro.url_api,
            });
        }
        if ([3, 11].includes(parseInt(registro.id_geracao_integracao_tipo)) && String(registro.id_sistema_fotovoltaico).includes("=")) {
            registro.id_sistema_fotovoltaico = registro.id_sistema_fotovoltaico.split("=")[1];
        }

        const registroFormatado = {
            ...registro,
            id_sistema_fotovoltaico:
                registro.id_geracao_integracao_tipo == 3
                    ? registro.id_sistema_fotovoltaico && `https://www.solarweb.com/PvSystems/PvSystem?pvSystemId=${registro.id_sistema_fotovoltaico}`
                    : registro.id_geracao_integracao_tipo == 12
                    ? registro.id_sistema_fotovoltaico && `https://www.soliscloud.com/#/station/stationDetails/generalSituation/${registro.id_sistema_fotovoltaico}`
                    : registro.id_geracao_integracao_tipo == 11
                    ? registro.id_sistema_fotovoltaico && `https://my.solarview.com.br/monitoramento?und=${registro.id_sistema_fotovoltaico}`
                    : [1, 4, 10, 13, 7, 6, 9].includes(parseInt(registro.id_geracao_integracao_tipo))
                    ? registro.id_sistema_fotovoltaico
                    : "",
        };

        if (!registro.id) {
            dispatch(setAguardando(true));
            axios
                .post(`${consts.API_URL}/geracaoIntegracao`, registro)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                    dispatch(setAguardando(false));
                })
                .catch((e) => {
                    setErroAPI(e);
                    dispatch(setAguardando(false));
                    dispatch(initForm(registroFormatado));
                });
        } else {
            confirmDialog("Deseja realmente alterar?", () => {
                dispatch(setAguardando(true));

                axios
                    .put(`${consts.API_URL}/geracaoIntegracao`, registro)
                    .then((resp) => {
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getLista());
                        dispatch(setModoTela("lista"));
                        dispatch(setAguardando(false));
                    })
                    .catch((e) => {
                        setErroAPI(e);
                        dispatch(setAguardando(false));
                        dispatch(initForm(registroFormatado));
                    });
            });
        }
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        confirmDialog("Deseja realmente excluir?", () => {
            axios
                .delete(`${consts.API_URL}/geracaoIntegracao?id=${registro.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            //busca lista de UC
            dispatch(setAguardando(true));
            await dispatch(getUcValidarUsuarioImplantacao());

            const idCliente = getState().geracaoIntegracao.filtro.id_cliente;
            const idUc = getState().geracaoIntegracao.filtro.id_unidade_consumidora;

            const resp = await axios.get(`${consts.API_URL}/geracaoIntegracao?id_usuario_logado=${usuarioLogado.id}&id_cliente=${idCliente}&id_unidade_consumidora=${idUc}`);
            const { listaUcValidarUsuarioImplantacao } = getState().cliente;
            const listaFiltrada = adicionarBloqueioUsuarioImplantacao(usuarioLogado, listaUcValidarUsuarioImplantacao, resp.data);

            dispatch({
                type: "GERACAO_INTEGRACAO_LISTADO",
                payload: { data: listaFiltrada },
            });
        } catch (ex) {
            setError("Não foi possível carregar a lista de integrações");
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getListaCliente() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};
            
            const request = axios.get(`${consts.API_URL}/cliente/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "GERACAO_INTEGRACAO_CLIENTE_SELECT_LISTADO",
                payload: request,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export function getListaGeracaoIntegracaoTipo() {
    const request = axios.get(`${consts.API_URL}/geracaoIntegracaoTipo/listarSelect`);
    return {
        type: "GERACAO_INTEGRACAO_TIPO_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "GERACAO_INTEGRACAO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
                payload: request,
            });
        } catch (e) {
            setErroAPI(e);
        }
    };
}
