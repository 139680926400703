import React, { useRef, useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { WhatsappShareButton } from "react-share";
import "./displayPDF.css";
import { PdfViewer } from "./PdfViewer";
import { uploadPDF } from "./displayPDFActions";
import { useDisablePinchZoomEffect } from "../hooks/useDisablePinchZoomEffect";

function DisplayPDFComponent({ pdfBase64, closePdf, nomePdf, uploadPDF, pdf_link, setDownloadRealizado }) {
    const extensao = (pdfBase64.split(",")[0] || "").split(/[^a-zA-Z]+/)[2];

    const [zoom, setZoom] = useState( window.innerWidth < 768 ? 0.7 : 2);

    const componentRef = useRef();

    useDisablePinchZoomEffect();

    useEffect(() => {
        const base64 = pdfBase64.split(",")[1];
        uploadPDF(base64, nomePdf);
        return () => {
            closePdf();
        };
    }, []);

    const handleDownload = async () => {
        const clearURL = pdfBase64.split(",")[1];
        const linkSource = "data:application/octet-stream;base64," + encodeURI(clearURL);
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        const existeExtensao = nomePdf.split(".").length > 1; //se tiver um ponto ou mais considera que o nome já veio com extensão
        downloadLink.download = `${existeExtensao ? nomePdf : nomePdf + "." + extensao}`;
        downloadLink.click();
        if (setDownloadRealizado) {
            await setDownloadRealizado();
        }
    };

    return (
        <div className="displayPDF">
            <header>
                <div className="closePDF">
                    <i
                        title="Fechar"
                        style={{ cursor: "pointer" }}
                        className="fas fa-times"
                        onClick={() => {
                            closePdf();
                        }}
                    />
                </div>
                <div className="buttonsPDF">
                    <div
                        title="Aumentar Zoom"
                        style={{ cursor: "pointer" }}
                        className="fas fa-search-plus"
                        onClick={() => {
                            setZoom((prev) => prev + 0.2);
                        }}
                    />
                    <div
                        title="Diminuir Zoom"
                        style={{ cursor: "pointer" }}
                        className="fas fa-search-minus"
                        onClick={() => {
                            setZoom((prev) => (prev > 0.6 ? prev - 0.2 : prev));
                        }}
                    />

                    <WhatsappShareButton
                        title={`*${nomePdf}*`}
                        separator={`\nLink de download: `}
                        onFocus={(e) => (e.target.style.outline = "none")}
                        url={pdf_link}
                        windowWidth={750}
                        windowHeight={700}
                        windowPosition="windowCenter"
                    >
                        <div title="Compartilhar pelo WhatsApp" style={{ cursor: "pointer" }} className="fab fa-whatsapp" />
                    </WhatsappShareButton>

                    <div title="Baixar" style={{ cursor: "pointer" }} className="fas fa-download" onClick={handleDownload} />
                    {/* <ReactToPrint
						trigger={() => <i title="Imprimir" style={{ cursor: "pointer" }} className="fas fa-print" />}
						content={() => componentRef.current}
					/> */}
                </div>
            </header>
            {pdfBase64.includes("data:image") ? (
                <div style={{ backgroundColor: "#ccc", width: "100%", maxWidth: "100%", display: "flex", justifyContent: zoom > 3.5 ? "left" : "center", overflow: "scroll" }}>
                    <img ref={componentRef} src={pdfBase64} alt="" width={`${60 + 10 * (1 + zoom)}%`} />
                </div>
            ) : pdfBase64.includes("data:application/") && extensao === "pdf" ? (
                <PdfViewer ref={componentRef} pdfBase64={pdfBase64} zoom={zoom} setZoom={setZoom} />
            ) : (
                <h6 style={{ textAlign: "center", paddingBlock: 50 }}>O formato do arquivo não permite visualização(.{extensao}), realize o download do arquivo.</h6>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({ pdf_link: state.displayPDF.pdf_link });

const mapDispatchToProps = (dispatch) => bindActionCreators({ uploadPDF }, dispatch);
export const DisplayPDF = connect(mapStateToProps, mapDispatchToProps)(DisplayPDFComponent);
