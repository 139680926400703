import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";
import confirmDialog from "../common/dialog/confirmDialog";

export function setModoTela(modo, registro = {}) {
    return {
        type: "DOCUMENTO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setAguardando(aguardando) {
    return {
        type: "DOCUMENTO_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function initForm(registro = {}) {
    return [initialize("documentoForm", registro)];
}

export function setFiltro(filtro) {
    return {
        type: "DOCUMENTO_FILTRO",
        payload: filtro,
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        try {

			const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const idCliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : getState().documento.filtro.id_cliente;
            const idUC = getState().documento.filtro.id_unidade_consumidora;
            const idDocumentoTipo = getState().documento.filtro.id_documento_tipo;

            const resp = await axios.get(`${consts.API_URL}/documento?id_usuario_logado=${usuarioLogado.id}&id_cliente=${idCliente}&id_unidade_consumidora=${idUC}&id_documento_tipo=${idDocumentoTipo}`);
            dispatch({
                type: "DOCUMENTO_LISTADO",
                payload: resp,
            });

        } catch (e) {
            setErroAPI(e);
        }
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        if (!registro.id) {
            dispatch(setAguardando(true));

            axios
                .post(`${consts.API_URL}/documento`, {
                    ...registro,
                    vigencia_inicio:
                        (registro.vigencia_inicio || "").split("/")[0] &&
                        ((registro.vigencia_inicio || "").split("/")[0].match(/\d+/g) || []).join("").trim().length == 2 &&
                        (registro.vigencia_inicio || "").split("/")[1] &&
                        ((registro.vigencia_inicio || "").split("/")[1].match(/\d+/g) || []).join("").trim().length == 4
                            ? `${(registro.vigencia_inicio || "").split("/")[1]}${(registro.vigencia_inicio || "").split("/")[0]}`
                            : null,
                    vigencia_fim:
                        (registro.vigencia_fim || "").split("/")[0] &&
                        ((registro.vigencia_fim || "").split("/")[0].match(/\d+/g) || []).join("").trim().length == 2 &&
                        (registro.vigencia_fim || "").split("/")[1] &&
                        ((registro.vigencia_fim || "").split("/")[1].match(/\d+/g) || []).join("").trim().length == 4
                            ? `${(registro.vigencia_fim || "").split("/")[1]}${(registro.vigencia_fim || "").split("/")[0]}`
                            : null,
                    id_usuario_inclusao: getState().auth.usuario.id,
                    id_usuario_alteracao: getState().auth.usuario.id,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                    dispatch(setAguardando(false));
                })
                .catch((e) => {
                    setErroAPI(e);
                    dispatch(setAguardando(false));
                });
        } else {
            confirmDialog("Deseja realmente alterar?", () => {
                dispatch(setAguardando(true));

                axios
                    .put(`${consts.API_URL}/documento`, {
                        ...registro,
                        vigencia_inicio:
                            (registro.vigencia_inicio || "").split("/")[0] &&
                            ((registro.vigencia_inicio || "").split("/")[0].match(/\d+/g) || []).join("").trim().length == 2 &&
                            (registro.vigencia_inicio || "").split("/")[1] &&
                            ((registro.vigencia_inicio || "").split("/")[1].match(/\d+/g) || []).join("").trim().length == 4
                                ? `${(registro.vigencia_inicio || "").split("/")[1]}${(registro.vigencia_inicio || "").split("/")[0]}`
                                : null,
                        vigencia_fim:
                            (registro.vigencia_fim || "").split("/")[0] &&
                            ((registro.vigencia_fim || "").split("/")[0].match(/\d+/g) || []).join("").trim().length == 2 &&
                            (registro.vigencia_fim || "").split("/")[1] &&
                            ((registro.vigencia_fim || "").split("/")[1].match(/\d+/g) || []).join("").trim().length == 4
                                ? `${(registro.vigencia_fim || "").split("/")[1]}${(registro.vigencia_fim || "").split("/")[0]}`
                                : null,
                        id_usuario_alteracao: getState().auth.usuario.id,
                    })
                    .then((resp) => {
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getLista());
                        dispatch(setModoTela("lista"));
                        dispatch(setAguardando(false));
                    })
                    .catch((e) => {
                        setErroAPI(e);
                        dispatch(setAguardando(false));
                    });
            });
        }
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        confirmDialog("Deseja realmente excluir?", () => {
            axios
                .delete(`${consts.API_URL}/documento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function getListaCliente() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};
            
            const request = axios.get(`${consts.API_URL}/cliente/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "DOCUMENTO_CLIENTE_SELECT_LISTADO",
                payload: request,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export function getListaUnidadeConsumidora() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "DOCUMENTO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
                payload: request,
            });
        } catch (e) {
            setErroAPI(e);
        }
    };
}

export function getListaDocumentoTipo() {
    const request = axios.get(`${consts.API_URL}/documentoTipo/listarSelect?id_segmento=2`); //id_segmento 2 é de cliente
    return {
        type: "DOCUMENTO_TIPO_SELECT_LISTADO",
        payload: request,
    };
}
