const INITIAL_STATE = {
    registro: {},
    modoTela: "lista",
    lista: [],
    listaUsuario: [], //usado nos select
    listaCliente: [],
    totalUsuarios: 0,
    logados: 0,
    acessos_semana: 0,
    listaLogados: [],
    aguardando: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "USUARIO_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };
        case "USUARIO_LISTADO":
            return { ...state, modoTela: "lista", lista: action.payload.data };

        case "TOTAL_USUARIOS":
            return {
                ...state,
                totalUsuarios: action.payload.data.total_usuarios || 0,
                logados: action.payload.data.logados || 0,
                acessos_semana: action.payload.data.acessos_semana || 0,
            };

        case "LOGADOS_USUARIOS":
            return {
                ...state,
                listaLogados: action.payload.data,
            };

        case "USUARIO_SELECT_LISTADO":
            return {
                ...state,
                listaUsuario: action.payload.data,
            };

        case "USUARIO_SET_AGUARDANDO":
            return {
                ...state,
                aguardando: action.payload,
            };

        case "USUARIO_CLIENTE_LISTADO":
            const lista = [{ id: "admin", valor: "Administrativo" }];
            for (const cli of action.payload.data) {
                lista.push(cli);
            }
            return { ...state, listaCliente: lista };

        default:
            return state;
    }
};
