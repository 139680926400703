import DateFormat from "../common/dateFormat/DateFormat";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";
const INITIAL_STATE = {
    registro: {},
    aguardando: false,
    modoTela: "lista",
    lista: [],
    modulos: [],
    listaCliente: [],
    listaUnidadeConsumidora: [],
    filtro: {
        id_cliente: null,
        id_unidade_consumidora: null,
        id_agrupador: null, //id_geracao_integracao ou id_unidade_consumidora
        agrupar_por_uc: true,
        id_geracao_integracao_tipo: null,
    },
    listaGraficoTodasIntegracoesAgrupadasPorHora: [],
    listaGraficoEnergiaDia: [],
    listaGraficoEnergiaDiasMes: [],
    listaGraficoEnergiaMesesAno: [],
    dataGrafico: DateFormat.getDataAtual().replace(/\//g, "-"),
    AMARELA: "AMARELA",
    VERMELHA: "VERMELHA",
    VERDE: "VERDE",
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GERACAO_LEITURA_MODO_TELA":
            return {
                ...state,
                modoTela: action.payload.modo,
                registro: action.payload.registro,
            };

        case "GERACAO_LEITURA_SET_STATUS_VISUALIZADO":
            const idOuListaIds = action.payload instanceof Array ? action.payload : [action.payload];
            //Modifica a lista atual para não precisar buscar novamente a cada clique nos botões vermelhos
            const lista = state.lista.map((item) => {
                const itemRetorno = { ...item };
                if (idOuListaIds.includes(item.id)) {
                    itemRetorno.status_visualizado = true;
                }
                return itemRetorno;
            });
            return { ...state, lista: ordenarListaLeituras(lista, state) };

        case "GERACAO_LEITURA_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        case "GERACAO_LEITURA_SET_DATA_GRAFICO":
            return { ...state, dataGrafico: action.payload };

        case "GERACAO_LEITURA_FILTRO":
            return { ...state, filtro: action.payload };

        case "GERACAO_LEITURA_LISTA_GRAFICO_TODAS_INTEGRACOES_AGRUPADAS_POR_HORA":
            return {
                ...state,
                listaGraficoTodasIntegracoesAgrupadasPorHora: inserirLeiturasZeradasGraficos({
                    dataGrafico: action.payload.dataGrafico,
                    leituras: action.payload.data,
                    nomeAtributoValor: "potencia_instantanea_media_hora",
                    minutosIntervalo: parseInt(action.payload.intervaloTempo),
                }),
            };
        case "GERACAO_LEITURA_LISTA_GRAFICO_ENERGIA_DIA":
            return {
                ...state,
                listaGraficoEnergiaDia: inserirLeiturasZeradasGraficos({
                    dataGrafico: state.dataGrafico,
                    leituras: action.payload.data,
                    nomeAtributoValor: "potencia_instantanea",
                    minutosIntervalo: 5,
                }),
            };

        case "GERACAO_LEITURA_LISTA_GRAFICO_ENERGIA_DIAS_MES":
            return {
                ...state,
                listaGraficoEnergiaDiasMes: action.payload.data,
            };

        case "GERACAO_LEITURA_LISTA_GRAFICO_ENERGIA_MESES_ANO":
            return {
                ...state,
                listaGraficoEnergiaMesesAno: action.payload.data,
            };

        case "GERACAO_LEITURA_LISTADO":
            action.payload.data.forEach((item, index, array) => {
                const modulosPotenciaTotal = parseFloat(item.modulos_potencia_total) >= 0 ? parseFloat(item.modulos_potencia_total) : 0;
                const inversorPotenciaTotal = parseFloat(item.inversor_potencia_total) >= 0 ? parseFloat(item.inversor_potencia_total) : 0;

                //se for zero considera 1
                const potenciaInstalada = Math.min(modulosPotenciaTotal, inversorPotenciaTotal);
                const potenciaInstantanea = parseFloat(item.potencia_instantanea) >= 0 ? parseFloat(item.potencia_instantanea) : 0;

                array[index].desempenho_potencia_instantanea = potenciaInstantanea && potenciaInstalada ? Number((potenciaInstantanea * 100) / potenciaInstalada).toFixed(1) : 0;
                array[index].desempenho_energia_dia =
                    parseFloat(item.prod_max_esperada_dia_atual) && parseFloat(item.energia_dia)
                        ? (parseFloat(item.energia_dia) / parseFloat(item.prod_max_esperada_dia_atual)) * 100
                        : 0;
                array[index].desempenho_energia_mes =
                    parseFloat(item.prod_max_esperada_mes_atual) && parseFloat(item.energia_mes)
                        ? (parseFloat(item.energia_mes) / parseFloat(item.prod_max_esperada_mes_atual)) * 100
                        : 0;
                array[index].desempenho_energia_ano =
                    parseFloat(item.prod_max_esperada_ano_atual) && parseFloat(item.energia_ano)
                        ? (parseFloat(item.energia_ano) / parseFloat(item.prod_max_esperada_ano_atual)) * 100
                        : 0;
            });
            return {
                ...state,
                lista: ordenarListaLeituras(action.payload.data, state),
            };

        case "GERACAO_LEITURA_MODULOS_LISTADO":
            return {
                ...state,
                modulos: action.payload.data,
            };

        case "GERACAO_LEITURA_CLIENTE_SELECT_LISTADO":
            return {
                ...state,
                listaCliente: action.payload.data,
            };

        case "GERACAO_LEITURA_UNIDADE_CONSUMIDORA_SELECT_LISTADO":
            return {
                ...state,
                listaUnidadeConsumidora: filtrarUcPorDataDesligamento(action.payload.data),
            };

        default:
            return state;
    }
};

function inserirLeiturasZeradasGraficos({ dataGrafico, leituras, nomeAtributoValor, minutosIntervalo }) {
    const tempoMs = 1000 * 60 * minutosIntervalo;
    const ano = dataGrafico.substring(0, 4);
    const mes = parseInt(dataGrafico.substring(5, 7)) - 1;
    const dia = parseInt(dataGrafico.substring(8, 10));
    const primeiroHorarioDoDia = new Date(ano, mes, dia, 0, 0, 0, 0);
    const ultimoHorarioDoDia = new Date(ano, mes, dia, 23, 59, 59, 0);

    //Se a lista vier vazia cria uma nova lista com leituras fake de 5 em 5 min e potencia_instantanea zerada
    if (!leituras.length) {
        const listaTodosVazios = [
            {
                data_hora: primeiroHorarioDoDia.toISOString(),
                [nomeAtributoValor]: "0",
            },
        ];

        //enquanto estiver maior que 00:00h e menor que 23:59h vai adicionando
        while (new Date(listaTodosVazios[listaTodosVazios.length - 1].data_hora).getTime() + tempoMs < ultimoHorarioDoDia.getTime()) {
            const msNovaData = new Date(listaTodosVazios[listaTodosVazios.length - 1].data_hora).getTime() + tempoMs;
            const dataNovaLeitura = new Date(msNovaData);

            const novaLeitura = {
                data_hora: dataNovaLeitura.toISOString(),
                [nomeAtributoValor]: "0",
            };
            listaTodosVazios.push(novaLeitura);
        }
        return listaTodosVazios;
    } else {
        //se a lista não vier vazia, adiciona leituras zeradas (fake) antes e depois da lista
        const listaComVazios = [...leituras];

        //enquanto for maior que 00:00h e menor q o primeira leitura válida adiciona leituras zeradas (fake)
        while (new Date(listaComVazios[0].data_hora).getTime() - tempoMs > primeiroHorarioDoDia.getTime()) {
            const novaLeitura = {
                data_hora: new Date(new Date(listaComVazios[0].data_hora).getTime() - tempoMs).toISOString(),
                [nomeAtributoValor]: "0",
            };
            listaComVazios.unshift(novaLeitura);
        }
        //enquanto for maior que a última leitura válida da lista e menor q 23:59h adiciona leituras zeradas (fake)
        while (new Date(listaComVazios[listaComVazios.length - 1].data_hora).getTime() + tempoMs < ultimoHorarioDoDia.getTime()) {
            const novaLeitura = {
                data_hora: new Date(new Date(listaComVazios[listaComVazios.length - 1].data_hora).getTime() + tempoMs).toISOString(),
                [nomeAtributoValor]: "0",
            };
            listaComVazios.push(novaLeitura);
        }
        return listaComVazios;
    }
}

function validarStatusDetalhesSemana(item, indice, lista, telaDetalhesAtiva) {
    //VALIDA STATUS E HORÁRIO DA ÚLTIMA LEITURA

    if (telaDetalhesAtiva) {
        if (indice === 0) {
            //compara com a datahora atual
            return item.status && new Date().setHours(new Date().getHours() - 3) < new Date(item.data_hora) && item.potencia_instantanea > 0;
        } else {
            //compara com o anterior
            return item.status && new Date(lista[indice - 1].data_hora).getHours() - new Date(item.data_hora).getHours() < 3 && item.potencia_instantanea > 0;
        }
    } else {
        const todosInversoresOk = item.resumo_equipamento.every((inversor) => inversor.status && parseFloat(inversor.potencia_instantanea || 0) > 0);
        return item.status && validarLeituraUltimasTresHoras(item) && parseFloat(item.potencia_instantanea) > 0 && todosInversoresOk;
    }
}

function validarLeituraUltimasTresHoras(item) {
    const horarioTresHorasAtras = new Date();
    horarioTresHorasAtras.setHours(horarioTresHorasAtras.getHours() - 3);
    return new Date(item.data_hora).getTime() > horarioTresHorasAtras.getTime();
}

function ordenarListaLeituras(lista, state) {
    //SÓ LISTA O STATUS AMARELO E VERMELHO NO TOPO SE NÃO ESTIVER NA TELA DETALHES -------------------------
    const telaDetalhesAtiva = !!state.filtro.id_agrupador;

    //ADICIONA PROPRIEDADE statusVerificado, VALIDANDO ALÉM DO STATUS DO BANCO, O PERÍODO SEM GERAÇÃO DE ENERGIA E A POTÊNCIA INSTANTÂNEA
    const listaStatusVerificado = lista.map((item, i) => {
        const dataStringLeitura = DateFormat.formatarDataTelaParaParametro(item.data_hora);
        const dataStrintHoje = DateFormat.getDataAtual().replace(/\//g, "-");
        // console.log({ dataStringLeitura, dataStrintHoje });
        // if (dataStringLeitura !== dataStrintHoje) {
        //     console.log(item);
        // }
        return {
            ...item,
            energia_dia: dataStringLeitura === dataStrintHoje ? item.energia_dia : 0,
            desempenho_energia_dia: dataStringLeitura === dataStrintHoje ? item.desempenho_energia_dia : 0,
            nuvemOuChuva: parseInt(item.nebulosidade) > 0 || parseInt(item.quantidade_precipitacao) > 0, //se tiver nuvem ou chuca só emite alerta sonoro entre 9h e 17h
            statusVerificado: validarStatusDetalhesSemana(item, i, lista, telaDetalhesAtiva),
        };
    });

    //MONTA UMA LISTA COM TODOS CONSIDERADOS STATUS AMARELO
    const listaStatusAmarelo = listaStatusVerificado
        .filter((item) => validarInversoresStatusAmarelo(item))
        .map((item) => {
            const hoje = new Date();
            const doisDiasAtras = new Date(hoje.setHours(hoje.getHours() - 48));
            const amarelo_nos_ultimos_2_dias = !!item.resumo_equipamento.find((inversor) => {
                const lidoAMaisDeDoisDias = inversor.data_hora && new Date(inversor.data_hora) < doisDiasAtras;
                return lidoAMaisDeDoisDias;
            });

            // deixa o aviso de funcionando parcialmente, se realmente tem inversor com data inferior a 2 dias, ou se alguma das leituras esta 2 dias sem producao
            let algumaLeituraDoisDiasSemProducao = validarLeiturasDoisDiasSemProducao(item);

            // Retorna o novo objeto com os ajustes
            return {
                ...item,
                corStatus: state.AMARELA,
                amarelo_nos_ultimos_2_dias: amarelo_nos_ultimos_2_dias || algumaLeituraDoisDiasSemProducao,
                energia_zerada_ultimos_2_dias: algumaLeituraDoisDiasSemProducao,
            };
        });

    //ORDENA OS AMARELOS PARA OS QUE ESTÃO EM ALERTA APARECER PRIMEIRO
    listaStatusAmarelo.sort((a, b) => a.status_visualizado - b.status_visualizado);

    //MONTA UMA LISTA COM TODOS CONSIDERADOS STATUS VERMELHO
    const listaStatusVermelho = listaStatusVerificado
        .filter((item) => !item.statusVerificado && !listaStatusAmarelo.find((amarelo) => amarelo.id == item.id))
        .map((item) => ({
            ...item,
            corStatus: state.VERMELHA,
            energia_zerada_ultimos_2_dias: validarLeiturasDoisDiasSemProducao(item),
        }));

    //ORDENA OS VERMELHOS PARA OS QUE ESTÃO EM ALERTA APARECER PRIMEIRO
    listaStatusVermelho.sort((a, b) => a.status_visualizado - b.status_visualizado);

    //MONTA UMA LISTA COM TODOS CONSIDERADOS STATUS VERDE
    const listaStatusVerde = listaStatusVerificado
        .filter((item) => item.statusVerificado)
        .map((item) => ({
            ...item,
            corStatus: state.VERDE,
            energia_zerada_ultimos_2_dias: validarLeiturasDoisDiasSemProducao(item),
        }));

    //UNIFICA AS DUAS LISTAS, JUNTAMENTE COM OS STATUS VERDE
    const listaOrdenadaCores = [...listaStatusAmarelo, ...listaStatusVermelho, ...listaStatusVerde];

    //CONDICIONA A ORDENAÇÃO DE ACORDO COM O MODO DE TELA ATIVO
    return telaDetalhesAtiva ? listaOrdenadaCores.sort((a, b) => b.id - a.id) : listaOrdenadaCores;
    //--------------------------------------------------------------------------------------------
}

//se ta agrupado por uc, vem array de bool, senao vem só bool, ai precisa converter pra bool
function validarLeiturasDoisDiasSemProducao(item) {
    // Verifica se energia_zerada_ultimos_2_dias é um array
    if (Array.isArray(item.energia_zerada_ultimos_2_dias)) {
        return item.energia_zerada_ultimos_2_dias.some((zerada) => zerada);
    } else if (typeof item.energia_zerada_ultimos_2_dias === "boolean") {
        // Se for booleano, utiliza o valor diretamente
        return item.energia_zerada_ultimos_2_dias;
    } else {
        return false;
    }
}

function validarInversoresStatusAmarelo(leitura) {
    //SE A LEITURA FOR DE MAIS DE 3 HORAS ATRÁS FICA VERMELHO E IGNORA A VALIDAÇÃO DOS AMARELOS
    if (!validarLeituraUltimasTresHoras(leitura)) return;

    //SE PASSAR NA VALIDAÇÃO DE TEMPO DA LEITURA IRÁ VALIDAR O STATUS DOS INVERSORES
    //SE TIVER PELO MENOS UM ONLINE E UM OFFLINE CONSIDERA AMARELO
    if (leitura.resumo_equipamento.length > 1) {
        const temAlgumOnline = leitura.resumo_equipamento.some((item) => item.status && parseFloat(item.potencia_instantanea || 0) > 0);
        const temAlgumOffline = leitura.resumo_equipamento.some((item) => !item.status || !parseFloat(item.potencia_instantanea));
        return temAlgumOnline && temAlgumOffline;
    }
    //SE TIVER APENAS UM INVERSOR NUNCA VAI SER AMARELO, ENTÃO IGNORA O REGISTRO
    //POIS ELE SÓ FICARA VERDE OU VERMELHO
    return false;
}
