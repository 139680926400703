import React from "react";
import Menu from "./menu";
import imagemLogo from "../../assets/images/logo_sem_fundo.png";
import imagemLogoNome from "../../assets/images/logo_nome_sem_fundo.png";
import imagemLogoEficience from "../../assets/images/by_eficience.png";
import gorroNatal from "../../assets/images/christmas hat.gif";
import felizAnoNovo from "../../assets/images/felizAnoNovo.png";
import brinde from "../../assets/images/brinde.png";

export default (props) => {
    const dataAtual = new Date();
    const isNatal = dataAtual.getMonth() == 11 && dataAtual.getDate() < 26;
    const isAnoNovo = (dataAtual.getMonth() == 11 && dataAtual.getDate() >= 26) || (dataAtual.getMonth() == 0 && dataAtual.getDate() <= 10);

    return (
        <aside className="main-sidebar elevation-4 sidebar-light-indigo" style={{ backgroundColor: "#BA0000" }}>
            <a href="../../" id="logo-gradios-sidebar" className="brand-link navbar-light" style={{ backgroundColor: "#ffffff" }}>
                {isNatal ? (
                    <img
                        src={gorroNatal}
                        style={{
                            maxWidth: 40,
                            position: "absolute",
                            top: -2,
                            left: 13,
                            rotate: "-12deg",
                        }}
                        alt="Feliz Natal!"
                    />
                ) : null}
                {isAnoNovo ? (
                    <img
                        src={brinde}
                        style={{
                            maxWidth: 40,
                            position: "absolute",
                            top: 0,
                            left: 32,
                            rotate: "-2deg",
                            transform:'scale(0.8)'
                        }}
                        alt="Boas Festas!"
                    />
                ) : null}
                <img src={imagemLogo} alt="Gradios Logo" className="brand-image" />
                <span className="brand-text font-weight-light">
                    <img src={imagemLogoNome} className="brand-image" style={{ marginLeft: 0 }} alt="Gradios" />
                </span>
            </a>
            <div className="sidebar" style={{overflowX:'hidden'}}>
                <Menu sessao={props.sessao} />
            </div>
            <div style={isAnoNovo ? { display: "flex", flexDirection: "column", marginTop: -160, alignItems: "center" } : { marginTop: -80, textAlign: "center" }}>
                {isAnoNovo ? <img src={felizAnoNovo} style={{ width: "85%", maxWidth: 80, objectFit: "contain", filter:'brightness(3.4)' }} alt="Boas Festas!" /> : null}
                <img src={imagemLogoEficience} alt="Eficience" className="brand-image" style={{ width: "85%", maxWidth: 80, objectFit: "contain" }} />
            </div>
        </aside>
    );
};
