import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";
import confirmDialog from "../common/dialog/confirmDialog";
import { setArquivoPDF } from "../displayPDF/displayPDFActions";
import { getLista as getListaClienteComUC } from "../cliente/clienteActions";
import sleep from "../utils/sleep";
import { base64ToBlob } from "../utils/base64ToBlob";
import { combinarPdfRetornarUrl } from "../utils/combinarPdfRetornarUrl";

export function setModoTela(modo, registro = {}) {
    return {
        type: "FATURA_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}
export function initForm(registro = {}) {
    return [initialize("faturaForm", registro)];
}

export function setFiltro(filtro) {
    return {
        type: "FATURA_FILTRO",
        payload: filtro,
    };
}

export function setAguardando(aguardando) {
    return {
        type: "FATURA_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function getTotalFaturasImportadas() {
    return (dispatch, getState) => {
        axios
            .get(`${consts.API_URL}/fatura/totalFaturasImportadas`)
            .then((resp) => {
                dispatch({
                    type: "TOTAL_FATURAS_IMPORTADAS",
                    payload: resp.data.total_faturas_importadas,
                });
            })
            .catch((ex) => {});
    };
}

export function resetarFiltro() {
    return (dispatch, getState) => {
        dispatch({
            type: "FATURA_RESETAR_FILTRO",
            payload: null,
        });
    };
}

function filtrarIdsUnidadesConsumidoras(state) {
    //se selecionou alguma UC manda a lista de ids das UCs selecionadas
    let ids_unidades_consumidoras = state.filtro.unidades_consumidoras.map((item) => item.value);
    if (ids_unidades_consumidoras.length) {
        return ids_unidades_consumidoras;
    }

    //se nenhum filtro tiver sido alterado manda nulo para trazer todas UCs
    if (!state.filtro.id_grupo_faturamento && state.filtro.irrigante && state.filtro.naoIrrigante) return null;

    //se não tiver nenhuma UC selecionada, filtra a lista de UC original de acordo com os filtros selecionados
    const listaFiltrada = state.listaUnidadeConsumidora
        .filter(
            (item) =>
                (!state.filtro.id_cliente || item.id_cliente == state.filtro.id_cliente) &&
                (!state.filtro.id_grupo_faturamento ||
                    item.id_grupo_faturamento == state.filtro.id_grupo_faturamento) &&
                ((state.filtro.irrigante && item.irrigante) || (state.filtro.naoIrrigante && !item.irrigante))
        )
        .map((item) => item.id);

    return listaFiltrada.length ? listaFiltrada : [0];
}

//usada para que após importar uma fatura e carregar os itens, atualize os dados da fatura carregada no formulário
export function getFatura(idFatura) {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado || getState().fatura.modoTela !== "cadastro" || !idFatura) return {};

            dispatch(setAguardando(true));

            const resposta = await axios.get(
                `${consts.API_URL}/fatura?id_usuario_logado=${usuarioLogado.id}&id=${idFatura}`
            );
            const fatura = resposta.data[0];
            const faturaFormatada = {
                ...fatura,
                competencia: `${String(fatura.competencia).substring(4, 6)}/${String(fatura.competencia).substring(
                    0,
                    4
                )}`,
                valor_fatura: String(fatura.valor_fatura).replace(".", ","),
            };
            dispatch(initForm(faturaFormatada));
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getFaturasVencidasUltimos4Meses() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const idCliente = usuarioLogado.acesso_cliente
                ? usuarioLogado.id_cliente
                : getState().fatura.filtro.id_cliente;
            const filtroUCMultiplo = filtrarIdsUnidadesConsumidoras(getState().fatura);

            dispatch(setAguardando(true));

            const resposta = await axios.get(
                `${consts.API_URL}/fatura/listarFaturasVencidasUltimos4Meses?id_usuario_logado=${
                    usuarioLogado.id
                }&id_cliente=${idCliente}&ids_unidades_consumidoras=${encodeURIComponent(filtroUCMultiplo)}`
            );
            dispatch({
                type: "FATURAS_VENCIDAS_LISTADO",
                payload: resposta,
            });
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getLista() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const listaUCsFiltradas = [];
            let listaClienteComUC = [];
            let listaUnidadeConsumidora = [];
            const filtrarUsuarioImplantacao = usuarioLogado.usuario_implantacao;

            dispatch(setAguardando(true));

            await Promise.all([
                dispatch(getListaCliente()),
                dispatch(getListaProdutoTipo()),
                dispatch(getListaClienteComUC()),
                dispatch(getListaConcessionaria()),
                dispatch(getListaUnidadeConsumidora()),
            ]);
            //Cria uma lista de com os clientes cujo o usuario logado é o implantador
            //cada cliente tem apenas as UCs do usuário implantador
            listaClienteComUC = filtrarClientesUsuarioImplantacao(usuarioLogado, getState().cliente.lista);

            //junta todas UCs que o usuário é o implantador em uma única lista
            listaClienteComUC.forEach((cliente) => {
                // cliente.itens.forEach((uc) => {
                listaUCsFiltradas.push(...cliente.itens);
                // });
            });

            //se o usuario logado for implantador filtra as UCs do Select para listar apenas as que o usuário logado é o implantador
            listaUnidadeConsumidora = getState().fatura.listaUnidadeConsumidora.filter(
                (ucSelect) => !filtrarUsuarioImplantacao || listaUCsFiltradas.find((uc) => uc.id === ucSelect.id)
            );

            dispatch({
                type: "FATURA_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
                payload: {
                    data: listaUnidadeConsumidora,
                },
            });

            let listaFaturas = [];
            const filtroIdCliente = usuarioLogado.acesso_cliente
                ? usuarioLogado.id_cliente
                : getState().fatura.filtro.id_cliente;
            const filtroIdUC = getState().fatura.filtro.id_unidade_consumidora;
            const filtroUCMultiplo = filtrarIdsUnidadesConsumidoras(getState().fatura);
            const filtroAno = getState().fatura.filtro.ano;
            const filtroMes = getState().fatura.filtro.mes;

            const url = `${consts.API_URL}/fatura?id_usuario_logado=${
                usuarioLogado.id
            }&id_cliente=${filtroIdCliente}&id_unidade_consumidora=${filtroIdUC}&ids_unidades_consumidoras=${encodeURIComponent(
                filtroUCMultiplo
            )}&ano=${filtroAno}&mes=${filtroMes}`;
            const resposta = await axios.get(url);
            listaFaturas = resposta.data;

            const listaFaturasFiltrada = filtrarUsuarioImplantacao
                ? listaFaturas.filter((item) =>
                      listaUnidadeConsumidora.find((uc) => item.id_unidade_consumidora === uc.id)
                  )
                : listaFaturas;

            const listaClienteFiltrada = filtrarUsuarioImplantacao
                ? getState().fatura.listaCliente.filter((cliente) =>
                      listaClienteComUC.find((item) => item.id === cliente.id)
                  )
                : getState().fatura.listaCliente;

            dispatch({
                type: "FATURA_CLIENTE_SELECT_LISTADO",
                payload: { data: listaClienteFiltrada },
            });
            dispatch({
                type: "FATURA_LISTADO",
                payload: listaFaturasFiltrada,
            });
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function getTotalFaturaEmpenho() {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));

            const filtroIdCliente = getState().fatura.filtro.id_cliente;
            const filtroUCMultiplo = filtrarIdsUnidadesConsumidoras(getState().fatura);
            const filtroAno = getState().fatura.filtro.ano;

            //se não tiver filtro é pq n selecionou o empenho, então define o total como zero
            if (!filtroIdCliente || !(filtroUCMultiplo || []).length || !filtroAno) {
                return {
                    type: "FATURA_TOTAL_EMPENHO",
                    payload: { data: 0 },
                };
            }

            const url = `${
                consts.API_URL
            }/fatura/totalFaturaEmpenho?id_cliente=${filtroIdCliente}&ids_unidades_consumidoras=${encodeURIComponent(
                filtroUCMultiplo
            )}
			&ano_referencia=${filtroAno}`;
            const resposta = await axios.get(url);
            dispatch({
                type: "FATURA_TOTAL_EMPENHO",
                payload: resposta,
            });
        } catch (erro) {
            // console.log(erro);
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

//retorna uma lista de com os clientes cujo o usuario logado é o implantador
//cada cliente tem apenas as UCs do usuário implantador
function filtrarClientesUsuarioImplantacao(usuarioLogado, listaClientes) {
    const clientesFiltrados = listaClientes.filter((cliente) =>
        cliente.itens.find((uc) => uc.id_usuario_implantacao === usuarioLogado.id)
    );
    if (!clientesFiltrados.length) return [];
    const clientesComUCsFiltradas = clientesFiltrados.map((cliente) => {
        const unidadesConsumidoras = cliente.itens.filter((uc) => {
            return uc.id_usuario_implantacao === usuarioLogado.id;
        });
        return { ...cliente, itens: unidadesConsumidoras };
    });
    return clientesComUCsFiltradas || [];
}

export function buscarPdfFaturaAdicional({ id_fatura, id_produto, nomePdf }) {
    return async (dispatch, getState) => {
        try {
            const request = await axios.get(
                `${consts.API_URL}/fatura/faturaAdicional?id_fatura=${id_fatura}&id_produto=${id_produto}`
            );
            dispatch(setArquivoPDF(request.data, nomePdf, "portrait"));
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export function buscarPdf(id, nomePdf, confirmacao = false) {
    return async (dispatch, getState) => {
        if (confirmacao && !window.confirm("Deseja abrir a fatura em pdf")) return {};
        try {
            const resp = await axios.get(`${consts.API_URL}/fatura/buscarPdf?id=${id}`);
            if (resp.data && resp.data.arquivo_pdf) {
                dispatch(setArquivoPDF(resp.data.arquivo_pdf, nomePdf, "portrait", id));
            }
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export function buscarAnexoRefaturamento(id) {
    return async (dispatch, getState) => {
        try {
            const resp = await axios.get(`${consts.API_URL}/fatura/buscarAnexoRefaturamento?id=${id}`);
            if (resp.data && resp.data.anexo_refaturamento) {
                let base64 = resp.data.anexo_refaturamento;
                const indiceInicioExtensao = base64.indexOf("/") + 1;
                const indiceFimExtensao = base64.indexOf(";");
                const extensao = base64.substring(indiceInicioExtensao, indiceFimExtensao);

                dispatch(setArquivoPDF(resp.data.anexo_refaturamento, `Anexo_Refaturamento.${extensao}`, "portrait", null));
            }
        } catch (ex) {
            setErroAPI(ex);
        }
    };
}

export function imprimirMultiplasFaturas(listaFaturas, listaFaturasSemPdf) {
    return async (dispatch, getState) => {
        try {
            if (!window.confirm("Essa ação pode demorar, dependendo da quantidade de faturas, deseja continuar?")) {
                return {};
            }
            const usuarioLogado = getState().auth.usuarioLogado;
            //seleciona apenas as faturas que possuem arquivo pdf e que forem do cliente logado ou se for um admin
            const faturasComPdf = listaFaturas.filter(
                (item) =>
                    item.arquivo_pdf && (usuarioLogado.id_cliente == item.id_cliente || usuarioLogado.administrador)
            );
            if (!faturasComPdf.length) {
                setError("Nenhuma fatura com PDF na lista");
                return {};
            }
            const ucsSemFatura = listaFaturasSemPdf.map((item) => " " + item.valor);
            if (
                ucsSemFatura.length &&
                !window.confirm(
                    `Existem faturas ainda não disponíveis pela concessionária de energia nessa lista: ${ucsSemFatura}. Deseja continuar mesmo assim?`
                )
            ) {
                return {};
            }
            dispatch(setAguardando(true));
            const ids = faturasComPdf.map((item) => item.id);
            const resp = await axios.get(
                `${consts.API_URL}/fatura/buscarMultiplasFaturasPdf?ids=${encodeURIComponent(ids)}`
            );
            // Array de arquivos PDF em formato Base64
            const listaPdfBase64 = resp.data.map((item) => item.arquivo_pdf);
            const pdfUrl = await combinarPdfRetornarUrl(listaPdfBase64);
            // Abre o URL do PDF combinado em uma nova janela para impressão
            const printWindow = window.open(pdfUrl, "_blank");

            while (!printWindow) {
                await sleep(1000);
            }
            printWindow.focus();
            printWindow.print();
            // Fechar a janela após a impressão
            printWindow.onafterprint = () => {
                printWindow.close();
                URL.revokeObjectURL(pdfUrl); // Liberar o URL de objeto
            };
        } catch (erro) {
            //console.log(erro);
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export function baixarMultiplasFaturasPdf(listaFaturas, listaFaturasSemPdf) {
    return async (dispatch, getState) => {
        try {
            if (
                !window.confirm(
                    "Essa ação pode levar alguns minutos, e após concluída será necessário descompactar o arquivo baixado para acessar todos os PDFs, deseja continuar?"
                )
            ) {
                return {};
            }
            const ano = String(getState().fatura.filtro.ano).substring(2);
            const mes = String(getState().fatura.filtro.mes);
            const usuarioLogado = getState().auth.usuarioLogado;
            //seleciona apenas as faturas que possuem arquivo pdf e que forem do cliente logado ou se for um admin
            const faturasComPdf = listaFaturas.filter(
                (item) =>
                    item.arquivo_pdf && (usuarioLogado.id_cliente == item.id_cliente || usuarioLogado.administrador)
            );
            if (!faturasComPdf.length) {
                setError("Nenhuma fatura com PDF na lista");
                return {};
            }
            const ucsSemFatura = listaFaturasSemPdf.map((item) => " " + item.valor);
            if (
                ucsSemFatura.length &&
                !window.confirm(
                    `Existem faturas ainda não disponíveis pela concessionária de energia nessa lista: ${ucsSemFatura}. Deseja continuar mesmo assim?`
                )
            ) {
                return {};
            }
            dispatch(setAguardando(true));
            const ids = faturasComPdf.map((item) => item.id);
            const idsComNomeArquivo = faturasComPdf.map((item) => {
                return {
                    id: item.id,
                    nome_arquivo: `Fatura_UC-${item.nome_unidade_consumidora.split("-")[0].trim()}_${String(
                        item.competencia
                    ).substring(4, 6)}-${String(item.competencia).substring(0, 4)}.pdf`,
                };
            });

            const resp = await axios.get(
                `${consts.API_URL}/fatura/buscarMultiplasFaturasPdf?ids=${encodeURIComponent(ids)}`
            );
            // //console.log(resp.data);

            // Array de arquivos PDF em formato Base64
            const pdfBase64ArrayComId = resp.data;

            // Crie uma nova instância de JSZip
            const zip = new JSZip();

            // Função para baixar múltiplos PDFs em formato Base64
            pdfBase64ArrayComId.forEach((item) => {
                const { id, arquivo_pdf } = item;
                // Converta o Base64 para um blob
                const pdfBlob = base64ToBlob(arquivo_pdf);
                const objArquivo = idsComNomeArquivo.find((elemento) => elemento.id == id);
                if (objArquivo) {
                    const nomeArquivo = objArquivo.nome_arquivo;
                    // Adicione o blob ao ZIP com um nome de arquivo específico
                    zip.file(nomeArquivo, pdfBlob);
                }
            });
            // Gere o conteúdo do ZIP como um blob
            const zipBlob = await zip.generateAsync({ type: "blob" });

            // Salve o arquivo ZIP usando file-saver
            saveAs(zipBlob, `faturas_${mes}_${ano}.zip`);
            await dispatch(incluirDownloadRealizado(ids));
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export async function incluirDownloadRealizado(id_faturas) {
    return async (dispatch, getState) => {
        try {
            const id_usuario = parseInt((getState().auth.usuarioLogado || {}).id);
            if (!id_usuario || !(id_faturas || []).length || (id_faturas || []).find((id) => isNaN(parseInt(id)))) {
                throw new Error('Não foi possível alterar a situação da fatura para "Donwload Realizado"');
            }
            dispatch(setAguardando(true));
            await axios.put(`${consts.API_URL}/fatura/incluirDownloadRealizado`, { id_faturas, id_usuario });
            setSuccess("Download realizado");
            await dispatch(getLista());
        } catch (error) {
            setErroAPI(error);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export async function excluirDownloadRealizado(idFatura) {
    return async (dispatch, getState) => {
        try {
            const id_usuario = parseInt((getState().auth.usuarioLogado || {}).id);
            const id_fatura = parseInt(idFatura);
            if (!id_usuario || !id_fatura) {
                throw new Error('Não foi possível alterar a situação da fatura para "Donwload Pendente"');
            }
            dispatch(setAguardando(true));
            if (!window.confirm('Deseja realmente definir a fatura como "Download Pendente"')) return {};
            await axios.put(`${consts.API_URL}/fatura/excluirDownloadRealizado`, { id_fatura, id_usuario });
            setSuccess('Situação da fatura alterada para "Download Pendente"');
            await dispatch(getLista());
        } catch (error) {
            setErroAPI(error);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export async function alterarAliquotaIcmsImportada(registro = {}, callback) {
    return async (dispatch, getState) => {
        let { id, icms_importado } = registro;
        icms_importado = String(icms_importado).replace(",", ".");
        const confirmado = window.confirm("Deseja realmente alterar a alíquota do ICMS(%) importada na fatura?");
        try {
            if (!id || isNaN(parseFloat(icms_importado)) || !confirmado) {
                setError("Alíquota de ICMS inválida");
                return {};
            }
            dispatch(setAguardando(true));
            const resp = await axios.put(`${consts.API_URL}/fatura/aliquotaIcmsImportada`, { id, icms_importado });
            setSuccess("Alíquota do ICMS alterada com sucesso");
            dispatch(
                initForm({
                    ...registro,
                    icms_importado: resp.data.icms_importado,
                })
            );
        } catch (error) {
            setErroAPI(error);
        } finally {
            dispatch(setAguardando(false));
            callback();
        }
    };
}
export async function alterarValorIcmsImportado(registro = {}, callback) {
    return async (dispatch, getState) => {
        let { id, valor_icms } = registro;
        valor_icms = String(valor_icms).replace(",", ".");
        const confirmado = window.confirm("Deseja realmente alterar o valor do ICMS(R$) importado na fatura?");
        try {
            if (!id || isNaN(parseFloat(valor_icms)) || !confirmado) {
                setError("Valor do ICMS inválido");
                return {};
            }
            dispatch(setAguardando(true));
            const resp = await axios.put(`${consts.API_URL}/fatura/valorIcmsImportado`, { id, valor_icms });
            setSuccess("Valor do ICMS alterado com sucesso");
            dispatch(
                initForm({
                    ...registro,
                    valor_icms: resp.data.valor_icms,
                })
            );
        } catch (error) {
            setErroAPI(error);
        } finally {
            dispatch(setAguardando(false));
            callback();
        }
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        if (!registro.id) {
            dispatch(setAguardando(true));

            axios
                .post(`${consts.API_URL}/fatura`, {
                    ...registro,
                    competencia:
                        (registro.competencia || "").split("/")[0] &&
                        ((registro.competencia || "").split("/")[0].match(/\d+/g) || []).join("").trim().length == 2 &&
                        (registro.competencia || "").split("/")[1] &&
                        ((registro.competencia || "").split("/")[1].match(/\d+/g) || []).join("").trim().length == 4
                            ? `${(registro.competencia || "").split("/")[1]}${
                                  (registro.competencia || "").split("/")[0]
                              }`
                            : null,
                    erros_engenharia: JSON.stringify(registro.erros_engenharia),
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(
                        initForm({
                            ...registro,
                            ...resp.data,
                            competencia: registro.competencia,
                        })
                    );
                    dispatch(setAguardando(false));
                })
                .catch((e) => {
                    setErroAPI(e);
                    dispatch(setAguardando(false));
                });
        } else {
            confirmDialog("Deseja realmente alterar?", () => {
                dispatch(setAguardando(true));

                axios
                    .put(`${consts.API_URL}/fatura`, {
                        ...registro,
                        competencia:
                            (registro.competencia || "").split("/")[0] &&
                            ((registro.competencia || "").split("/")[0].match(/\d+/g) || []).join("").trim().length ==
                                2 &&
                            (registro.competencia || "").split("/")[1] &&
                            ((registro.competencia || "").split("/")[1].match(/\d+/g) || []).join("").trim().length == 4
                                ? `${(registro.competencia || "").split("/")[1]}${
                                      (registro.competencia || "").split("/")[0]
                                  }`
                                : null,
                        erros_engenharia: JSON.stringify(registro.erros_engenharia),
                        valor_fatura: parseFloat(String(registro.valor_fatura).replace(",", ".")) || 0,
                    })
                    .then((resp) => {
                        setSuccess("Operação Realizada com sucesso.");
                        dispatch(getLista());
                        dispatch(setModoTela("lista"));
                        dispatch(setAguardando(false));
                    })
                    .catch((e) => {
                        setErroAPI(e);
                        dispatch(setAguardando(false));
                    });
            });
        }
    };
}

export function salvarMultiplos(registro) {
    return async (dispatch, getState) => {
        if (!registro.id_cliente) {
            setError("Cliente não informado");
            return;
        }
        if (!registro.id_unidade_consumidora) {
            setError("Unidade Consumidora não informada");
            return;
        }
        if (!registro.competencia_inicial) {
            setError("Competência inicial não informada");
            return;
        }
        let periodoInicial = new Date(
            `${registro.competencia_inicial.substring(3, 7)}/${registro.competencia_inicial.substring(0, 2)}/01`
        );
        if (!periodoInicial || periodoInicial == "Invalid Date") {
            setError("Competência inicial inválida");
            return;
        }
        if (!registro.competencia_final) {
            setError("Competência final não informada");
            return;
        }
        let periodoFinal = new Date(
            `${registro.competencia_final.substring(3, 7)}/${registro.competencia_final.substring(0, 2)}/01`
        );
        if (!periodoFinal || periodoFinal == "Invalid Date") {
            setError("Competência final inválida");
            return;
        }

        if (
            parseInt(
                `${periodoInicial.getFullYear()}${periodoInicial.getMonth() < 9 ? "0" : ""}${
                    periodoInicial.getMonth() + 1
                }`
            ) >
            parseInt(
                `${periodoFinal.getFullYear()}${periodoFinal.getMonth() < 9 ? "0" : ""}${periodoFinal.getMonth() + 1}`
            )
        ) {
            setError("Competência inicial não pode ser maior que a final");
            return;
        }

        dispatch(setAguardando(true));

        let competenciaAtual = null;
        let competenciaFinal = `${(registro.competencia_final || "").split("/")[1]}${
            (registro.competencia_final || "").split("/")[0]
        }`;
        while (competenciaAtual != competenciaFinal) {
            competenciaAtual = `${periodoInicial.getFullYear()}${periodoInicial.getMonth() < 9 ? "0" : ""}${
                periodoInicial.getMonth() + 1
            }`;

            await axios
                .post(`${consts.API_URL}/fatura`, {
                    ...registro,
                    competencia: competenciaAtual,
                })
                .then((resp) => {})
                .catch((e) => {
                    setErroAPI(e);
                });

            periodoInicial.setMonth(periodoInicial.getMonth() + 1);
        }

        setSuccess("Operação Realizada com sucesso.");
        dispatch(getLista());
        dispatch(setModoTela("lista"));
        dispatch(setAguardando(false));
    };
}

//CHAMAR A ACTION NO BOTAO [$] e configurar rota no backend
//para atualizar a data de pagamento para o dia atual
export function setDataPagamento(fatura) {
    const { id, dados_fatura, ...registro } = fatura;

    return (dispatch, getState) => {
        const modoExclusao = getState().fatura.modoTela === "excluirDataPagamento";
        if (
            modoExclusao &&
            window.confirm(
                "Se remover a data de pagamento a fatura vai retornar o status para não pago. Deseja realmente alterar o status de pagamento?"
            )
        ) {
            registro.data_pagamento = null;
            registro.comprovantes_pagamento = null;

            axios
                .put(`${consts.API_URL}/fatura/definirDataPagamento${fatura.id ? `?id=${id}` : ""}`, registro)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
        if (!modoExclusao) {
            axios
                .put(`${consts.API_URL}/fatura/definirDataPagamento${fatura.id ? `?id=${id}` : ""}`, registro)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}

export function setAlertaVerificado(fatura) {
    const { id } = fatura;

    return (dispatch, getState) => {
        if (!window.confirm('Deseja realmente marcar o alerta como "Revisado"?')) return {};
        axios
            .put(`${consts.API_URL}/fatura/definirAlertaVerificado${id ? `?id=${id}` : ""}`)
            .then((resp) => {
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getLista());
                dispatch(setModoTela("lista"));
            })
            .catch((e) => {
                setErroAPI(e);
            });
    };
}

//retorna a cor da data de vencimento na tela de listagem de faturas do cliente
export function getCorDataVencimentoFatura(fatura, usuarioLogado) {
    const faturaIsenta = fatura.fatura_isenta;
    const foiImportada = fatura.arquivo_pdf && (!fatura.erros_importacao.length || fatura.importacao_validada);
    //se o cliente não controla vencimento deixa todas brancas
    if (
        (usuarioLogado.acesso_cliente && !fatura.controla_vencimento_fatura) ||
        (!foiImportada && !fatura.data_pagamento)
    )
        return { backgroundColorTr: "rgb(255, 255, 255)", backgroundColorTd: "rgb(245, 245, 245)" };
    //se estiver paga retorna verde
    if (fatura.data_pagamento || faturaIsenta)
        return { backgroundColorTr: "rgb(221, 255, 221)", backgroundColorTd: "rgb(68, 255, 170)" };
    if (!fatura.data_vencimento)
        return {
            backgroundColorTr: "rgb(243 255 255)",
            backgroundColorTd: "rgb(197 255 255)",
        };

    if (fatura.data_vencimento) {
        const doisDiasMs = 1000 * 60 * 60 * 24 * 2;
        const dataVencimentoMs = new Date(fatura.data_vencimento).getTime();
        const data2DiasParaVencimentoMs = new Date(dataVencimentoMs - doisDiasMs).getTime();
        const dataAtual = new Date();
        dataAtual.setHours(0, 0, 0, 0);
        const dataAtualMs = dataAtual.getTime();

        //cinza se faltar 3 dias ou mais para o vencimento
        //OU SE NÃO ESTIVER PAGA MAS TIVER A COMPETÊNCIA ANTERIOR A DATA DE IMPLANTAÇÃO DA ROTINA
        // if (dataAtualMs < data2DiasParaVencimentoMs)
        if (dataAtualMs < data2DiasParaVencimentoMs || fatura.competencia <= 202309)
            return {
                backgroundColorTr: "rgb(243 255 255)",
                backgroundColorTd: "rgb(197 255 255)",
            };

        //amarela quando falta 2 ou 1 dia para o vencimento
        if (dataAtualMs >= data2DiasParaVencimentoMs && dataAtualMs < dataVencimentoMs)
            return {
                backgroundColorTr: "rgb(255 255 212)",
                backgroundColorTd: "rgb(255 239 113)",
            };

        //vermelha se esta no dia do vencimento ou se já está vencida
        if (dataAtualMs >= dataVencimentoMs)
            return {
                backgroundColorTr: "rgb(251 202 202)",
                backgroundColorTd: "rgb(255 101 105)",
            };
    }
}

export function excluir(registro) {
    return (dispatch) => {
        confirmDialog("Deseja realmente excluir?", () => {
            axios
                .delete(`${consts.API_URL}/fatura?id=${registro.id}`)
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function setSubsidioIrriganteVerificado(registro) {
    return (dispatch) => {
        confirmDialog('Deseja realmente marcar o registro como "Verificado" ?', () => {
            axios
                .put(`${consts.API_URL}/fatura/verificarSubsidioIrrigante`, { id: registro.id })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function setIcmsVerificado(registro) {
    return (dispatch) => {
        confirmDialog('Deseja realmente marcar o registro como "Verificado" ?', () => {
            axios
                .put(`${consts.API_URL}/fatura/verificarIcms`, { id: registro.id })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function setIdStatusSubsidioIrrigante(registro) {
    //TABELA STATUS
    //================================================================
    //5 - "Subsídio Irrigante Correto"____________________BRANCO
    //6 - "Verifique Subsídio Irrigante"__________________VERMELHO
    //7 - "Correção de Consumo Reservado Encaminhada"_____AZUL
    //8 - "Subsídio Irrigante Corrigido"__________________VERDE

    //valida se o original era vermelho
    if (registro.id_status_subsidio_irrigante != 6) {
        setError("Status inválido");
        return {};
    }

    return (dispatch) => {
        confirmDialog('Deseja realmente alterar o status para "Correção de Consumo Reservado Encaminhada"" ?', () => {
            axios
                .put(`${consts.API_URL}/fatura/alterarIdStatusSubsidioIrrigante`, {
                    id: registro.id,
                    id_status_subsidio_irrigante: 7,
                }) //altera para azul
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function setIdStatusIcms(registro) {
    //TABELA STATUS
    //1 - "ICMS Correto"  ________________________________BRANCO
    //2 - "Verifique ICMS" _______________________________VERMELHO
    //3 - "Correção de ICMS Encaminhada"__________________AZUL
    //4 - "ICMS Corrigido"________________________________VERDE

    //valida se o original era vermelho
    if (registro.id_status_icms != 2) {
        setError("Status inválido");
        return {};
    }

    return (dispatch) => {
        confirmDialog('Deseja realmente alterar o status para "Correção de ICMS Encaminhada"" ?', () => {
            axios
                .put(`${consts.API_URL}/fatura/alterarIdStatusIcms`, { id: registro.id, id_status_icms: 3 })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        });
    };
}

export function setImportacaoValidada(registro) {
    return (dispatch, getState) => {
        const itensImportados = getState().faturaItem.lista || [];

        if (!itensImportados.length) {
            setError("É necessário importar os itens antes de validar a importação");
            return {};
        }

        if (registro.erros_importacao.length > 0 && !registro.observacao_importacao && registro.importacao_validada) {
            setError("Informe a observação da importação");
            return {};
        }

        let engenhariaErros = [];
        if (registro.importacao_validada) {
            let valorErros = 0;
            let nivel = 0;
            itensImportados
                .filter((item) => item.id_tipo == 12 || item.id_tipo == 13 || item.id_tipo == 14)
                .forEach((item) => {
                    valorErros += parseFloat(item.valor_total);
                });

            if (valorErros > 0) {
                if (valorErros <= 200) {
                    //amarelo
                    nivel = 1;
                } else if (valorErros <= 500) {
                    //laranja
                    nivel = 2;
                } else if (valorErros <= 1000) {
                    //vermelho
                    nivel = 3;
                } else {
                    //roxo
                    nivel = 4;
                }
            }

            //Demanda Complementar
            if (itensImportados.filter((item) => item.id_tipo == 12).length > 0) {
                engenhariaErros.push({
                    nivel: nivel,
                    tipo: "Demanda Complementar",
                    mensagem: "Demanda complementar foi identificada",
                });
            }
            //Demanda Ultrapassagem
            if (itensImportados.filter((item) => item.id_tipo == 13).length > 0) {
                engenhariaErros.push({
                    nivel: nivel,
                    tipo: "Demanda Ultrapassagem",
                    mensagem: "Demanda ultrapassagem foi identificada",
                });
            }
            //Consumo Reativo
            if (itensImportados.filter((item) => item.id_tipo == 14).length > 0) {
                engenhariaErros.push({
                    nivel: nivel,
                    tipo: "Consumo Reativo",
                    mensagem: "Consumo reativo foi identificado",
                });
            }
        }

        axios
            .put(`${consts.API_URL}/fatura`, {
                ...registro,
                competencia:
                    (registro.competencia || "").split("/")[0] &&
                    ((registro.competencia || "").split("/")[0].match(/\d+/g) || []).join("").trim().length == 2 &&
                    (registro.competencia || "").split("/")[1] &&
                    ((registro.competencia || "").split("/")[1].match(/\d+/g) || []).join("").trim().length == 4
                        ? `${(registro.competencia || "").split("/")[1]}${(registro.competencia || "").split("/")[0]}`
                        : null,
                erros_engenharia: JSON.stringify(engenhariaErros),
                engenharia_validada: false,
                valor_fatura: parseFloat(String(registro.valor_fatura).replace(",", ".")) || null,
            })
            .then((resp) => {
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getLista());
                dispatch(
                    initForm({
                        ...registro,
                        importacao_validada: registro.importacao_validada,
                        erros_engenharia: engenhariaErros,
                        engenharia_validada: false,
                    })
                );
            })
            .catch((e) => {
                setErroAPI(e);
            });
    };
}

export function setEngenhariaValidada(registro) {
    return (dispatch, getState) => {
        if (registro.erros_engenharia.length > 0 && !registro.observacao_engenharia) {
            setError("Informe a observação da análise de engenharia");
            return;
        }

        axios
            .put(`${consts.API_URL}/fatura`, {
                ...registro,
                competencia:
                    (registro.competencia || "").split("/")[0] &&
                    ((registro.competencia || "").split("/")[0].match(/\d+/g) || []).join("").trim().length == 2 &&
                    (registro.competencia || "").split("/")[1] &&
                    ((registro.competencia || "").split("/")[1].match(/\d+/g) || []).join("").trim().length == 4
                        ? `${(registro.competencia || "").split("/")[1]}${(registro.competencia || "").split("/")[0]}`
                        : null,
                erros_engenharia: JSON.stringify(registro.erros_engenharia),
            })
            .then((resp) => {
                setSuccess("Operação Realizada com sucesso.");
                dispatch(getLista());
                dispatch(
                    initForm({
                        ...registro,
                    })
                );
            })
            .catch((e) => {
                setErroAPI(e);
            });
    };
}

export function getListaCliente() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const request = axios.get(`${consts.API_URL}/cliente/listarSelect?id_usuario_logado=${usuarioLogado.id}`);
            dispatch({
                type: "FATURA_CLIENTE_SELECT_LISTADO",
                payload: request,
            });
        } catch (error) {
            setErroAPI(error);
        }
    };
}

export function getListaUnidadeConsumidora() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            const request = axios.get(
                `${consts.API_URL}/unidadeConsumidora/listarSelect?id_usuario_logado=${usuarioLogado.id}`
            );
            dispatch({
                type: "FATURA_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
                payload: request,
            });
        } catch (e) {
            setErroAPI(e);
        }
    };
}

export function getListaConcessionaria() {
    const request = axios.get(`${consts.API_URL}/concessionaria/listarSelect`);
    return {
        type: "FATURA_CONCESSIONARIA_SELECT_LISTADO",
        payload: request,
    };
}
export function getListaProdutoTipo() {
    const request = axios.get(`${consts.API_URL}/produtoTipo/listarSelect`);
    return {
        type: "FATURA_PRODUTO_TIPO_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaGraficoCusto(callback) {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            if (!usuarioLogado) return {};

            dispatch({
                type: "FATURA_GRAFICO_CUSTO_LISTADO",
                payload: { data: [] },
            });

            const idCliente = getState().fatura.registro.id_cliente || 0;
            const idUc = getState().fatura.registro.id_unidade_consumidora || 0;

            const resp = await axios.get(
                `${consts.API_URL}/fatura/listarGraficoCusto?id_usuario_logado=${usuarioLogado.id}&id_cliente=${idCliente}&id_unidade_consumidora=${idUc}`
            );
            dispatch({
                type: "FATURA_GRAFICO_CUSTO_LISTADO",
                payload: resp,
            });
        } catch (e) {
        } finally {
            callback();
        }
    };
}

export function getListaGraficoCustoPorUnidade(callback) {
    return (dispatch, getState) => {
        dispatch({
            type: "FATURA_GRAFICO_CUSTO_UNIDADE_LISTADO",
            payload: { data: [] },
        });

        axios
            .get(
                `${consts.API_URL}/fatura/listarGraficoCustoPorUnidade?
			id_cliente=${getState().fatura.registro.id_cliente || 0}`
            )
            .then((resp) => {
                dispatch({
                    type: "FATURA_GRAFICO_CUSTO_UNIDADE_LISTADO",
                    payload: resp,
                });
                callback();
            })
            .catch((ex) => {
                callback();
            });
    };
}

export function extrairDadosPDF(id) {
    return async (dispatch, getState) => {
        try {
            const resp1 = await axios.get(`${consts.API_URL}/fatura/buscarPdf?id=${id}`);
            if (!resp1.data || !resp1.data.arquivo_pdf) {
                setError("Fatura não encontrada");
                return {};
            }

            const resp2 = await axios.post(`${consts.API_URL}/fatura/extrairDadosPDF`, {
                id_fatura: id,
                base64: resp1.data.arquivo_pdf,
            });
            if (resp2.data && resp2.data.codigoBarras) {
                window.alert(`Código de Barras: \n${resp2.data.codigoBarras}`);
            } else {
                window.alert("Código de Barras não encontrado");
            }
        } catch (error) {
            setErroAPI(error);
        }
    };
}
export async function gerarRelatorio({ html, landscape, scale }) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const response = await axios.post(`${consts.API_URL}/relatorio`, { html, landscape, scale });
            const pdfBase64 = response.data;
            dispatch(setArquivoPDF(pdfBase64, "Relatório.pdf", "landscape"));
            dispatch(setAguardando(false));
        } catch (erro) {
            setError("Erro ao gerar o relatório");
        }
    };
}

export async function gerarXlsxFaturaCliente({ nomeArquivo, dados }) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            const response = await axios.post(
                `${consts.API_URL}/gerarXlsxFaturaCliente`,
                { nomeArquivo, dados },
                { responseType: "blob" }
            );

            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `${nomeArquivo}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            dispatch(setAguardando(false));
        } catch (erro) {
            setError("Erro ao gerar o relatório");
        }
    };
}
