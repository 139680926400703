import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import Button from "../common/button/button";
import Form from "./geracaoIntegracaoForm";
import Select from "../common/form/select";
import { adicionarReticencias } from "../utils/adicionarReticencias";
import {
    setModoTela,
    initForm,
    salvar,
    excluir,
    setFiltro,
    getLista,
    getListaCliente,
    getListaUnidadeConsumidora,
    getListaGeracaoIntegracaoTipo,
} from "./geracaoIntegracaoActions";
import { Loading } from "../common/loading/loading";

class GeracaoIntegracao extends Component {
    state = {
        listaInicial: [],
    };
    componentWillMount() {
        this.props.getListaCliente();
        this.props.getListaGeracaoIntegracaoTipo();
        this.props.getListaUnidadeConsumidora();
        this.props.getLista();
    }

    componentDidUpdate() {
        if (!this.state.listaInicial.length && this.props.lista.length) {
            this.setState({ listaInicial: [...this.props.lista] });
        }
    }

    render() {
        //Sem acesso
        if (!this.props.usuarioLogado || this.props.usuarioLogado.acesso_cliente) {
            return <div />;
        }

        return (
            <div>
                {this.props.aguardando ? <Loading /> : null}

                <Content>
                    {this.props.modoTela === "visualizacao" ? <Form visualizar onSubmit={() => {}} /> : null}
                    {this.props.modoTela === "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
                    {this.props.modoTela === "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
                    {this.props.modoTela === "lista" ? this.lista() : null}
                </Content>
            </div>
        );
    }

    lista() {
        function formatarDadosAPI(item) {
            //se for solis separa os atributos do JSON para aparecer um em cada campo separado, no Form
            if (item.id_geracao_integracao_tipo == 12) {
                const registro = {
                    ...item,
                    id_chave_api: item.chave_api && JSON.parse(item.chave_api).KeyId,
                    segredo_chave_api: item.chave_api && JSON.parse(item.chave_api).KeySecret,
                    url_api: item.chave_api && JSON.parse(item.chave_api).APIURL,
                };
                return registro;
            }
            return item;
        }
        return (
            <ContentCard>
                <ContentCardHeader>
                    <Row>
                        <Grid cols="6 6 4 2">
                            <Button
                                text="Adicionar"
                                type={"success"}
                                icon={"fa fa-plus"}
                                disabled={!this.props.usuarioLogado.acesso_solar_editar}
                                event={() => {
                                    this.props.setModoTela("cadastro", {});
                                    this.props.initForm({});
                                }}
                            />
                        </Grid>
                    </Row>
                </ContentCardHeader>
                <ContentCardBody>
                    <Row>
                        <Select
                            name="id_cliente"
                            options={this.props.listaCliente.filter((cliente) =>
                                this.state.listaInicial.find((gi) => gi.id_cliente == cliente.id)
                            )}
                            label="Cliente"
                            cols="12 6 4"
                            placeholder="Selecione o Cliente"
                            value={this.props.filtro.id_cliente}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_cliente: value,
                                });
                                this.props.getLista();
                            }}
                        />
                        <Select
                            name="id_unidade_consumidora"
                            options={this.props.listaUnidadeConsumidora.filter((uc) => {
                                const filtroCliente =
                                    !this.props.filtro.id_cliente || this.props.filtro.id_cliente == uc.id_cliente;
                                const estaNaLista = this.state.listaInicial.find(
                                    (item) => item.id_unidade_consumidora == uc.id
                                );
                                return filtroCliente && estaNaLista;
                            })}
                            label="Unidade Consumidora"
                            cols="12 6 4"
                            placeholder="Selecione a UC"
                            value={this.props.filtro.id_unidade_consumidora}
                            onChange={(value) => {
                                this.props.setFiltro({
                                    ...this.props.filtro,
                                    id_unidade_consumidora: value,
                                });
                                this.props.getLista();
                            }}
                        />
                    </Row>
                    <Table responsive>
                        <THead>
                            <NewTr backgroundColorTr={"#FFFFFF"} backgroundColorTd={"#F5F5F5"}>
                                <Th sticky>Cliente</Th>
                                <Th sticky>UC</Th>
                                <Th sticky>Tipo</Th>
                                <Th sticky>Usuário</Th>
                                <Th sticky>Senha</Th>
                                <Th sticky>Chave da API</Th>
                                <Th sticky>Link/Nome da instalação</Th>
                            </NewTr>
                        </THead>
                        <TBody>
                            {this.props.lista.map((item) => {
                                return (
                                    <NewTr
                                        key={item.id}
                                        backgroundColorTr={"#FFFFFF"}
                                        backgroundColorTd={"#F5F5F5"}
                                        botoes={[
                                            {
                                                type: "primary",
                                                icon: "fas fa-eye",
                                                disabled: !this.props.usuarioLogado.acesso_solar_visualizar,
                                                event: () => {
                                                    const registro = formatarDadosAPI(item);
                                                    this.props.setModoTela("visualizacao", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                            {
                                                type: "warning",
                                                icon: "fas fa-pencil-alt",
                                                disabled:
                                                    !this.props.usuarioLogado.acesso_solar_editar ||
                                                    item.bloquearEdicaoUsuarioImplantacao,
                                                event: () => {
                                                    const registro = formatarDadosAPI(item);
                                                    this.props.setModoTela("cadastro", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                            {
                                                type: "danger",
                                                icon: "fas fa-trash-alt",
                                                disabled:
                                                    !this.props.usuarioLogado.acesso_solar_editar ||
                                                    item.bloquearEdicaoUsuarioImplantacao,
                                                event: () => {
                                                    const registro = formatarDadosAPI(item);
                                                    this.props.setModoTela("exclusao", registro);
                                                    this.props.initForm(registro);
                                                },
                                            },
                                        ]}
                                    >
                                        <Td maxWidth={200}>{item.nome_cliente}</Td>
                                        <Td maxWidth={230} paddingRight={10}>
                                            {item.nome_unidade_consumidora}
                                        </Td>
                                        <Td>{item.tipo}</Td>
                                        <Td title={item.usuario}>{adicionarReticencias(item.usuario)}</Td>
                                        <Td>{item.senha}</Td>
                                        <Td maxWidth={200} alignCenter title={item.chave_api}>
                                            {adicionarReticencias(item.chave_api)}
                                        </Td>
                                        <Td maxWidth={200} alignCenter title={item.id_sistema_fotovoltaico}>
                                            {adicionarReticencias(item.id_sistema_fotovoltaico)}
                                        </Td>
                                    </NewTr>
                                );
                            })}
                        </TBody>
                    </Table>
                </ContentCardBody>
            </ContentCard>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    usuarioLogado: state.auth.usuarioLogado,
    modoTela: state.geracaoIntegracao.modoTela,
    formularioValues: getFormValues("geracaoIntegracaoForm")(state),
    aguardando: state.geracaoIntegracao.aguardando,
    filtro: state.geracaoIntegracao.filtro,
    lista: state.geracaoIntegracao.lista,
    listaCliente: state.geracaoIntegracao.listaCliente,
    listaUnidadeConsumidora: state.geracaoIntegracao.listaUnidadeConsumidora,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setModoTela,
            initForm,
            setFiltro,
            salvar,
            excluir,
            getLista,
            getListaCliente,
            getListaUnidadeConsumidora,
            getListaGeracaoIntegracaoTipo,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(GeracaoIntegracao);
